import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
  bottom: 30px;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  border-collapse: separate;
  border-spacing: 2px;
  width: 100%;
  min-width: max-content;
`;

export const TableRowHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  white-space: nowrap;
  align-items: end;
  height: max-content;
  padding-top: 20px;
  margin-bottom: 10px;
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 2;
  box-shadow: 0px 23px 9px -20px rgba(145, 145, 145, 0.54);
`;

export const TableRowBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 56px;
  margin: 0;
  white-space: nowrap;
  align-items: center;
`;

export const FixedTableRow = styled.div`
  display: flex;
  position: sticky;
  left: 0;
  white-space: nowrap;
  background-color: #ffffff;
  z-index: 1;
  .col-12 {
    height: 0px;
    padding: 0;
    margin: 0;
  }
  input,
  select {
    border: 1px solid #ffffff;
    height: 36px !important;
    border-radius: 4px;
    font-size: 16px;
  }
`;

export const TableRow = styled.div`
  display: flex;
  white-space: nowrap;
  .col-12 {
    height: 0px;
    padding: 0;
    margin: 0;
  }
  .col-12 .row {
    margin: 0 !important;
  }
  input,
  select {
    border: 1px solid #ffffff;
    height: 36px !important;
    border-radius: 4px;
    height: 36px;
  }
`;
