import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useFormContext, useWatch } from 'react-hook-form';

import { Col, FormControlProps } from 'react-bootstrap';

import { CertificateState } from 'enum/certificateDataState.enum';
import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';
import { CertificateDataValuesTableRowProps } from 'interfaces/CertificateData';
import {
  CertTableStruct,
  FieldAffects,
  FormOptions,
} from 'interfaces/CertificateTemplate';

import { Combo } from '..';
import { FormControl } from '../styles';
import { CommonProps } from '../Types';
import * as S from './styles';

interface TableFixedProps extends FormControlProps, CommonProps {
  tableStructure: CertTableStruct[];
  valueTable: CertificateDataValuesTableRowProps[];
  affects?: FieldAffects[];
  options?: FormOptions[];
}

const TableFixed: FC<TableFixedProps> = ({
  xs,
  fieldId,
  disabled,
  affects,
  options,
  tableStructure,
  valueTable,
  autoObservations,
}) => {
  const {
    handleAffects,
    handleAutoObservations,
    autoSave,
    certOptions,
    certData,
  } = useCerts();
  const { control, getValues, setValue } = useFormContext();
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(false);
  const comboOptions = useMemo(() => {
    if (certOptions) {
      return certOptions[fieldId] ? certOptions[fieldId] : options;
    }
  }, [certOptions, options]);

  const currentValue = useWatch({
    control,
    name: fieldId,
  });

  useEffect(() => {
    if (
      disabled ||
      !autoSaveEnabled ||
      certData?.certificateState === CertificateState.ISSUED
    )
      return;
    // eslint-disable-next-line prefer-const
    let timeoutId: any;

    // clear the previous timeout
    timeoutId && clearTimeout(timeoutId);

    // set a new timeout of 5 seconds
    timeoutId = setTimeout(() => {
      // call the API

      autoSave({
        type: FieldType.TableFixed,
        fieldId,
        value: currentValue,
        getValues,
        setValue,
      });
      if (affects) {
        handleAffects({
          fieldId,
          type: FieldType.TableFixed,
          affects,
          newVal: currentValue,
          getValues,
          setValue,
        });
      }
      if (autoObservations) {
        handleAutoObservations({
          fieldId,
          autoObservations,
          getValues,
          setValue,
        });
      }
    }, 1000);

    // clean up the timeout when the component unmounts or the `currentValue` changes
    return () => clearTimeout(timeoutId);
  }, [currentValue]);

  return (
    <Fragment>
      <Col xs={xs}>
        <S.TableCertificateTableHeader>
          <S.TableHeaderText>
            {tableStructure.map(
              ({ keyColumn, columnSize, columnName }, index) => (
                <Fragment key={keyColumn + 'tableth'}>
                  <S.TableTH
                    columnSize={columnSize}
                    lastColumn={index === tableStructure.length - 1}
                  >
                    {columnName}
                  </S.TableTH>
                  {index !== tableStructure.length - 1 && (
                    <S.TableHeaderDivider></S.TableHeaderDivider>
                  )}
                </Fragment>
              )
            )}
          </S.TableHeaderText>
        </S.TableCertificateTableHeader>
      </Col>
      <S.TableBody>
        {valueTable?.map((row) => {
          const rowKey = fieldId + '-row-field-item-' + row.index;

          return (
            <Fragment key={rowKey}>
              {/* // Show sub header row it's exists */}
              {row.isSubHeader && (
                <S.TableTDSubHeader
                  columnSize={'100%'}
                  hasSize={'0'}
                  firstColumn={true}
                >
                  {tableStructure.reduce<JSX.Element[]>(
                    (acc, struct, index) => {
                      const subHeaderKey =
                        rowKey +
                        'sub-header-column' +
                        struct.columnName +
                        index;
                      const cloumn = row.columns.find(
                        (coloumn) => coloumn.index === index
                      );

                      if (cloumn) {
                        acc.push(
                          <S.TableTDSubHeaderSpan
                            key={subHeaderKey}
                            roundedLeft={index === 0}
                            roundedRight={index === row.columns.length - 1}
                            columnSize={struct.columnSize}
                            justifyContent={index > 0 ? 'left' : 'center'}
                            className="tableItemOption grayed"
                          >
                            {cloumn?.value ?? ''}
                          </S.TableTDSubHeaderSpan>
                        );
                      }
                      return acc;
                    },
                    []
                  )}
                  <FormControl name={`${fieldId}.${row.index}`} type="hidden" />
                </S.TableTDSubHeader>
              )}

              {/* // Show normal rows */}
              {!row.isSubHeader && (
                <>
                  <S.TableRow>
                    {tableStructure.map(
                      (
                        { columnSize, columnType, options: fieldOptions },
                        columnIndex
                      ) => {
                        const tableColumnKey =
                          rowKey + 'column-key' + columnIndex;
                        const isFirstColumn = columnIndex === 0;
                        const { value } = row.columns[columnIndex] || {};

                        return (
                          <Fragment key={tableColumnKey}>
                            <S.TableTD columnSize={columnSize}>
                              {columnType === 'auto-number' && (
                                <span
                                  className={clsx('tableAutoNumOption', {
                                    disabled,
                                  })}
                                >
                                  {value}
                                </span>
                              )}
                              {columnType === 'string' && (
                                <span
                                  className={clsx(
                                    'tableItemOption grayed',
                                    { 'first-column': isFirstColumn },
                                    {
                                      disabled,
                                    }
                                  )}
                                >
                                  {value}
                                </span>
                              )}
                              {columnType === 'combo' && (
                                <Combo
                                  onlyInput
                                  xs={12}
                                  height="100%"
                                  label=""
                                  isCertificate={false}
                                  disabled={disabled}
                                  fieldId={`${fieldId}.${row.index}.columns.${columnIndex}.value`}
                                  options={comboOptions ?? fieldOptions}
                                  additionalOnChange={() => {
                                    setAutoSaveEnabled(true);
                                    const current = getValues(
                                      `${fieldId}.${row.index}.columns.${columnIndex}`
                                    );
                                    if (!current.index) {
                                      setValue(
                                        `${fieldId}.${row.index}.columns.${columnIndex}.index`,
                                        columnIndex
                                      );
                                      setValue(
                                        `${fieldId}.${row.index}.columns.${columnIndex}.valueType`,
                                        'string'
                                      );
                                    }
                                  }}
                                />
                              )}
                            </S.TableTD>
                          </Fragment>
                        );
                      }
                    )}
                  </S.TableRow>
                  {row.columns[1].auxLabel && (
                    <S.TableRow>
                      <S.TableTD
                        columnSize={tableStructure[0].columnSize}
                      ></S.TableTD>
                      <S.TableTD columnSize={tableStructure[1].columnSize}>
                        <S.FixedTableAuxLabel>
                          {row.columns[1].auxLabel}
                        </S.FixedTableAuxLabel>
                      </S.TableTD>
                      <S.TableTD
                        columnSize={tableStructure[2].columnSize}
                      ></S.TableTD>
                    </S.TableRow>
                  )}
                </>
              )}
            </Fragment>
          );
        })}
      </S.TableBody>
    </Fragment>
  );
};

TableFixed.defaultProps = {
  xs: 12,
};

export { TableFixed };
