import React from 'react';
import { Link } from 'react-router-dom';

import doodleImg from 'assets/images/logo/doddle_logo_color.png';

import './styles.css';

interface HelpAreaProps {
  className?: string;
}

const HelpArea: React.FC<HelpAreaProps> = ({ className }) => {
  return (
    <div className={className}>
      <div id="doodle-symbol">
        <img src={doodleImg} alt="Doddle" />
      </div>
      <div id="help-area">
        Need help or have any questions?
        <br />
        <Link className="bold" to="/contact">
          Contact us and we&apos;ll do our best to assist you.
        </Link>
      </div>
    </div>
  );
};

export default HelpArea;
