export enum AffectFunction {
  LOOKUP_OPTIONS_TABLE = 'lookup-options-table',
  CHANGE_OPTIONS = 'change-options',
  LOOKUP_TABLE = 'lookup-table',
  GET_VALUE = 'get-value',
  GET_BOARDS = 'get-boards',
  CHANGE_REQUIRED_VALUE = 'change-required-value',
  MULTIPLIER = 'multiplier',
  LAYOUT_DECIDER = 'layout-decider',
  GET_CIRCUITS_VALUE = 'get-circuits-value',
}
