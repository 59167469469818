import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useCerts from 'hooks/useCerts';

import SplashScreen from 'components/root/SplashScreen';

const ManageCertificates = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { templateId } = useParams();
  const { redirectToEdit, setRedirectToEdit, loadCertificateTemplate } =
    useCerts();

  useEffect(() => {
    const customerId = state?.customerId ?? state?.customerIdAdded;

    if (templateId) {
      loadCertificateTemplate(templateId, undefined, customerId);
    }
  }, [templateId]);

  useEffect(() => {
    if (redirectToEdit) {
      setRedirectToEdit();
      navigate(redirectToEdit);
    }
  }, [redirectToEdit]);

  return <SplashScreen />;
};

export default ManageCertificates;
