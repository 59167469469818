export const downloadPdf = (base64: string | null, fileName: string) => {
  if (base64) {
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${base64}`;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
  }
};

export const base64ToObjectUrl = (base64String: string, type: string) => {
  const binaryString = window.atob(base64String);
  const uint8Array = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type });
  return URL.createObjectURL(blob);
};
