import React, { FC } from 'react';

import BackButton from 'components/root/BackButton';

import { MenuBackContainer, MenuBackTitle } from './styles';

interface MenuBackProps {
  menuTitle: string;
  handleBack: () => void;
}

const MenuBack: FC<MenuBackProps> = ({ menuTitle, handleBack, children }) => {
  return (
    <MenuBackContainer>
      <BackButton handleBack={handleBack} />
      <MenuBackTitle>{menuTitle}</MenuBackTitle>
      {children ?? <span>&nbsp;</span>}
    </MenuBackContainer>
  );
};

export default MenuBack;
