import * as yup from 'yup';

import { string } from 'utils/formUtils';

export const yupSchema = yup.object().shape({
  description: string.required('Please introduced a valid name'),
  signature: yup.string().required('Please introduced a valid signature'),
});

export const defaultValues = {
  description: '',
  signature: '',
};
