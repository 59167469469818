import styled from 'styled-components';

export const ColorSelectWrapper = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && 'opacity: 0.3;'}
  display: flex;
`;

export const ColorSelectBox = styled.div<{ selected?: boolean }>`
  ${({ selected }) => {
    if (selected) {
      return `
        background-color: #ffffff;
        border: 1px solid #4fa3a1;
        border-radius: 8px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
        `;
    }
  }}

  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ColorSelectBody = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 100%;
  width: 30px;
  height: 30px;
`;
