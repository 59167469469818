import React, { ReactNode } from 'react';
import { OnboardingPage } from 'store/@types/user';

import Button from 'react-bootstrap/esm/Button';

interface TextImageProps {
  page: OnboardingPage;
  stepper: ReactNode;
  nextStep: () => void;
  prevStep: () => void;
}

const TextImage: React.FC<TextImageProps> = ({
  page,
  stepper,
  prevStep,
  nextStep,
}) => {
  const { description, primaryButton, secondaryButton, image } = page;
  return (
    <>
      <div className="image">
        <img src={image} alt="onboarding-open-img" />
      </div>
      <div className="description">{description}</div>
      <div className="button-area">
        {secondaryButton && (
          <Button className="button-secondary" onClick={prevStep}>
            {secondaryButton}
          </Button>
        )}
        {primaryButton && (
          <Button
            className="button-primary"
            form="user-onboarding"
            onClick={nextStep}
          >
            {primaryButton}
          </Button>
        )}
      </div>
      <div className="pagination">{stepper}</div>
    </>
  );
};

export default TextImage;
