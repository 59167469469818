import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ResetPasswordProps } from 'store/@types/user';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { apiRecoverPassword } from 'api/user';
import withHelmet from 'hooks/withHelmet';
import { defaultToast } from 'utils/toast';

import { TextField } from 'components/Form';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';

import { defaultValues, schema } from './yupSchema';

function ResetPassword() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [successOperation, setSuccessOperation] = useState(false);

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  // React hook form instance
  const methods = useForm<ResetPasswordProps>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  useEffect(() => {
    const token = query.get('token');
    if (!token) navigate('/login');
  }, [query, navigate]);

  const onSubmit = async (payload: ResetPasswordProps) => {
    try {
      await apiRecoverPassword(payload);
      setSuccessOperation(true);
      setIsLoading(false);
    } catch (err) {
      defaultToast('E');
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <SplashScreen />}
      {!successOperation && (
        <NoAuthBasePage
          title="Glad to have you back"
          subTitle="Please set a new password"
        >
          <div className="white-card-text-body no-padding-top margin-bottom">
            <p>
              To complete the recovery process we need you to set up a new
              password.
            </p>
            <p>
              Please be sure to use at least 8 characters, containing uppercase
              and lowercase letters, numbers and special characters, such as
              punctuation.
            </p>
            <br />
            <FormProvider {...methods}>
              <Form
                id="reset-pw"
                onSubmit={methods.handleSubmit((data) => {
                  setIsLoading(true);
                  onSubmit({ ...data, token: query.get('token') || '' });
                })}
              >
                <Row>
                  <TextField
                    xs={6}
                    type="password"
                    fieldId="newPassword"
                    label="Set up a password"
                  />
                  <TextField
                    xs={6}
                    type="password"
                    fieldId="passwordConfirm"
                    label="Confirm your password"
                  />
                </Row>
                <Row>
                  <Col xs={6} className="align-self-end">
                    <Link className="gray bold" to="/login">
                      Back to login screen
                    </Link>
                  </Col>
                  <Col xs={6} className="align-self-end">
                    <Button
                      className="button-primary float-right"
                      type="submit"
                    >
                      Set new password
                    </Button>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </div>
        </NoAuthBasePage>
      )}
      {successOperation && (
        <NoAuthBasePage
          title="Your password has been set"
          subTitle="You can login now"
        >
          <div className="white-card-text-body no-padding-top margin-bottom">
            <p>Your password has been reset successfully.</p>
            <p>
              Use your new password to log in to your account.
              <br />
              If you have any issues, feel free to reach out to us for
              assistance.
            </p>
          </div>
          <Row>
            <Col xs={6} />
            <Col xs={6}>
              <Button
                className="button-primary float-right"
                type="button"
                onClick={() => {
                  navigate('/login');
                }}
              >
                Go to login
              </Button>
            </Col>
          </Row>
        </NoAuthBasePage>
      )}
    </>
  );
}

export default withHelmet(ResetPassword);
