import React from 'react';

import icon from 'assets/images/iconset/doddle-circle.svg';
import trialIcon from 'assets/images/iconset/doddle-trial.svg';
import {
  StripeSubscription,
  StripeSubscriptionPrices,
} from 'interfaces/Subscription';
import { formatStringDecimalCurrency } from 'utils/currencyUtils';
import { formatDateShort } from 'utils/dateUtils';

import * as S from './styles';
interface SubscriptionCardProps {
  subscription: StripeSubscriptionPrices;
  currentUserSubscription?: StripeSubscription;
  isSelected?: boolean;
  onClick: () => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  subscription,
  currentUserSubscription,
  isSelected,
  onClick,
}) => {
  const { product, price, currency } = subscription;
  const colorMap: Record<string, string> = {
    'Half-Yearly': '#ffd4d2',
    Yearly: '#ffffff',
    Monthly: '#ddecec',
  };
  const renewsAt = formatDateShort(currentUserSubscription?.renewsAt);
  const trialExpiresAt = formatDateShort(
    currentUserSubscription?.trialExpiresAt
  );
  const isCurrent = !!currentUserSubscription;
  const inTrial = !!currentUserSubscription?.inTrial;

  return (
    <S.CardContainer
      isSelected={isSelected}
      isCurrent={isCurrent || isSelected}
      onClick={onClick}
    >
      <S.Container>
        <S.IconContainer bgColor={inTrial ? '#ffffff' : colorMap[product]}>
          {inTrial && (
            <img src={trialIcon} width={43} height={43} alt="Subscription" />
          )}
          {!inTrial && (
            <img src={icon} width={43} height={43} alt="Subscription" />
          )}
        </S.IconContainer>
        <S.ProductContainer>
          <span>{inTrial ? 'Free trial' : product}</span>
          {!inTrial && (
            <span>
              {!isCurrent
                ? formatStringDecimalCurrency(currency, price)
                : `Annual subscription - Renews on ${renewsAt}`}
            </span>
          )}
          {inTrial && <span>{`Expires on ${trialExpiresAt}`}</span>}
        </S.ProductContainer>
      </S.Container>
    </S.CardContainer>
  );
};
export default SubscriptionCard;
