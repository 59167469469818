import { FC } from 'react';

import ErrorArea from 'components/ErrorArea';
import Copyright from 'components/root/Copyright';
import HelpArea from 'components/root/HelpArea';

import './styles.css';

interface NoAuthBasePageProps {
  title: string;
  subTitle: string;
  error?: string | React.ReactNode;
  noFooter?: boolean;
}

const NoAuthBasePage: FC<NoAuthBasePageProps> = ({
  children,
  title,
  subTitle,
  error,
  noFooter,
}) => {
  return (
    <>
      {error && <ErrorArea message={error} />}
      <div className="root">
        <div className="white-card">
          <h1 className="white-card-title">{title}</h1>
          <h2 className="white-card-subtitle">{subTitle}</h2>
          <div
            className={
              'white-card-body' + (!noFooter ? '' : ' white-card-long-body')
            }
          >
            {children}
          </div>
          {!noFooter && <HelpArea />}
        </div>
        <Copyright />
      </div>
    </>
  );
};

NoAuthBasePage.defaultProps = {
  error: undefined,
  noFooter: false,
};

export default NoAuthBasePage;
