import React, { Fragment } from 'react';

import {
  CertificateDataValuesTableColumnProps,
  CertTemplateField,
} from 'interfaces/CertificateTemplate';
import { SelectedTableIndexes, TableColumnStyles } from 'interfaces/Circuits';

import { ComponentFieldSelector } from 'components/Form/ComponentFieldSelector';
import { TextFieldCircuitTable } from 'components/Form/TextFieldCircuitTable';

import SelectItem from '../SelectItem';
import { TableColumn } from './styles';

interface CircuitBodyColumnsProps {
  getTemplateField: (
    tableIndex: number,
    indexRow: number,
    indexColumn: number
  ) => CertTemplateField;
  selectIndexes: (indexes: SelectedTableIndexes) => void;
  columns: CertificateDataValuesTableColumnProps[];
  tableColumnStyles: TableColumnStyles;
  fieldDisabled: boolean;
  tableIndex: number;
  rowIndex: number;
  rowKey: string;
}

const CircuitBodyColumns: React.FC<CircuitBodyColumnsProps> = ({
  tableColumnStyles,
  getTemplateField,
  fieldDisabled,
  selectIndexes,
  tableIndex,
  rowIndex,
  columns,
  rowKey,
}) => {
  return (
    <Fragment>
      {columns.map(({ index: columnIndex }) => {
        const columnKey = rowKey + columnIndex + '-column> ';
        const tableStyles = tableColumnStyles[columnIndex];
        const field = getTemplateField(tableIndex, rowIndex, columnIndex);

        if (!field?.fieldId) return;
        return (
          <TableColumn key={columnKey} column={tableStyles.column}>
            {columnIndex == 0 ? (
              <div
                onClick={() =>
                  selectIndexes({
                    row: { tableIndex, rowIndex, columnIndex },
                  })
                }
              >
                <TextFieldCircuitTable
                  className="first-column-field"
                  fieldId={field.fieldId}
                  readOnly
                />
              </div>
            ) : (
              <div
                onClick={(e) => {
                  if (e.detail == 2) {
                    selectIndexes({
                      cloumn: { tableIndex, rowIndex, columnIndex },
                    });
                  } else {
                    selectIndexes({});
                  }
                }}
              >
                <SelectItem
                  type="column"
                  tableIndex={tableIndex}
                  rowIndex={rowIndex}
                  columnIndex={columnIndex}
                >
                  <ComponentFieldSelector
                    fieldDisabled={fieldDisabled}
                    key={field.fieldId}
                    field={field}
                    isCertificate
                    hiddenRequired
                  />
                </SelectItem>
              </div>
            )}
          </TableColumn>
        );
      })}
    </Fragment>
  );
};

export default CircuitBodyColumns;
