import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Col, Row } from 'react-bootstrap';

import searchIcon from 'assets/images/iconset/input_search.svg';
import { getAddressDetail, getAddressSugestions } from 'utils/getAddressApi';
import { defaultToast } from 'utils/toast';

import { TextField } from '../TextField';
import * as S from './styles';

interface Sugestions {
  address: string;
  id: string;
}
interface PostalCodeInputProps {
  fieldId: string;
  label: string;
  countyId: string;
  cityId: string;
  streetId: string;
  companyNameId: string;
  postalCodeId: string;
  xs: number;
}

const PostalCodeInput: React.FC<PostalCodeInputProps> = ({
  fieldId,
  label,
  countyId,
  cityId,
  streetId,
  companyNameId,
  postalCodeId,
  xs,
}) => {
  const { getValues, setValue } = useFormContext();
  const [addressSugestions, setAddressSugestions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const setAddress = (suggestions: Sugestions) => {
    getAddressDetail(suggestions.id)
      .then((res) => {
        if (res.residential) {
          const addressArray = [res.line_1, res.line_2, res.line_3, res.line_4];
          const address = addressArray
            .filter((line) => line.length > 0)
            .join(', ');

          setValue(streetId, address);
        } else {
          const addressArray = [res.line_2, res.line_3, res.line_4];
          const address = addressArray
            .filter((line) => line.length > 0)
            .join(', ');

          setValue(companyNameId, res.line_1);
          setValue(streetId, address);
        }
        setValue(cityId, res.town_or_city);
        setValue(countyId, res.county);
        setDropdownOpen(false);
      })
      .catch((err) => {
        defaultToast('E', err.message);
      });
  };

  const searchAddress = () => {
    getAddressSugestions(getValues(postalCodeId))
      .then((res) => {
        if (res.suggestions.length === 0) {
          defaultToast('E', 'No address found');
        } else {
          setDropdownOpen(true);
          setAddressSugestions(res.suggestions);
        }
      })
      .catch((err) => {
        defaultToast('E', err.message);
      });
  };

  return (
    <Col xs={xs} required>
      {dropdownOpen && (
        <S.DropdownOverlay
          onClick={() => {
            setDropdownOpen(false);
          }}
        />
      )}
      <Row xs={4}>
        <TextField
          xs={12}
          required
          fieldId={fieldId}
          label={label}
          inputImage={
            <img
              className="input_icon_placeholder"
              src={searchIcon}
              alt="Search for a postcode"
              onClick={searchAddress}
            />
          }
        />
      </Row>
      {dropdownOpen && (
        <S.Dropdown>
          {addressSugestions.map((suggestions: Sugestions) => (
            <S.DropdownItem
              key={suggestions.address}
              onClick={() => setAddress(suggestions)}
            >
              {suggestions.address}
            </S.DropdownItem>
          ))}
        </S.Dropdown>
      )}
    </Col>
  );
};

export default PostalCodeInput;
