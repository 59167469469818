import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { OnboardingPage } from 'store/@types/user';

import Button from 'react-bootstrap/esm/Button';
import Row from 'react-bootstrap/Row';

import { FieldType } from 'enum/FieldType.enum';
import { CertTemplateField } from 'interfaces/CertificateTemplate';

import { Signature } from 'components/Form';
import {
  ComponentFieldSelector,
  convertWithInColumn,
} from 'components/Form/ComponentFieldSelector';

interface TextImageProps {
  page: OnboardingPage;
  stepper: ReactNode;
  nextStep: () => void;
  prevStep: () => void;
  submit: () => void;
}

const FormPage: React.FC<TextImageProps> = ({
  page,
  stepper,
  prevStep,
  nextStep,
  submit,
}) => {
  const { description, fields, primaryButton, secondaryButton } = page;

  const { trigger, setValue, getValues } = useFormContext();

  const checkAndNextStep = async () => {
    const isValid = await trigger(
      fields?.map(({ id }) => String(id)),
      { shouldFocus: true }
    );

    if (isValid) {
      submit();
      nextStep();
    }
  };

  return (
    <>
      {description && <div className="description">{description}</div>}
      <Row className="mb-3">
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {fields?.map(({ toFill, id, entity, ...field }, fieldOrder) => {
          const fieldKey =
            'onboarding-page-' +
            page.title.replaceAll(' ', '_') +
            fieldOrder +
            '-field';

          if (field.type === FieldType.Signature) {
            return (
              <Signature
                xs={convertWithInColumn(field.fieldWidth)}
                key={fieldKey}
                componentType={FieldType.Signature}
                fieldId={`${id}`}
                label={field.label}
                handleOnLoadNew={(file) => {
                  const currentValue = getValues(`${id}`);
                  setValue(`${id}`, {
                    ...currentValue,
                    signature: file,
                  });
                }}
              />
            );
          }
          return (
            <ComponentFieldSelector
              hiddenRequired
              key={fieldKey}
              field={
                {
                  ...field,
                  name: toFill,
                  fieldId: `${id}`,
                } as unknown as CertTemplateField
              }
            />
          );
        })}
      </Row>
      <div className="button-area">
        {secondaryButton && (
          <Button className="button-secondary" onClick={prevStep}>
            {secondaryButton}
          </Button>
        )}
        {primaryButton && (
          <Button className="button-primary" onClick={checkAndNextStep}>
            {primaryButton}
          </Button>
        )}
      </div>
      <div className="pagination">{stepper}</div>
    </>
  );
};

export default FormPage;
