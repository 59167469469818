import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  CertificateSettings,
  RoleEnum,
  SettingsBusinessInfo,
  SettingsBusinessInfoPayload,
  SettingsUserInfo,
} from 'store/@types/user';

import { Container } from 'react-bootstrap';

import { PractitionerStatus } from 'enum/PractitionerStatus';
import useAuth from 'hooks/useAuth';
import { defaultToast } from 'utils/toast';

import { MenuMain } from 'components/Menu/MenuMain';
import MenuTab from 'components/Menu/MenuTab';
import MenuTabItem from 'components/Menu/MenuTab/MenuTabItem';
import MainLayout from 'components/root/MainLayout';
import { ContentWrapper } from 'components/root/MainLayout/styles';
import SplashScreen from 'components/root/SplashScreen';

import AboutThis from './AboutThis';
import BusinessInformation from './BusinessInformation';
import CertificateInformation from './CertificateInformation';
import Subscription from './Subscription';
import WorkEquipment from './WorkEquipment';
import YourInformation from './YourInformation';
import {
  BusinessSchema,
  BusinessSchemaDV,
  CertificateSettingsDV,
  CertificateSettingsSchema,
  YourAccountSchema,
  YourAccountSchemaDV,
} from './yupSchema';

import './styles.css';

export interface EquipmentProps {
  _id: string;
  user: string;
  name: string;
  serialNumber: string;
  type: string;
  make: string;
  modelNumber: string;
  calibrationDate: string;
  createdAt: string;
  updatedAt: string;
  __v: string;
  default: boolean;
}

const Settings = () => {
  const {
    user,
    userSignatures,
    userHasRequiredInfoFilled,
    business,
    reload,
    currentRole,
  } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [query, setQueryParams] = useSearchParams();
  const [verifyState, setVerifyState] = useState<{ verify: boolean }>();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [selectedPage, setSelectedPage] = useState('init');
  const tab = query.get('tab');
  const { missUserInfo, missBusinessInfo, missEquimpentInfo } =
    userHasRequiredInfoFilled();

  // Access roles
  const hasSubscriptionAccess = currentRole === RoleEnum.SOLO;

  const buttons = [
    { name: 'Your account', value: 'profile', show: true },
    { name: 'Business profile', value: 'business', show: true },
    {
      name: t('certificate_settings_section_title'),
      value: 'certificate',
      show: true,
    },
    { name: 'Test equipments', value: 'workEquipment', show: true },
    {
      name: t('subscription_title'),
      value: 'subscription',
      show: hasSubscriptionAccess,
    },
    { name: 'About this app', value: 'about', show: true },
  ];

  useEffect(() => {
    setVerifyState(state);
    reload({ reloadBusiness: true });
    if (tab) {
      setSelectedPage(tab);
    }
  }, []);

  // React hook form instance
  const methodsYourAccount = useForm<SettingsUserInfo>({
    mode: 'onTouched',
    resolver: yupResolver(YourAccountSchema),
    defaultValues: YourAccountSchemaDV,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const handleYourInfoFormReset = () => {
    if (!user) return;
    methodsYourAccount.reset({
      ...methodsYourAccount.watch(),
      picture: user.picture,
      name: user.name,
      email: user.email,
      practitionerStatus: user?.practitionerStatus
        ? user?.practitionerStatus
        : PractitionerStatus.SOLO,
      position: user?.position ?? '',
    });
  };

  // React hook form instance
  const methodsBusiness = useForm<SettingsBusinessInfoPayload>({
    mode: 'onTouched',
    resolver: yupResolver(BusinessSchema),
    defaultValues: BusinessSchemaDV,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const handleBusinessFormReset = (data: SettingsBusinessInfo) => {
    if (!data) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { team, __v, _id, ...businessInfo } = data;

    methodsBusiness.reset({
      ...businessInfo,
      logoPhoto: businessInfo.logoPhoto ?? '',
      schemeLogo: businessInfo.schemeLogo ?? '',
      phoneNumber: businessInfo ? businessInfo.phoneNumber : '',
      companyNumber: businessInfo ? businessInfo.companyNumber : '',
      fax: businessInfo ? businessInfo.fax : '',
    });

    if (verifyState && missBusinessInfo) {
      methodsBusiness.trigger();
    }
  };

  // React hook form instance
  const methodsCertificate = useForm<CertificateSettings>({
    mode: 'onTouched',
    resolver: yupResolver(CertificateSettingsSchema),
    defaultValues: CertificateSettingsDV,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const handleCertificateFormReset = (data: CertificateSettings) => {
    if (!data) return;
    const { prefix, color, startNum } = data;

    methodsCertificate.reset({
      prefix: prefix ?? '',
      startNum,
      color: color ?? '',
    });
  };

  const requiredInfoVerification = (value: string) => {
    if (verifyState) {
      if (value === 'profile')
        return Object.keys(methodsYourAccount.formState.errors).length > 0;
      if (value === 'business')
        return Object.keys(methodsBusiness.formState.errors).length > 0;
      if (value === 'workEquipment') return missEquimpentInfo;
    }
    return false;
  };

  useEffect(() => {
    if (user) {
      handleYourInfoFormReset();
    }
  }, [user]);

  useEffect(() => {
    handleBusinessFormReset(business as SettingsBusinessInfo);
    if (business?.certificateSettings) {
      handleCertificateFormReset(business.certificateSettings);
    }
  }, [business]);

  useEffect(() => {
    if (!userSignatures) return;
    const defaultOne = userSignatures.find((s) => s.default);

    if (!defaultOne) return;
    methodsYourAccount.setValue('presetSignature', defaultOne, {
      shouldDirty: false,
    });
  }, [userSignatures]);

  useEffect(() => {
    if (verifyState && missUserInfo) {
      methodsYourAccount.trigger();
    }
  }, [verifyState, user]);

  return (
    <>
      {isLoading && <SplashScreen />}
      <MainLayout title="Settings" pageTitle="Settings">
        <MenuMain />
        <ContentWrapper style={{ paddingTop: '0' }}>
          <MenuTab>
            {buttons
              .filter(({ show }) => show)
              .map(({ name, value }) => (
                <MenuTabItem
                  key={name}
                  label={name}
                  value={value}
                  selectedButton={value === selectedPage}
                  errorButton={requiredInfoVerification(value)}
                  onClick={() => {
                    if (value === selectedPage) {
                      setSelectedPage('init');
                      setQueryParams();
                    } else {
                      setSelectedPage(value);
                      setQueryParams(`tab=${value}`);
                    }
                  }}
                />
              ))}
          </MenuTab>
          <Container
            id="settings-container"
            style={{ minWidth: 1050, maxWidth: 'none' }}
          >
            <FormProvider {...methodsYourAccount}>
              <YourInformation
                selectedPage={selectedPage}
                setIsLoading={(v) => setIsLoading(v)}
              />
            </FormProvider>

            <FormProvider {...methodsBusiness}>
              <BusinessInformation
                selectedPage={selectedPage}
                setIsLoading={(v) => setIsLoading(v)}
                handleBusinessFormReset={(a) => {
                  handleBusinessFormReset(a);
                  defaultToast('S');
                }}
              />
            </FormProvider>
            <FormProvider {...methodsCertificate}>
              <CertificateInformation
                selectedPage={selectedPage}
                setIsLoading={(v) => setIsLoading(v)}
                handleCertificateFormReset={(a) => {
                  handleCertificateFormReset(a);
                  defaultToast('S');
                }}
              />
            </FormProvider>

            <WorkEquipment
              selectedPage={selectedPage}
              setIsLoading={(v) => setIsLoading(v)}
              noEquipsError={verifyState?.verify === true && missEquimpentInfo}
            />
            {hasSubscriptionAccess && (
              <Subscription
                selectedPage={selectedPage}
                setIsLoading={(v) => setIsLoading(v)}
              />
            )}
            <AboutThis selectedPage={selectedPage} />
          </Container>
        </ContentWrapper>
      </MainLayout>
    </>
  );
};

export default Settings;
