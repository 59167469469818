import styled from 'styled-components';

export const MenuActionDropdownContainer = styled.div`
  position: relative;
`;

export const MenuActionDropdownIcon = styled.div`
  display: flex;
  gap: 40px;
  cursor: pointer;
`;

export const MenuActionDropdownOverlay = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const MenuActionDropdownContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  background: #ffffff;
  z-index: 999;
  width: 250px;
  top: 35px;
  right: 0px;
  padding-inline: 25px;
  padding-top: 25px;
  border-radius: 12px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
`;

export const MenuActionDropdownItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `opacity: 0.6;
  cursor: not-allowed;`}

  padding-bottom: 25px;
  span {
    color: var(--color-primary);
    font-family: Lato;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
  }
  img {
    width: 20px;
    height: 20px;
  }
`;
