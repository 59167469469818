import React, { FC } from 'react';

import { Col, Row } from 'react-bootstrap';

import { apiDeleteCustomerImage, apiSetCustomerImage } from 'api/customer';

import { TextField, UploadPhoto } from 'components/Form';

const CustomerInfo: FC<{
  customerId?: string;
  onLoad: (file: File) => void;
}> = ({ customerId, onLoad }) => {
  return (
    <>
      <Row className="mr-5">
        <Col xs={12}>
          <h2 className="section-title">Customer information</h2>
          <h3 className="section-subtitles">
            Fill in the summary details to create a customer
          </h3>
        </Col>
        <UploadPhoto
          xs={4}
          inputSize={1}
          fieldId="picture"
          label="Profile photo"
          onload={(file) => {
            if (customerId) {
              apiSetCustomerImage(customerId, file);
            } else {
              onLoad(file);
            }
          }}
          onDelete={() => {
            if (customerId) {
              apiDeleteCustomerImage(customerId);
            }
          }}
        />
        <TextField xs={4} required label="Name" fieldId="name" />
        <TextField xs={4} required label="Email" fieldId="mainContact.email" />
        <Col xs={4} />
        <TextField xs={4} label="Phone number" fieldId="mainContact.phone" />
        <TextField xs={4} label="Fax" fieldId="mainContact.fax" />
      </Row>
      <Row>
        <Col xs={12}>
          <hr />
        </Col>
      </Row>
    </>
  );
};

export default CustomerInfo;
