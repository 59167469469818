import { FieldType } from 'enum/FieldType.enum';
import {
  HandleAutoObservationsParams,
  ObservationsFieldsApply,
} from 'interfaces/AutoObservations';

import { AutoObervationsTemp } from './autoObservations';
import {
  addObservationSol,
  deleteObervation,
  getCircuitDesignationValue,
  getCurrentDynamicTableValue,
  getCurrentFixedTableValue,
  getLocationValue,
  isObservationSolExists,
} from './autoObservationsUtils';

export const removeObservations = (
  autoObervationsTemp: AutoObervationsTemp[],
  handleParams: HandleAutoObservationsParams
): void => {
  const fields: ObservationsFieldsApply = {};

  autoObervationsTemp.forEach((temp) => {
    const { autoObservation, currentFieldsIds } = temp;
    const { observation, sol } = autoObservation;

    // * Observation
    if (observation) {
      if (!fields[observation.fieldId]?.observationTable) {
        fields[observation.fieldId] = {
          observationTable: getCurrentDynamicTableValue(
            observation.fieldId,
            handleParams
          ),
        };
      }

      // ** Get location from current board or return main supply
      const location = getLocationValue(handleParams, currentFieldsIds);

      // ** Get circuit designation from current circuit and board
      const circuitDesignation = getCircuitDesignationValue(
        handleParams,
        currentFieldsIds
      );

      const isObservationDeleted = deleteObervation(
        observation,
        location,
        circuitDesignation,
        fields[observation.fieldId]?.observationTable
      );

      if (isObservationDeleted) {
        fields[observation.fieldId].removed = true;
        // console.log('Observation marked to remove', observation);
      }
    }

    // * Sol
    if (sol) {
      if (!fields[sol.fieldId]?.observationSolTable) {
        fields[sol.fieldId] = {
          observationSolTable: getCurrentFixedTableValue(
            sol.fieldId,
            handleParams
          ),
        };
      }

      const observationSolExists = isObservationSolExists(
        sol,
        fields[sol.fieldId]?.observationSolTable
      );

      if (observationSolExists) {
        addObservationSol(sol, fields[sol.fieldId]?.observationSolTable, {
          emptyCode: true,
        });
        fields[sol.fieldId].removed = true;
        // console.log('Observation Sol marked to remove', sol);
      }
    }
  });

  const { autoSave, getValues, setValue } = handleParams;

  // * Set values and autosave one time per field
  Object.entries(fields).forEach(([fieldId, data]) => {
    if (data.removed && data.observationTable) {
      handleParams.setValue(fieldId, data.observationTable);
      if (autoSave) {
        autoSave({
          type: FieldType.Table,
          fieldId,
          value: data.observationTable,
          getValues,
          setValue,
          onSave: () => {
            // console.log('Observations Deleted: ', fieldId, data);
          },
        });
      }
    }

    if (data.removed && data.observationSolTable) {
      handleParams.setValue(fieldId, data.observationSolTable);
      if (autoSave) {
        autoSave({
          type: FieldType.TableFixed,
          fieldId,
          value: data.observationSolTable,
          getValues,
          setValue,
          onSave: () => {
            // console.log('Sols Deleted: ', fieldId, data);
          },
        });
      }
    }
  });
};
