import * as yup from 'yup';

import { errors, regex, string } from 'utils/formUtils';

export const schema = yup.object().shape({
  password: string
    .required(errors.password.required)
    .matches(regex.passwordSecure, errors.password.passwordSecure),
  passwordConfirm: string
    .oneOf([yup.ref('password')], errors.password.dontMatch)
    .required(errors.password.required),
});

export const defaultValues = {
  password: '',
  passwordConfirm: '',
};
