import styled from 'styled-components';

export const CertificateButton = styled.button`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  gap: 10px;
  height: 35px;
  border-radius: 12px;
  border: 1px solid rgba(79, 163, 161, 1);
  padding: 10px 20px 10px 20px;
  color: rgba(79, 163, 161, 1);
  font-family: lato;
  text-wrap: nowrap;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  background-color: rgba(0, 0, 0, 0);
  :hover {
    background-color: rgba(79, 163, 161, 0.1);
  }
`;
