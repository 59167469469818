import { FieldType } from 'enum/FieldType.enum';
import { PractitionerStatus } from 'enum/PractitionerStatus';
import { CertificatePreset } from 'interfaces/CertificatePreset';
import { Signature } from 'interfaces/Signature';
import { StripeSubscription } from 'interfaces/Subscription';
import { ApiError } from 'services/api';

export enum UserActions {
  SET_LOADING = '@@user/SET_LOADING',
  SET_ERROR = '@@user/SET_ERROR',
  SET_LOGIN = '@@user/SET_LOGIN',
  SET_ONBOARDING = '@@user/SET_ONBOARDING',
}

export interface UserState {
  readonly loading: boolean;
  readonly error: ApiError | null;
  readonly userInfo: User | null;
  readonly userAuth: UserAuth | null;
  readonly onBoarding: SoloOnboarding | null;
}

export type User = {
  _id: string;
  name: string;
  email: string;
  createdAt: string;
  isEmailValidated: boolean;
  onboardingComplete: boolean;
  practitionerStatus: PractitionerStatus;
  position: string;
  picture: string;
  roles: Array<UserRole>;
  notificationsEnabled: boolean;
  devices: string[];
  certificatePresets?: CertificatePreset[];
  subscription?: StripeSubscription;
};

export type UserRole = {
  id: string;
  businessId: string;
  role: RoleEnum;
};

export enum RoleEnum {
  ADMIN = 'admin',
  MANAGER = 'manager',
  QS = 'qs',
  LEAD = 'lead',
  ENGINEER = 'engineer',
  SOLO = 'solo',
  EMPTY = 'empty',
}

export interface WorkSpace {
  businessId: string;
  businessName: string;
  role: RoleEnum;
}

export interface UserRegisterResponse {
  _id: string;
}

export interface UserRegisterProps {
  email: string;
  password: string;
}
export interface ResetPasswordProps {
  token: string;
  newPassword: string;
}

export interface SendReinviteProps {
  email: string;
  businessId: string;
}

export interface LoginPost {
  email: string;
  password: string;
}

export interface UserAuth {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
}

export interface LoginResponse extends UserAuth {
  user: User;
}

export interface SoloOnboarding {
  _id: string;
  pages: OnboardingPage[];
}

export interface OnboardingPage {
  type: string;
  title: string;
  subtitle?: string;
  description?: string;
  image?: string;
  primaryButton: string;
  secondaryButton?: string;
  fields?: OnboardingField[];
}

export interface OnboardingField {
  id: number;
  type: FieldType;
  label: string;
  fieldWidth?: number;
  validations?: OnboardingValidation[];
  hasTextbox?: boolean;
  entity?: string;
  toFill?: string;
  options?: OnboardingOption[];
}

export interface OnboardingValidation {
  required: boolean;
  type: string;
  error: string;
}

export interface OnboardingOption {
  key: string;
  value: string;
}

export type FileContentType = string | ArrayBuffer | null | undefined;

export interface PhotoPayload {
  fileName: string;
  contentType: string;
  fileContent: FileContentType;
}

export interface SettingsUserInfo {
  picture?: string;
  presetSignature: Signature;
  practitionerStatus?: string;
  name: string;
  email: string;
  position: string;
}

export interface SettingsBusinessInfoPayload {
  schemeLogo?: string;
  logoPhoto?: string;
  organizationName: string;
  organizationAddress: string;
  organizationPostal: string;
  registrationNumber?: string;
  organizationEmail: string;
  phoneNumber?: string;
  fax?: string;
  companyNumber: string;
}

export interface CertificateSettings {
  prefix?: string;
  startNum?: number;
  color?: string;
}

export interface SettingsBusinessInfo {
  team: string[];
  __v: string;
  _id: string;
  schemeLogo?: string;
  logoPhoto?: string;
  organizationName: string;
  organizationAddress: string;
  organizationPostal: string;
  registrationNumber?: string;
  organizationEmail: string;
  phoneNumber?: string;
  fax?: string;
  companyNumber: string;
  certificateSettings?: CertificateSettings;
}

export interface SettingsUserRequiredInfo {
  missUserInfo: boolean;
  missBusinessInfo: boolean;
  missEquimpentInfo: boolean;
  hasAllRequiredInfo: boolean;
}

export interface CertificatePresetPayload {
  certificateType: string;
  name: string;
}
