enum Month {
  'Jan' = '01',
  'Feb' = '02',
  'Mar' = '03',
  'Apr' = '04',
  'May' = '05',
  'Jun' = '06',
  'Jul' = '07',
  'Aug' = '08',
  'Sep' = '09',
  'Oct' = '10',
  'Nov' = '11',
  'Dec' = '12',
}

function retrieveMonth(monthNum: string) {
  const month = Object.entries(Month).find((key) => key[1] === monthNum);

  return month ? month[0] : undefined;
}

export { retrieveMonth };
