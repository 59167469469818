import React, { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';

import api from 'services/api';
import { defaultToast } from 'utils/toast';

import { ButtonLabeled, Combo, DatePicker, TextField } from 'components/Form';

import { EquipmentProps } from '../..';
import { defaultValues, yupSchema } from './yupSchema';

import './styles.css';

interface ModalWorkEquipmentProps {
  equipmentData: unknown[];
  resetEquipment: () => void;
  setIsLoading: (value: boolean) => void;
  deleteEquipment: (value: string) => void;
  action: () => void;
  isEdit?: EquipmentProps;
}

const ModalWorkEquipment: FC<ModalWorkEquipmentProps> = ({
  setIsLoading,
  action,
  equipmentData,
  resetEquipment,
  deleteEquipment,
  isEdit,
}) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const saveWorkEquipment = async (values: any) => {
    setIsLoading(true);
    try {
      /* eslint-enable @typescript-eslint/no-unused-vars */
      const parsedValues = {
        ...values,
        calibrationDate: values.calibrationDate
          ? new Date(values.calibrationDate).toISOString()
          : undefined,
      };

      delete parsedValues._id;
      delete parsedValues.user;
      delete parsedValues.createdAt;
      delete parsedValues.updatedAt;
      delete parsedValues.__v;

      if (isEdit) {
        await api.put(`equipment/${isEdit._id}`, parsedValues);
      } else {
        await api.post('equipment', parsedValues);
      }
      defaultToast('S');
      resetEquipment();
    } catch (e) {
      defaultToast('E');
    } finally {
      setIsLoading(false);
    }
  };

  // React hook form instance
  const formMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(yupSchema),
    defaultValues: isEdit
      ? {
          ...isEdit,
          ...(isEdit.calibrationDate && {
            calibrationDate: new Date(isEdit.calibrationDate)
              .toISOString()
              .substr(0, 10),
          }),
        }
      : {
          ...defaultValues,
          default: equipmentData.length === 0,
        },
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const options = [
    'Multi-function',
    'Continuity',
    'Insulation resistance',
    'Earth fault loop impedance',
    'RCD',
    'Earth electrode resistance',
  ];

  return (
    <Modal show backdrop="static" className="add-container-equipment">
      <Modal.Header className="modal-red-header d-flex">
        <Modal.Title
          className="modal-header-left-action"
          onClick={() => action()}
        >
          Cancel
        </Modal.Title>
        <Modal.Title className="modal-title">
          {isEdit ? 'Equipment details' : 'Add test equipment'}
        </Modal.Title>
        <Modal.Title
          className="modal-header-right-action"
          onClick={() => {
            document.getElementById('submit-btn')?.click();
          }}
        >
          Save
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <FormProvider {...formMethods}>
            <Form
              id="add-work-equipment-form"
              onSubmit={formMethods.handleSubmit((data) => {
                saveWorkEquipment(data);
              })}
            >
              <Row>
                <TextField
                  xs={6}
                  required
                  label="Equipment name"
                  fieldId="name"
                />
                <TextField
                  xs={6}
                  required
                  label="Serial number"
                  fieldId="serialNumber"
                />
                <Combo
                  xs={6}
                  required
                  label="Type"
                  fieldId="type"
                  options={options.map((key) => ({
                    key,
                    value: key,
                  }))}
                />
                <TextField xs={6} label="Make" fieldId="make" />
                <TextField xs={6} label="Model number" fieldId="modelNumber" />
                <DatePicker
                  xs={6}
                  label="Next calibration date"
                  fieldId="calibrationDate"
                />
                {(!isEdit && equipmentData.length !== 0) ||
                (isEdit && !isEdit.default) ? (
                  <Combo
                    xs={6}
                    label="Default test equipment"
                    placeholder="Select"
                    fieldId="default"
                    options={[
                      { key: 'true', value: 'Yes' },
                      { key: 'false', value: 'No' },
                    ]}
                  />
                ) : (
                  <Col xs={6} />
                )}
                {isEdit && (
                  <ButtonLabeled
                    xs={6}
                    label="Remove test equipment"
                    buttonClasses="btn button-red"
                    buttonLabel="Delete"
                    buttonAction={() => isEdit && deleteEquipment(isEdit?._id)}
                  />
                )}
              </Row>
              <Button
                id="submit-btn"
                type="submit"
                style={{ display: 'none' }}
              ></Button>
            </Form>
          </FormProvider>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
export default ModalWorkEquipment;
