import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { Button, Col, FormControlProps } from 'react-bootstrap';

import tableDelete from 'assets/images/iconset/table_delete.svg';
import { CertificateState } from 'enum/certificateDataState.enum';
import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';
import {
  CertTableStruct,
  CertTemplateField,
  FormOptions,
} from 'interfaces/CertificateTemplate';

import ModalConfirm from 'components/modals/ModalConfirm';

import { TextField } from '..';
import { CommonProps } from '../Types';
import { PickModal } from './PickModal';

import './style.css';

interface TableModalProps extends FormControlProps, CommonProps {
  required?: boolean;
  tableStructure: CertTableStruct[];
  options?: FormOptions[];
  field: CertTemplateField;
}

const TableModal: FC<TableModalProps> = ({
  xs,
  disabled,
  tableStructure,
  //   isCertificate,
  label,
  options,
  fieldId,
  field,
  autoObservations,
}) => {
  const [openModal, toggleModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<
    undefined | number
  >(undefined);

  const { autoSave, certOptions, handleAutoObservations, certData } =
    useCerts();
  const { control, getValues, setValue } = useFormContext();

  const comboOptions = useMemo(() => {
    return certOptions && certOptions[fieldId]
      ? certOptions[fieldId]
      : options || [];
  }, [certOptions, options]);

  const currentValue = useWatch({
    control,
    name: fieldId,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldId,
  });

  useEffect(() => {
    if (disabled || certData?.certificateState === CertificateState.ISSUED)
      return;
    // eslint-disable-next-line prefer-const
    let timeoutId: any;

    // clear the previous timeout
    timeoutId && clearTimeout(timeoutId);

    // set a new timeout of 5 seconds
    timeoutId = setTimeout(() => {
      // call the API
      autoSave({
        type: FieldType.TableModal,
        fieldId,
        value: currentValue,
        getValues,
        setValue,
      });
      if (autoObservations) {
        handleAutoObservations({
          fieldId,
          autoObservations,
          getValues,
          setValue,
        });
      }
    }, 1200);

    // clean up the timeout when the component unmounts or the `currentValue` changes
    return () => clearTimeout(timeoutId);
  }, [currentValue]);

  return (
    <>
      <Col xs={xs}>
        <div className="certificate-table-header">
          <span className="header-text">{label}</span>
        </div>
        {openModal && !disabled && (
          <PickModal
            keyParam="value"
            otherOption={false}
            modalOptions={comboOptions}
            actionCancel={() => {
              toggleModal(false);
            }}
            actionConfirm={(selected) => {
              const addObject = [];
              for (const item of selected) {
                addObject.push({
                  0: { index: 0, valueType: 'string', value: item },
                });
              }
              append(addObject);
              toggleModal(false);
            }}
          />
        )}
      </Col>
      {fields.map((row, index) => {
        return (
          <Fragment key={row.id}>
            {tableStructure.map((tableColumn, columnIndex) => {
              const item = (row as any)[`${columnIndex}`];
              const isFromList: boolean =
                (Boolean(item?.value) &&
                  comboOptions &&
                  comboOptions.map((e) => e.value).includes(item.value)) ||
                false;

              return (
                <Col
                  xs={12}
                  className="table-row"
                  key={row.id + '-' + columnIndex}
                  style={{ marginTop: '20px' }}
                >
                  <span className="tableItemOption">
                    {isFromList && (
                      <span className="tableTextOption">{item?.value}</span>
                    )}

                    {!isFromList && (
                      <TextField
                        onlyInput
                        xs={12}
                        label=""
                        isCertificate={false}
                        fieldId={`${fieldId}.${index}.${columnIndex}.value`}
                      />
                    )}
                  </span>
                  <img
                    className="table-action-btn"
                    onClick={() => {
                      setShowDeleteConfirmation(index);
                    }}
                    src={tableDelete}
                    alt="table delete button"
                  />
                </Col>
              );
            })}
          </Fragment>
        );
      })}
      <Col xs={12} className="buttons-right-area">
        <Button
          className="button-primary"
          onClick={() => {
            toggleModal(true);
          }}
        >
          Choose from list
        </Button>
        <Button
          className="button-primary"
          onClick={() => {
            append({ 0: { index: 0, valueType: 'string', value: '' } });
          }}
        >
          Custom input
        </Button>
      </Col>
      {/* DELETE CUSTOMER ACCOUNT CONFIRMATION MODAL */}
      {field.popup && showDeleteConfirmation !== undefined && (
        <ModalConfirm
          title={field.popup.popupTitle}
          text={field.popup.popupDescription}
          confirmText="Cancel"
          actionConfirm={() => {
            setShowDeleteConfirmation(undefined);
          }}
          cancelText={field.popup.actionButtonLabel}
          actionCancel={() => {
            remove(showDeleteConfirmation);
            setShowDeleteConfirmation(undefined);
          }}
        />
      )}
    </>
  );
};

TableModal.defaultProps = {
  xs: 12,
};

export { TableModal };
