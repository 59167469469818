import { SettingsUserRequiredInfo, User } from 'store/@types/user';

import { Business } from 'interfaces/Business';
import { Signature } from 'interfaces/Signature';
import { EquipmentProps } from 'pages/Manager/Settings';

export const verifyUserInfo = (
  user?: User,
  business?: Business,
  userSignatures?: Signature[],
  equipmentData?: EquipmentProps[]
): SettingsUserRequiredInfo => {
  const result: SettingsUserRequiredInfo = {
    missUserInfo: false,
    missBusinessInfo: false,
    missEquimpentInfo: false,
    hasAllRequiredInfo: true,
  };

  /**
  A user without the following information:
    Display Name (from “Your Account”)
    Default Signature (from “Your Account”)
    Position (from “Your Account”)
    Organization Name (from “Business Information”)
    Registration Number (from “Business Information”)
    Address (from “Business Information”)
    Postcode (from “Business Information”)
    Email (from “Business Information”)
    Default Test Equipment (from “Business Information”)
      Name
      Serial
      Type
 */

  // User verification
  const { name, email, position } = user || {};

  if (
    user &&
    (!name ||
      !email ||
      !position ||
      !userSignatures ||
      userSignatures.length === 0)
  ) {
    result.missUserInfo = true;
    result.hasAllRequiredInfo = false;
  }

  // Business verification
  const {
    organizationName,
    registrationNumber,
    organizationAddress,
    organizationEmail,
    organizationPostal,
  } = business || {};

  if (
    business &&
    (!organizationName ||
      !registrationNumber ||
      !organizationAddress ||
      !organizationEmail ||
      !organizationPostal)
  ) {
    result.missBusinessInfo = true;
    result.hasAllRequiredInfo = false;
  }

  if (!equipmentData || equipmentData.length === 0) {
    result.missEquimpentInfo = true;
    result.hasAllRequiredInfo = false;
  }

  return result;
};
