import * as yup from 'yup';

import { boolean, string } from 'utils/formUtils';

export const yupSchema = yup.object().shape({
  certificateType: string.required('Please select a certificate type'),
  name: string.required('Please introduced a valid name'),
  isSetup: boolean,
});

export const defaultValues = {
  certificateType: '',
  name: '',
  isSetup: false,
};
