import { RoleEnum } from 'store/@types/user';

export const roleMapper: Record<string, string> = {
  [RoleEnum.ADMIN]: 'Admin',
  [RoleEnum.MANAGER]: 'Manager',
  [RoleEnum.QS]: 'QS',
  [RoleEnum.LEAD]: 'Lead Engineer',
  [RoleEnum.ENGINEER]: 'Engineer',
  [RoleEnum.SOLO]: 'Solo',
};
