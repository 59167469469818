import * as yup from 'yup';

import { string } from 'utils/formUtils';

export const yupSchema = yup.object().shape({
  boardId: string.required('Please select a certificate board'),
  circuit: string.required('Please select a valid circuit'),
});

export const defaultValues = {
  boardId: '',
  circuit: '',
};
