/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  width: 800px;
  height: 64px;
  margin-left: 15px;
  left: calc((100% - 800px) / 2);
  display: flex;
  bottom: 60px;
  position: fixed;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  justify-content: space-around;
  align-items: center;
`;
export const ImgButtonContainer = styled.img`
  width: 147px;
  height: 44px;
`;
