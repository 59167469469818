import { AffectFunction } from 'enum/AffectFunction.enum';
import { BoardGridCard } from 'interfaces/CertificateData';
import {
  CertificateOptions,
  FormOptions,
  RequiredValuesProps,
} from 'interfaces/CertificateTemplate';

export const getBoards = (
  fieldId: string,
  requiredValues: RequiredValuesProps[],
  newCertificateOptions: CertificateOptions,
  defaultValues: {
    [key: string]: unknown;
  }
  //   defaultValues: CertificateDataBoardsValuesProps[]
) => {
  const comboOptions: FormOptions[] = [];
  comboOptions.push({ key: 'Origin', value: 'Origin' });

  requiredValues.forEach(({ function: functionName, fieldId: reqFieldId }) => {
    if (reqFieldId && functionName === AffectFunction.GET_BOARDS) {
      const boards = defaultValues[reqFieldId] as BoardGridCard[];
      boards.forEach(({ id, fields }) => {
        const boardTitle = fields.find((e) => e.cardValue === 'board-title');
        if (boardTitle && boardTitle?.value?.trim() !== '') {
          comboOptions.push({
            key: id,
            value: boardTitle?.value?.trim(),
          });
        }
      });
      newCertificateOptions[fieldId] = comboOptions;
    }
  });
};
