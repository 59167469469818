import { FieldType } from 'enum/FieldType.enum';
import { CertificateDataValueProps } from 'interfaces/CertificateData';
import {
  CertTemplate,
  CertTemplateField,
} from 'interfaces/CertificateTemplate';

/**
 * It takes a template object and returns an array of objects with the default values of the template
 * @param {CertTemplate} data - CertTemplate
 * @returns An array of objects.
 */
export const createNewCertificateWithDefaultProps = (
  data: CertTemplate,
  customerId: string
) => {
  /**
   * It takes a field and a value and returns a CertificateDataValueProps object
   * @param {CertTemplateField} field - CertTemplateField - this is the field object that you are
   * trying to parse.
   * @param {unknown} [value] - The value of the field.
   * @returns An object with the following properties:
   *   key: string
   *   value: string
   *   valueType: string
   */
  const generateDefaultValuesToCreateCertData = (
    field: CertTemplateField
  ): CertificateDataValueProps => {
    const textDefault = (field: CertTemplateField) => ({
      key: field.fieldId,
      value: field.defaultValue ?? field.defaultSignature?.id,
      valueType: field.type,
    });

    const customerDefault = (field: CertTemplateField) => ({
      key: field.fieldId,
      valueType: field.type,
      valueCustomer: {
        customer: customerId || field.defaultValue,
      },
    });

    const valuePerType: {
      [type: string]: (f: CertTemplateField) => CertificateDataValueProps;
    } = {
      [FieldType.CustomerData]: customerDefault,
      [FieldType.Textbox]: textDefault,
      [FieldType.Textarea]: textDefault,
      [FieldType.SmallDatepicker]: textDefault,
      [FieldType.LargeDatepicker]: textDefault,
      [FieldType.SignatureArea]: textDefault,
      [FieldType.Radiobutton]: textDefault,
      [FieldType.Combo]: textDefault,
      [FieldType.Checkbox]: textDefault,
      [FieldType.ComboModal]: textDefault,
      [FieldType.ComboBoards]: textDefault,
      [FieldType.Table]: (field: CertTemplateField) => {
        return textDefault(field);
      },
      [FieldType.TableModal]: (field: CertTemplateField) => {
        return textDefault(field);
      },
      [FieldType.TableFixed]: (field: CertTemplateField) => {
        return textDefault(field);
      },
      [FieldType.BoardGrid]: (field: CertTemplateField) => {
        return textDefault(field);
      },
    };

    return valuePerType[field.type](field);
  };

  const formData: CertificateDataValueProps[] = [];
  data.pages.forEach((page) => {
    page.sections.forEach((section) => {
      section.rows.forEach(({ fields }) => {
        fields.forEach((field) => {
          const { defaultValue, defaultSignature, type } = field;
          (defaultValue ||
            defaultSignature ||
            (customerId && type === FieldType.CustomerData)) &&
            formData.push(generateDefaultValuesToCreateCertData(field));
        });
      });
    });
  });

  return formData;
};
