import { AffectAction } from 'enum/AffectAction.enum';
import {
  CertificateOptions,
  HandleAffectsParams,
} from 'interfaces/CertificateTemplate';

import { applicableAffectsByAction } from '../applicableAffectsByAction';

export const ifEmptyAction = (
  handleAffectsParams: HandleAffectsParams
): CertificateOptions => {
  const { newVal, type, affects, setValue } = handleAffectsParams;
  const newOptions: CertificateOptions = {};

  const ifEmptyAffects = applicableAffectsByAction(
    AffectAction.IF_EMPTY,
    type,
    affects,
    newVal
  );

  if (!ifEmptyAffects?.length) return newOptions;

  ifEmptyAffects.forEach(({ field, options }) => {
    if (options) {
      newOptions[field] = options;
      if (setValue) {
        setValue(`${field}options`, options);
      }
    }
  });
  return newOptions;
};
