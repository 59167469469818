import QRCode from 'qrcode';

import { BranchAddress } from 'interfaces/Customer';

import { defaultToast } from './toast';

export const generateQrCode = (item: BranchAddress): string | undefined => {
  const addressArray = [item.street, item.city, item.postalCode, 'UK'].filter(
    Boolean
  );

  const addressString = addressArray.join(',');

  let qrCodeDataUrl: string | undefined;

  QRCode.toDataURL(
    'https://www.google.com/maps/search/' +
      addressString.toString().replace(/\s/g, '+'),
    { width: 300 },
    (err, dataUrl) => {
      if (err) {
        defaultToast('E', 'Error generating QR code');
        qrCodeDataUrl = undefined;
      } else {
        qrCodeDataUrl = dataUrl;
      }
    }
  );

  return qrCodeDataUrl;
};
