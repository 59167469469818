import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { setLoading } from 'store/user/actions';

import { Col } from 'react-bootstrap';

import useAuth from 'hooks/useAuth';
import { CertData } from 'interfaces/CertificateData';
import { Customer } from 'interfaces/Customer';
import api from 'services/api';
import { certTemplateIds } from 'utils/certificates/helpers';
import { formatDate } from 'utils/index';

import CarouselSection from 'components/CarouselSection';
import MenuBack from 'components/Menu/MenuBack';
import MainLayout from 'components/root/MainLayout';
import { ContentWrapper } from 'components/root/MainLayout/styles';
import SplashScreen from 'components/root/SplashScreen';

function CertificatebyCustomer() {
  const { customerId } = useParams<{ customerId: string }>();
  const { business } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [issuedCerts, setIssuedCerts] = useState<CertData[] | null>(null);
  const [draftCerts, setDraftCerts] = useState<CertData[] | null>(null);

  const navigate = useNavigate();

  /* Fetching the customer data from the API and setting it to the customer state. */
  useEffect(() => {
    setLoading(true);
    api
      .get(`customer/${customerId}`)
      .then(({ data }) => {
        setCustomer(data);
      })
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (!customer || issuedCerts) return;
    setLoading(true);
    api
      .get('issued-certificate-data?customer=' + customer?._id)
      .then(({ data }) => {
        setIssuedCerts(data);
      })
      .finally(() => setIsLoading(false));
  }, [customer]);

  useEffect(() => {
    if (!customer || draftCerts) return;
    setLoading(true);
    api
      .get('/certificate-data?customer=' + customer?._id)
      .then(({ data }) => {
        setDraftCerts(data.reverse());
      })
      .finally(() => setIsLoading(false));
  }, [customer]);

  return (
    <>
      {isLoading && <SplashScreen />}
      <MainLayout
        title="Certificates"
        pageTitle={`Certificates - ${customer?.name}`}
      >
        {(!customer || !business) && <SplashScreen />}
        {customer && business && (
          <>
            <MenuBack
              menuTitle={`CERTIFICATES - ${customer?.name}`}
              handleBack={() => {
                navigate('/certificates');
              }}
            />

            <ContentWrapper>
              <Col>
                {/* Issued certificates */}
                <CarouselSection
                  title="Issued certificates"
                  cardSize="rectangle"
                  payload={
                    issuedCerts?.map((item) => ({
                      id: item._id ?? '0',
                      link: `/manage-certificate/${
                        certTemplateIds[item.template?.certificateName ?? '']
                      }/${item._id}`,
                      image: undefined,
                      title: `${item.template?.certificateName} - ${formatDate(
                        String(item.createdAt)
                      )}`,
                      topTitle: business?.organizationName ?? '',
                      clientName: item.customer?.name ?? '',
                    })) ?? undefined
                  }
                />
                {/* Saved drafts */}
                <CarouselSection
                  noDivider
                  title="Saved drafts"
                  cardSize="rectangle"
                  payload={
                    draftCerts?.map((item) => ({
                      id: item._id ?? '0',
                      link: `/manage-certificate/${item.template?._id}/${item._id}`,
                      image: undefined,
                      title: `${item.template?.certificateName} - ${formatDate(
                        String(item.createdAt)
                      )}`,
                      topTitle: business?.organizationName ?? '',
                      clientName: item.customer?.name ?? '',
                    })) ?? undefined
                  }
                />
              </Col>
            </ContentWrapper>
          </>
        )}
      </MainLayout>
    </>
  );
}

export default CertificatebyCustomer;
