import React, { FC, useState } from 'react';
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';

import { Button, Col, Row } from 'react-bootstrap';

import { Customer } from 'interfaces/Customer';

import ModalConfirm from 'components/modals/ModalConfirm';

import AddLocation from './AddLocation';
import LocationsContent from './LocationsContent';

const arrayName = 'branchAddresses';

// import { TextField } from 'components/Form';
interface BranchesSecndAddressProps {
  locations: UseFieldArrayReturn<Customer, 'branchAddresses'>;
  setValue: (n: string, p: unknown) => void;
  canEdit: boolean;
}

const BranchesSecndAddress: FC<BranchesSecndAddressProps> = ({
  locations,
  setValue,
  canEdit,
}) => {
  const { watch } = useFormContext();
  const { append, remove } = locations;

  const fields = watch('branchAddresses');
  const [confirmDelete, setConfirmDelete] = useState<number | undefined>(
    undefined
  );
  const [addLocation, setAddLocation] = useState<boolean>(false);
  const [editLocation, setEditLocations] = useState<number | undefined>(
    undefined
  );
  const isEdit =
    (typeof editLocation === 'number' && editLocation === 0 && fields[0]) ||
    (typeof editLocation === 'number' && fields[editLocation]) ||
    undefined;

  return (
    <>
      <Row className="mr-5">
        <Col xs={12}>
          <h2 className="section-title">Branches & Secondary addresses</h2>
          <h3 className="section-subtitles">
            Add secondary locations for your customer, such as branches or store
            addresses. These can later be used to fill out certificates
          </h3>
        </Col>
      </Row>
      <Row className="mt-0 mr-5">
        <LocationsContent
          data={fields}
          editItem={(i) => setEditLocations(i)}
          deleteItem={(i) => setConfirmDelete(i)}
        />
      </Row>
      {canEdit && (
        <Row>
          <Col xs={12} className="mt-4">
            <Button
              className="button-primary float-right"
              onClick={() => {
                setAddLocation(true);
              }}
            >
              Add Location
            </Button>
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12}>
          <hr />
        </Col>
      </Row>
      {/* DELETE LOCATION CONFIRMATION MODAL */}
      {(confirmDelete ||
        (typeof confirmDelete === 'number' && confirmDelete === 0)) && (
        <ModalConfirm
          title="Remove location?"
          text={
            <>
              Are you sure you want to remove this location from your list?
              <br />
              <br />
              This action cannot be undone.
            </>
          }
          cancelText="Remove"
          actionCancel={() => {
            remove(confirmDelete);
            // close delete modal
            setConfirmDelete(undefined);
          }}
          confirmText="Cancel"
          actionConfirm={() => {
            // close delete modal
            setConfirmDelete(undefined);
          }}
        />
      )}
      {(addLocation || isEdit) && canEdit && (
        <AddLocation
          deleteItem={() => setConfirmDelete(editLocation)}
          onCancel={() => {
            setEditLocations(undefined);
            setAddLocation(false);
          }}
          onSave={(data) => {
            if (isEdit) {
              Object.keys(data).forEach((field) => {
                const name = arrayName + '.' + editLocation + '.' + field;
                const value = (data as any)[field];
                setValue(name, value);
              });
            } else {
              append(data);
            }
            setEditLocations(undefined);
            setAddLocation(false);
          }}
          isEdit={isEdit}
        />
      )}
    </>
  );
};

export default BranchesSecndAddress;
