import { IDispatchType } from 'store/@types';
import {
  LoginResponse,
  SoloOnboarding,
  UserActions as Actions,
} from 'store/@types/user';

import { ApiError } from 'services/api';

import { userActions } from './reducer';

export const setLoading = (payload = true): IDispatchType =>
  userActions[Actions.SET_LOADING](payload);

export const setError = (payload: unknown): IDispatchType =>
  userActions[Actions.SET_ERROR]({ ...(payload as ApiError) });

export const setLogin = (payload: LoginResponse | null): IDispatchType =>
  userActions[Actions.SET_LOGIN](payload);

export const setOnBoarding = (payload: SoloOnboarding | null): IDispatchType =>
  userActions[Actions.SET_ONBOARDING](payload);
