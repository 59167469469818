import React, { FC } from 'react';

import { MenuTabItemButton } from '../styles';

export type MenuTabItemProps = {
  selectedButton: boolean;
  onClick: () => Promise<void> | void;
  highLightButton?: boolean;
  errorButton?: boolean;
  label?: string;
  value?: string;
};

const MenuTabItem: FC<MenuTabItemProps> = ({
  selectedButton,
  onClick,
  highLightButton,
  errorButton,
  label,
  value,
}) => {
  return (
    <MenuTabItemButton
      type={'button'}
      selectedButton={selectedButton}
      highLightButton={highLightButton}
      errorButton={errorButton}
      onClick={onClick}
      value={value}
    >
      {label}
    </MenuTabItemButton>
  );
};

MenuTabItem.defaultProps = {
  selectedButton: false,
  highLightButton: false,
  errorButton: false,
  label: '',
};

export default MenuTabItem;
