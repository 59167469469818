import React, { FC, useState } from 'react';

import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';

import favoriteBadge from 'assets/images/iconset/favorite_badge.svg';
import tableColapseImg from 'assets/images/iconset/table_colapse.svg';
import tableRowDelete from 'assets/images/iconset/table_delete.svg';
import tableEditImg from 'assets/images/iconset/table_edit.svg';
import tableExpandImg from 'assets/images/iconset/table_expand.svg';

import { InfoField } from 'components/Form/InfoField';

import { EquipmentProps } from '../..';

import './styles.css';

interface WorkEquipmentContentProps {
  equipmentData: EquipmentProps[];
  deleteEquipment: (id: string) => void;
  setEditWorkEquip: (data?: EquipmentProps) => void;
}

const WorkEquipmentContent: FC<WorkEquipmentContentProps> = ({
  equipmentData,
  setEditWorkEquip,
  deleteEquipment,
}) => {
  const [activeId, setActiveId] = useState<any>(null);
  const [hideBtn, setHideBtn] = useState('');
  const [hideInfo, setHideInfo] = useState('');

  function toggleActive(id: string) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  function buttonActive(id: string) {
    if (hideBtn === id) {
      setHideBtn('');
    } else {
      setHideBtn(id);
    }
  }

  function equipmentInfo(id: string) {
    if (hideInfo === id) {
      setHideInfo('');
    } else {
      setHideInfo(id);
    }
  }

  return (
    <Col xs={9} className="expandable-table-equipment">
      <Accordion>
        {equipmentData.map((equipment) => {
          return (
            <div key={equipment._id}>
              <Card>
                <Card.Header>
                  <div className="d-flex" style={{ whiteSpace: 'nowrap' }}>
                    {equipment.name}
                    {equipment.default ? (
                      <img
                        style={{ marginLeft: '10px' }}
                        src={favoriteBadge}
                        alt="default Equipment"
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div
                    style={{ width: '100%', height: 50, cursor: 'pointer' }}
                    onClick={() => {
                      toggleActive(equipment._id);
                      buttonActive(equipment._id);
                      equipmentInfo(equipment._id);
                    }}
                  />
                  <Accordion.Button
                    className="buttons-equipment-table"
                    as={Button}
                    eventkey={equipment._id}
                  >
                    <img
                      className={hideBtn === equipment._id ? '' : 'hide-btn'}
                      src={tableRowDelete}
                      alt="table delete"
                      onClick={() => {
                        deleteEquipment(equipment._id);
                      }}
                    />
                    <i
                      className={
                        hideBtn === equipment._id
                          ? 'divider-equipment'
                          : 'hide-btn'
                      }
                    >
                      &nbsp;
                    </i>
                    <img
                      className={hideBtn === equipment._id ? '' : 'hide-btn'}
                      src={tableEditImg}
                      alt="table edit"
                      onClick={() => {
                        setEditWorkEquip(equipment);
                      }}
                    />
                    <i
                      className={
                        hideBtn === equipment._id
                          ? 'divider-equipment'
                          : 'hide-btn'
                      }
                    >
                      &nbsp;
                    </i>
                    <img
                      id="expand-btn"
                      style={
                        hideBtn !== equipment._id ? { marginLeft: '100px' } : {}
                      }
                      src={
                        activeId === equipment._id
                          ? tableColapseImg
                          : tableExpandImg
                      }
                      alt="table expand"
                      onClick={() => {
                        toggleActive(equipment._id);
                        buttonActive(equipment._id);
                        equipmentInfo(equipment._id);
                      }}
                    />
                  </Accordion.Button>
                </Card.Header>
                <div
                  className={
                    hideInfo === equipment._id
                      ? 'equipment-details'
                      : 'hide-btn'
                  }
                >
                  <Row>
                    <InfoField
                      xs={4}
                      label="Serial number"
                      value={equipment.serialNumber}
                    />
                    <InfoField xs={4} label="Type" value={equipment.type} />
                    <InfoField xs={4} label="Make" value={equipment.make} />
                    <InfoField
                      xs={4}
                      label="Model number"
                      value={equipment.modelNumber}
                    />
                    <InfoField
                      xs={4}
                      label="Next calibration due"
                      value={
                        equipment.calibrationDate &&
                        new Date(equipment.calibrationDate).toLocaleDateString(
                          'pt-PT'
                        )
                      }
                    />
                  </Row>
                </div>
              </Card>
            </div>
          );
        })}
      </Accordion>
    </Col>
  );
};

export default WorkEquipmentContent;
