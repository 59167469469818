import React, { FC } from 'react';

import customerNoPreview from 'assets/images/iconset/no_img_customer.png';

import { CarouselItemProps } from 'components/Carousel';

import '../styles.css';

const CarouselCard: FC<CarouselItemProps> = ({
  link,
  image,
  title,
  onClick,
}) => {
  return (
    <div className="square-card">
      <a
        href={link || '#'}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <div className="square-card-image">
          <img src={(image as string) || customerNoPreview} alt="customer" />
        </div>
        <p className="truncate">{title}</p>
      </a>
    </div>
  );
};

export default CarouselCard;
