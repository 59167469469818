import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Button, Col, Row } from 'react-bootstrap';

import {
  apiSoloSubscriptionPrices,
  apiSubscriptionChangePayment,
  apiSubscriptionPayment,
} from 'api/payment';
import EmptyWorkEquipment from 'assets/images/iconset/missing_test_equipment.svg';
import useAuth from 'hooks/useAuth';
import { StripeSubscriptionPrices } from 'interfaces/Subscription';
import { defaultToast } from 'utils/toast';

import { ModalSubscription } from 'components/modals/ModalSubscription';
import SubscriptionCard from 'components/SubscriptionCard';

import * as S from './styles';

interface SubscriptionProps {
  selectedPage: string;
  setIsLoading: (isLoading: boolean) => void;
}

const Subscription: FC<SubscriptionProps> = ({
  selectedPage,
  setIsLoading,
}) => {
  const [subscriptions, setSubscriptions] = useState<
    StripeSubscriptionPrices[]
  >([]);
  const [showModal, setShowModal] = useState(false);
  const [currentSubscription, setCurrentSubscription] =
    useState<StripeSubscriptionPrices>();
  const { user, loadMe } = useAuth();
  const isNew = !user?.subscription?.priceId;
  const { t } = useTranslation();

  const loadPrices = () => {
    apiSoloSubscriptionPrices().then((data) => {
      setSubscriptions(data.web);
    });
  };

  const updateCurrentSubscription = (data: StripeSubscriptionPrices[]) => {
    if (user?.subscription?.priceId) {
      const currentSubscription = data.find(
        (sub) => sub.priceId === user?.subscription?.priceId
      );
      if (currentSubscription) {
        setCurrentSubscription(currentSubscription);
      }
    }
  };

  const subscriptionCheckout = (priceId: string, currency: string) => {
    setIsLoading(true);
    const baseCertificateUrl = window.location.pathname;

    apiSubscriptionPayment({
      successUrlPath: `${baseCertificateUrl}?tab=subscription&success=true`,
      cancelUrlPath: `${baseCertificateUrl}?tab=subscription&success=false`,
      priceId,
      currency,
    })
      .then((data) => {
        if (data.session.url) {
          window.location.href = data.session.url;
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        defaultToast('E');
      });
  };

  const changeSUbscriptionPlan = (priceId: string) => {
    if (user) {
      setIsLoading(true);
      apiSubscriptionChangePayment(user._id, priceId)
        .then(() => {
          const current = subscriptions.find((sub) => sub.priceId === priceId);
          if (current) {
            setCurrentSubscription(current);
          }
          defaultToast('S', 'Plan changed successfully');
        })
        .catch(() => {
          defaultToast('E');
        })
        .finally(() => {
          setIsLoading(false);
          setShowModal(false);
        });
    }
  };

  useEffect(() => {
    loadPrices();
    loadMe(true);
  }, []);

  useEffect(() => {
    updateCurrentSubscription(subscriptions);
  }, [subscriptions]);

  return (
    <>
      {(selectedPage === 'subscription' || selectedPage === 'init') && (
        <>
          <Row>
            <div>
              <h2 className="section-title">{t('subscription_title')}</h2>
              <h3 className="section-subtitles mb-0">
                {t('subscription_description')}
              </h3>
            </div>
          </Row>
          <Row className="mt-0">
            <div
              className="settings-container"
              style={{ marginTop: isNew ? 37 : 0 }}
            >
              {!user?.subscription && (
                <Row>
                  <Col xs={2} className="ml-4">
                    <img
                      width={163}
                      height={180}
                      src={EmptyWorkEquipment}
                      alt="missing Work Equipment"
                    />
                  </Col>
                  <Col xs={6}>
                    <div className="ml-4 mb-2 mt-4 pl-3 pt-3 pr-3">
                      <h4
                        style={{
                          color: '#370F08',
                          fontSize: 17,
                          fontWeight: 700,
                        }}
                      >
                        You don’t have an active subscription.
                      </h4>
                      <p style={{ color: '#666' }}>
                        Choose the subscription that better fits your needs.
                      </p>
                    </div>
                  </Col>
                </Row>
              )}
              {currentSubscription && (
                <Row>
                  <Col xs={6} style={{ maxWidth: 670 }}>
                    <S.SubTitle>
                      {t('subscription_active_plan_label')}
                    </S.SubTitle>
                    <SubscriptionCard
                      subscription={currentSubscription}
                      currentUserSubscription={user?.subscription}
                      onClick={() => null}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={6} lg={9} />
                <Col xs={3}>
                  <Button
                    className={clsx('button-primary float-right', {
                      hidden:
                        user?.subscription &&
                        user?.subscription?.origin !== 'web',
                    })}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {isNew ? 'Subscribe' : 'Manage subscription'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
          {showModal && (
            <ModalSubscription
              onCloseModal={() => {
                setShowModal(false);
              }}
              subscriptions={subscriptions}
              isNew={isNew}
              handleOnSave={(priceId, currency) => {
                if (isNew) {
                  subscriptionCheckout(priceId, currency);
                } else {
                  changeSUbscriptionPlan(priceId);
                }
              }}
            />
          )}
        </>
      )}
      {selectedPage === 'init' && (
        <Row className="my-4">
          <hr />
        </Row>
      )}
    </>
  );
};

export default Subscription;
