import React from 'react';

import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';

interface ModalConfirmProps {
  title: string;
  text: string | React.ReactNode;
  confirmText?: string;
  actionConfirm: () => void;
  cancelText?: string;
  actionCancel: () => void;
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
  title,
  text,
  actionCancel,
  actionConfirm,
  confirmText,
  cancelText,
}) => {
  return (
    <Modal
      show
      className="confirmation-modal"
      backdropClassName="confirmation-modal-backdrop"
      onHide={actionCancel}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Title>{title}</Modal.Title>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          className="button-secondary"
          onClick={actionCancel}
        >
          {cancelText}
        </Button>
        <Button
          type="button"
          className="button-primary"
          onClick={actionConfirm}
        >
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
ModalConfirm.defaultProps = {
  confirmText: 'Yes',
  cancelText: 'No',
};

export default ModalConfirm;
