import { FC } from 'react';

import btnRemove from 'assets/images/iconset/table_delete.svg';

import { CarouselItemProps } from 'components/Carousel';

import * as S from './styles';

const PresetCard: FC<CarouselItemProps> = ({
  onClick,
  onDeleteClick,
  title,
  topTitle,
  highlight,
}) => {
  return (
    <S.PresetCard highlightCard={highlight}>
      <S.Container justifyContent="flex-end">
        <S.PresetDeleteIcon
          src={btnRemove}
          alt="Delete preset"
          onClick={onDeleteClick}
        />
      </S.Container>
      <S.Container justifyContent="center" onClick={onClick} cursor="pointer">
        <S.CertificateType>{title}</S.CertificateType>
      </S.Container>
      <S.Container
        justifyContent="flex-start"
        onClick={onClick}
        cursor="pointer"
      >
        <S.PresetName>{topTitle}</S.PresetName>
      </S.Container>
    </S.PresetCard>
  );
};

export default PresetCard;
