/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

const RequireAuth: FC = () => {
  const { checkRedirect, isAuthenticated } = useAuth();
  const location = useLocation();

  const path = checkRedirect();
  if (path) {
    return <Navigate to={path} />;
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
