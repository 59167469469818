import { ChangeEvent, FC, Fragment, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Col, Form, FormControlProps, Row } from 'react-bootstrap';

import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';
import { CertificateDataValueProps } from 'interfaces/CertificateData';
import { FieldAffects, FormOptions } from 'interfaces/CertificateTemplate';

import Comments from 'components/Comments';
import {
  FormAuxLabel,
  FormControlFeedback,
  FormGroupWithErrorSpace,
  FormLabel,
  FormLabelContainer,
} from 'components/Form/styles';

import { getError, TextField } from '..';
import { CommonProps } from '../Types';

interface RadioButtonProps extends FormControlProps, CommonProps {
  placeholder?: string;
  maxLength?: number;
  options?: FormOptions[];
  affects?: FieldAffects[];
  isCertificate?: boolean;
  hasTextbox?: boolean;
  required?: boolean;
}

const RadioButton: FC<RadioButtonProps> = ({
  xs,
  fieldId,
  label,
  disabled,
  isCertificate,
  options,
  affects,
  auxLabel,
  autoObservations,
  ...rest
}) => {
  const {
    control,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useFormContext();

  const error = getError(fieldId, errors);
  const { autoSave, handleAffects, handleAutoObservations, certOptions } =
    useCerts();

  const radioOptions = useMemo(() => {
    return certOptions ? certOptions[fieldId] : options;
  }, [certOptions, options]);

  const thisFieldId = isCertificate ? fieldId + '.value' : fieldId;
  const thisFieldIdText = isCertificate ? fieldId + '.valueTextbox' : fieldId;

  if (!radioOptions?.length) return null;

  const currentValue: CertificateDataValueProps = {
    key: fieldId,
    valueType: FieldType.Radiobutton,
    value: '',
    valueTextbox: '',
  };

  const handleOnChange = (value: string, hasTextbox: boolean | undefined) => {
    setValue(thisFieldId, value);

    currentValue.value = value;
    currentValue.valueTextbox = '';

    if (isCertificate) {
      if (affects) {
        handleAffects({
          fieldId,
          type: FieldType.Radiobutton,
          affects,
          newVal: currentValue,
          getValues,
          setValue,
        });
      }

      setValue(thisFieldIdText, '', {
        shouldValidate: !hasTextbox,
      });

      autoSave({
        type: FieldType.Radiobutton,
        fieldId,
        value: currentValue,
        getValues,
        setValue,
      });
      if (autoObservations) {
        handleAutoObservations({
          fieldId,
          autoObservations,
          getValues,
          setValue,
        });
      }
    }

    if (hasTextbox) {
      setTimeout(() => setFocus(thisFieldIdText), 200);
    }
  };

  return (
    <Controller
      name={thisFieldId}
      control={control}
      render={({ field }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { value, ...fieldParsed } = field;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { size, ...others } = rest;

        return (
          <FormGroupWithErrorSpace xs={xs} height="max-content">
            {label && (
              <FormLabel>
                <FormLabelContainer>
                  {label}
                  <Comments
                    fieldId={fieldId}
                    disabledField={disabled}
                    isCertificate={isCertificate}
                    handleOnChange={(comments) => {
                      autoSave({
                        type: FieldType.Radiobutton,
                        fieldId,
                        value: {
                          ...currentValue,
                          value: `${getValues(thisFieldId)}`,
                          valueTextbox: getValues(thisFieldIdText),
                          comments,
                        },
                        getValues,
                        setValue,
                      });
                    }}
                  />
                </FormLabelContainer>
              </FormLabel>
            )}
            <Row>
              {radioOptions?.map((option) => (
                <Fragment key={'radio-component-option-' + option.key}>
                  <Col
                    className={'radio-box'}
                    xs={(option.hasTextbox && 2) || (xs && xs === 12 ? 6 : 12)}
                  >
                    <Form.Check
                      {...fieldParsed}
                      inline
                      className="radiobutton"
                      id={option.key}
                      value={option.key}
                      label={option.value}
                      checked={option.value === field.value}
                      type={'radio' as any}
                      disabled={disabled}
                      isInvalid={Boolean(isCertificate ? error?.value : error)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleOnChange(e.target.value, option.hasTextbox);
                      }}
                      {...others}
                    />
                  </Col>
                  {isCertificate && option.hasTextbox && (
                    <FormGroupWithErrorSpace
                      xs={4}
                      className="radio-box-with-textbox"
                      height={'65px'}
                    >
                      <TextField
                        onlyInput
                        fieldId={thisFieldIdText}
                        disabled={option.value !== field.value || disabled}
                        isInvalid={Boolean(error?.valueTextbox)}
                        additionalOnBlur={(text: string) => {
                          if (isCertificate) {
                            const newVal = {
                              ...currentValue,
                              value: option.value,
                              valueTextbox: text,
                            };
                            autoSave({
                              type: FieldType.Radiobutton,
                              fieldId,
                              value: newVal,
                              getValues,
                              setValue,
                            });
                          }
                        }}
                      />
                      <FormControlFeedback type="invalid">
                        {error?.valueTextbox?.message}
                      </FormControlFeedback>
                    </FormGroupWithErrorSpace>
                  )}
                </Fragment>
              ))}
              {auxLabel && (
                <Col xs={12}>
                  <FormAuxLabel>{auxLabel}</FormAuxLabel>
                </Col>
              )}
            </Row>
            <FormControlFeedback type="invalid">
              {error?.message || error?.value?.message}
            </FormControlFeedback>
          </FormGroupWithErrorSpace>
        );
      }}
    />
  );
};

RadioButton.defaultProps = {
  xs: 12,
};

export { RadioButton };
