import styled from 'styled-components';

export const MenuTabContainer = styled.div<{ marginBottom?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '0'};
`;

export const MenuTabStickyRow = styled.div<{
  sticky?: boolean;
  paddingLeft?: string;
  paddingRight?: string;
}>`
  display: flex;
  background-color: #fdfeff;
  @media only screen and (min-width: 992px) {
    white-space: nowrap;
  }
  ${({ sticky }) => {
    return (
      sticky &&
      `
      position: sticky;
      top: 0;
      z-index: 3;
    `
    );
  }};

  padding-left: ${({ paddingLeft }) => paddingLeft ?? '0'};
  padding-right: ${({ paddingRight }) => paddingRight ?? '0'};
`;

export const MenuTabDivider = styled.hr`
  margin: 0;
`;

export const MenuTabItemTab = styled.span<{
  selectedTab?: boolean;
  errorTab?: boolean;
  highLightTab?: boolean;
}>`
  height: 25px;
  padding-inline: 20px;
  padding-top: 1px;
  border-radius: 5px 5px 0px 0px;

  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.16px;
  text-align: center;
  cursor: pointer;

  transition: all 0.4s;

  box-sizing: border-box;
  ${({ selectedTab }) => {
    return selectedTab
      ? `
          color: #4FA3A1;
          background: #EFF7F6;
          border-bottom: 3px solid #4fa3a1;`
      : `
          color:#4A4A4A;
          background: #FFFFFF;
      `;
  }}
`;
