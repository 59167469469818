import { AffectFunction } from 'enum/AffectFunction.enum';
import {
  CertificateOptions,
  FieldAffects,
  FormOptions,
  HandleAffectsParams,
  RequiredValuesProps,
} from 'interfaces/CertificateTemplate';

export const lookupOptionsTable = (
  requiredValues: RequiredValuesProps[],
  affect: FieldAffects,
  handleAffectsParams: HandleAffectsParams
): CertificateOptions => {
  const newOptions: CertificateOptions = {};
  const {
    getValues,
    defaultValues = {},
    certOptions: existingOptions,
  } = handleAffectsParams;
  const lookUpAffects = requiredValues.filter(
    (reqValue) => reqValue.function === AffectFunction.LOOKUP_OPTIONS_TABLE
  );

  if (!lookUpAffects?.length) return newOptions;

  const constrains = lookUpAffects.flatMap(({ values = [] }) => values);

  constrains.forEach((constrain) => {
    if (constrain.optionsTable) {
      const { fieldX, fieldY, tableOptions, x, y } = constrain.optionsTable;
      let xValue = getValues ? getValues(fieldX) : defaultValues[fieldX];
      let yValue = getValues ? getValues(fieldY) : defaultValues[fieldY];

      xValue = typeof xValue !== 'string' ? xValue?.value : xValue;
      yValue = typeof yValue !== 'string' ? yValue?.value : yValue;

      if (xValue && yValue) {
        const xIndex = x.indexOf(xValue);
        const yIndex = y.indexOf(yValue);

        if (xIndex !== -1 && yIndex !== -1) {
          const options =
            (tableOptions[xIndex][yIndex].options as Array<FormOptions>) || [];

          newOptions[affect.field] = options;
          if (existingOptions) {
            existingOptions[affect.field] = options;
          }
        }
      }
    }
  });

  return newOptions;
};
