import React, { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Col, Row } from 'react-bootstrap';

import mapQrcode from 'assets/images/iconset/map_qrcode_icon.svg';
import { generateQrCode } from 'utils/generateQrCode';

import { TextField } from 'components/Form';
import PostalCodeInput from 'components/Form/PostalCodeInput';
import ModalImage from 'components/modals/ModalImage';

import * as S from './styles';

const MainAddress: FC<{ isEdit: boolean }> = ({ isEdit }) => {
  const [showModal, setShowModal] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const { getValues } = useFormContext();

  const qrCodeHandler = () => {
    const generatedQrCodeUrl = generateQrCode({
      street: getValues('headquartersAddress.street'),
      city: getValues('headquartersAddress.city'),
      postalCode: getValues('headquartersAddress.postalCode'),
      county: getValues('headquartersAddress.county'),
      occupier: getValues('headquartersAddress.companyName'),
    });

    if (generatedQrCodeUrl) {
      setQrCodeUrl(generatedQrCodeUrl);
      setShowModal(true);
    }
  };

  return (
    <>
      <Row className="mr-5">
        <Col xs={12}>
          <Row className="ml-0 align-middle">
            <h2 className="section-title">Main address</h2>
            {isEdit && (
              <S.MapButton
                src={mapQrcode}
                className="mb-2"
                onClick={qrCodeHandler}
              />
            )}
          </Row>
        </Col>

        <TextField
          xs={8}
          label="Name"
          fieldId="headquartersAddress.companyName"
        />
        <Col xs={4} />

        <PostalCodeInput
          xs={4}
          countyId="headquartersAddress.county"
          cityId="headquartersAddress.city"
          streetId="headquartersAddress.street"
          companyNameId="headquartersAddress.companyName"
          label="Postcode"
          fieldId="headquartersAddress.postalCode"
          postalCodeId="headquartersAddress.postalCode"
        />

        <TextField
          xs={4}
          required
          label="Town / City"
          fieldId="headquartersAddress.city"
        />
        <TextField xs={4} label="County" fieldId="headquartersAddress.county" />
        <TextField
          xs={12}
          required
          label="Address"
          fieldId="headquartersAddress.street"
        />
      </Row>
      <Row>
        <Col xs={12}>
          <hr />
        </Col>
      </Row>
      {showModal && (
        <ModalImage
          title="Directions"
          text="Scan this QR Code with your smartphone to instantly navigate to your desired destination."
          actionCancel={() => setShowModal(false)}
          qrCodeUrl={qrCodeUrl}
        />
      )}
    </>
  );
};

export default MainAddress;
