import React, { FC } from 'react';
import clsx from 'clsx';

import reqIcon from 'assets/images/iconset/doc_no_preview.svg';

import { CarouselItemProps } from 'components/Carousel';

import '../styles.css';

const RectangleCard: FC<CarouselItemProps> = ({
  link,
  image,
  onClick,
  highlight,
  title,
  topTitle,
  clientName,
}) => {
  return (
    <div className={clsx('rectangle-card', { 'highlight-card': highlight })}>
      <a
        href={link || '#'}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <p className="title-top truncate">{topTitle}</p>
        <div className="rectangle-card-image">
          <img src={(image as string) || reqIcon} alt="customer" />
        </div>
        <p className="title-client truncate">{clientName}</p>
        <p className="title truncate">{title}</p>
      </a>
    </div>
  );
};

export default RectangleCard;
