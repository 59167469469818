import React, { useMemo } from 'react';

import useCerts from 'hooks/useCerts';

import { SelectedItemContainer } from './styles';

interface SelectItemProps {
  type: 'row' | 'column';
  tableIndex: number;
  rowIndex: number;
  columnIndex?: number;
  fixed?: boolean;
}
const SelectItem: React.FC<SelectItemProps> = ({
  type,
  children,
  tableIndex,
  rowIndex,
  columnIndex,
  fixed,
}) => {
  const { selectedCircuitIndexes: indexes } = useCerts();

  const checkSelectItem = useMemo(() => {
    if (type === 'column' && indexes?.cloumn?.columnIndex) {
      return (
        indexes.cloumn?.tableIndex === tableIndex &&
        indexes.cloumn?.rowIndex === rowIndex &&
        indexes.cloumn?.columnIndex === columnIndex
      );
    } else if (type === 'row') {
      return (
        indexes.row?.tableIndex === tableIndex &&
        indexes.row?.rowIndex === rowIndex
      );
    }
    return false;
  }, [indexes]);

  return (
    <SelectedItemContainer type={type} selected={checkSelectItem} fixed={fixed}>
      {children}
    </SelectedItemContainer>
  );
};

export default SelectItem;
