import { Business } from 'interfaces/Business';

import { RoleEnum, UserRole, WorkSpace } from '../store/@types/user/index';
import { getRoleByBusiness } from './userRoles';

const BUSINESS_WORKSPACE_KEY = 'hmc.app.business';
const ROLE_WORKSPACE_KEY = 'hmc.app.role';
const WORKSPACES_KEY = 'hmc.app.workspaces';

export const getLSWorkspaceBusiness = (): string =>
  localStorage.getItem(BUSINESS_WORKSPACE_KEY) as string;

export const setLSWorkspaceBusiness = (businessId: string) =>
  localStorage.setItem(BUSINESS_WORKSPACE_KEY, businessId);

export const getLSWorkspaces = (): WorkSpace[] =>
  JSON.parse(localStorage.getItem(WORKSPACES_KEY) as string) as WorkSpace[];

export const setLSWorkspaces = (workSpaces: WorkSpace[]) =>
  localStorage.setItem(WORKSPACES_KEY, JSON.stringify(workSpaces));

export const getLSWorkspaceRole = (): RoleEnum =>
  (localStorage.getItem(ROLE_WORKSPACE_KEY) as RoleEnum) || RoleEnum.EMPTY;

export const setLSWorkspaceRole = (role: RoleEnum) =>
  localStorage.setItem(ROLE_WORKSPACE_KEY, role);

export const buildWorkspacesData = (
  business: Business[],
  roles: UserRole[]
): WorkSpace[] => {
  return business?.map((item) => {
    return {
      businessId: item._id as string,
      businessName: item.organizationName as string,
      role: getRoleByBusiness(item._id as string, roles),
    };
  });
};
