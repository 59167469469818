/* eslint-disable @typescript-eslint/no-unused-vars */
import { AffectAction } from 'enum/AffectAction.enum';
import { CertificateDataBoardsValuesProps } from 'interfaces/CertificateData';
import {
  CertificateDataValuesTableColumnProps,
  CertificateOptions,
  FormOptions,
  HandleAffectsParams,
} from 'interfaces/CertificateTemplate';

import { applicableAffectsByAction } from '../applicableAffectsByAction';

// Function to create options for each phase row
const createOptions = (phaseRow: any): FormOptions[] => {
  const { columns } = phaseRow;
  const [circuitNumber, circuitDescription] = columns;
  const options: FormOptions[] = [];

  if (circuitNumber && circuitNumber.value?.trim() !== '') {
    const value =
      circuitDescription.value?.trim() !== ''
        ? circuitNumber.value + ' - ' + circuitDescription?.value
        : (circuitNumber.value as string);

    options.push({
      key: circuitNumber.value as string,
      value,
    });
  }

  return options;
};

export const changeCircuits = (
  handleAffectsParams: HandleAffectsParams
): CertificateOptions => {
  const {
    newVal: boardIdSelected,
    type,
    affects,
    getValues,
    defaultValues,
  } = handleAffectsParams;

  const newOptions: CertificateOptions = {};

  const affectsToApply = applicableAffectsByAction(
    AffectAction.CHANGE_CIRCUITS,
    type,
    affects,
    boardIdSelected
  );

  if (!affectsToApply?.length) return newOptions;

  const generateOptionsFromRows = (
    payload: {
      columns: CertificateDataValuesTableColumnProps[];
    },
    field: string
  ) => {
    const options = createOptions(payload);
    if (options.length > 0) {
      if (newOptions[field]) {
        newOptions[field] = [...newOptions[field], ...options];
      } else {
        newOptions[field] = options;
      }
    }
  };

  affectsToApply.forEach(({ field }) => {
    let selectedBoard: CertificateDataBoardsValuesProps[] = [];
    if (getValues) {
      selectedBoard = getValues(boardIdSelected as string);
    } else if (defaultValues) {
      selectedBoard = defaultValues[
        boardIdSelected as string
      ] as CertificateDataBoardsValuesProps[];
    }

    if (!selectedBoard) return;
    const circuits = selectedBoard.find(
      (input) => input.valueType === 'operations-table'
    );

    if (!circuits) return;
    const { valueOperationsTable } = circuits;

    valueOperationsTable?.forEach((phases) => {
      const { rows, mergedRow } = phases;
      if (mergedRow) {
        generateOptionsFromRows(mergedRow, field);
      } else {
        rows.forEach((phaseRow) => {
          generateOptionsFromRows(phaseRow, field);
        });
      }
    });
  });

  return newOptions;
};
