import styled from 'styled-components';

//* RED HEADER TH START
interface TableTHProps {
  columnSize?: string;
  lastColumn: boolean;
}
export const TableCertificateTableHeader = styled.div`
  background-color: #ff0000;
  border-radius: 12px 12px 0 0;
  min-height: 50px;
  display: flex;
  align-items: center;
`;

export const TableHeaderText = styled.span`
  width: 100%;
  display: flex;
  height: 100%;
`;

export const TableHeaderDivider = styled.div`
  min-width: 2px;
  background-color: white;
  margin-inline: 1px;
  margin-block: 10px;
`;

export const TableTH = styled.div<TableTHProps>`
  padding-block: 12px;
  display: flex;
  align-items: center;
  color: white;
  font-family: Lato;
  font-size: 17px;
  font-weight: 600;
  width: 100%;
  min-width: ${({ columnSize }) => columnSize ?? 'unset'};
  max-width: ${({ columnSize }) => columnSize ?? 'unset'};
  justify-content: ${({ columnSize }) =>
    columnSize ? 'center' : 'flex-start'};
  text-align: ${({ columnSize }) => (columnSize ? 'center' : 'flex-start')};
  margin-left: ${({ columnSize }) => {
    return columnSize ? '0' : '10px';
  }};
`;

//* RED HEADER TH END
//* NORMAL TABLE TD START
interface TableTDProps {
  columnSize?: string;
}

export const TableTD = styled.div<TableTDProps>`
  width: 100%;
  height: 100%;
  min-width: ${({ columnSize }) => columnSize ?? 'unset'};
  max-width: ${({ columnSize }) => columnSize ?? 'unset'};
  text-align: ${({ columnSize }) => (columnSize ? 'center' : 'left')};
`;

export const ActionContainer = styled.div`
  min-width: 64px;
  text-align: center;
`;

export const ButtonAction = styled.button`
  border: 0;
  background-color: unset;
  :disabled {
    opacity: 0.6;
  }
`;
