import { AppThunk } from 'store/@types';

import { apiGetContactTopics, apiSendContactUs } from 'api/contact';

import { ContactUsPayload } from '../@types/contact/index';
import { setError, setHelpTopics, setLoading } from './actions';

export const thunkFetchTopics =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      const topics = await apiGetContactTopics();
      dispatch(setHelpTopics(topics));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const thunkSendContact =
  (payload: ContactUsPayload, success: () => void): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      await apiSendContactUs(payload);
      success();
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const clearError = (): AppThunk => (dispatch) => {
  dispatch(setError(null));
};
