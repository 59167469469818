import React, { FC } from 'react';
import {
  ScrollbarProps,
  Scrollbars as OGScrollbars,
} from 'react-custom-scrollbars-2';

const CustomScrollbars = OGScrollbars as unknown as React.FC<ScrollbarProps>;

interface ScrollProps extends ScrollbarProps {
  style?: { [css: string]: string };
  customClasses?: string;
  marginLeft?: string;
}

const Scrollbars: FC<ScrollProps> = ({
  width,
  height,
  style,
  marginLeft,
  customClasses,
  ...componentProps
}) => {
  return (
    <CustomScrollbars
      autoHide
      style={{
        width: width || '100%',
        height,
        overflowY: 'hidden',
        marginLeft: marginLeft || '-15px',
        ...style,
      }}
      className={customClasses}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderTrackVertical={(props: any) => (
        <div
          {...props}
          style={{ display: 'none' }}
          className="track-vertical"
        />
      )}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      //   renderTrackHorizontal={(props: any) => (
      //     <div
      //       {...props}
      //       style={{ display: 'none' }}
      //     />
      //   )}
      {...componentProps}
    />
  );
};

Scrollbars.defaultProps = {
  customClasses: '',
  marginLeft: '-15px',
  style: {},
};

export default Scrollbars;
