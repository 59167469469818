import * as yup from 'yup';

import { errors, string } from 'utils/formUtils';

export const yupSchema = (customerName: string) =>
  yup.object().shape({
    customerName: string.oneOf(
      [customerName.toUpperCase()],
      errors.customerName.dontMatch
    ),
    customerNameConfirmation: string.oneOf(
      [customerName.toUpperCase()],
      errors.customerNameConfirmation.dontMatch
    ),
  });

export const defaultValues = {
  customerName: '',
  customerNameConfirmation: '',
};
