import {
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';

import {
  apiCertificateAutoSave,
  apiCertificateBoardAutoSave,
} from 'api/certificates';
import { FieldType } from 'enum/FieldType.enum';
import { AutoSaveSubmitParams } from 'interfaces/AutoSaveParams';
import {
  BoardGridCard,
  BoardGridCardValue,
  CertificateComments,
  CertificateDataBoardsValueProps,
  CertificateDataBoardsValuesProps,
  CertificateDataOperationsTableProps,
  CertificateDataValueProps,
  CertificateDataValuesTableRowProps,
} from 'interfaces/CertificateData';
import { CertTemplateField } from 'interfaces/CertificateTemplate';

import { updateOperationsTableColunmValue } from './circuitsUtils';
import { getBoardInfo, getBoardName } from './defaultValues';

type ArrayOrObject = unknown[] | Record<string, unknown>;

//**
//** PARSE +++++ TABLE +++++ VALUES FOR AUTOSAVE SUBMIT */
const parseTableValues = (
  initialPayload: any,
  value: any,
  templateFields?: CertTemplateField[] | null
) => ({
  ...initialPayload,
  value: '',
  valueTable: parseArray(value as any)
    .map((originalColumns: any, lineIndex: number) => {
      const columns = originalColumns.filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ isSubHeader, ...obj }: any) => {
          const values = Object.values(obj);
          return (
            values.length > 0 &&
            values.every((val) => val !== '' && val !== undefined)
          );
        }
      );
      return {
        index: lineIndex,
        columns,
      };
    })
    .map((row: CertificateDataValuesTableRowProps, index) => {
      if (templateFields) {
        const tempField = templateFields.find(
          (temp) => temp.fieldId === initialPayload.key
        );
        if (tempField) {
          tempField?.tableStructure?.forEach((struct, columnIndex) => {
            if (struct.columnType === 'auto-number') {
              const lineNumber =
                `${index + 1}`.length === 1 ? `0${index + 1}` : `${index + 1}`;
              row.columns[columnIndex].value = lineNumber;
            }
          });
        }
      }

      return row;
    }),
  valueTextbox: null,
  valueBoards: null,
});

//**
//** PARSE +++++ TABLE ARRAY +++++ VALUES FOR AUTOSAVE SUBMIT */
function parseArray(array: ArrayOrObject[]): unknown[][] {
  const result: unknown[][] = [];

  for (const item of array) {
    // check if the item is an array or an object
    if (Array.isArray(item)) {
      // handle the case where the item is an array
      result.push(item);
    } else {
      // handle the case where the item is an object
      const subItems = Object.values(item);
      result.push(subItems);
    }
  }
  return result;
}

export const autoSavePayloadConverter = (
  valueType: FieldType,
  key: string,
  value: unknown,
  templateFields?: CertTemplateField[] | null,
  comments?: CertificateComments
): CertificateDataValueProps[] => {
  //**
  //** PARSE +++++ TABLE ARRAY +++++ VALUES FOR AUTOSAVE SUBMIT */
  //**
  const initialPayload = {
    key,
    valueType,
    ...(comments && { comments }),
  } as CertificateDataValueProps;

  const parseStringValues = () => {
    return {
      ...initialPayload,
      value: value as string,
    } as CertificateDataValueProps;
  };

  const parseObjectValues = () =>
    ({
      ...initialPayload,
      ...(value as CertificateDataValueProps),
    } as CertificateDataValueProps);

  const parseFixedTableValues = () =>
    ({
      ...initialPayload,
      valueTable: value as CertificateDataValuesTableRowProps[],
    } as CertificateDataValueProps);

  const parseBoardValues = () =>
    ({
      ...initialPayload,
      valueBoards: value as CertificateDataBoardsValueProps[],
    } as CertificateDataValueProps);

  //**
  //** PARSE +++++ REST OF DIRECT COMPONENTS +++++ VALUES FOR AUTOSAVE SUBMIT */
  //**
  const types: Record<string, () => CertificateDataValueProps> = {
    [FieldType.Textbox]: () => parseStringValues(),
    [FieldType.Textarea]: () => parseStringValues(),
    [FieldType.TextboxCircuitTable]: () => parseStringValues(),
    [FieldType.ComboCircuitTable]: () => parseStringValues(),
    [FieldType.Combo]: () =>
      typeof value === 'string' ? parseStringValues() : parseObjectValues(),
    [FieldType.CustomerData]: () => parseObjectValues(),
    [FieldType.ComboModal]: () => parseStringValues(),
    [FieldType.ComboBoards]: () => parseStringValues(),
    [FieldType.LargeDatepicker]: () => parseStringValues(),
    [FieldType.SmallDatepicker]: () => parseStringValues(),
    [FieldType.SignatureArea]: () => parseObjectValues(),
    [FieldType.Radiobutton]: () => parseObjectValues(),
    [FieldType.Checkbox]: () => parseObjectValues(),
    [FieldType.TableModal]: () => parseTableValues(initialPayload, value),
    [FieldType.Table]: () =>
      parseTableValues(initialPayload, value, templateFields),
    [FieldType.TableFixed]: () => parseFixedTableValues(),
    [FieldType.BoardGrid]: () => parseBoardValues(),
  };

  return [types[valueType]()];
};

export const autoSaveBoardPayloadConverter = (
  key: string,
  value: unknown,
  getValues: UseFormGetValues<FieldValues>,
  setValue: UseFormSetValue<FieldValues>
): CertificateDataBoardsValuesProps[] => {
  //**
  //** PARSE +++++ TABLE ARRAY +++++ VALUES FOR AUTOSAVE SUBMIT */
  //**
  const { boardId, fieldId, boardIndex, boardGridId } = getBoardInfo(key);
  const borderName = getBoardName(boardGridId, Number(boardIndex), boardId);
  const boards = getValues(boardId) as CertificateDataBoardsValuesProps[];

  if (boards) {
    const newBoardValues = boards.map((e: CertificateDataBoardsValuesProps) => {
      if (e.key === fieldId) {
        if (
          [
            FieldType.Textbox,
            FieldType.Combo,
            FieldType.ComboBoards,
            FieldType.ComboCircuits,
          ].includes(e.valueType as FieldType)
        ) {
          return { ...e, value } as CertificateDataBoardsValuesProps;
        }

        if (e.valueType === FieldType.Checkbox) {
          const { value: parsedValue = '', valueTextbox = '' } =
            value as CertificateDataBoardsValuesProps;

          return {
            ...e,
            value: parsedValue,
            valueTextbox,
          } as CertificateDataBoardsValuesProps;
        }

        if (e.valueType === FieldType.OperationsTable) {
          // Enter here in circuit case
          if (key.includes('circuit')) {
            const currentOperationsTable = getValues(
              `${borderName}*${e.key}`
            ) as CertificateDataOperationsTableProps[];

            // Just apply changes on especific field, return void
            updateOperationsTableColunmValue(
              currentOperationsTable,
              key,
              value
            );

            return {
              key: e.key,
              valueType: e.valueType,
              valueOperationsTable: currentOperationsTable,
            } as CertificateDataBoardsValuesProps;
          }

          // Here when is created at the first time
          return {
            key: e.key,
            valueType: e.valueType,
            valueOperationsTable: value,
          } as CertificateDataBoardsValuesProps;
        }
      }

      // UPDATE BOARD VALUES CHANGED BY AFFECT
      const formValue = getValues(`${borderName}*${e.key}`);
      if (formValue !== e.value) {
        if (e.valueType === FieldType.OperationsTable) {
          return {
            key: e.key,
            valueType: e.valueType,
            valueOperationsTable: formValue ?? [],
          } as CertificateDataBoardsValuesProps;
        }

        if (e.valueType === FieldType.Checkbox) {
          const { value: parsedValue = '', valueTextbox = '' } =
            formValue as CertificateDataBoardsValuesProps;

          return {
            ...e,
            value: `${parsedValue}`,
            valueTextbox,
          } as CertificateDataBoardsValuesProps;
        }

        return {
          ...e,
          value: formValue,
        } as CertificateDataBoardsValuesProps;
      }

      return e as CertificateDataBoardsValuesProps;
    });

    setValue(boardId, newBoardValues);
    return newBoardValues;
  }
  return [];
};

export const autoSaveSubmit = (params: AutoSaveSubmitParams) => {
  const {
    fieldId,
    getValues,
    setValue,
    type,
    value,
    certDataId,
    setASLoading,
    templateFields,
    comments,
    onSuccess,
  } = params;

  const {
    boardGridId,
    boardId,
    fieldId: changedFieldId,
    boardIndex,
  } = getBoardInfo(fieldId);

  //** HANDLE AUTOSAVE FOR BOARD COMPONENTS */
  if (fieldId.includes('board') && getValues && setValue) {
    const boards = getValues(boardGridId) as BoardGridCard[];
    const isComplete = boards.some(
      (board) => board.id === boardId && board.isComplete === true
    );

    const autoSavePayload = autoSaveBoardPayloadConverter(
      fieldId,
      value,
      getValues,
      setValue
    );

    const borderName = getBoardName(boardGridId, Number(boardIndex), boardId);
    setASLoading(true);
    apiCertificateBoardAutoSave(certDataId as string, boardId, {
      key: boardGridId,
      values: autoSavePayload,
      isComplete: isComplete,
    })
      .then((data) => {
        onSuccess(data);
        const boards: BoardGridCard[] = getValues(boardGridId);
        const currentCards = boards?.map((card: any) => {
          if (card.id === boardId) {
            return {
              ...card,
              fields: card.fields.map((field: BoardGridCardValue) => {
                if (field.fieldId === changedFieldId) {
                  if (field.cardValue === 'board-title') {
                    //? update the board name used in the board-grid cards
                    setValue(card.id + '*name', value);
                  }
                  return { ...field, value: value };
                }

                // UPDATE GRID BOARD VALUES CHANGED AFTER SAVE
                field.value = getValues(`${borderName}*${field.fieldId}`);
                return field;
              }),
            };
          }
          return card;
        });
        setValue(boardGridId, currentCards);
      })
      .finally(() => setASLoading(false));
    return;
  }

  const autoSavePayload = autoSavePayloadConverter(
    type,
    fieldId,
    value,
    templateFields,
    comments
  );

  //** HANDLE AUTOSAVE FOR DIRECT FORM COMPONENTS */
  setASLoading(true);
  apiCertificateAutoSave(certDataId, {
    ...(type === FieldType.CustomerData && {
      customer: (value as CertificateDataValueProps).valueCustomer?.customer,
    }),
    values: autoSavePayload,
  })
    .then((data) => {
      onSuccess(data);
    })
    .finally(() => setASLoading(false));
};
