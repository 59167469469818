import { CertificateSettings, User } from 'store/@types/user';

import { Business, BusinessMedia } from 'interfaces/Business';
import { DELETE, GET, POST, PUT } from 'utils/requestTypes';
import { restCall } from 'utils/restHandler';

export const apiSetCertificateSettings = (
  id: string,
  certificateSettings: CertificateSettings
) =>
  restCall<Business>(
    PUT,
    `/business/${id}/certificate-settings`,
    certificateSettings
  );

export const apiSetBusinessImage = (
  id: string,
  businessMedia: BusinessMedia
) => {
  const { logoPhoto, schemeLogo } = businessMedia;
  const formData = new FormData();
  if (logoPhoto) {
    formData.append('logoPhoto', logoPhoto);
  }
  if (schemeLogo) {
    formData.append('schemeLogo', schemeLogo);
  }
  return restCall<Business>(POST, `/business/${id}/media`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const apiDeleteBusinessImage = (
  id: string,
  prop: 'logoPhoto' | 'schemeLogo'
) => {
  return restCall<Business>(DELETE, `/business/${id}/media?files=${prop}`);
};

export const apiGetUserFromBusiness = async (id: string) => {
  const business = await restCall<Business>(
    GET,
    `/business/${id}?populateTeam=true`
  );
  return business.team as User[];
};
