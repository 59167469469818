import { ApiError } from 'services/api';

export enum ContactActions {
  SET_LOADING = '@@contact/SET_LOADING',
  SET_ERROR = '@@contact/SET_ERROR',
  SET_HELP_TOPICS = '@@contact/SET_HELP_TOPICS',
}

export interface ContactState {
  readonly loading: boolean;
  readonly error: ApiError | unknown | null;
  readonly helpTopics: ContactTopic[];
}

export interface ContactUsPayload {
  topic: string;
  name: string;
  email: string;
  description: string;
}

export interface ContactTopic {
  _id: string;
  destination: string;
  template: string;
  name: string;
}

export interface ContactUsResponse {
  description: string;
  email: string;
  name: string;
  topic: string;
  _id: string;
}
