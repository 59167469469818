import { Customer } from 'interfaces/Customer';
import { DELETE, POST } from 'utils/requestTypes';
import { restCall } from 'utils/restHandler';

export const apiSetCustomerImage = (id: string, picture: File) => {
  const formData = new FormData();
  if (picture) {
    formData.append('picture', picture);
  }

  return restCall<Customer>(POST, `/customer/${id}/media`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const apiDeleteCustomerImage = (id: string) => {
  return restCall<Customer>(DELETE, `/customer/${id}/media?files=picture`);
};
