import React, { FC } from 'react';

import CreateCertIcon from 'assets/images/iconset/add_certificate_icon.svg';

import * as S from './styles';

interface CreateCertButtonProps {
  label: string;
  onClick: () => void;
}

const CreateCertButton: FC<CreateCertButtonProps> = ({ label, onClick }) => {
  return (
    <S.CertificateButton onClick={onClick}>
      <img
        src={CreateCertIcon}
        alt="new certificate"
        width="16px"
        height="16px"
      />
      <span>{label}</span>
    </S.CertificateButton>
  );
};

export default CreateCertButton;
