import React, { FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { LoginPost, LoginResponse } from 'store/@types/user';

import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import useAuth from 'hooks/useAuth';
import withHelmet from 'hooks/withHelmet';
import { defaultToast } from 'utils/toast';

import { TextField } from 'components/Form/TextField';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';

import { ReduxProps } from '.';
import { defaultValues, schema } from './yupSchema';

import './styles.css';

const LoginPage: FC<ReduxProps> = ({ login, logout, user: reduxUser }) => {
  const navigate = useNavigate();
  const { user, setLogin } = useAuth();
  const { loading, error } = reduxUser;
  const [match] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (error) {
      defaultToast('E', "Sorry, we don't recognize your credentials");
    }
  }, [error]);

  // React hook form instance
  const methods = useForm<LoginPost>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  useEffect(() => {
    const invitedEmail = match.get('email');
    if (invitedEmail) {
      logout();
      methods.setValue('email', invitedEmail);
      methods.setFocus('password');
    }
  }, [match]);

  const onSubmit = (loginPayload: LoginPost) =>
    login(loginPayload, (successResponse?: LoginResponse) => {
      if (successResponse) {
        setLogin(successResponse);
        navigate('/dashboard');
      }
    });

  return (
    <>
      {loading && <SplashScreen />}
      {/* Validation email error */}
      <NoAuthBasePage title={t('login_title')} subTitle={t('login_subtitle')}>
        <FormProvider {...methods}>
          <Form
            id="user-login"
            onSubmit={methods.handleSubmit((data) => {
              onSubmit(data);
            })}
          >
            <Row className="mb-3">
              <TextField xs={6} fieldId="email" label="Email" />
              <TextField
                xs={6}
                type="password"
                fieldId="password"
                label="Password"
              />
            </Row>
            <Button className="button-primary float-right" type="submit">
              Login
            </Button>
          </Form>
        </FormProvider>

        <div id="forgot-login">
          Forgot your credentials?
          <br />
          Nothing to worry,{' '}
          <Link className="bold red" to="/password-recovery">
            we&apos;re here to help.
          </Link>
        </div>
        <div id="create-account">
          If you can&apos;t have an account yet, simply{' '}
          <Link className="bold red" to="/register">
            tap here to sign up in 2 minutes.
          </Link>
        </div>
      </NoAuthBasePage>
    </>
  );
};

export default withHelmet(LoginPage);
