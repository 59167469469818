import { RoleEnum } from 'store/@types/user';
import * as yup from 'yup';

import { AffectAction } from 'enum/AffectAction.enum';
import { FieldType } from 'enum/FieldType.enum';
import { RequiredValueFunctionTypeEnum } from 'enum/RequiredValueFunctionType.enum';
import { ValidationType } from 'enum/ValidationType.enum';
import {
  CertificateOptions,
  CertTemplateField,
} from 'interfaces/CertificateTemplate';
import { hasTextboxField } from 'utils/certificates/helpers';
import {
  boolean,
  number,
  stringRequired,
  stringWithCustomLength,
} from 'utils/formUtils';

import { getCircuitDesignationFieldId } from './circuitsUtils';
import { getBoardInfo } from './defaultValues';

const types: {
  [typeName: string]: any;
} = {
  textbox: stringWithCustomLength,
  textarea: stringWithCustomLength,
  'customer-data': stringWithCustomLength,
  'combo-modal': stringWithCustomLength,
  'large-datepicker': stringWithCustomLength,
  'small-datepicker': stringWithCustomLength,
  'signature-area': stringWithCustomLength,
  signature: yup.object().shape({}),
  radiobutton: yup.object().shape({
    key: stringWithCustomLength,
    value: stringWithCustomLength,
    valueType: stringWithCustomLength,
    valueTextbox: stringWithCustomLength,
  }),
  combo: stringWithCustomLength,
  checkbox: yup.object().shape({
    key: stringRequired,
    value: boolean,
    valueType: stringRequired,
    valueTextbox: stringWithCustomLength,
  }),
};

export const createFormValidationSchema = (
  fields: CertTemplateField[],
  defaultValues: { [key: string]: unknown },
  currentRole: RoleEnum
) => {
  const objectSchema: Record<string, yup.Schema<unknown, unknown>> = {};

  fields.forEach(
    ({
      type: fieldType,
      validations,
      affects,
      hasTextbox,
      options,
      permissions,
      ...field
    }) => {
      const fieldKey: string = (field as any)['fieldId'];
      const affectsOptions = (defaultValues as CertificateOptions)[
        `${fieldKey}options`
      ];

      const validationTypes = {
        [ValidationType.IsRequired]: (validationField: any, error: string) => {
          /*
           * Normal key:value fields that require a value differente than null/empty
           */
          if (
            [
              FieldType.Textbox,
              FieldType.Textarea,
              FieldType.ComboBoards,
              FieldType.ComboModal,
              FieldType.LargeDatepicker,
              FieldType.SmallDatepicker,
            ].includes(fieldType)
          ) {
            if (affectsOptions?.length) {
              return validationField.when(`${fieldKey}options`, {
                is: (currentVal: unknown) => Boolean(currentVal),
                then: validationField.required(error),
              });
            }
            return validationField.required(error);
          }
          /*
           * Signature Area Validation
           */
          if (FieldType.SignatureArea === fieldType) {
            return yup.object({
              value: yup.string().required(error),
            });
          }
          /*
           * radiobutton Textbox Validation
           */
          if (FieldType.Radiobutton === fieldType) {
            return yup.object({
              value: yup.string().required(error),
              valueTextbox: yup.string().when('value', {
                is: (curentVal: string) =>
                  hasTextboxField(
                    fieldType,
                    curentVal,
                    affectsOptions || options
                  ),
                then: yup.string().required(error),
              }),
            });
          }
          /*
           * combo with Textbox Validation
           */
          if (FieldType.Combo === fieldType && hasTextbox) {
            if (affectsOptions) {
              return yup.object({
                value: yup.string().when(`${fieldKey}options`, {
                  is: (currentVal: unknown[]) => currentVal?.length > 0,
                  then: yup.string().required(error),
                  otherwise: yup.string(),
                }),
              });
            }
            return yup.object({
              value: yup.string().required(error),
            });
          }
          /*
           * combo Validation without textbox
           */
          if (FieldType.Combo === fieldType && !hasTextbox) {
            if (affectsOptions) {
              return yup.string().when(`${fieldKey}options`, {
                is: (currentVal: unknown[]) => currentVal?.length > 0,
                then: yup.string().required(error),
                otherwise: yup.string(),
              });
            }
            return yup.string().required(error);
          }
          /*
           * circuit combo and text fields Validation
           */
          if (
            [
              FieldType.ComboCircuitTable,
              FieldType.TextboxCircuitTable,
            ].includes(fieldType)
          ) {
            const designationFieldId = getCircuitDesignationFieldId(
              field.fieldId
            );

            if (field.fieldId === designationFieldId) {
              if (affectsOptions?.length) {
                return validationField.when(`${fieldKey}options`, {
                  is: (currentVal: unknown) => Boolean(currentVal),
                  then: validationField.required(error),
                });
              }
              return validationField.required(error);
            } else {
              if (affectsOptions?.length) {
                return validationField.when(
                  [`${fieldKey}options`, designationFieldId],
                  {
                    is: (currentVal: unknown, designationVal: string) =>
                      Boolean(currentVal) && designationVal !== 'SPARE',
                    then: validationField.required(error),
                  }
                );
              }
              return validationField.when(designationFieldId, {
                is: (designationVal: string) => designationVal !== 'SPARE',
                then: validationField.required(error),
              });
            }
          }
          /*
           * checkbox Textbox Validation
           */
          if (FieldType.Checkbox === fieldType) {
            return yup.object().shape({
              value: yup.string().required(error),
              valueTextbox: yup.string().when('value', {
                is: (curentVal: string) => curentVal === 'true',
                then: yup.string().required(error),
              }),
            });
          }

          /*
           * table Validation
           */
          if (FieldType.Table === fieldType) {
            const columnValidations: any = {};

            field.tableStructure?.forEach((col, colIndex) => {
              columnValidations[colIndex] = yup.object().shape({
                index: yup.number().required(error),
                value: yup.string().required(error),
                valueType: yup.string().required(error),
              });
            });

            return yup.array().of(yup.object().shape(columnValidations));
          }

          /*
           * table fixed Validation
           */
          if (FieldType.TableFixed === fieldType) {
            const columnSchema = yup.object().shape({
              index: yup.number().required(),
              value: yup.string().trim().required(),
              valueType: yup.string().required(),
            });

            const itemSchema = yup.object().shape({
              index: yup.number().required(),
              columns: yup.array().when('isSubHeader', {
                is: (isSubHeader) => !isSubHeader,
                then: yup.array().of(columnSchema).min(1),
                otherwise: yup.array(),
              }),
              isSubHeader: yup.boolean(),
            });

            if (affectsOptions) {
              return yup.array().when(`${fieldKey}options`, {
                is: (fieldOptions: unknown[]) => fieldOptions?.length > 0,
                then: yup.array().of(itemSchema),
                otherwise: yup.array(),
              });
            }
            return yup.array().of(itemSchema);
          }

          /*
           * board lenght Validation
           */
          if (FieldType.BoardGrid === fieldType) {
            return yup.array().min(1, error);
          }

          return validationField;
        },
        [ValidationType.TextboxTequiresValue]: (
          validationField: any,
          error: string
        ) => {
          /*
           * Combo with text box Validation
           */
          if (fieldType === FieldType.Combo && hasTextbox) {
            return yup.object({
              valueTextbox: yup.string().when('value', {
                is: (curentVal: string) =>
                  hasTextboxField(
                    fieldType,
                    curentVal,
                    affectsOptions || options
                  ),
                then: yup.string().required(error),
              }),
            });
          }
          return validationField;
        },
        [ValidationType.IfRequiredIf]: (
          validationField: any,
          error: string,
          fieldId?: string
        ) => {
          /*
           * Normal key:value fields that require a value differente than null/empty
           */
          if (fieldType === FieldType.ComboCircuitTable && fieldId) {
            return validationField.when(fieldId, {
              is: (currentVal: string) => Boolean(currentVal),
              then: yup.string().required(error),
            });
          }
        },
      };

      let inputValidationObject = types[fieldType] || stringWithCustomLength;

      if (
        validations &&
        (!permissions ||
          (permissions && permissions?.enabled.includes(currentRole)))
      ) {
        validations.forEach(({ type, error, fieldId }) => {
          if (type === ValidationType.IsRequired) {
            inputValidationObject = validationTypes[ValidationType.IsRequired](
              inputValidationObject,
              error
            );
          }

          if (type === ValidationType.TextboxTequiresValue) {
            inputValidationObject = validationTypes[
              ValidationType.TextboxTequiresValue
            ](inputValidationObject, error).concat(inputValidationObject);
          }

          if (type === ValidationType.IfRequiredIf) {
            inputValidationObject = validationTypes[
              ValidationType.IfRequiredIf
            ](inputValidationObject, error, fieldId).concat(
              inputValidationObject
            );
          }
        });
      }

      /*
       * operations table fields with lengh validation inside board
       */
      if (
        field.fieldId.includes('board') &&
        fieldType === FieldType.OperationsTable
      ) {
        const requiredField = field.requiredValues?.find(
          (req) => req.function === RequiredValueFunctionTypeEnum.MULTIPLIER
        );

        if (requiredField?.fieldId) {
          const { boardGridId, boardId, boardIndex } = getBoardInfo(
            field.fieldId
          );
          const requiredFieldId = `${boardGridId}*board*${boardIndex}*${boardId}*${requiredField?.fieldId}`;
          inputValidationObject = yup.array().when(requiredFieldId, {
            is: (currentVal: string) => currentVal !== 'N/A',
            then: yup.array().min(1),
          });
        }
      }

      objectSchema[fieldKey] = inputValidationObject;

      // AFTER APPLY ALL AFFECTS, ALSO APPLY NUMBER VALIDATION ON ALl field00023options
      if (affects?.length) {
        for (const { action } of affects) {
          if (action === AffectAction.CHANGE_OPTIONS) {
            objectSchema[`${fieldType}options`] = number;
          }
        }
      }
    }
  );
  return objectSchema;
};
