import { Customer } from 'interfaces/Customer';
import { retrieveMonth } from 'utils/retrieveMonth';

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const formatDate = (date?: string, showYear?: boolean) => {
  if (date) {
    const splitedDate = date.split('-');
    const month = retrieveMonth(splitedDate[1]);
    const year = showYear ? ` ${splitedDate[0]}` : '';

    return `${splitedDate[2].substring(0, 2)} ${month}${year}`;
  }
};

export function deleteEmptyStrings(obj: any) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (Array.isArray(obj[key])) {
        // check if the value is an array
        for (let i = obj[key].length - 1; i >= 0; i--) {
          if (typeof obj[key][i] === 'object') {
            deleteEmptyStrings(obj[key][i]); // recursively call deleteEmptyStrings if the array element is an object
          } else if (obj[key][i] === '') {
            obj[key].splice(i, 1); // remove the empty string element from the array
          }
        }
      } else {
        deleteEmptyStrings(obj[key]); // recursively call deleteEmptyStrings if the value is an object but not an array
      }
    } else if (obj[key] === '') {
      delete obj[key]; // delete the key-value pair if the value is an empty string
    }
  }
  return obj; // return the modified object
}

export const parseCustomerAddress = ({
  name,
  headquartersAddress,
}: Customer) => {
  const { street, city, county, postalCode } = headquartersAddress;
  const countyField = county ? `${county}, ` : '';

  return {
    name,
    address: `${street}, ${city}, ${countyField}${postalCode}`,
  };
};
