import { IDispatchType } from 'store/@types';
import { ContactActions as Actions, ContactTopic } from 'store/@types/contact';

import { contactActions } from './reducer';

export const setLoading = (loading = true): IDispatchType =>
  contactActions[Actions.SET_LOADING](loading);

export const setHelpTopics = (payload: ContactTopic[]): IDispatchType =>
  contactActions[Actions.SET_HELP_TOPICS](payload);

export const setError = (payload: unknown): IDispatchType =>
  contactActions[Actions.SET_ERROR](payload);
