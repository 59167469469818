export { BoardGrid } from './BoardGrid';
export { ButtonLabeled } from './ButtonLabeled';
export { Checkbox } from './Checkbox';
export { Combo } from './Combo';
export { ComboModal } from './ComboModal';
export { DatePicker } from './DatePicker';
export { Divider } from './Divider';
export { FormLabel } from './FormLabel';
export { InfoField } from './InfoField';
export { OperationsTable } from './OperationsTable';
export { PickCustomer } from './PickCustomer';
export { RadioButton } from './RadioButton';
export { SignatureComponent as Signature } from './Signature';
export { Table } from './Table';
export { TableModal } from './TableModal';
export { TextField } from './TextField';
export { UploadPhoto } from './UploadPhoto';

export const getError = (fieldId: string, errors: any) => {
  const properties = fieldId.split('.'); // split the string into an array of property names
  let error = errors as any;
  for (let i = 0; i < properties.length; i++) {
    if (error) {
      error = error[properties[i]];
    }
  }
  return error;
};
