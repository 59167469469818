import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 50px !important;
  padding: 0 40px;

  background-color: var(--color-secundary);

  ul {
    display: flex;
    align-items: center;
    gap: 54px;

    list-style: none;

    li {
      a {
        height: 50px !important;
        display: flex;
        align-items: center;
        gap: 12px;
      }

      span {
        max-width: 80px;
        position: relative;

        font-size: 14px;
        font-weight: bold;

        color: white;

        &.active::after {
          content: '';
          width: 80px;
          height: 1px;

          background-color: white;
          position: absolute;
          bottom: -4px;
          left: 0;
        }
      }
    }
  }
`;
