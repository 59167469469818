/* eslint-disable arrow-body-style */
import { useContext } from 'react';
import { AuthContext, AuthProps } from 'context/AuthProvider/AuthProvider';

const useAuth = () => {
  const context = useContext<AuthProps>(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export default useAuth;
