import React from 'react';
import { ConnectedAuthProvider } from 'context/AuthProvider';
import { CertificatesProvider } from 'context/CertificateProvider';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Routes from 'routes';

import { store } from './store';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/global.css';
import 'assets/styles/inputs.css';
import 'assets/styles/buttons.css';
import 'assets/styles/modals.css';
import 'assets/i18n';

function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <ConnectedAuthProvider>
        <CertificatesProvider>
          <Routes />
        </CertificatesProvider>
      </ConnectedAuthProvider>
    </Provider>
  );
}

export default App;
