import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Form, Modal, Row } from 'react-bootstrap';

import { signatureRegister } from 'api/signature';
import { SignatureValue } from 'interfaces/Signature';
import { objectToFormData } from 'utils/formUtils';

import { TextField, UploadPhoto } from 'components/Form';

import { defaultValues, yupSchema } from './yupSchema';

import './styles.css';

interface SignatureAddProps {
  submitNewSignature?: boolean;
  signatures?: SignatureValue[] | null;
  action: () => void;
  handleSave: (data: SignatureValue) => void;
  onLoadNew?: (file: File) => void;
}

const SignatureAdd: React.FC<SignatureAddProps> = ({
  submitNewSignature,
  action,
  signatures,
  handleSave,
  onLoadNew,
}) => {
  const [signatureFile, setSignatureFile] = useState<File | null>(null);
  const [signatureBase64, setSignatureBase64] = useState<string>('');
  // React hook form instance
  const formMethods = useForm<SignatureValue>({
    mode: 'onTouched',
    resolver: yupResolver(yupSchema),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  function saveSignature(values: Omit<SignatureValue, 'user'>) {
    if (submitNewSignature) {
      const newData = {
        ...values,
        signature: signatureFile,
        default: signatures?.length === 0 ? 'true' : 'false',
      };
      const formData = objectToFormData(newData);

      signatureRegister(formData).then((signatureSaved) => {
        handleSave(signatureSaved);
        if (onLoadNew && signatureFile) {
          onLoadNew(signatureFile);
        }
      });
    } else {
      handleSave({ ...values, signature: signatureBase64 });
      if (onLoadNew && signatureFile) {
        onLoadNew(signatureFile);
      }
    }
  }

  return (
    <Modal show backdrop="static" className="modal-v1">
      <Modal.Header>
        <Modal.Title>Upload new signature</Modal.Title>
      </Modal.Header>
      <FormProvider {...formMethods}>
        <Form
          id="modal-add-signature-form"
          onSubmit={(e) => {
            e.stopPropagation();
            formMethods.handleSubmit(() => {
              saveSignature(formMethods.watch());
            })(e);
          }}
        >
          <Modal.Body>
            <Row>
              <TextField
                required
                label="Add a name to your signature"
                fieldId="description"
              />
              <UploadPhoto
                required
                inputSize={2}
                label="Selected file"
                fieldId="signature"
                onload={(file, base64) => {
                  setSignatureBase64(base64);
                  setSignatureFile(file);
                  formMethods.setValue('signature', base64);
                }}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="button-secondary" onClick={action}>
              Cancel
            </Button>
            <Button
              className="button-primary"
              type="submit"
              form="modal-add-signature-form"
            >
              Upload
            </Button>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default SignatureAdd;
