import * as yup from 'yup';

import { errors, string } from 'utils/formUtils';

export const yupSchema = (userName: string, userEmail: string) =>
  yup.object().shape({
    confirmUserName: string.oneOf([userName], errors.confirmUserName.dontMatch),
    confirmUserEmail: string.oneOf(
      [userEmail],
      errors.confirmUserEmail.dontMatch
    ),
  });

export const defaultValues = {
  confirmUserName: '',
  confirmUserEmail: '',
};
