export enum FieldType {
  BoardGrid = 'board-grid',
  Boolean = 'boolean',
  Checkbox = 'checkbox',
  Combo = 'combo',
  CustomerData = 'customer-data',
  Datepicker = 'datepicker',
  DescriptionField = 'description-field',
  Divider = 'divider',
  Label = 'label',
  LargeDatepicker = 'large-datepicker',
  OperationsTable = 'operations-table',
  Radiobutton = 'radiobutton',
  SignatureArea = 'signature-area',
  SmallDatepicker = 'small-datepicker',
  String = 'string',
  Subtitle = 'subtitle',
  Table = 'table',
  TableFixed = 'table-fixed',
  TableModal = 'table-modal',
  Textbox = 'textbox',
  Textarea = 'textarea',
  ComboModal = 'combo-modal',
  ComboBoards = 'combo-boards',
  ComboCircuits = 'combo-circuits',
  ComboCircuitTable = 'combo-circuit-table', // IT THERE IS ONLY ON FRONTEND
  TextboxCircuitTable = 'text-circuit-table', // IT THERE IS ONLY ON FRONTEND
  Signature = 'signature',
}
