import styled from 'styled-components';

import { Row } from 'react-bootstrap';

type MenuTabItemButtonProps = {
  selectedButton?: boolean;
  errorButton?: boolean;
  highLightButton?: boolean;
};

export const MenuTabStickyRow = styled(Row)`
  @media only screen and (min-width: 992px) {
    white-space: nowrap;
  }
  position: sticky;
  top: 0;
  z-index: 3;
  padding-top: ${({ paddingtop }) => (paddingtop ? paddingtop : '40px')};
  background-color: #fdfeff;
`;

export const MenuTabItemButton = styled.button<MenuTabItemButtonProps>`
  height: 36px;
  padding: 0 13px;
  border-radius: 12px;
  margin: 0 20px 20px 0px;
  color: ${({ selectedButton }) => (selectedButton ? '#ffffff' : '#666666')};
  background: ${({ selectedButton }) =>
    selectedButton ? '#4fa3a1' : '#f2f2f2'};

  font-size: 14px;
  font-weight: 600;

  box-shadow: none;
  border: ${({ errorButton, highLightButton }) =>
    (highLightButton && '2px solid #4fa3a1') ||
    (errorButton && '1px solid var(--color-secundary)') ||
    '2px solid transparent'};
`;
