import React, { FC, useMemo, useState } from 'react';
import clsx from 'clsx';
import { disabledFieldOnStates } from 'constants/certificate.constants';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RoleEnum } from 'store/@types/user';

import { apiCertificateDeleteBoard } from 'api/certificates';
import plusIcon from 'assets/images/iconset/signature_add.svg';
import deleteIcon from 'assets/images/iconset/table_delete.svg';
import { CertificateState } from 'enum/certificateDataState.enum';
import { FieldType } from 'enum/FieldType.enum';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import {
  BoardGridCard,
  CertificateComments,
  CertificateDataBoardsValueProps,
} from 'interfaces/CertificateData';
import { getBoardInfo, getBoardName } from 'utils/certificates/defaultValues';
import { defaultToast } from 'utils/toast';

import { CarouselItemProps } from 'components/Carousel';
import Comments from 'components/Comments';
import { FormLabelContainer } from 'components/Form/styles';
import ModalConfirm from 'components/modals/ModalConfirm';

import './styles.css';

interface BoardGridCardProps
  extends Omit<
    CarouselItemProps,
    'title' | 'topTitle' | 'clientName' | 'image' | 'id'
  > {
  boardItem: BoardGridCard | null;
  boardName: string;
  fieldId: string;
  addCard?: boolean;
}

const BoardCard: FC<BoardGridCardProps> = ({
  onClick,
  addCard,
  boardItem,
  boardName,
  fieldId,
}) => {
  const { getValues, setValue } = useFormContext();
  const { certData, autoSave, loadCertificateTemplate, clearAll } = useCerts();
  const { t } = useTranslation();
  const { currentRole } = useAuth();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fieldDisabled =
    disabledFieldOnStates.includes(
      certData?.certificateState as CertificateState
    ) || currentRole === RoleEnum.MANAGER;

  const parsedValues = useMemo(() => {
    if (!boardItem) return null;
    const { fields } = boardItem;

    return {
      boardDescription: fields.find((e) => e.cardValue === 'board-title') ?? {
        value: '',
      },
      boardLocation: fields.find((e) => e.cardValue === 'board-subtitle') ?? {
        value: '',
      },
      rest: fields
        .filter(
          (e) =>
            e.cardValue !== 'board-subtitle' && e.cardValue !== 'board-title'
        )
        .map((board) => {
          if (board.type === 'combo-boards') {
            if (board.value !== 'Origin' && board.value !== '') {
              return { ...board, value: getValues(board.value + '*name') };
            }
          }
          return board;
        }),
    };
  }, [boardItem]);

  const handleCommentsSave = (comments?: CertificateComments) => {
    if (getValues && setValue != null) {
      const { boardGridId, boardId } = getBoardInfo(boardName);
      const boardGrids = getValues(boardGridId) as BoardGridCard[];
      const values = boardGrids.map((board, index) => {
        const isBoard = boardId === board.id;
        const otherBoarderName = getBoardName(boardGridId, index, board.id);
        return {
          id: board.id,
          isComplete: board.isComplete,
          values: getValues(board.id),
          comments: getValues(`${otherBoarderName}comments`),
          ...(isBoard && { comments }),
        } as CertificateDataBoardsValueProps;
      });

      autoSave({
        type: FieldType.BoardGrid,
        fieldId,
        value: values,
        getValues,
        setValue,
      });
    }
  };
  function deleteBoard() {
    apiCertificateDeleteBoard(certData?._id, boardItem?.id, fieldId)
      .then(() => {
        setShowDeleteModal(false);
        clearAll();
        defaultToast('S', `${t('toaster_delete_board')}`);
        certData?.template?._id &&
          loadCertificateTemplate(certData?.template?._id, certData?._id);
      })
      .catch(() => defaultToast('E'));
  }
  return (
    <>
      {!addCard && boardItem && parsedValues && (
        <div className="board-grid-card">
          <FormLabelContainer className="title-container">
            <p className="title-top truncate">
              {parsedValues.boardDescription.value}
            </p>
            <div className="boardgrid-buttons-wrapper">
              <Comments
                fieldId={boardName}
                isCertificate={true}
                disabledField={fieldDisabled}
                handleOnChange={handleCommentsSave}
              />
              {!fieldDisabled && (
                <img
                  onClick={() => setShowDeleteModal(true)}
                  src={deleteIcon}
                  className="delete-icon-btn"
                />
              )}
            </div>
          </FormLabelContainer>
          <button
            type="button"
            className="board-grid-card-button"
            onClick={() => {
              if (onClick) onClick();
            }}
          >
            <div
              className={clsx('board-grid-card-body', {
                completed: boardItem?.isComplete,
              })}
            >
              <p className="text truncate green">
                {parsedValues.boardLocation.value}
              </p>
              {parsedValues.rest.map(({ fieldId, label, value }) => (
                <p key={fieldId + '-' + label} className="text truncate">
                  {label}: {value}
                </p>
              ))}
            </div>
          </button>
        </div>
      )}
      {addCard && !fieldDisabled && (
        <div className="board-grid-card">
          <div className="add-board">
            <button
              type="button"
              className="board-grid-card-button"
              onClick={() => {
                if (onClick) onClick();
              }}
            >
              <img src={plusIcon} alt="Add board" />
              <p>Add board</p>
            </button>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <ModalConfirm
          title={t('delete_boards_modal_title')}
          text={
            <>
              Are you sure you want to remove Board &nbsp;
              {parsedValues?.boardDescription.value}?
              <br />
              <br />
              This action cannot be undone, and all boards and circuits data
              will be lost.
            </>
          }
          cancelText={t('btn_remove').toString()}
          actionConfirm={() => {
            setShowDeleteModal(false);
          }}
          confirmText={t('btn_cancel').toString()}
          actionCancel={() => deleteBoard()}
        />
      )}
    </>
  );
};

export default BoardCard;
