declare global {
  interface Window {
    __RUNTIME_CONFIG__: EnvConfig;
  }
}

interface EnvConfig {
  REACT_APP_BACKEND_URL: string;
  REACT_APP_GET_ADDRESS_API_KEY: string;
}

export const envConfig: EnvConfig = {
  ...process.env,
  ...window.__RUNTIME_CONFIG__,
};
