import moment, { Moment } from 'moment';
import * as yup from 'yup';

export const errors = {
  defaultSuccess: 'Your changes were saved successfully!',
  defaultError: 'Something went wrong, please try again later.',
  email: {
    personalRequired: 'Please enter your email.',
    required: 'Please enter a valid email.',
    dontMatch: 'Emails don’t match. Please check.',
  },
  password: {
    personalRequired: 'Please define a password',
    required: 'Please define a valid password',
    passwordSecure:
      'Please define a password according to the rules specified.',
    dontMatch: 'Passwords don’t match. Please check.',
  },
  customerName: {
    dontMatch: 'Customer name does not match',
  },
  customerNameConfirmation: {
    dontMatch: 'Customer name does not match',
  },
  confirmUserName: {
    dontMatch: 'The name must match your display name.',
  },
  confirmUserEmail: {
    dontMatch: 'The email must match your account email.',
  },
};

export const regex = {
  url: /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  nif: /^[0-9]{9}$/,
  passwordSecure:
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^*?,!~:;&+=_.-])(?=\S+$).{8,}$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export const removeTimezone = (
  date: Date | Moment | string
): Moment | Date | string =>
  typeof date === 'string' ? date.split('+')[0] : date;

export const parseDateToShow = (date: Moment | string, hours = false): string =>
  moment(removeTimezone(date)).format(
    hours ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY'
  );

export const parseDateToManage = (
  date: Date | Moment | string,
  clearHour = false
): string =>
  clearHour
    ? `${moment(removeTimezone(date)).format('YYYY/MM/DD')} 00:00:00`
    : moment(removeTimezone(date)).format('YYYY/MM/DD HH:mm:ss');

export const requiredMessage = 'Field cannot be empty';

const parseDateString = (
  value: string | Moment,
  originalValue: Moment | string
): Date | string | Moment | boolean | null => {
  if (!originalValue) return null;

  let payload = null;
  payload = originalValue;

  if (typeof originalValue === 'object')
    payload = new Date(parseDateToManage(originalValue));

  if (typeof originalValue === 'string') {
    if (
      originalValue.trim() === '' ||
      originalValue.trim().toLowerCase() === 'invalid date'
    ) {
      payload = false;
    } else {
      payload = value;
    }
  }

  return payload;
};

export const arrayNotEmpty = yup.array().min(1, requiredMessage);

export const dateDefault = yup
  .date()
  .default(undefined)
  .nullable()
  .transform(parseDateString)
  .typeError('Must be a valid date');

export const dateRequired = dateDefault.required(requiredMessage);

export const string = yup
  .string()
  .nullable()
  .max(250, 'This field exceeds the maximum limit (250)')
  .transform((value: string, originalValue: string) =>
    originalValue && originalValue.trim && originalValue.trim() === ''
      ? null
      : value
  );
export const stringWithCustomLength = yup
  .string()
  .nullable()
  .transform((value: string, originalValue: string) =>
    originalValue && originalValue.trim && originalValue.trim() === ''
      ? null
      : value
  );

export const emailValidator = string.email(errors.email.required);

export const emailValidatorRequired = string
  .email(errors.email.required)
  .required(errors.email.required);

export const stringLong = yup
  .string()
  .nullable()
  .max(65535, 'This field exceeds the maximum limit (65535)')
  .transform((value: string, originalValue: string) =>
    originalValue && originalValue.trim && originalValue.trim() === ''
      ? null
      : value
  );
export const stringUnlimited = yup
  .string()
  .nullable()
  .transform((value, originalValue) =>
    originalValue && originalValue.trim && originalValue.trim() === ''
      ? null
      : value
  );

export const stringRequired = string.required(requiredMessage);
export const stringLongRequired = stringLong.required(requiredMessage);
export const stringUnlimitedRequired =
  stringUnlimited.required(requiredMessage);

export const boolean = yup.bool().typeError('Must be true or false value');

export const number = yup
  .number()
  .typeError('Must be a valid Number')
  .transform((value, originalValue) =>
    originalValue && originalValue.trim && originalValue.trim() === ''
      ? null
      : value
  )
  .nullable();
export const numberRequired = number.required(requiredMessage);

export const numberPositive = number.test(
  'bigger-than-zero',
  'Must be bigger than zero.',
  (data: number | null | undefined): boolean =>
    // return true -> the error will NOT show
    // return false -> the error will show
    data ? data > 0 : false
);
export const numberZeroOrPositive = number.test(
  'bigger-than-or-zero',
  requiredMessage,
  (data: number | null | undefined): boolean =>
    // return true -> the error will NOT show
    // return false -> the error will show
    data ? data >= 0 : false
);
export const numberPositiveRequired = numberPositive.required(requiredMessage);
export const numberZeroOrPositiveRequired =
  numberZeroOrPositive.required(requiredMessage);

export const passwordSecure = stringRequired
  .required('Your password must be at least 8 characters')
  .test(
    'password-strength',
    // 'Password is not strong enough',
    'Your password must be at least 8 characters',
    (data) => {
      const rules = {
        length:
          (data?.length as number) > 7 /* && (data?.length as number) < 25 */,
        // upperCase: data?.match(/[A-Z]/g) !== null,
        // lowerCase: data?.match(/[a-z]/g) !== null,
        // numberAndSpecial:
        // 	data?.match(/[0-9]/g) !== null && data?.match(/[ !@#$%&*()_+\-=[\]{};':"\\|,.<>/?]/) !== null
      };
      // return true -> the error will NOT show
      // return false -> the error will show
      return !Object.values(rules).includes(false);
    }
  );

export const objectToFormData = (
  obj: any,
  form?: FormData,
  namespace?: string
): FormData => {
  const fd: FormData = form ?? new FormData();
  let formKey: string;

  for (const property in obj) {
    if (obj[property]) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }

      // if the property is an array of objects
      if (Array.isArray(obj[property])) {
        obj[property].forEach((item: any, index: number) => {
          if (typeof item === 'object' && !(item instanceof File)) {
            objectToFormData(item, fd, `${formKey}[${index}]`);
          } else {
            // Handle array elements that are not objects
            fd.append(`${formKey}[${index}]`, item);
          }
        });
      } else if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File)
      ) {
        // Handle nested objects
        objectToFormData(obj[property], fd, formKey);
      } else {
        // Handle other types like strings or File objects
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
};
