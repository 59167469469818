import styled from 'styled-components';

import checkedIcon from 'assets/images/iconset/checkbox_checked.svg';
import uncheckedIcon from 'assets/images/iconset/checkbox_unchecked.svg';

export const UserContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const UserCard = styled.div`
  display: flex;
  width: 100%;
  height: 69px;
  background-color: #f4f4f4;
  border-radius: 12px;
  padding: 10px;
`;

export const ImageContainer = styled.div<{ corner?: boolean }>`
  display: flex;
  min-width: 50px;
  min-height: 50px;
  justify-content: center;
  align-items: center;

  ${({ corner }) => {
    return (
      corner &&
      `
      img {
        border-radius: 12px;
      }
      `
    );
  }}
`;

export const UserInfo = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  span {
    color: #666;
    font-family: Lato;
    font-style: normal;
    line-height: normal;
  }

  span:nth-child(1) {
    font-size: 17px;
    font-weight: 400;
  }

  span:nth-child(2) {
    font-size: 14px;
    font-weight: 700;
  }
`;
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 64px;
  justify-content: center;
  align-items: center;
  height: 69px;
  padding-left: 20px;
`;

export const CheckButton = styled.button<{ checked: boolean }>`
  border: 0;
  content: '';
  background-color: unset;
  min-width: 30px;
  min-height: 30px;
  border: none;
  box-shadow: none;
  border-radius: 25px;

  ${({ checked }) => {
    return checked
      ? `background: url(${checkedIcon});`
      : `background: url(${uncheckedIcon});`;
  }}
  background-size: 30px 30px;
  background-repeat: no-repeat;
`;
export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  padding-right: 10px;

  img {
    position: absolute;
    right: 20px;
  }
`;

export const SearchInput = styled.input`
  background-color: #f9f9f9;
  border-radius: 12px;
  width: 100%;
  height: 36px;
  margin-block: 10px;
  padding-left: 20px;
  font-weight: normal;
`;
