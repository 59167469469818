import styled from 'styled-components';

export const PresetCard = styled.div<{ highlightCard?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  width: 214px;
  min-width: 214px;
  height: 142px;
  margin-right: 18px;
  ${({ highlightCard }) => {
    if (highlightCard) {
      return `
        border: 1px solid #4fa3a1;
        border-radius: 12px;
        box-shadow: 0 0 10px 0 #4fa3a1;`;
    }
  }}
`;

export const Container = styled.div<{
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  cursor?: 'pointer';
}>`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 12px 10px;

  ${({ justifyContent }) => {
    return justifyContent ? `justify-content: ${justifyContent};` : '';
  }}
  ${({ cursor }) => {
    return cursor ? `cursor: ${cursor};` : '';
  }}
`;

export const CertificateType = styled.h1`
  color: #4fa3a1;
  font-family: Lato;
  font-size: 16px;
`;

export const PresetDeleteIcon = styled.img`
  color: #4fa3a1;
  height: '16px';
  width: '16px';
  cursor: pointer;
`;

export const PresetName = styled.p`
  display: block;
  line-height: normal;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 100%;
  color: #666666;
  font-family: Lato;
  font-size: 14px;
`;
