import { Signature } from 'interfaces/Signature';
import { GET, POST, PUT } from 'utils/requestTypes';
import { restCall } from 'utils/restHandler';

export const signatureRegister = (formData: FormData) =>
  restCall<Signature>(POST, '/signature', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const apiListAllSignatures = () =>
  restCall<Signature[]>(GET, '/signature');

export const apiGetDefaultSignature = () =>
  restCall<Signature[]>(GET, '/signature?defaultSignature=true');

export const apiSetDefaultSignature = (id: string) =>
  restCall<Signature[]>(PUT, `/signature/${id}`, { default: true });
