import { FC, useEffect, useMemo, useRef, useState } from 'react';

import comboOpen from 'assets/images/iconset/combo-open.svg';

import { FormControl } from 'components/Form/styles';

import * as S from './styles';

interface ComboCustomOptions {
  key: string;
  value: string;
  businessName: string;
  userRoleName: string;
}

interface ComboCustomProps {
  handleSave: (value: string) => void;
  disabled?: boolean;
  options?: Array<ComboCustomOptions>;
  height?: string;
  width?: string;
  value?: string;
}

const ComboWorkspace: FC<ComboCustomProps> = ({
  disabled,
  height,
  width,
  handleSave,
  value,
  options = [],
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownTop, setDropdownTop] = useState(0);
  const [currentOption, setCurrentOption] = useState<ComboCustomOptions>();
  const readOnlyInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const openDropdow = () => {
    if (readOnlyInputRef.current) {
      const { top } = readOnlyInputRef?.current?.getBoundingClientRect() ?? {};
      setDropdownTop(window.innerHeight - top > 256 ? 10 : -261);

      setDropdownOpen(true);
    }
  };

  useEffect(() => {
    const currentOption = options.find((option) => option.value === value);
    if (currentOption) {
      setCurrentOption(currentOption);
    } else if (options.length > 0) {
      setCurrentOption(options[0]);
    }
  }, [value]);

  const isUnnamed = useMemo(() => {
    return currentOption?.businessName === 'unnamed';
  }, [currentOption]);

  return (
    <S.ComboWrapper width={width} height={height}>
      {dropdownOpen && (
        <S.DropdownOverlay
          onClick={() => {
            setDropdownOpen(false);
          }}
        />
      )}
      <S.CustomSelect
        icon={comboOpen}
        ref={readOnlyInputRef}
        height={height}
        disabled={disabled}
        isVerticalCentered={!currentOption || isUnnamed}
        onClick={() => {
          if (!disabled) {
            openDropdow();
          }
        }}
      >
        {isUnnamed && (
          <S.TitlePlaceHolder>{currentOption?.userRoleName}</S.TitlePlaceHolder>
        )}
        {!isUnnamed && currentOption && (
          <>
            <S.TitlePlaceHolder>
              {currentOption?.businessName}
            </S.TitlePlaceHolder>
            <S.SubTitlePlaceHolder>
              {currentOption?.userRoleName}
            </S.SubTitlePlaceHolder>
          </>
        )}
        {!currentOption && (
          <S.TitlePlaceHolder>Select option</S.TitlePlaceHolder>
        )}
      </S.CustomSelect>
      <FormControl ref={inputRef} type="hidden" />
      {dropdownOpen && (
        <S.Dropdown top={dropdownTop}>
          {options?.map((option) => {
            const optionUnnamed = option?.businessName === 'unnamed';
            return (
              <S.DropdownItem
                key={'combobox-' + option.key + option.value}
                onClick={() => {
                  handleSave(option.value);
                  setCurrentOption(option);
                  setDropdownOpen(false);
                }}
              >
                {optionUnnamed && option?.userRoleName}
                {!optionUnnamed &&
                  option?.businessName + ', ' + option?.userRoleName}
              </S.DropdownItem>
            );
          })}
        </S.Dropdown>
      )}
    </S.ComboWrapper>
  );
};

export { ComboWorkspace };
