import { FC, useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { Col, FormControlProps } from 'react-bootstrap';

import selectIcon from 'assets/images/iconset/signature_add_select.svg';
import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';
import { FormOptions } from 'interfaces/CertificateTemplate';

import Comments from 'components/Comments';

import { TextField } from '..';
import { FormLabel, FormLabelContainer } from '../styles';
import { CommonProps } from '../Types';
import { PickModal } from './PickModal';

interface ComboModalProps extends FormControlProps, CommonProps {
  required?: boolean;
  otherOption?: boolean;
  options?: FormOptions[];
  [x: string]: unknown;
}

const ComboModal: FC<ComboModalProps> = ({
  xs,
  disabled,
  isCertificate,
  label,
  options,
  otherOption,
  fieldId,
  required,
  autoObservations,
}) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { autoSave, certOptions, handleAutoObservations } = useCerts();

  const comboOptions = useMemo(() => {
    return certOptions && certOptions[fieldId]
      ? certOptions[fieldId]
      : options || [];
  }, [certOptions, options]);

  const [openModal, toggleModal] = useState(false);

  const currentValue = useWatch({
    control,
    name: fieldId,
  });

  const handleSaveCallback = useCallback((data: string) => {
    setValue(fieldId, data, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
    if (isCertificate) {
      autoSave({
        type: FieldType.ComboModal,
        fieldId,
        value: data,
        getValues,
        setValue,
      });
      if (autoObservations) {
        handleAutoObservations({
          fieldId,
          autoObservations,
          getValues,
          setValue,
        });
      }
    }
  }, []);

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({ field }) => (
        <>
          <Col>
            <FormLabel>
              <FormLabelContainer>
                {label}
                <Comments
                  fieldId={fieldId}
                  disabledField={disabled}
                  isCertificate={isCertificate}
                  handleOnChange={(comments) => {
                    autoSave({
                      type: FieldType.ComboModal,
                      fieldId,
                      value: getValues(fieldId),
                      getValues,
                      setValue,
                      comments,
                    });
                  }}
                />
              </FormLabelContainer>
              {required && (
                <span className="required float-right">Required</span>
              )}
            </FormLabel>
          </Col>
          <TextField
            xs={xs}
            required
            hasError={errors[fieldId]}
            fieldId="null"
            disabled={disabled}
            value={field.value || ''}
            readOnly
            onClick={() => {
              toggleModal(true);
            }}
            inputImage={
              <img
                className="input_icon_placeholder"
                src={selectIcon}
                style={{ top: 25 }}
                alt="Add a customer"
                onClick={() => {
                  toggleModal(true);
                }}
              />
            }
          />
          {openModal && !disabled && (
            <PickModal
              fieldId={fieldId}
              current={currentValue}
              otherOption={otherOption}
              modalOptions={comboOptions}
              actionCancel={() => {
                toggleModal(false);
              }}
              actionConfirm={(selected) => {
                handleSaveCallback(selected);
                toggleModal(false);
              }}
            />
          )}
        </>
      )}
    />
  );
};

ComboModal.defaultProps = {
  xs: 12,
};

export { ComboModal };
