import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;q
`;

export const AssignEngineerButton = styled.button`
  border: 0;
  background-color: unset;
`;
