import React, { FC, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Button, Col, Row } from 'react-bootstrap';

import withHelmet from 'hooks/withHelmet';
import { ApiError } from 'services/api';
import { defaultToast } from 'utils/toast';

import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';

import { ReduxProps } from '.';

const UnverifiedPage: FC<ReduxProps> = ({
  logout,
  user: reduxUser,
  resendVerifyEmail,
}) => {
  const [successOperation, setSuccessOperation] = useState(false);
  const { token, usermail } = useParams();
  const { loading } = reduxUser;

  const onSubmit = async () => {
    if (token) {
      resendVerifyEmail(token, (success?: boolean, error?: ApiError) => {
        if (success) {
          setSuccessOperation(true);
          defaultToast('S', 'The email was sent successfully');
        } else if (error) {
          defaultToast('E');
        }
      });
    }
  };

  return (
    <>
      {loading && <SplashScreen />}
      {!successOperation && (
        <NoAuthBasePage
          title="Almost done"
          subTitle="You need to verify your account"
        >
          <div className="white-card-text-body margin-bottom">
            <p>We apologise, but your account is still pending verification.</p>
            <p>
              You need to request an activation code to be sent to your email
              address at <span className="bold">{usermail}</span> so that we can
              validate your account. The code will be valid for 2 hours from the
              moment you request it.{' '}
            </p>
            <br />
            <Row>
              <Col xs={6} className="align-self-end">
                <Link
                  className="gray bold"
                  to="#"
                  onClick={(event) => {
                    event.preventDefault();
                    logout(true);
                  }}
                >
                  Back to login screen
                </Link>
              </Col>
              <Col xs={6} className="align-self-end">
                <Button
                  className="button-primary float-right"
                  type="button"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Resend email
                </Button>
              </Col>
            </Row>
          </div>
        </NoAuthBasePage>
      )}
      {successOperation && (
        <NoAuthBasePage
          title="Please check your inbox"
          subTitle="To verify your account"
        >
          <div className="white-card-text-body margin-bottom">
            <p>
              Your account has been created successfully, but it is pending
              activation.
            </p>
            <p>
              Please check your inbox at{' '}
              <span className="bold">{usermail}</span> and click the link to
              verify your identity and start using your Doddle account.
              <br />
              Please be patient, as this might take a few minutes to arrive at
              your inbox.
            </p>
            <p>
              If you don’t receive anything in the next 10 minutes, please be
              sure to check your spam folder, as some servers can filter
              automated responses messages.
            </p>
          </div>
          <Row>
            <Col xs={6} />
            <Col xs={6}>
              <Button
                className="button-primary float-right"
                type="button"
                onClick={() => {
                  logout(true);
                }}
              >
                Go to login
              </Button>
            </Col>
          </Row>
        </NoAuthBasePage>
      )}
    </>
  );
};

export default withHelmet(UnverifiedPage);
