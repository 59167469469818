import { useEffect, useState } from 'react';

import DoodleLogo from 'assets/images/logo/ddl_target_teste.svg';

import './styles.css';

function SplashScreen() {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAdditionalInfo(true);
    }, 5000);

    // Cleanup function
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div id="splash-screen">
      <object id="target-img" type="image/svg+xml" data={DoodleLogo} />
      {showAdditionalInfo && (
        <>
          <p className="loading-title">Tuning up a couple of things.</p>
          <p className="loading-sub-title">
            This might take a few seconds. Please be patient and do not close
            the app. Thank you!
          </p>
        </>
      )}
    </div>
  );
}

export default SplashScreen;
