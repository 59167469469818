import React, { FC } from 'react';

import { Row } from 'react-bootstrap';

import Scrollbars from 'components/Scrollbars';

import { MenuTabStickyRow } from './styles';

export type MenuTabProps = {
  sticky?: boolean;
  className?: string;
  paddingtop?: string;
};

const MenuTab: FC<MenuTabProps> = ({
  sticky,
  children,
  className,
  ...rest
}) => {
  return sticky ? (
    <MenuTabStickyRow className={className} {...rest}>
      <Scrollbars height="65px" marginLeft="15px">
        {children}
      </Scrollbars>
    </MenuTabStickyRow>
  ) : (
    <Row className={className}>{children}</Row>
  );
};

MenuTab.defaultProps = {
  sticky: true,
};

export default MenuTab;
