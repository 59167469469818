import { CertificateTypeEnum } from 'enum/CertificateType.enum';
import { CertificatePreset } from 'interfaces/CertificatePreset';

export const isPreset = () => {
  return window.location.pathname.includes('manage-preset');
};

export const getPresetByCertificateId = (
  certificateId?: string,
  certificatePresets?: CertificatePreset[]
) => {
  return certificatePresets?.find((preset) => preset.data === certificateId);
};

export const getPresetByCertificateType = (
  certificateType?: CertificateTypeEnum,
  certificatePresets?: CertificatePreset[]
) => {
  return certificatePresets?.find(
    (preset) => preset.certificateType === certificateType
  );
};
