import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  CertificatePresetPayload,
  CertificateSettings,
  RoleEnum,
} from 'store/@types/user';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { apiSetCertificateSettings } from 'api/business';
import { apiListCertTemplatesTypes } from 'api/certificates';
import {
  apiDeleteCertificatePreset,
  apiSetCertificatePreset,
  apiUpdateCertificatePreset,
} from 'api/user';
import { apiGerCertificatePresets } from 'api/user';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import { CertificatePreset } from 'interfaces/CertificatePreset';
import { CertTemplate } from 'interfaces/CertificateTemplate';
import { defaultToast } from 'utils/toast';

import Carousel from 'components/Carousel';
import { FormLabel, TextField } from 'components/Form';
import ColorPicker from 'components/Form/ColorPicker';
import ModalConfirm from 'components/modals/ModalConfirm';
import ModalPreset from 'components/modals/ModalPreset';

interface CertificateInformationProps {
  selectedPage: string;
  setIsLoading: (isLoading: boolean) => void;
  handleCertificateFormReset: (data: CertificateSettings) => void;
}

const CertificateInformation: FC<CertificateInformationProps> = ({
  selectedPage,
  setIsLoading,
  handleCertificateFormReset,
}) => {
  const methodsCertificate = useFormContext();
  const [query] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [currentPreset, setCurrentPreset] = useState<CertificatePreset>();
  const { user, currentRole, currentBusiness, reload, loadMe } = useAuth();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [presets, setPresets] = useState<CertificatePreset[]>([]);
  const [templates, setTemplates] = useState<CertTemplate[]>([]);
  const [showPresetModal, setShowPresetModal] = useState(false);
  const { clearAll } = useCerts();

  const duplicatedId = query.get('duplicated');
  const canEditCustomNumeration = currentRole === RoleEnum.SOLO;
  const canEditPresets = [RoleEnum.SOLO, RoleEnum.LEAD].includes(currentRole);

  const loadPresets = () => {
    if (canEditPresets) {
      apiGerCertificatePresets()
        .then((data) => {
          setPresets(data.reverse());
        })
        .catch(() => {
          defaultToast('E');
        });
    }
  };

  const saveCertificateSettings = (values: CertificateSettings) => {
    if (!user) return;
    setIsLoading(true);
    apiSetCertificateSettings(currentBusiness, {
      ...values,
      startNum: Number(values.startNum),
    })
      .then((businessData) => {
        if (businessData.certificateSettings) {
          handleCertificateFormReset(businessData.certificateSettings);
        }
      })
      .catch((error) => {
        if (error.statusCode === 400) {
          defaultToast('E', error?.data?.message);
        }
      })
      .finally(() => {
        reload({ reloadBusiness: true });
        setIsLoading(false);
      });
  };

  const redirectToSetup = (preset: CertificatePreset) => {
    // Update user context with preset changes
    loadMe(true);

    const template = templates.find(
      (temp) => temp.certificateName === preset.certificateType
    );
    if (template) {
      navigate(`/manage-preset/${template?._id}/${preset.data}`);
    }
  };

  const createPreset = (
    payload: CertificatePresetPayload,
    isSetup: boolean
  ) => {
    setIsLoading(true);
    apiSetCertificatePreset(payload)
      .then((preset) => {
        defaultToast('S', 'Your preset was created successfully');
        if (isSetup) {
          redirectToSetup(preset);
        } else {
          loadPresets();
          setShowPresetModal(false);
          setIsLoading(false);
        }
      })
      .catch(() => {
        defaultToast('E');
        setIsLoading(false);
      });
  };

  const updatePreset = (
    payload: CertificatePresetPayload,
    isSetup: boolean,
    presetId: string
  ) => {
    setIsLoading(true);
    apiUpdateCertificatePreset(presetId, payload)
      .then((preset) => {
        if (isSetup) {
          redirectToSetup(preset);
        } else {
          defaultToast('S');
          loadPresets();
          setShowPresetModal(false);
          setIsLoading(false);
        }
      })
      .catch(() => {
        defaultToast('E');
        setIsLoading(false);
      });
  };

  const deletePreset = (presetId: string) => {
    setIsLoading(true);
    apiDeleteCertificatePreset(presetId)
      .then(() => {
        defaultToast('S', 'Your preset was deleted successfully');
        loadPresets();
        setShowDeleteConfirmation(false);
      })
      .catch(() => {
        defaultToast('E');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    clearAll();
    loadPresets();
    apiListCertTemplatesTypes()
      .then((data) => {
        setTemplates(data);
      })
      .catch(() => {
        defaultToast('E', 'Error on load certificate templates');
      });
  }, []);

  return (
    <>
      {(selectedPage === 'certificate' || selectedPage === 'init') && (
        <>
          <Row>
            <div>
              <h2 className="section-title">
                {t('certificate_settings_section_title')}
              </h2>
              <h3 className="section-subtitles mb-0">
                {t('certificate_settings_section_description')}
              </h3>
            </div>
          </Row>
          <Row className="mt-0">
            <Form
              onSubmit={methodsCertificate.handleSubmit((data) => {
                saveCertificateSettings(data);
              })}
            >
              <Row>
                {canEditPresets && (
                  <Col xs={12}>
                    <Row xs={12}>
                      <FormLabel
                        xs={10}
                        label={t('certificate_settings_sub_section_templates')}
                      />
                      <Col xs={12}>
                        <Carousel
                          cardSize="preset"
                          payload={presets?.map((item) => ({
                            id: item._id,
                            highlight: duplicatedId === item._id,
                            onClick: () => {
                              setCurrentPreset(item);
                              setShowPresetModal(true);
                            },
                            onDeleteClick: () => {
                              setCurrentPreset(item);
                              setShowDeleteConfirmation(true);
                            },
                            title: item.certificateType,
                            topTitle: item.name,
                          }))}
                        />
                      </Col>
                    </Row>
                    <Col xs={9} className="mt-4">
                      <FormLabel
                        xs={2}
                        normal
                        className="float-right mb-0 context-icon-back"
                        label={t('certificate_settings_btn_create_template')}
                        onClick={() => {
                          setCurrentPreset(undefined);
                          setShowPresetModal(true);
                        }}
                      />
                    </Col>
                  </Col>
                )}
                <Col xs={8}>
                  <Row>
                    <FormLabel
                      xs={10}
                      label={t('certificate_settings_sub_section_numeration')}
                    />
                    <TextField
                      xs={5}
                      disabled={!canEditCustomNumeration}
                      label={t('certificate_settings_input_prefix') as string}
                      fieldId="prefix"
                    />
                    <TextField
                      xs={5}
                      disabled={!canEditCustomNumeration}
                      type="number"
                      label={
                        t('certificate_settings_input_numeration') as string
                      }
                      fieldId="startNum"
                    />
                    <FormLabel
                      xs={10}
                      className="mt-0"
                      label={t(
                        'certificate_settings_sub_section_print_color_code'
                      )}
                    />
                    <ColorPicker
                      xs={5}
                      disabledField={!canEditCustomNumeration}
                      fieldId="color"
                      colorOptions={[
                        '#FF0000',
                        '#9C00FF',
                        '#4FA3A1',
                        '#0088FF',
                      ]}
                    />
                  </Row>
                </Col>
              </Row>
              {(canEditPresets || canEditCustomNumeration) && (
                <Row>
                  <Col xs={7} lg={10} />
                  <Col xs={2}>
                    <Button
                      className="button-primary float-right"
                      type="submit"
                      disabled={
                        !methodsCertificate.formState.isDirty ||
                        Object.keys(methodsCertificate.formState.errors)
                          .length > 0
                      }
                    >
                      Save changes
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </Row>
        </>
      )}
      {showPresetModal && (
        <ModalPreset
          templates={templates}
          currentPreset={currentPreset}
          closeModal={() => {
            setShowPresetModal(false);
          }}
          handleSave={(payload, isSetup) => {
            if (currentPreset) {
              updatePreset(payload, isSetup, currentPreset._id);
            } else {
              createPreset(payload, isSetup);
            }
          }}
        />
      )}
      {showDeleteConfirmation && (
        <ModalConfirm
          title="Delete preset?"
          text={'Are you certain you wish to delete this preset?'}
          confirmText="Cancel"
          actionConfirm={() => {
            setShowDeleteConfirmation(false);
          }}
          cancelText="Delete preset"
          actionCancel={() => {
            if (currentPreset) {
              deletePreset(currentPreset?._id);
            }
          }}
        />
      )}
      {selectedPage === 'init' && (
        <Row className="my-4">
          <hr />
        </Row>
      )}
    </>
  );
};

export default CertificateInformation;
