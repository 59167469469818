import { RoleEnum, UserRole } from 'store/@types/user';

export const verifyAuthRoles = (userRoles: UserRole[] = []): boolean =>
  userRoles.some(
    ({ role }) =>
      !!(
        role === RoleEnum.SOLO ||
        role === RoleEnum.LEAD ||
        role === RoleEnum.ENGINEER
      )
  );

export const newExpireDate = (expires_in: number) => {
  return new Date(new Date().getTime() + expires_in * 1000);
};
