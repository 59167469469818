import React from 'react';

import './styles.css';

function Copyright() {
  return (
    <div id="copyright-component">
      &copy; Arma Software {new Date().getFullYear()} - All rights reserved.
    </div>
  );
}

export default Copyright;
