/* eslint-disable @typescript-eslint/no-unused-vars */
import { AffectFunction } from 'enum/AffectFunction.enum';
import { CertificateDataOperationsTableProps } from 'interfaces/CertificateData';
import {
  AffectedFieldsProps,
  CertificateDataValuesTableColumnProps,
  FieldAffects,
  HandleAffectsParams,
  ReqCircuitValue,
} from 'interfaces/CertificateTemplate';
import { getBoardInfo, getBoardName } from 'utils/certificates/defaultValues';
import { hashCode } from 'utils/certificates/helpers';

export const getCircuitsValues = (
  fieldId: string,
  affect: FieldAffects,
  handleAffectsParams: HandleAffectsParams,
  reqValue: ReqCircuitValue
) => {
  if (!reqValue || reqValue.function !== AffectFunction.GET_CIRCUITS_VALUE)
    return;

  const { setValue, getValues, defaultValues, affectedFields, certData } =
    handleAffectsParams;
  if (!certData) return;

  const getValueFromPayload = (id: string) => {
    return getValues ? getValues(id) : defaultValues && defaultValues[id];
  };

  const { boardGridId, fieldId: asd, boardId } = getBoardInfo(affect.field);

  const currentBoard: {
    fieldIdMapping: string;
    boardId: string;
    operationsTable?: CertificateDataOperationsTableProps[];
    circuitSelected: string;
  } = {
    fieldIdMapping: affect.field.replace(asd, ''),
    boardId,
    operationsTable: undefined,
    circuitSelected: '',
  };

  currentBoard.circuitSelected = getValueFromPayload(
    currentBoard.fieldIdMapping + reqValue.boardCircuitId
  );

  if (
    !currentBoard.circuitSelected ||
    currentBoard.circuitSelected === 'N/A' ||
    currentBoard.circuitSelected === ''
  ) {
    console.warn('no selected circuit');
    return;
  }

  //*
  //* MAP THE FIELD.ID TO GET THE CIRCUITS TABLE STRUCT
  const selectedBoard: {
    fieldIdMapping: string;
    boardId: string;
    supplyMapping: string;
    operationsTableStruct?: AffectedFieldsProps;
    operationsTable?: CertificateDataOperationsTableProps[];
    operationsTableId: string;
  } = {
    fieldIdMapping: '',
    boardId: getValueFromPayload(fieldId),
    supplyMapping: fieldId,
    operationsTableId: '',
    operationsTableStruct: undefined,
  };

  const boardsOnForm = getValueFromPayload(boardGridId);
  const indexSelBoard = boardsOnForm.findIndex(
    (e: any) => e.id === selectedBoard.boardId
  );

  //* BOARD SELECTED IS ORIGIN, return
  if (indexSelBoard < 0) {
    console.warn('BOARD SELECTED IS ORIGIN, return');
    return;
  }

  selectedBoard.fieldIdMapping =
    getBoardName(boardGridId, indexSelBoard, selectedBoard.boardId) + '*';

  selectedBoard.operationsTableId =
    selectedBoard.fieldIdMapping + reqValue.operationsTableId;

  selectedBoard.operationsTableStruct = getValueFromPayload(
    selectedBoard.operationsTableId + '*struct'
  );

  if (!selectedBoard.operationsTableStruct?.mappedFields) {
    console.warn('NO mappedFields of the circuit table');
    return;
  }
  //*
  //* Get the column affected by the required value
  const columnToRead =
    selectedBoard.operationsTableStruct.mappedFields[
      `crt${hashCode(reqValue.circuitColumn)}`
    ];

  if (!columnToRead) {
    console.warn('NO column to read from');
    return;
  }

  currentBoard.operationsTable = getValueFromPayload(
    selectedBoard.fieldIdMapping + reqValue.operationsTableId
  );

  const handleCircuitRowColumns = (
    columns: CertificateDataValuesTableColumnProps[]
  ) => {
    const columnKey = columns[0].value;
    if (columnKey === currentBoard.circuitSelected) {
      // console.warn('found circuit row');
      const fetchedValue = columns[columnToRead.index].value;

      // console.warn('the value of this circuit field is: ', fetchedValue);
      //** set the fetched VALUE to the affected field
      if (setValue) {
        setValue(affect.field, fetchedValue);
      } else if (defaultValues) {
        defaultValues[affect.field] = fetchedValue;
      }
    }
  };

  currentBoard.operationsTable?.forEach((section) => {
    if (section.mergedRow) {
      handleCircuitRowColumns(section.mergedRow.columns);
    } else {
      section.rows.forEach(({ columns }) => {
        handleCircuitRowColumns(columns);
      });
    }
  });
};
