import styled from 'styled-components';

export const CustomSelect = styled.div<{
  icon: string;
  disabled?: boolean;
  height?: string;
  isInvalid?: boolean;
}>`
  display: block;
  width: 100%;
  padding: 6px 35px 6px 12px;
  background: #f4f4f4 url(${({ icon }) => icon}) no-repeat right 10px center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: normal;
  color: var(--color-primary);
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  ${({ disabled, height, isInvalid }) => {
    return `
    height: ${height ? `${height}px` : '36px'};
    opacity: ${disabled ? '0.6' : '1'};
    cursor: ${disabled ? 'initial' : 'pointer'};
    border: 1px solid ${isInvalid ? '#ff0000' : '#ffffff'};
    `;
  }};
`;

export const Dropdown = styled.div<{ top: number }>`
  width: min-content;
  position: relative;
  overflow-x: hidden;
  min-width: 250px;
  max-width: 675px;
  max-height: 220px;
  top: ${({ top }) => (top ? `${Math.trunc(top)}px` : '0px')};
  z-index: 2;
  overflow-y: auto;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  border-radius: 12px;
`;

export const DropdownItem = styled.p`
  width: 100%;
  height: auto;
  display: flex;
  cursor: pointer;
  padding: 12px 25px;
  color: #666666;
  font-family: Lato;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  letter-spacing: normal;
  &:hover {
    background-color: var(--color-primary);
    color: #ffffff;
  }
`;

export const DropdownOverlay = styled.div`
  z-index: 1;
  position: fixed;
  inset: 0;
`;
