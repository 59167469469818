/* eslint-disable arrow-body-style */
import { useContext } from 'react';
import {
  CertificateContext,
  CertificateProps,
} from 'context/CertificateProvider/CertificateProvider';

const useCerts = () => {
  const context = useContext<CertificateProps>(CertificateContext);

  if (!context) {
    throw new Error('useCerts must be used within an AuthProvider');
  }

  return context;
};

export default useCerts;
