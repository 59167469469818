import { FC } from 'react';

import CalendarIcon from 'assets/images/iconset/calendar.svg';

import { BaseCarouselProps } from 'components/NewCarousel/interfaces/BaseCarouselProps';
import { CertificateCardPayload } from 'components/NewCarousel/interfaces/CertificateCardPayload';

import { MainContainer } from '../styles';
import * as S from './styles';

interface CertificateCardCardProps<T extends BaseCarouselProps> {
  payloadItem: T;
}

const CertificateCard: FC<CertificateCardCardProps<BaseCarouselProps>> = ({
  payloadItem,
}) => {
  const {
    title,
    subtitle,
    siteName,
    city,
    postalCode,
    updatedAt,
    highlight,
    onClick,
  } = payloadItem as CertificateCardPayload;

  return (
    <MainContainer
      width="214px"
      height="234px"
      highlightCard={highlight}
      onClick={onClick}
      cursor="pointer"
    >
      <S.HeaderContainer>
        <span>{title}</span>
        <span>{subtitle}</span>
      </S.HeaderContainer>

      <S.BodyContainer>
        <S.CustomerInfoContainer>
          {siteName ? <span>{siteName}</span> : <span>&nbsp;</span>}
          <span>{city}</span>
          <span>{postalCode}</span>
        </S.CustomerInfoContainer>
        <S.DateContainer>
          <S.DateIcon
            src={CalendarIcon}
            alt="Last updated date"
            width="18px"
            height="18px"
          />
          <S.DateValue>{updatedAt}</S.DateValue>
        </S.DateContainer>
      </S.BodyContainer>
    </MainContainer>
  );
};

export default CertificateCard;
