import { CIRCUIT_INDEXES } from 'constants/certificate.constants';

import { FieldType } from 'enum/FieldType.enum';
import { ValidationType } from 'enum/ValidationType.enum';
import {
  CertificateDataOperationsColumnsProps,
  CertificateDataOperationsTableProps,
} from 'interfaces/CertificateData';
import {
  AffectedMappedFieldsProps,
  CertificateOptions,
  CertTableStruct,
  CertTemplateField,
  FieldAffects,
  FieldValidation,
  FormOptions,
  LookupTableProps,
  MatrixTableProps,
  OptionsTableRequired,
  RequiredValuesProps,
  TableProps,
  ValuesRequiredTable,
} from 'interfaces/CertificateTemplate';
import {
  CircuitsState,
  SelectedTableIndexes,
  TableColumnStyles,
} from 'interfaces/Circuits';

import { circuitDivider, fieldDivider, getBoardInfo } from './defaultValues';

export const buildCircuitsState = (
  key: string,
  tableStructure: CertTableStruct[],
  tableStructureValues: CertificateDataOperationsTableProps[]
): CircuitsState => ({
  circuitId: key,
  tableStructureFields: tableStructure,
  tableStructureValues: tableStructureValues,
  tableStructHeaders: tableStructure.sort(sortColumnsByIndex).map((struct) => ({
    headerIndex: struct.index,
    headerTitle: struct.columnName,
  })),
  tableColumnStyles: tableStructure.reduce(
    (acc, { index, columnSize = '114px' }) => {
      acc[index] = { column: { width: columnSize } };
      return acc;
    },
    {} as TableColumnStyles
  ),
});

export const getCircuitTemplateField = (
  tableStructureFields: CertTableStruct[],
  templateFields: CertTemplateField[],
  circuitId: string,
  tableIndex: number,
  indexRow: number,
  indexColumn: number
): CertTemplateField => {
  const { keyColumn = '' } =
    tableStructureFields.find(({ index }) => index === indexColumn) ?? {};

  const fieldName = `${circuitId}${circuitDivider}${keyColumn}${fieldDivider}${tableIndex}${fieldDivider}${indexRow}${fieldDivider}${indexColumn}`;
  const field = templateFields?.find(
    (tempField) => tempField.fieldId === fieldName
  );

  if (field) {
    return {
      ...field,
      label: '',
    };
  }
  return {} as CertTemplateField;
};

export const getColumnIndexByKey = (
  tableStructure: CertTableStruct[] | undefined,
  columnkey: string
) => tableStructure?.find((struct) => struct.keyColumn === columnkey)?.index;

export const sortColumnsByIndex = (
  a: { index: number },
  b: { index: number }
) => a.index - b.index;

interface CircuitTableValuesGeneratorParams {
  numberOfPhases: number;
  numberOfWays: number;
  rowNomenclature: string;
  certTableStructs: CertTableStruct[];
}

export const CircuitTableValuesGenerator = (
  stateBoardCircuit: CircuitTableValuesGeneratorParams
): CertificateDataOperationsTableProps[] => {
  const operationsTableValues: CertificateDataOperationsTableProps[] = [];
  const { certTableStructs, numberOfPhases, numberOfWays, rowNomenclature } =
    stateBoardCircuit;

  let lineGroup = 1;
  let lineCount = 1;

  if (numberOfPhases === 1) {
    for (let tableIndex = 1; tableIndex <= numberOfWays; tableIndex++) {
      operationsTableValues.push({
        rows: [
          makeEmptyRow(0, tableIndex - 1, String(tableIndex), certTableStructs),
        ],
        index: tableIndex - 1,
        merged: false,
      });
    }
  } else {
    for (let tableIndex = 1; tableIndex <= numberOfWays; tableIndex++) {
      const operationsTable: CertificateDataOperationsTableProps = {
        rows: [],
        index: tableIndex - 1,
        merged: false,
      };

      for (let rowIndex = 1; rowIndex <= numberOfPhases; rowIndex++) {
        const value = `${lineGroup}${rowNomenclature}${lineCount}`;

        operationsTable.rows.push(
          makeEmptyRow(rowIndex - 1, tableIndex - 1, value, certTableStructs)
        );
        lineCount++;
        if (lineCount > numberOfPhases) {
          lineCount = 1;
          lineGroup++;
        }
      }
      operationsTableValues.push(operationsTable);
    }
  }

  return operationsTableValues;
};

export const makeEmptyRow = (
  rowIndex: number,
  tableIndex: number,
  rowName: string,
  certTableStructs: CertTableStruct[]
): CertificateDataOperationsColumnsProps => {
  return {
    columns: certTableStructs.map(({ columnType, index }) => {
      return {
        index,
        value: index === 0 ? rowName : '',
        valueType: columnType,
      };
    }),
    index: rowIndex,
    parentGroup: tableIndex,
  };
};

export const updateOperationsTableColunmValue = (
  operationsTable: CertificateDataOperationsTableProps[],
  circuitFieldId: string,
  value: unknown
) => {
  const { colunmIndex, rowIndex, tableIndex } =
    getCircuitFieldIdInfo(circuitFieldId);

  if (operationsTable[tableIndex].merged) {
    operationsTable[tableIndex].mergedRow?.columns.forEach((colunm) => {
      if (colunm.index === colunmIndex) {
        colunm.value = value as string;
      }
    });
  } else {
    operationsTable[tableIndex].rows[rowIndex].columns[colunmIndex].value =
      value as string;
  }
};

export const getCircuitFieldIdInfo = (fieldId: string) => {
  const [, circuitField] = fieldId.split(circuitDivider);

  const circuitFieldId = circuitField.split('*')[0];
  const tableIndex = Number(circuitField.split('*')[1]);
  const rowIndex = Number(circuitField.split('*')[2]);
  const colunmIndex = Number(circuitField.split('*')[3]);

  return {
    circuitField,
    tableIndex,
    rowIndex,
    colunmIndex,
    circuitFieldId,
  };
};

export const gerCircuitFieldTypeMapped = (columnType: string) => {
  if (columnType === FieldType.String || columnType === FieldType.Textbox) {
    return FieldType.TextboxCircuitTable;
  }
  if (columnType === FieldType.Combo) {
    return FieldType.ComboCircuitTable;
  }
  return columnType as FieldType;
};

export const getOptionsByColumnIndex = (
  tableStructure: CertTableStruct[],
  certOptions: CertificateOptions,
  circuitsId: string,
  columnIndex: number,
  tableIndex: number,
  rowIndex: number
): FormOptions[] => {
  const circuitName = `${circuitsId}${circuitDivider}`;
  const field = `${fieldDivider}${tableIndex}${fieldDivider}${rowIndex}${fieldDivider}${columnIndex}`;
  const { keyColumn } =
    tableStructure.find((struct) => struct.index === columnIndex) ?? {};
  return keyColumn ? certOptions[`${circuitName}${keyColumn}${field}`] : [];
};

export const parseCircuitFieldAffects = (
  mappedFields: AffectedMappedFieldsProps,
  circuitName: string,
  tableIndex: number,
  rowIndex: number,
  affects?: FieldAffects[]
) => {
  return affects?.map((affect) => {
    if (affect.field.includes('crt')) {
      const columnIndex = mappedFields[affect.field].index;

      return {
        ...affect,
        field: `${circuitName}${affect.field}${fieldDivider}${tableIndex}${fieldDivider}${rowIndex}${fieldDivider}${columnIndex}`,
      };
    } else {
      return affect;
    }
  });
};

export const parseCircuitValidations = (
  mappedFields: AffectedMappedFieldsProps,
  circuitName: string,
  tableIndex: number,
  rowIndex: number,
  validations?: FieldValidation[]
) => {
  return validations?.map((validation) => {
    if (
      validation.type === ValidationType.IfRequiredIf &&
      validation.fieldId &&
      validation.fieldId.includes('crt') &&
      !validation.fieldId.includes(circuitDivider)
    ) {
      const columnIndex = mappedFields[validation.fieldId].index;
      const newValidation: FieldValidation = {
        ...validation,
      };
      newValidation.fieldId = `${circuitName}${validation.fieldId}${fieldDivider}${tableIndex}${fieldDivider}${rowIndex}${fieldDivider}${columnIndex}`;

      return newValidation;
    } else {
      return validation;
    }
  });
};

export const parseCircuitRequiredValues = (
  mappedFields: AffectedMappedFieldsProps,
  prefixName: string,
  tableIndex: number,
  rowIndex: number,
  requiredValues?: RequiredValuesProps[]
): RequiredValuesProps[] | undefined => {
  const parseField = (fieldId: string) => {
    if (fieldId.includes('crt') && !fieldId.includes(circuitDivider)) {
      const columnIndex = mappedFields[fieldId].index;

      return `${prefixName}${fieldId}${fieldDivider}${tableIndex}${fieldDivider}${rowIndex}${fieldDivider}${columnIndex}`;
    }
    return fieldId;
  };

  if (!requiredValues?.length) return;
  const newRequiredValues: RequiredValuesProps[] = requiredValues.map(
    (requiredValue) => {
      const newRequiredValue: RequiredValuesProps = {
        ...requiredValue,
      };

      if (requiredValue.fieldId) {
        newRequiredValue.fieldId = parseField(requiredValue.fieldId);
      }

      if (requiredValue.values?.length) {
        const newValues: ValuesRequiredTable[] = requiredValue.values.map(
          (value) => {
            const newValue: ValuesRequiredTable = { ...value };
            if (value.optionsTable && newValue.optionsTable) {
              const newOptionsTable: OptionsTableRequired = {
                ...value.optionsTable,
              };
              newOptionsTable.fieldX = parseField(value.optionsTable.fieldX);
              newOptionsTable.fieldY = parseField(value.optionsTable.fieldY);
              newValue.optionsTable = newOptionsTable;
            }

            if (value.matrixTable?.length) {
              const newMatrixTables: MatrixTableProps[] = value.matrixTable.map(
                (matrixTable) => {
                  const newMatrixTable = { ...matrixTable };
                  newMatrixTable.fieldId = parseField(matrixTable.fieldId);

                  if (matrixTable.lookupTable?.length) {
                    const newLookupTables: LookupTableProps[] =
                      matrixTable.lookupTable.map((lookupTable) => {
                        const newLookupTable: LookupTableProps = {
                          ...lookupTable,
                        };
                        const newTable: TableProps = { ...lookupTable.table };
                        newLookupTable.fieldId = parseField(
                          lookupTable.fieldId
                        );
                        newTable.fieldX = parseField(lookupTable.table.fieldX);
                        newTable.fieldY = parseField(lookupTable.table.fieldY);

                        newLookupTable.table = newTable;
                        return newLookupTable;
                      });
                    newMatrixTable.lookupTable = newLookupTables;
                  }
                  return newMatrixTable;
                }
              );
              newValue.matrixTable = newMatrixTables;
            }
            return newValue;
          }
        );
        newRequiredValue.values = newValues;
      }
      return newRequiredValue;
    }
  );
  return newRequiredValues;
};

export const getCircuitDesignationFieldId = (circuitFieldId: string) => {
  const { boardGridId, boardId, boardIndex, fieldId } =
    getBoardInfo(circuitFieldId);
  const { rowIndex, tableIndex } = getCircuitFieldIdInfo(circuitFieldId);

  return `${boardGridId}*board*${boardIndex}*${boardId}*${fieldId}*circuit*crt766937047*${tableIndex}*${rowIndex}*1`;
};

export const getLSCircuitIndexes = (): SelectedTableIndexes =>
  JSON.parse(
    localStorage.getItem(CIRCUIT_INDEXES) as string
  ) as SelectedTableIndexes;

export const setLSCircuitIndexes = (indexes: SelectedTableIndexes) =>
  localStorage.setItem(CIRCUIT_INDEXES, JSON.stringify(indexes));
