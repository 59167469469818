import { FC, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormControlProps } from 'react-bootstrap';

import comboIcon from 'assets/images/iconset/combo-arrow-down.svg';
import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';
import {
  CertTemplateField,
  FieldAffects,
  FormOptions,
} from 'interfaces/CertificateTemplate';

import { FormControl, FormGroup } from 'components/Form/styles';

import { getError } from '..';
import { CommonProps } from '../Types';
import {
  CustomSelect,
  Dropdown,
  DropdownItem,
  DropdownOverlay,
} from './styles';

interface ComboProps extends FormControlProps, CommonProps {
  placeholder?: string;
  options?: Array<FormOptions>;
  affects?: FieldAffects[];
  required?: boolean;
  height?: string;
  additionalOnChange?: (changesValue: string) => void;
  field?: CertTemplateField;
}

const ComboCircuitTable: FC<ComboProps> = ({
  xs,
  fieldId,
  disabled,
  affects,
  autoObservations,
  height,
  additionalOnChange,
  options,
}) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const error = getError(fieldId, errors);
  const { certOptions, handleAffects, handleAutoObservations, autoSave } =
    useCerts();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownTop, setDropdownTop] = useState(0);
  const readOnlyInputRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const comboOptions = useMemo(() => {
    if (certOptions[fieldId]) {
      return certOptions[fieldId];
    }
    return options ?? [];
  }, [certOptions, options]);

  const comboPlaceholder: string = comboOptions?.length ? 'Select option' : '-';

  const openDropdow = () => {
    if (readOnlyInputRef.current) {
      const { top } = readOnlyInputRef?.current?.getBoundingClientRect() ?? {};
      setDropdownTop(window.innerHeight - top > 256 ? 5 : -261);
      setDropdownOpen(true);
    }
  };

  const handleSave = (changedValue: string) => {
    setValue(fieldId, changedValue, { shouldValidate: true });

    if (additionalOnChange) {
      additionalOnChange(changedValue);
    }

    if (affects) {
      handleAffects({
        fieldId,
        type: FieldType.ComboCircuitTable,
        affects,
        newVal: changedValue,
        getValues,
        setValue,
      });
    }
    if (autoObservations) {
      handleAutoObservations({
        fieldId,
        autoObservations,
        getValues,
        setValue,
      });
    }
    autoSave({
      type: FieldType.ComboCircuitTable,
      fieldId,
      value: changedValue,
      getValues,
      setValue,
    });
  };

  return (
    <FormGroup xs={xs}>
      <Controller
        name={fieldId}
        control={control}
        render={({ field }) => {
          const currentOption = comboOptions.find(
            (option) => field.value === option.value
          );
          const placeHolder = field.value === '-' ? '-' : comboPlaceholder;
          const parsedValue = currentOption?.key ?? placeHolder;
          const isDisabled =
            !comboOptions || comboOptions?.length === 0 || disabled;
          return (
            <>
              {dropdownOpen && (
                <DropdownOverlay
                  onClick={() => {
                    setDropdownOpen(false);
                  }}
                />
              )}
              <CustomSelect
                icon={comboIcon}
                className="custom-select"
                ref={readOnlyInputRef}
                height={height}
                isInvalid={Boolean(error)}
                disabled={isDisabled}
                onClick={() => {
                  if (!isDisabled) {
                    openDropdow();
                  }
                }}
              >
                {parsedValue}
              </CustomSelect>
              <FormControl {...field} ref={inputRef} type="hidden" />
              {dropdownOpen && (
                <Dropdown top={dropdownTop}>
                  {comboOptions?.length > 0 && (
                    <DropdownItem
                      onClick={() => {
                        handleSave('');
                        setDropdownOpen(false);
                      }}
                    >
                      {'Select option'}
                    </DropdownItem>
                  )}
                  {comboOptions?.map((option) => {
                    return (
                      <DropdownItem
                        key={'combobox-' + option.key + option.value}
                        onClick={() => {
                          handleSave(option.value);
                          setDropdownOpen(false);
                        }}
                      >
                        {option.value}
                      </DropdownItem>
                    );
                  })}
                </Dropdown>
              )}
            </>
          );
        }}
      />
    </FormGroup>
  );
};

export { ComboCircuitTable };
