export enum AffectAction {
  CHANGE_OPTIONS = 'changeOptions',
  TRIGGER_REQUIRED_VALUES = 'trigger-required-values',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
  IF_EMPTY = 'if-empty',
  CHANGE_TABLE_STRUCURE = 'change-table-structure',
  CHANGE_CIRCUITS = 'change-circuits',
  INCREMENT_DATE = 'increment-date',
}
