import { UseFormSetValue } from 'react-hook-form';

import { FieldType } from 'enum/FieldType.enum';
import { ValidationType } from 'enum/ValidationType.enum';
import {
  BoardGridCard,
  CertData,
  CertificateDataBoardsValueProps,
  CertificateDataValueProps,
  CertificateDataValuesTableRowProps,
} from 'interfaces/CertificateData';
import {
  CertTableStruct,
  CertTemplateField,
  FieldAffects,
  FormOptions,
} from 'interfaces/CertificateTemplate';

import { getCircuitDesignationFieldId } from './circuitsUtils';
import { getBoardName } from './defaultValues';

export const isNormalValueTextInputs = (type: FieldType) => {
  return [
    FieldType.Textbox,
    FieldType.Textarea,
    FieldType.ComboBoards,
    FieldType.ComboModal,
    FieldType.LargeDatepicker,
    FieldType.SmallDatepicker,
    FieldType.ComboCircuitTable,
    FieldType.TextboxCircuitTable,
  ].includes(type);
};
export const isFormLabelComponents = (type: FieldType) => {
  return [
    FieldType.Label,
    FieldType.Divider,
    FieldType.Subtitle,
    FieldType.DescriptionField,
  ].includes(type);
};
export const isDynamicTableComponents = (type: FieldType) => {
  return [FieldType.TableModal, FieldType.Table].includes(type);
};

export const hasTextboxField = (
  fieldType: FieldType,
  fieldValue: string,
  formOptions?: FormOptions[]
): boolean => {
  if (!formOptions?.length) return false;
  const inputsWithTextbox = [
    FieldType.Radiobutton,
    FieldType.Checkbox,
    FieldType.Combo,
  ];

  if (inputsWithTextbox.includes(fieldType)) {
    return formOptions.some(
      (option) =>
        (option.hasTextbox || option.enableTextbox) &&
        fieldValue === option?.value
    );
  }

  return false;
};

export const filterAffectsByAction = (
  affects: FieldAffects[],
  affectAction: unknown | unknown[]
) => affects?.filter(({ action }) => hasActionAffect(action, affectAction));

export const hasActionAffect = (
  action: unknown,
  affectAction: unknown | unknown[]
) => {
  return Array.isArray(affectAction)
    ? affectAction.includes(action)
    : affectAction === action;
};

export const parseToFormTextInputValues = ({
  value = '',
}: CertificateDataValueProps): string => {
  return value;
};

export const parseToFormComboInputValues = (
  { key, valueType, value, valueTextbox = '' }: CertificateDataValueProps,
  hasTextbox = false
): CertificateDataValueProps | string => {
  if (hasTextbox) {
    return {
      key,
      valueType,
      valueTextbox,
      value,
    };
  } else {
    return value ?? '';
  }
};

export const parseToFormRadioButtonInputValues = ({
  key,
  valueType,
  value,
  valueTextbox = '',
}: CertificateDataValueProps): CertificateDataValueProps => {
  return {
    key,
    valueType,
    valueTextbox,
    value: value ?? '',
  };
};

export const parseToFormCustomerDataInputValues = ({
  key,
  valueType,
  valueCustomer,
  value,
}: CertificateDataValueProps): CertificateDataValueProps => {
  return {
    key,
    valueType,
    value,
    valueCustomer,
  };
};

export const parseToFormCheckboxInputValues = ({
  key,
  valueType,
  value,
  valueTextbox = '',
}: CertificateDataValueProps): { [key: string]: unknown } => {
  return {
    key,
    valueType,
    valueTextbox,
    value: value === 'true',
  };
};

export const parseToFormSignatureAreaInputValues = ({
  key,
  valueType,
  value,
  valueSignature,
}: CertificateDataValueProps): { [key: string]: unknown } => {
  return {
    key,
    valueType,
    valueSignature,
    value,
  };
};

export const applyEmptyTableFixedInputValues = (
  valueTable?: CertificateDataValuesTableRowProps[],
  tableStructure?: CertTableStruct[]
) => {
  return valueTable?.map((table) => {
    if (!table?.isSubHeader) {
      tableStructure?.forEach((struct, index) => {
        if (struct.columnType === 'combo') {
          table.columns[index] = {
            index,
            value: '',
            valueType: 'string',
          };
        }
      });
    }
    return table;
  });
};

export const parseToFormTableDynamicInputValues = (
  valueTable?: CertificateDataValuesTableRowProps[]
): { [key: string]: unknown }[] | undefined => {
  return valueTable
    ?.map(({ columns }) =>
      columns.map((col, indexCol) => ({
        ...col,
        index: indexCol,
      }))
    )
    .reduce((acc, curr) => {
      const obj: any = {};
      curr.forEach((item) => {
        obj[item.index] = item;
      });
      acc.push(obj);
      return acc;
    }, []);
};

export const parseToFormTableFixedValues = (
  key: string,
  templateFields: CertTemplateField[],
  valueTable?: CertificateDataValuesTableRowProps[]
): CertificateDataValuesTableRowProps[] => {
  const field = templateFields.find((e) => e.fieldId === key);

  if (field?.valueTable && valueTable) {
    const formatedValueTable = [...valueTable];
    const templateValueTable = field?.valueTable;

    formatedValueTable.forEach((row, index) => {
      if (templateValueTable[index]?.isSubHeader) {
        row.isSubHeader = true;
      }
    });
    return formatedValueTable;
  }
  return valueTable ?? [];
};

export const hashCode = (s: string) =>
  [...s]
    .reduce((hash, c) => (Math.imul(31, hash) + c.charCodeAt(0)) | 0, 0)
    .toString()
    .replace('-', '');

// TODO remove on the future.
export const certTemplateIds: Record<string, string> = {
  EICR: '6079e2750848000011de6ee5',
  EIC: '6285224b70037ea4e9801671',
  'Domestic EICR': '61f9518ce25ddd51b8857b2b',
  MEIWC: '616fff4053297100103e6bdf',
  EDN: '65ba8c619879d42d48c4d404',
  EL: '65c25fc825eb98110afec74d',
  'EL Small': '65c25f7765364d8c044f81fe',
};

export const getSiteNameByCertData = (
  certData: CertData
): { siteName: string; city: string; postalCode: string } => {
  const { customer, siteName = '' } = certData;

  if (siteName === customer?.headquartersAddress?.companyName) {
    return {
      siteName,
      city: customer.headquartersAddress?.city ?? '',
      postalCode: customer.headquartersAddress?.postalCode ?? '',
    };
  }

  const branch = customer?.branchAddresses.find((branch) => {
    return siteName === branch.occupier;
  });

  if (branch) {
    return {
      siteName,
      city: branch?.city ?? '',
      postalCode: branch?.postalCode ?? '',
    };
  }

  return {
    siteName,
    city: customer?.headquartersAddress.city ?? '',
    postalCode: customer?.headquartersAddress.postalCode ?? '',
  };
};

export const sortCertificateDataByLastUpdated = (a: CertData, b: CertData) => {
  const dateA = a.updatedAt ?? a.createdAt ?? new Date();
  const dateB = b.updatedAt ?? b.createdAt ?? new Date();
  if (dateA < dateB) {
    return 1;
  }
  if (dateA > dateB) {
    return -1;
  }
  return 0;
};

export const updateCertDataWithBoards = (params: {
  certData: CertData;
  boardGridId: string;
  singleBoard?: CertificateDataBoardsValueProps;
  boardList?: CertificateDataBoardsValueProps[];
}) => {
  const { boardGridId, certData, boardList, singleBoard } = params;
  const { values, ...restCertData } = certData;

  const updatedCertData: CertData = {
    ...restCertData,
    values: values.map((value) => {
      if (value.key === boardGridId && value.valueBoards?.length) {
        const { valueBoards, ...restValue } = value;

        const newValue: CertificateDataValueProps = {
          ...restValue,
          valueBoards: singleBoard
            ? valueBoards.map((board) => {
                return board.id === singleBoard.id ? singleBoard : board;
              })
            : boardList || valueBoards,
        };
        return newValue;
      }
      return value;
    }),
  };

  return updatedCertData;
};

export const getBoardGridFieldFromPage = (
  selectedPage: unknown,
  fieldsPerPage: {
    [key: string]: string | number;
  },
  templateFields: CertTemplateField[] | null
) => {
  const pageFieldsIds = Object.entries(fieldsPerPage).reduce(
    (acc: string[], [id, page]) => {
      if (selectedPage == page) {
        acc.push(id);
      }
      return acc;
    },
    []
  );
  const boardGridField = templateFields?.find(
    (tempField) => tempField.type === FieldType.BoardGrid
  );

  if (boardGridField && pageFieldsIds.includes(boardGridField.fieldId)) {
    return boardGridField;
  }
  return null;
};

export const validateBoardCompletion = (
  boardGridId: string,
  getValues: (fieldId: string) => any,
  setValue: UseFormSetValue<any>,
  templateFields?: CertTemplateField[] | null
): CertificateDataBoardsValueProps[] => {
  const boards: BoardGridCard[] = getValues(boardGridId);

  const updatedBoards = boards.map((boardFind, index) => {
    const otherBoarderName = getBoardName(boardGridId, index, boardFind.id);
    boardFind.isComplete = !templateFields
      ?.filter((field) => {
        return field.fieldId.includes(boardFind.id);
      })
      .some((field) => {
        if (field.validations && field.validations[0].required) {
          if (field.fieldId.includes('circuit')) {
            const designationFieldId = getCircuitDesignationFieldId(
              field.fieldId
            );
            const designationValue = getValues(designationFieldId);

            if (
              field.fieldId !== designationFieldId &&
              designationValue === 'SPARE'
            ) {
              return false;
            }
          }

          if (
            field.validations[0].type === ValidationType.IfRequiredIf &&
            field.validations[0].fieldId
          ) {
            const value = getValues(field.fieldId);
            const valueFromRequired = getValues(field.validations[0].fieldId);

            if (valueFromRequired && !value) {
              return true;
            }
            return false;
          } else {
            const value = getValues(field.fieldId);
            if (!value) {
              return true;
            }
            return false;
          }
        }

        // Add circuit validation for set isComplete false
        if (field.type === FieldType.OperationsTable) {
          const value = getValues(field.fieldId) as [];
          if (value.length === 0) {
            return true;
          }
        }
        return false;
      });

    const comments = getValues(`${otherBoarderName}comments`);
    return {
      id: boardFind.id,
      isComplete: boardFind.isComplete,
      values: getValues(boardFind.id),
      ...(comments && { comments }),
    } as CertificateDataBoardsValueProps;
  });

  // Update board grid with isComplete
  setValue(
    boardGridId,
    boards.map((board) => {
      const updatedBoard = updatedBoards.find((b) => b.id === board.id);
      if (updatedBoard) {
        board.isComplete = updatedBoard.isComplete;
      }
      return board;
    })
  );
  return updatedBoards;
};
