import styled from 'styled-components';

export const PresetModalCard = styled.div<{ highlightCard?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  width: 236px;
  height: 182px;
  cursor: pointer;
  ${({ highlightCard }) => {
    if (highlightCard) {
      return `
        border: 1px solid #4fa3a1;
        border-radius: 12px;
        box-shadow: 0 0 10px 0 #4fa3a1;`;
    }
  }}
`;

export const Container = styled.div<{
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  cursor?: 'pointer';
}>`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 12px 10px;

  ${({ justifyContent }) => {
    return justifyContent ? `justify-content: ${justifyContent};` : '';
  }}
  ${({ cursor }) => {
    return cursor ? `cursor: ${cursor};` : '';
  }}
`;

export const CertificateType = styled.h1`
  position: absolute;
  color: #4fa3a1;
  font-family: Lato;
  font-size: 16px;
`;

export const PresetName = styled.p`
  font-size: 16px;
  line-height: 19px;
  position: absolute;
  bottom: 0;
  margin-left: 20px;
  color: #666666;
  font-family: Lato;
`;
