import React, { useMemo, useState } from 'react';

import { Button, Modal } from 'react-bootstrap';

import { apiSetDefaultSignature } from 'api/signature';
import exportImg from 'assets/images/iconset/customer_export.svg';
import tableDelete from 'assets/images/iconset/table_delete.svg';
import tableSelect from 'assets/images/iconset/table_select.svg';
import tableSelectEmpty from 'assets/images/iconset/table_select_empty.svg';
import { Signature } from 'interfaces/Signature';
import api from 'services/api';
import { capitalizeFirstLetter } from 'utils/index';
import { defaultToast } from 'utils/toast';

import ModalConfirm from '../ModalConfirm';

import './styles.css';

interface SignatureListProps {
  canAdd?: boolean;
  currentSignature?: Signature;
  addSignature: () => void;
  closeItself: () => void;
  handleSave: (data: Signature) => void;
  signatures: Signature[] | undefined;
  handleDelete: () => void;
  type: 'pick' | 'manage';
}

const SignatureList: React.FC<SignatureListProps> = ({
  canAdd,
  currentSignature,
  handleSave,
  addSignature,
  closeItself,
  signatures,
  handleDelete,
  type,
}) => {
  const [confirmModal, setConfirmModal] = useState<Signature | undefined>(
    undefined
  );

  const deleteSignature = (payload: Signature) => {
    api
      .delete(`signature/${payload._id}`)
      .then(() => {
        defaultToast('S');
        const nonDeletedSignaures = signatures?.filter(
          (sign) => sign._id !== payload._id
        );

        if (
          payload.default &&
          nonDeletedSignaures &&
          nonDeletedSignaures?.length > 0
        ) {
          apiSetDefaultSignature(nonDeletedSignaures[0]._id).then(() => {
            handleDelete();
          });
        } else {
          handleDelete();
        }
      })
      .catch(() => {
        defaultToast(
          'E',
          'An error occurred deleting this signature. Please try again.'
        );
      })
      .finally(() => {
        setConfirmModal(undefined);
      });
  };

  const manageSelected = useMemo(() => {
    if (currentSignature) return currentSignature._id;
    return type === 'manage' && signatures?.find((e) => e.default)?._id;
  }, [currentSignature]);

  return (
    <>
      <Modal show backdrop="static" className="modal-v1">
        <Modal.Header>
          <Modal.Title>Select digital signature</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ maxHeight: canAdd ? '270px' : '340px', overflowY: 'auto' }}
        >
          {signatures?.map((sign) => {
            const { _id, description, signature } = sign;
            return (
              <div key={_id + '-signature-row'} className="signature-row">
                <p className="description-signature">
                  {capitalizeFirstLetter(description)}
                </p>
                {type === 'pick' && (
                  <>
                    <div className="signature-img-container">
                      <img
                        className="signature-img"
                        src={signature}
                        alt="user signature"
                      />
                    </div>
                    <img
                      className="table-select-signature"
                      src={
                        manageSelected === sign._id
                          ? tableSelect
                          : tableSelectEmpty
                      }
                      onClick={() => {
                        handleSave(sign);
                        closeItself();
                      }}
                      alt="select"
                    />
                  </>
                )}
                {type === 'manage' && (
                  <>
                    <div className="signature-img-container">
                      <img
                        className="signature-img"
                        src={signature}
                        alt="user signature"
                      />
                    </div>
                    <img
                      className="table-delete-btn"
                      onClick={() => {
                        setConfirmModal(sign);
                      }}
                      src={tableDelete}
                      alt="table delete button"
                    />
                    <img
                      className="table-select-signature"
                      src={
                        manageSelected === sign._id
                          ? tableSelect
                          : tableSelectEmpty
                      }
                      onClick={() => {
                        handleSave(sign);
                        closeItself();
                      }}
                      alt="select"
                    />
                  </>
                )}
              </div>
            );
          })}
        </Modal.Body>
        {canAdd && (
          <Modal.Footer
            style={{ paddingBottom: 20 }}
            className="d-flex justify-content-end"
          >
            <Button
              type="button"
              className="button-secondary"
              onClick={() => closeItself()}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="button-secondary"
              onClick={() => addSignature()}
            >
              <img src={exportImg} className="mr-3" alt="export icon" /> Upload
              new signature
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      {/* DELETE WORK EQUIPMENT CONFIRMATION MODAL */}
      {confirmModal && (
        <ModalConfirm
          title="Remove signature?"
          text={
            <>
              Are you sure you want to remove {confirmModal.description}?
              <br />
              This action cannot be undone.
            </>
          }
          cancelText="Remove"
          actionCancel={() => {
            deleteSignature(confirmModal);
          }}
          confirmText="Cancel"
          actionConfirm={() => {
            // close delete modal
            setConfirmModal(undefined);
          }}
        />
      )}
    </>
  );
};

export default SignatureList;
