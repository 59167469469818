import { CertTableStruct } from 'interfaces/CertificateTemplate';

import { hashCode } from './helpers';

export const normalizeTableStructure = (
  tableStructure: CertTableStruct[]
): void => {
  const parseFieldId = (fieldId: string) => {
    if (!fieldId.includes('crt') && !fieldId.includes('field')) {
      return `crt${hashCode(fieldId)}`;
    }
    return fieldId;
  };

  tableStructure.forEach((struct, index) => {
    // ADD MISSING COLUMN INDEX
    struct.index = index;
    // NORMALIZE TO FIELD ID HASH
    struct.keyColumn = parseFieldId(struct.keyColumn);

    if (struct.affects) {
      struct.affects.forEach((affect) => {
        // NORMALIZE TO FIELD ID HASH
        affect.field = parseFieldId(affect.field);
      });
    }

    if (struct.validations) {
      struct.validations.forEach((validation) => {
        // NORMALIZE TO FIELD ID HASH
        if (validation.fieldId) {
          validation.fieldId = parseFieldId(validation.fieldId);
        }
      });
    }

    if (struct.requiredValues) {
      struct.requiredValues?.forEach((requiredValue) => {
        if (requiredValue.fieldId) {
          requiredValue.fieldId = parseFieldId(requiredValue.fieldId);
        }
        requiredValue.values?.forEach((value) => {
          if (value.optionsTable) {
            value.optionsTable.fieldX = parseFieldId(value.optionsTable.fieldX);
            value.optionsTable.fieldY = parseFieldId(value.optionsTable.fieldY);
          }

          value.matrixTable?.forEach((matrixTable) => {
            matrixTable.fieldId = parseFieldId(matrixTable.fieldId);

            matrixTable.lookupTable?.forEach((lookupTable) => {
              lookupTable.fieldId = parseFieldId(lookupTable.fieldId);

              lookupTable.table.fieldX = parseFieldId(lookupTable.table.fieldX);
              lookupTable.table.fieldY = parseFieldId(lookupTable.table.fieldY);
            });
          });
        });
      });
    }
    return struct;
  });
};
