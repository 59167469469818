import { DODDLE_NAME, DODDLE_VERSION } from 'constants/doddle.constants';

interface DeviceInfo {
  deviceName: string;
  deviceModel: string;
  operatingSystemName: string;
  operatingSystemVersion: string;
  screenWidth: number;
  screenHeight: number;
  browserName: string;
  browserVersion: string;
}

export const getDeviceInfoFormated = () => {
  const info = getDeviceInfo();
  const osName =
    info.deviceName !== 'Unknown' ? info.deviceName : info.operatingSystemName;
  const osVersion =
    info.deviceModel !== 'Unknown'
      ? info.deviceModel
      : info.operatingSystemVersion;

  return `<b>OS name:</b> ${osName}<br/><b>OS version:</b> ${osVersion}<br/><b>Browser name:</b> ${info.browserName}<br/><b>Browser version:</b> ${info.browserVersion}<br/><b>Screen Resolution:</b> ${info.screenWidth} x ${info.screenHeight}<br/><b>Doddle name:</b> ${DODDLE_NAME}<br/><b>Doddle version:</b> ${DODDLE_VERSION}`;
};

export const getDeviceInfo = (): DeviceInfo => {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  let deviceName = 'Unknown';
  let deviceModel = 'Unknown';

  // Get device name and model based on user agent
  if (/iPhone/.test(userAgent) && !window.MediaStream) {
    deviceName = 'iPhone';
    deviceModel = 'iPhone';
  } else if (/iPad/.test(userAgent) && !window.MediaStream) {
    deviceName = 'iPad';
    deviceModel = 'iPad';
  } else if (/Android/.test(userAgent)) {
    deviceName = 'Android';
    // Extract device model from user agent
    const match = userAgent.match(/Android\s([^\s;]+)/);
    if (match && match[1]) {
      deviceModel = match[1];
    }
  }

  // Get operating system name and version
  let operatingSystemName = 'Unknown';
  let operatingSystemVersion = 'Unknown';
  if (platform.startsWith('Win')) {
    operatingSystemName = 'Windows';
    // Extract Windows version from user agent
    const match = userAgent.match(/Windows NT (\d+\.\d+)/);
    if (match && match[1]) {
      operatingSystemVersion = match[1];
    }
  } else if (platform.startsWith('Mac')) {
    operatingSystemName = 'Mac OS';

    // Extract Mac OS version from user agent
    const match = userAgent.match(/Mac OS X (\d+_\d+)/);
    if (match && match[1]) {
      operatingSystemVersion = match[1].replace('_', '.');
    }
  } else if (platform.startsWith('Linux')) {
    operatingSystemName = 'Linux';
    operatingSystemVersion = 'Unknown';
  }

  // Get browser name and version
  const browserName = (() => {
    if (userAgent.indexOf('Firefox') > -1) return 'Firefox';
    if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1)
      return 'Opera';
    if (userAgent.indexOf('Trident') > -1) return 'Internet Explorer';
    if (userAgent.indexOf('Edge') > -1) return 'Edge';
    if (userAgent.indexOf('Chrome') > -1) return 'Chrome';
    if (userAgent.indexOf('Safari') > -1) return 'Safari';
    return 'Unknown';
  })();

  const browserVersion = (() => {
    const match = userAgent.match(
      /(?:Edge\/|Firefox\/|Chrome\/|Version\/)(\d+(\.\d+)?)/
    );
    return match ? match[1] : 'Unknown';
  })();

  return {
    deviceName,
    deviceModel,
    operatingSystemName,
    operatingSystemVersion,
    screenWidth,
    screenHeight,
    browserName,
    browserVersion,
  };
};
