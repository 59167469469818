import React from 'react';

import BackIcon from 'assets/images/iconset/web_back.svg';

import { BackButtonConteiner } from './styles';

type BackButtonProps = {
  handleBack?: () => void;
};
const BackButton: React.FC<BackButtonProps> = ({ handleBack }) => {
  return (
    <BackButtonConteiner onClick={handleBack}>
      <img src={BackIcon} alt="Back button" />
    </BackButtonConteiner>
  );
};

export default BackButton;
