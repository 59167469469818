import React, { FC } from 'react';

import { Col, Row } from 'react-bootstrap';

import { TextField } from 'components/Form';

const MainAddress: FC = () => {
  return (
    <>
      <Row className="mr-5">
        <Col xs={12}>
          <h2 className="section-title">Billing information</h2>
          <h3 className="section-subtitles">
            Be specific on your customers’ billing details. Avoid incorrect
            invoices
          </h3>
        </Col>
        <TextField
          xs={4}
          label="Billing address"
          fieldId="billingAddress.street"
        />
        <TextField
          xs={4}
          label="Postcode"
          fieldId="billingAddress.postalCode"
        />
        <TextField
          xs={4}
          label="VAT number"
          fieldId="billingAddress.vatNumber"
        />
      </Row>
    </>
  );
};

export default MainAddress;
