import {
  CertificatePresetPayload,
  LoginPost,
  LoginResponse,
  ResetPasswordProps,
  SendReinviteProps,
  User,
  UserRegisterProps,
  UserRegisterResponse,
} from 'store/@types/user';

import { CertificatePreset } from 'interfaces/CertificatePreset';
import { DELETE, GET, POST, PUT } from 'utils/requestTypes';
import { restCall } from 'utils/restHandler';

export const apiLogin = ({ email, password }: LoginPost) =>
  restCall<LoginResponse>(POST, '/auth', {
    username: email,
    password,
  });

export const apiLoadMe = () => restCall<User>(GET, '/auth/me');

export const userLoginRegister = ({ email, password }: UserRegisterProps) =>
  restCall<UserRegisterResponse>(POST, '/user', {
    email,
    password,
  });

export const apiSendPasswordRecovery = ({ email }: { email: string }) =>
  restCall<UserRegisterResponse>(POST, '/auth/send-recover-email', {
    email,
  });

export const apiRecoverPassword = ({
  token,
  newPassword,
}: ResetPasswordProps) =>
  restCall<UserRegisterResponse>(POST, `auth/recover-password?token=${token}`, {
    newPassword,
  });

export const apiSendReinvite = ({ email, businessId }: SendReinviteProps) =>
  restCall(POST, `/user-invite/notify-admin`, {
    invitedEmail: email,
    businessId,
  });

export const apiResendVerifyEmail = (token: string) =>
  restCall(GET, `/auth/resend-verify-email/${token}`);

export const apiDeleteUser = () => restCall(DELETE, '/user/me');

export const apiGerCertificatePresets = (type?: string) => {
  const paramType = `?certificateType${type}`;
  return restCall<CertificatePreset[]>(
    GET,
    `/user/me/certificate-preset${type ? paramType : ''}`
  );
};

export const apiSetCertificatePreset = (payload: CertificatePresetPayload) =>
  restCall<CertificatePreset>(POST, '/user/me/certificate-preset', payload);

export const apiUpdateCertificatePreset = (
  id: string,
  payload: CertificatePresetPayload
) =>
  restCall<CertificatePreset>(
    PUT,
    `/user/me/certificate-preset/${id}`,
    payload
  );

export const apiDeleteCertificatePreset = (id: string) =>
  restCall(DELETE, `/user/me/certificate-preset/${id}`);

export const apiDuplicateCertificatePreset = (id: string) =>
  restCall<CertificatePreset>(
    POST,
    `/user/me/certificate-preset/${id}/duplicate`
  );

export const apiSetUserImage = (picture: File) => {
  const formData = new FormData();
  if (picture) {
    formData.append('picture', picture);
  }

  return restCall<User>(POST, `/user/me/media`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const apiDeleteUserImage = () => {
  return restCall<User>(DELETE, '/user/me/media?files=picture');
};
