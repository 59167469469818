import {
  HandleAutoObservationsParams,
  ObservationCondition,
} from 'interfaces/AutoObservations';

import {
  getAandBfromCompatarionTable,
  getCurrentStringValue,
  getTabulatedValueY,
  processValueWithOperator,
} from './autoObservationsUtils';

export const checkObservationCondition = (
  condition: ObservationCondition,
  handleParams: HandleAutoObservationsParams,
  currentFieldsIds: string[] = []
) => {
  const data = { valueA: '', valueB: '' };
  const {
    operator,
    fieldX,
    fieldY,
    valueY,
    comparationTable,
    tabulatedValueY,
    fieldYOperation,
  } = condition;

  data.valueA = getCurrentStringValue(fieldX, handleParams, currentFieldsIds);

  if (valueY) {
    data.valueB = valueY;
  } else {
    if (fieldY) {
      data.valueB = getCurrentStringValue(
        fieldY,
        handleParams,
        currentFieldsIds
      );
    }
    if (comparationTable) {
      const { newValueA, newValueB } = getAandBfromCompatarionTable(
        data.valueA,
        comparationTable,
        handleParams,
        currentFieldsIds
      );

      data.valueA = newValueA;
      data.valueB = newValueB;
    }

    if (tabulatedValueY) {
      data.valueB = getTabulatedValueY(
        tabulatedValueY,
        handleParams,
        currentFieldsIds
      );
    }

    if (fieldYOperation) {
      const { operator, value } = fieldYOperation;
      data.valueB = processValueWithOperator(
        operator,
        Number(data.valueB),
        Number(value)
      );
    }
  }

  // * Operators logical
  // * Return false if one of both values are empty
  if (!data.valueA || !data.valueB) return false;

  // * String equals check
  if (operator === '==') {
    return data.valueA.toLocaleLowerCase() === data.valueB.toLocaleLowerCase();
  }

  // * Number more than check
  if (operator === '>') {
    return Number(data.valueA) > Number(data.valueB);
  }

  // * Number less than check
  if (operator === '<') {
    return Number(data.valueA) < Number(data.valueB);
  }

  // * Contains character
  if (operator === 'CONTAINS') {
    return data.valueA.includes(data.valueB);
  }

  // * LITERAL_GREATER check
  if (operator === 'LITERAL_GREATER') {
    if (data.valueA.includes('>')) {
      return true;
    }
    return Number(data.valueA) > Number(data.valueB);
  }

  console.info('missing operator logical for autoObservation: ', operator);
  return false;
};
