import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleEnum } from 'store/@types/user';

import { Col, Row } from 'react-bootstrap';

import useAuth from 'hooks/useAuth';

import AddCard from 'components/Carousel/Cards/AddCard';
import CarouselSection from 'components/CarouselSection';
import { MenuMain } from 'components/Menu/MenuMain';
import MissingInfoModal from 'components/modals/MissingInfoModal';
import MainLayout from 'components/root/MainLayout';
import { ContentWrapper } from 'components/root/MainLayout/styles';
import SplashScreen from 'components/root/SplashScreen/index';

const CustomerPage = () => {
  const navigate = useNavigate();
  const {
    authLoading,
    customers,
    userHasRequiredInfoFilled,
    currentRole,
    reload,
  } = useAuth();
  const canEdit = currentRole === RoleEnum.SOLO;
  const [showVerify, setShowVerify] = useState(false);

  const newTemplate = () => {
    const { hasAllRequiredInfo } = userHasRequiredInfoFilled();
    if (hasAllRequiredInfo) {
      navigate(`/customer/new`);
    } else {
      setShowVerify(true);
    }
  };

  useEffect(() => {
    reload({ reloadCustomers: true });
  }, []);

  return (
    <>
      {authLoading && <SplashScreen />}
      {showVerify && <MissingInfoModal close={() => setShowVerify(false)} />}
      <MainLayout pageTitle="Customers" title="Customers">
        <MenuMain />
        <ContentWrapper>
          <Col>
            <CarouselSection
              title="My customers"
              subTitle="Add new customers and edit details on existing ones"
              cardSize="square"
              payload={customers?.map((item) => ({
                id: item._id ?? '0',
                link: `/customer/edit/${item._id}`,
                image: item.picture,
                title: item.name,
              }))}
            />
            {canEdit && (
              <>
                <Row>
                  <div>
                    <h2 className="section-title">Customer operations</h2>
                  </div>
                </Row>
                <Row className="my-4">
                  <AddCard
                    onClick={() => {
                      newTemplate();
                    }}
                    title="Add new customer"
                  />
                </Row>
              </>
            )}
          </Col>
        </ContentWrapper>
      </MainLayout>
    </>
  );
};

export default CustomerPage;
