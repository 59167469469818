import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { ContactUsPayload } from 'store/@types/contact/index';

import { Button, Col, Form, Row } from 'react-bootstrap';

import FormSent from 'assets/images/onboarding/image_formsent.svg';
import { defaultToast } from 'utils/toast';

import { Combo } from 'components/Form/Combo';
import { TextField } from 'components/Form/TextField';
import SplashScreen from 'components/root/SplashScreen';

import { defaultValues, schema } from '../../../pages/Public/Contact/yupSchema';
import { ReduxProps } from '.';
import * as S from './styles';

const ModalContactUs: FC<ReduxProps> = ({
  contact: reduxContact,
  thunkFetchTopics,
  thunkSendContact,
  clearError,
  onClose,
}) => {
  const [successOperation, setSuccessOperation] = useState(false);
  const { error, helpTopics, loading } = reduxContact;

  useEffect(() => {
    thunkFetchTopics();
  }, []);

  useEffect(() => {
    if (error) {
      defaultToast(
        'E',
        'An error occurred while sending your message. Please try again later.'
      );
      clearError();
    }
  }, [error]);

  // React hook form instance
  const methods = useForm<ContactUsPayload>({
    mode: 'onTouched',
    resolver: yupResolver(schema({ description: 500 })),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const onSubmit = async (payload: ContactUsPayload) => {
    thunkSendContact(payload, () => setSuccessOperation(true));
  };

  return (
    <S.Modal show={true} backdrop="static">
      {loading && <SplashScreen />}
      {!successOperation && (
        <div className="modal-body">
          <S.ContactTitle className="white-card-title">
            Describe your issue below
          </S.ContactTitle>
          <S.ContactSubTitle className="white-card-subtitle">
            So we can assist you the best
          </S.ContactSubTitle>

          <div className="white-card-text-body p-0">
            <p>
              Please fill out the form below to best describe the issue you need
              assistance with. Our support team will do our best to get back to
              you shortly.
            </p>
            <FormProvider {...methods}>
              <Form
                id="user-register"
                onSubmit={methods.handleSubmit((data) => {
                  onSubmit(data);
                })}
              >
                <br />
                <Row>
                  <Combo
                    xs={6}
                    fieldId="topic"
                    options={helpTopics?.map((e) => ({
                      key: e._id,
                      value: e.name,
                    }))}
                    label="What do you need help with?"
                  />
                  <Col xs={6} />
                  <TextField xs={6} fieldId="name" label="Name" />
                  <TextField xs={6} fieldId="email" label="Email" />
                  <TextField
                    xs={12}
                    fieldId="description"
                    label="Tell us how we can help"
                    placeholder="Please describe your issue as well as possible."
                    maxLength={500}
                  />
                </Row>
                <Row>
                  <Col xs={6} className="align-self-end">
                    <span
                      style={{ cursor: 'pointer' }}
                      className="gray bold d-block py-2 pe-pointer"
                      onClick={onClose}
                    >
                      Cancel
                    </span>
                  </Col>
                  <Col xs={6} className="align-self-end">
                    <Button
                      className="button-primary float-right"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </div>
        </div>
      )}

      {successOperation && (
        <div className="modal-body">
          <S.ContactTitle className="white-card-title">
            Thank you
          </S.ContactTitle>
          <S.ContactSubTitle className="white-card-subtitle">
            Your request was sent successfully
          </S.ContactSubTitle>
          <div className="white-card-text-body p-0">
            <img
              src={FormSent}
              style={{
                width: '598px',
                height: '387px',
                marginTop: '-40px',
                marginBottom: '20px',
              }}
              alt="formSentImage"
            />
            <p style={{ marginBottom: '30px' }}>
              Thank you for your message.
              <br />
              We’ll do our best to assist you as briefly as possible. If you
              need any further help please reach us at{' '}
              <a href="mailto:doddle.support@arma-software.com">
                doddle.support@arma-software.com
              </a>
            </p>
            <Row>
              <Col xs={6} />
              <Col xs={6}>
                <Button
                  className="button-primary float-right"
                  type="button"
                  onClick={onClose}
                >
                  Back to settings
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </S.Modal>
  );
};

export default ModalContactUs;
