import React, { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Button, Col, Row } from 'react-bootstrap';

import withHelmet from 'hooks/withHelmet';
import { ApiError } from 'services/api';
import { defaultToast, notifications, toast } from 'utils/toast';

import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';

import { ReduxProps } from '.';

const VerifyExpiredPage: FC<ReduxProps> = ({
  logout,
  user: reduxUser,
  resendVerifyEmail,
}) => {
  const [successOperation, setSuccessOperation] = useState(false);
  const { token } = useParams();
  const { loading } = reduxUser;

  const onSubmit = async () => {
    if (token) {
      resendVerifyEmail(token, (success?: boolean, error?: ApiError) => {
        success && setSuccessOperation(true);
        error && defaultToast('E');
      });
    }
  };

  useEffect(() => {
    if (successOperation) {
      toast('The email was sent successfully', {
        ...notifications.success,
        toastId: 'Contact',
      });
    }
  }, [successOperation]);

  return (
    <>
      {loading && <SplashScreen />}
      {!successOperation && (
        <NoAuthBasePage
          title="This link has expired"
          subTitle="We can’t verify your account"
        >
          <div className="white-card-text-body margin-bottom">
            <p>
              The activation link you clicked has expired, so you will need to
              request a new one.
            </p>
            <p>
              Please tap the button below to request a new code to be sent to
              your email address. The code will be valid for 2 hours from the
              moment you request it.
            </p>
            <br />
            <Row>
              <Col xs={6} className="align-self-end">
                <Link
                  className="gray bold"
                  to="#"
                  onClick={(event) => {
                    event.preventDefault();
                    logout(true);
                  }}
                >
                  Back to login screen
                </Link>
              </Col>
              <Col xs={6} className="align-self-end">
                <Button
                  className="button-primary float-right"
                  type="button"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  Request new activation
                </Button>
              </Col>
            </Row>
          </div>
        </NoAuthBasePage>
      )}
      {successOperation && (
        <NoAuthBasePage
          title="Please check your inbox"
          subTitle="To verify your account"
        >
          <div className="white-card-text-body margin-bottom">
            <p>
              Your account has been created successfully, but it is pending
              activation.
            </p>
            <p></p>
            <p>
              If you don’t receive anything in the next 10 minutes, please be
              sure to check your spam folder, as some servers can filter
              automated responses messages.
            </p>
          </div>
          <Row>
            <Col xs={6} />
            <Col xs={6}>
              <Button
                className="button-primary float-right"
                type="button"
                onClick={() => {
                  logout(true);
                }}
              >
                Go to login
              </Button>
            </Col>
          </Row>
        </NoAuthBasePage>
      )}
    </>
  );
};

export default withHelmet(VerifyExpiredPage);
