import { AffectAction } from 'enum/AffectAction.enum';
import { FieldType } from 'enum/FieldType.enum';
import {
  CertificateDataValueProps,
  CertificateDataValuesTableRowProps,
} from 'interfaces/CertificateData';
import { FieldAffects } from 'interfaces/CertificateTemplate';

import { hasActionAffect, isNormalValueTextInputs } from '../helpers';

export const applicableAffectsByAction = (
  affectAction: string | string[],
  type: FieldType,
  affects: FieldAffects[],
  newVal: unknown
) =>
  affects?.filter(({ action, operator, value }) => {
    if (hasActionAffect(action, affectAction)) {
      // Filter by '==' operator

      if (AffectAction.IF_EMPTY === action) {
        return ifEmptyAffects(type, newVal);
      }

      return isEqualsAffect(type, operator, value, newVal);
    }
  });

export const ifEmptyAffects = (type: FieldType, newVal: unknown) => {
  if (type === FieldType.Table) {
    if ((newVal as CertificateDataValuesTableRowProps[])?.length === 0) {
      return true;
    }

    return false;
  }
};

export const isEqualsAffect = (
  type: FieldType,
  AffectOperator: string,
  AffectValue: string,
  newVal: unknown
): boolean => {
  if (AffectOperator === '==') {
    // TEXT COMPONENTS
    if (isNormalValueTextInputs(type)) {
      return AffectValue === newVal;
    }

    if (type === FieldType.SignatureArea) {
      return AffectValue === (newVal as CertificateDataValueProps)?.value;
    }

    if (type === FieldType.Combo) {
      if (typeof newVal === 'string') {
        return AffectValue === newVal;
      }
      return AffectValue === (newVal as CertificateDataValueProps)?.value;
    }

    if (type === FieldType.Radiobutton) {
      return AffectValue === (newVal as CertificateDataValueProps)?.value;
    }

    if (type === FieldType.Checkbox) {
      return AffectValue === (newVal as CertificateDataValueProps)?.value;
    }

    if (type === FieldType.Table) {
      return JSON.stringify(newVal).includes(AffectValue);
    }

    if (type === FieldType.ComboCircuits) {
      return AffectValue === newVal;
    }

    console.warn('field type affect not mapped:', type);
  }

  if (AffectOperator === '!=') {
    // TEXT COMPONENTS
    if (isNormalValueTextInputs(type)) {
      return AffectValue !== newVal;
    }

    if (type === FieldType.SignatureArea) {
      return AffectValue === (newVal as CertificateDataValueProps)?.value;
    }

    if (type === FieldType.Combo) {
      if (typeof newVal === 'string') {
        return AffectValue !== newVal;
      }
      return AffectValue !== (newVal as CertificateDataValueProps)?.value;
    }

    if (type === FieldType.Radiobutton) {
      return AffectValue !== (newVal as CertificateDataValueProps)?.value;
    }

    if (type === FieldType.Checkbox) {
      return AffectValue !== (newVal as CertificateDataValueProps)?.value;
    }

    if (type === FieldType.Table) {
      return !JSON.stringify(newVal).includes(AffectValue);
    }

    if (type === FieldType.ComboCircuits) {
      return AffectValue !== newVal;
    }

    console.warn('field type affect not mapped:', type);
  }
  return false;
};
