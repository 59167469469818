import { FC } from 'react';

import { FieldType } from 'enum/FieldType.enum';
import useAuth from 'hooks/useAuth';
import { CertificateDataValuesTableRowProps } from 'interfaces/CertificateData';
import {
  CertTableStruct,
  CertTemplateField,
} from 'interfaces/CertificateTemplate';
import { isPreset } from 'utils/certificates/presetUtils';

import {
  BoardGrid,
  Checkbox,
  Combo,
  ComboModal,
  DatePicker,
  Divider,
  FormLabel,
  OperationsTable,
  PickCustomer,
  RadioButton,
  Signature,
  Table,
  TableModal,
  TextField,
} from '.';
import { ComboCircuitTable } from './ComboCircuitTable';
import { TableFixed } from './TableFixed';
import { TextFieldCircuitTable } from './TextFieldCircuitTable';

export function convertWithInColumn(width?: number) {
  const columnTypes: { [key: number]: number } = {
    25: 3,
    33: 4,
    50: 6,
    66: 8,
    75: 9,
    100: 12,
  };

  return width && columnTypes[width] ? columnTypes[width] : 12;
}

interface ComponentFieldSelectorProps {
  field: CertTemplateField;
  fieldDisabled?: boolean;
  hiddenRequired?: boolean;
  isCertificate?: boolean;
}

export const ComponentFieldSelector: FC<ComponentFieldSelectorProps> = ({
  field,
  fieldDisabled,
  hiddenRequired,
  isCertificate = false,
}) => {
  const { currentRole } = useAuth();
  const {
    fieldId,
    type,
    inputType,
    placeholder,
    fieldWidth,
    label,
    affects,
    validations,
    maxLength,
    hasTextbox,
    options,
    auxLabel,
    tableStructure,
    alternativeLabel,
    disabled,
    valueTable,
    permissions,
    autoObservations,
    tableIssuedAction,
  } = field;

  const commonProps = {
    key: fieldId,
    xs: convertWithInColumn(fieldWidth),
    fieldId,
    label,
    affects,
    isCertificate,
    autoObservations,
    disabled: fieldDisabled ?? disabled,
    required:
      !hiddenRequired && validations?.some((e) => e.type === 'is-required'),
  };

  if (permissions) {
    const { enabled, visible } = permissions;
    if (!commonProps.disabled) {
      commonProps.disabled = !enabled.includes(currentRole);
    }
    if (!visible.includes(currentRole)) {
      return null;
    }
  }

  switch (type) {
    case FieldType.ComboCircuitTable:
      return (
        <ComboCircuitTable
          {...commonProps}
          options={options}
          placeholder={placeholder}
        />
      );
    case FieldType.Textbox:
      if (inputType === 'number') {
        return (
          <TextField {...commonProps} type="number" placeholder={placeholder} />
        );
      }
      if (inputType === 'number-decimal') {
        return (
          <TextField
            {...commonProps}
            type="number"
            decimal={true}
            placeholder={placeholder}
          />
        );
      }
      return <TextField {...commonProps} placeholder={placeholder} />;

    case FieldType.TextboxCircuitTable:
      if (inputType === 'number') {
        return (
          <TextFieldCircuitTable
            {...commonProps}
            type="number"
            placeholder={placeholder}
          />
        );
      }
      if (inputType === 'number-decimal') {
        return (
          <TextFieldCircuitTable
            {...commonProps}
            type="number"
            decimal={true}
            placeholder={placeholder}
          />
        );
      }
      return (
        <TextFieldCircuitTable {...commonProps} placeholder={placeholder} />
      );

    case FieldType.Textarea:
      return (
        <TextField
          {...commonProps}
          isTextArea={true}
          maxLength={maxLength}
          placeholder={placeholder}
        />
      );
    case FieldType.ComboBoards:
    case FieldType.ComboCircuits:
    case FieldType.Combo:
      return (
        <Combo
          {...commonProps}
          type={type}
          options={options}
          auxLabel={auxLabel}
          hasTextbox={hasTextbox}
          placeholder={placeholder}
        />
      );
    case FieldType.CustomerData:
      return !isPreset() ? <PickCustomer {...commonProps} /> : null;
    case FieldType.Label:
      return <FormLabel {...commonProps} />;
    case FieldType.Divider:
      return <Divider />;
    case FieldType.Signature:
      return <Signature componentType={FieldType.Signature} {...commonProps} />;
    case FieldType.Radiobutton:
      return (
        <RadioButton auxLabel={auxLabel} options={options} {...commonProps} />
      );
    case FieldType.Checkbox:
      return (
        <Checkbox
          auxLabel={auxLabel}
          hasTextbox={hasTextbox}
          {...commonProps}
        />
      );
    case FieldType.Subtitle:
      return <FormLabel {...commonProps} />;
    case FieldType.SmallDatepicker:
      return <DatePicker {...commonProps} />;
    case FieldType.LargeDatepicker:
      return <DatePicker longDate {...commonProps} />;
    case FieldType.SignatureArea:
      return (
        <Signature
          {...commonProps}
          componentType={FieldType.SignatureArea}
          type="select-with-preview"
          submitNewSignature
        />
      );
    case FieldType.ComboModal:
      return <ComboModal otherOption={hasTextbox} {...commonProps} />;
    case FieldType.DescriptionField:
      return (
        <FormLabel
          normal={!alternativeLabel}
          auxLabel={auxLabel}
          {...commonProps}
        />
      );

    case FieldType.TableModal:
      return (
        <TableModal
          field={field}
          tableStructure={tableStructure as CertTableStruct[]}
          {...commonProps}
        />
      );
    case FieldType.Table:
      return (
        <Table
          tableStructure={tableStructure as CertTableStruct[]}
          tableIssuedAction={tableIssuedAction}
          {...commonProps}
        />
      );
    case FieldType.TableFixed:
      return (
        <TableFixed
          valueTable={valueTable as CertificateDataValuesTableRowProps[]}
          tableStructure={tableStructure as CertTableStruct[]}
          {...commonProps}
        />
      );
    case FieldType.BoardGrid:
      return (
        <BoardGrid
          tableStructure={tableStructure as CertTableStruct[]}
          {...commonProps}
        />
      );

    // Circuits operations table
    case FieldType.OperationsTable:
      return <OperationsTable field={field} {...commonProps} />;
    default:
      return <></>;
  }
};
