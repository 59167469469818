import React, { useEffect } from 'react';
import { roleMapper } from 'constants/roleMapper.constants';
import { useLocation } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import withHelmet from 'hooks/withHelmet';

import { ReduxProps } from '.';
import * as S from './styles';
import Workspaces from './Workspaces';

import './styles.css';

interface MainLayoutProps extends ReduxProps {
  title?: string;
  pageTitle: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  title,
  pageTitle,
  children,
}) => {
  const { user, loadMe, business, currentRole } = useAuth();
  const { pathname } = useLocation();
  const subTitle =
    business && `${business?.organizationName}, ${roleMapper[currentRole]}`;

  useEffect(() => {
    document.title = 'Doddle - ' + pageTitle;
  }, [pageTitle]);

  useEffect(() => {
    if (!user) {
      loadMe();
    }
  }, [user]);

  return (
    <S.Main>
      <S.Header>
        <S.HeaderTitleContainer>
          <S.HeaderTitle>{title ?? 'Welcome!'}</S.HeaderTitle>
          {!['/dashboard', '/'].includes(pathname) && (
            <S.HeaderSubTitle>{subTitle}</S.HeaderSubTitle>
          )}
        </S.HeaderTitleContainer>
        {['/dashboard', '/'].includes(pathname) && <Workspaces />}
      </S.Header>
      <S.Content>
        {children}
        <S.Footer fixed>
          &copy; Arma Software {new Date().getFullYear()} - All rights reserved.
        </S.Footer>
      </S.Content>
    </S.Main>
  );
};

export default withHelmet(MainLayout, '#FFFFFF');
