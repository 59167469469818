import { FC, useMemo } from 'react';
import { roleMapper } from 'constants/roleMapper.constants';

import useAuth from 'hooks/useAuth';
import { getRoleByBusiness } from 'utils/userRoles';
import { getLSWorkspaceBusiness, getLSWorkspaces } from 'utils/workspace.util';

import { ComboWorkspace } from 'components/Form/ComboWorkspace';

import * as S from './styles';

const Workspaces: FC = () => {
  const { updateCurrentWorkSpace, user, reload } = useAuth();

  const workspaceOptions = getLSWorkspaces()?.map(
    ({ businessId, businessName, role }) => {
      return {
        key: businessId,
        value: businessId,
        businessName: businessName || 'unnamed',
        userRoleName: roleMapper[role],
      };
    }
  );

  const currentBusiness = useMemo(() => {
    return getLSWorkspaceBusiness();
  }, [user]);

  if (!workspaceOptions) return null;
  return (
    <S.WorkspaceCointainer>
      <ComboWorkspace
        options={workspaceOptions}
        value={currentBusiness}
        handleSave={(businessId) => {
          const role = getRoleByBusiness(businessId, user?.roles ?? []);
          updateCurrentWorkSpace({
            businessId,
            role,
            id: '',
          });
          reload({ reloadAll: true });
        }}
      />
    </S.WorkspaceCointainer>
  );
};

export default Workspaces;
