import * as yup from 'yup';

import { emailValidator, string } from 'utils/formUtils';

export const yupSchema = yup.object().shape({
  name: string.required('Please name your customer'),
  picture: yup.string().nullable(),
  mainContact: yup.object().shape({
    email: emailValidator.required('Invalid email. Please check.'),
    phone: string,
    fax: string,
  }),
  secondaryContact: yup.object().shape({
    email: emailValidator,
    phone: string,
    fax: string,
  }),
  headquartersAddress: yup.object().shape({
    companyName: string,
    street: string.required('Please fill in your customer’s address'),
    city: string.required('Please fill in your customer’s town/city'),
    county: string,
    postalCode: string.required('Please fill in your customer’s postcode'),
  }),
  branchAddresses: yup.array(
    yup.object().shape({
      occupier: string,
      street: string,
      city: string,
      county: string,
      postalCode: string,
    })
  ),
  billingAddress: yup.object().shape({
    street: string,
    postalCode: string,
    vatNumber: string,
  }),
});

export const defaultValues = {
  picture: '',
  name: '',
  mainContact: {
    email: '',
    phone: '',
    fax: '',
  },
  secondaryContact: {
    email: '',
    phone: '',
    fax: '',
  },
  headquartersAddress: {
    companyName: '',
    street: '',
    city: '',
    county: '',
    postalCode: '',
  },
  branchAddresses: [],
  billingAddress: {
    street: '',
    postalCode: '',
    vatNumber: '',
  },
};
