export const formatStringDecimalCurrency = (
  currency: string,
  value: string
) => {
  const integer = value.slice(0, -2);
  const decimals = value.slice(-2);

  const parsedValue =
    value.length > 2 ? Number(`${integer}.${decimals}`) : Number(`00.${value}`);

  const localeCode = getCurrentLocaleCode();
  const formattedCurrency = new Intl.NumberFormat(localeCode, {
    style: 'currency',
    currency,
  }).format(parsedValue);

  return formattedCurrency;
};

export const getCurrentLocaleCode = () => {
  return 'en-GB';
};
