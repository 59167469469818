import React, { FC, useState } from 'react';

import {
  MenuActionDropdownContainer,
  MenuActionDropdownContainerItems,
  MenuActionDropdownIcon,
  MenuActionDropdownItem,
  MenuActionDropdownOverlay,
} from './styles';

export interface MenuActionDropdownProps {
  menuIcon: string;
  items: MenuActionDropdownItemProps[];
}

type MenuActionDropdownItemProps = {
  onClick: () => Promise<void> | void;
  label: string;
  icon: string;
  disabled?: boolean;
  show?: boolean;
};

const MenuActionDropdown: FC<MenuActionDropdownProps> = ({
  menuIcon,
  items,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <MenuActionDropdownContainer>
      <MenuActionDropdownIcon>
        <img
          src={menuIcon}
          alt="context icon"
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
        />
      </MenuActionDropdownIcon>
      {menuOpen && (
        <>
          <MenuActionDropdownOverlay
            onClick={() => {
              setMenuOpen(false);
            }}
          />
          <MenuActionDropdownContainerItems>
            {items
              .filter((item) => item.show)
              .map((item) => {
                return (
                  <MenuActionDropdownItem
                    key={item.label + '-item'}
                    onClick={() => {
                      if (item.disabled) return null;
                      item.onClick();
                      setMenuOpen(false);
                    }}
                    disabled={item.disabled}
                  >
                    <span>{item.label}</span>
                    <img src={item.icon} alt={item.label} />
                  </MenuActionDropdownItem>
                );
              })}
          </MenuActionDropdownContainerItems>
        </>
      )}
    </MenuActionDropdownContainer>
  );
};

export default MenuActionDropdown;
