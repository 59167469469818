import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { Col, FormControlProps, Row } from 'react-bootstrap';

import plusIcon from 'assets/images/iconset/signature_add.svg';
import { FieldType } from 'enum/FieldType.enum';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import {
  CertificateComments,
  CertificateDataValueProps,
} from 'interfaces/CertificateData';
import { parseCustomerAddress } from 'utils/index';

import Comments from 'components/Comments';
import ModalCustomer from 'components/modals/ModalCustomers';

import { Combo, TextField } from '..';
import { FormLabel, FormLabelContainer } from '../styles';
import { CommonProps } from '../Types';

interface PickCustomerProps extends FormControlProps, CommonProps {
  required?: boolean;
  [x: string]: unknown;
}
interface AddressToCombo {
  key: string;
  value: string;
}

const PickCustomer: FC<PickCustomerProps> = ({
  xs,
  isCertificate,
  disabled,
  fieldId,
  required,
}) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const { autoSave, certData } = useCerts();
  const { customers } = useAuth();
  const { state } = useLocation();
  const customerIdAdded = state?.customerIdAdded as string;

  const [showCustomer, setShowCustomer] = useState(false);
  const [selCustomer, setSelCustomer] = useState<{
    name: string;
    address: string;
  } | null>(null);
  const [customerAddresses, setCustomerAddresses] = useState<
    Array<AddressToCombo>
  >([]);
  const thisFieldId = fieldId + '.valueCustomer.customer';
  const thisFieldIdAddress = fieldId + '.valueCustomer.branchAddress';

  const currentCustomerId: string = useWatch({
    control,
    name: thisFieldId,
  });

  const currentCustomerAddressId: string = useWatch({
    control,
    name: thisFieldIdAddress,
  });

  useEffect(() => {
    if (!currentCustomerId && certData?.customer?._id) {
      handleSaveCallback(certData.customer._id);
    }
  }, [currentCustomerId]);

  useEffect(() => {
    if (customerIdAdded) {
      handleSaveCallback(customerIdAdded);
    }
  }, [customerIdAdded]);

  useEffect(() => {
    if (currentCustomerId && customers) {
      const customer = customers.find((c) => c._id === currentCustomerId);
      if (customer) {
        const customerInfo = parseCustomerAddress(customer);
        setSelCustomer(customerInfo);
        const headquartersAddress = customer.headquartersAddress;
        const tempArray: Array<AddressToCombo> = [
          { key: 'headquarters', value: headquartersAddress?.street ?? '' },
          ...customer.branchAddresses.map((b) => ({
            key: b._id ?? '',
            value: b.street,
          })),
        ];

        setCustomerAddresses(tempArray);
        setValue(
          thisFieldIdAddress,
          currentCustomerAddressId || 'headquarters'
        );
      }
    }
  }, [customers, currentCustomerId]);

  const handleSaveCallback = useCallback(
    (customerId: string, branchAddressId = 'headquarters') => {
      const data: CertificateDataValueProps = {
        key: fieldId,
        valueType: FieldType.CustomerData,
        value: '',
        valueCustomer: {
          customer: customerId,
          branchAddress: branchAddressId,
        },
      };

      setValue(fieldId, data, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });

      if (!isCertificate) return;
      removeBranchAddressIfnotDefined(data);
      autoSave({
        type: FieldType.CustomerData,
        fieldId,
        value: data,
        getValues,
        setValue,
      });
    },
    []
  );

  const removeBranchAddressIfnotDefined = (data: CertificateDataValueProps) => {
    if (
      data.valueCustomer?.branchAddress === 'headquarters' &&
      data.valueCustomer
    ) {
      data.valueCustomer.branchAddress = undefined;
    }
  };

  const handleSaveComments = (comments?: CertificateComments) => {
    const currentData = getValues(fieldId);
    removeBranchAddressIfnotDefined(currentData);
    autoSave({
      type: FieldType.CustomerData,
      fieldId,
      value: currentData,
      getValues,
      setValue,
      comments,
    });
  };

  return (
    <>
      <Col xs={xs ? xs / 2 : 6}>
        <FormLabel>
          <FormLabelContainer>
            {'Name'}
            <Comments
              fieldId={fieldId}
              disabledField={disabled}
              isCertificate={isCertificate}
              handleOnChange={handleSaveComments}
            />
          </FormLabelContainer>
          {required && <span className="required float-right">Required</span>}
        </FormLabel>
        <Row>
          <TextField
            required
            hasError={errors[thisFieldId]}
            fieldId="null"
            disabled={disabled}
            value={selCustomer?.name ?? ''}
            readOnly
            onClick={() => {
              setShowCustomer(true);
            }}
            inputImage={
              <img
                className="input_icon_placeholder"
                src={plusIcon}
                style={{ top: 13 }}
                alt="Add a customer"
                onClick={() => {
                  setShowCustomer(true);
                }}
              />
            }
          />
        </Row>
      </Col>
      <Col>
        <FormLabel>
          <FormLabelContainer>
            {'Address'}
            <Comments
              fieldId={fieldId}
              disabledField={disabled}
              isCertificate={isCertificate}
              handleOnChange={handleSaveComments}
            />
          </FormLabelContainer>
          {required && <span className="required float-right">Required</span>}
        </FormLabel>
        {customerAddresses.length > 0 && (
          <Combo
            fieldId={thisFieldIdAddress}
            disabled={disabled}
            type={FieldType.Combo}
            options={customerAddresses}
            onlyInput
            additionalOnChange={(e) => {
              handleSaveCallback(currentCustomerId, e);
            }}
          />
        )}
      </Col>
      {showCustomer && !disabled && (
        <ModalCustomer
          setOpenModal={setShowCustomer}
          handleSave={handleSaveCallback}
        />
      )}
    </>
  );
};

PickCustomer.defaultProps = {
  xs: 12,
};

export { PickCustomer };
