import {
  CertificateComments,
  CertificateCommentsItem,
} from 'interfaces/CertificateData';

export const getLastComment = (
  comments: CertificateComments
): CertificateCommentsItem => {
  const [last] = comments ? comments.items.slice(-1) : [];
  return last;
};
