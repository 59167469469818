import React, { FC } from 'react';

import * as S from './styles';

interface InfoCardProps {
  iconBack: string;
  icon: string;
  title: string;
  description: string;
}

const InfoCard: FC<InfoCardProps> = ({
  icon,
  iconBack,
  title,
  description,
}) => {
  return (
    <S.InfoCardContainer icon={iconBack}>
      <S.InfoCardTitle>
        <S.InfoCardIcon
          src={icon}
          width="40px"
          height="40px"
          alt={description}
        />
        {title}
      </S.InfoCardTitle>
      <S.InfoCardDescription>{description}</S.InfoCardDescription>
    </S.InfoCardContainer>
  );
};

export default InfoCard;
