import React, { useCallback, useState } from 'react';

import defaultIncreaseIcon from 'assets/images/iconset/board_add.svg';
import defaultDecreaseIcon from 'assets/images/iconset/minus.svg';

import * as S from './styles';

interface RangeNumberInputProps {
  decreaseIcon?: string;
  increaseIcon?: string;
  defaultValue?: number;
  handleChange: (value: number) => void;
}

export const RangeNumberInput: React.FC<RangeNumberInputProps> = ({
  decreaseIcon,
  increaseIcon,
  handleChange,
  defaultValue,
}) => {
  const [rangeNumber, setRangeNumber] = useState(defaultValue || 0);

  const changeRange = useCallback(
    (value: number) => {
      setRangeNumber(value);
      handleChange(value);
    },
    [handleChange]
  );

  return (
    <S.RangeContainer>
      <S.RangerColumn>
        <S.RangeButton
          icon={decreaseIcon || defaultDecreaseIcon}
          onClick={() => {
            if (rangeNumber > 0) {
              changeRange(rangeNumber - 1);
            }
          }}
        />
      </S.RangerColumn>
      <S.RangerColumn>
        <S.RangeNumber>{rangeNumber}</S.RangeNumber>
      </S.RangerColumn>
      <S.RangerColumn>
        <S.RangeButton
          icon={increaseIcon || defaultIncreaseIcon}
          onClick={() => {
            changeRange(rangeNumber + 1);
          }}
        />
      </S.RangerColumn>
    </S.RangeContainer>
  );
};
