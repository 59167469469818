import React, { FC, useState } from 'react';

import { Button, Col, Row } from 'react-bootstrap';

import EmptyWorkEquipment from 'assets/images/iconset/missing_test_equipment.svg';
import useAuth from 'hooks/useAuth';
import api from 'services/api';
import { defaultToast } from 'utils/toast';

import ModalConfirm from 'components/modals/ModalConfirm';

import { EquipmentProps } from '..';
import ModalWorkEquipment from './ManageWorkEquipment';
import WorkEquipmentContent from './WorkEquipmentContent';

interface WorkEquipmentProps {
  selectedPage: string;
  setIsLoading: (isLoading: boolean) => void;
  noEquipsError: boolean;
}

const WorkEquipment: FC<WorkEquipmentProps> = ({
  selectedPage,
  setIsLoading,
  noEquipsError,
}) => {
  const { equipmentData, reload } = useAuth();
  const [addWorkEquipModal, setAddWorkEquipModal] = useState(false);
  const [editWorkEquip, setEditWorkEquip] = useState<
    EquipmentProps | undefined
  >(undefined);

  const [showDeleteWorkEquipment, setShowDeleteWorkEquipment] = useState<
    string | undefined
  >(undefined);

  const clearStates = () => {
    // close delete modal
    setShowDeleteWorkEquipment(undefined);

    // close add/edit modal
    setAddWorkEquipModal(false);
    setEditWorkEquip(undefined);
  };

  const deleteEquipment = async () => {
    if (!equipmentData) return;
    setIsLoading(true);

    // clear states
    clearStates();

    await api.delete(`equipment/${showDeleteWorkEquipment}`);

    const equipment = equipmentData?.find(
      (e) => e._id === showDeleteWorkEquipment
    );
    if (equipment?.default && equipmentData) {
      const newDefault = equipmentData.filter(
        (e) => e._id !== showDeleteWorkEquipment
      )[0];

      if (newDefault) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { _id, user, createdAt, updatedAt, __v, ...newVal } = newDefault;
        await api.put(`equipment/${_id}`, {
          ...newVal,
          default: true,
        });
      }
    }
    reload({ reloadEquipments: true });
    setIsLoading(false);
    defaultToast('S');
  };

  return (
    <>
      {(selectedPage === 'workEquipment' || selectedPage === 'init') && (
        <>
          <Row>
            <div>
              <h2 className="section-title">Test equipments</h2>
              <h3 className="section-subtitles">
                Set up your test equipment for accurate reports. This
                information is required to issue certificates.
              </h3>
            </div>
          </Row>
          <Row>
            <div className="settings-container">
              {!equipmentData?.length && (
                <Row>
                  <Col xs={3}>
                    <img
                      src={EmptyWorkEquipment}
                      alt="missing Work Equipment"
                    />
                  </Col>
                  <Col xs={9}>
                    <div
                      className="ml-4 mb-2 mt-4 pl-3 pt-3 pr-3"
                      style={{
                        maxWidth: '400px',
                        ...(noEquipsError && {
                          border: '1px solid #FF0000',
                          borderRadius: 12,
                        }),
                      }}
                    >
                      <h4
                        style={{
                          color: '#370F08',
                          fontSize: 17,
                          fontWeight: 600,
                        }}
                      >
                        No test equipment found…
                      </h4>
                      <p>
                        You need to have at least one test equipment added to be
                        able to fill out certificates.
                      </p>
                    </div>
                    {noEquipsError && (
                      <span
                        className="ml-4 pl-3 pb-3 pt-1"
                        style={{ color: '#FF0000' }}
                      >
                        At least one test equipment is required
                      </span>
                    )}
                  </Col>
                </Row>
              )}
              {equipmentData && equipmentData.length > 0 && (
                <Row>
                  <WorkEquipmentContent
                    deleteEquipment={(id) => {
                      setShowDeleteWorkEquipment(id);
                    }}
                    setEditWorkEquip={(p: EquipmentProps | undefined) =>
                      setEditWorkEquip(p)
                    }
                    equipmentData={equipmentData}
                  />
                </Row>
              )}
              <Row>
                <Col xs={6} lg={9} />
                <Col xs={3}>
                  <Button
                    className="button-primary float-right"
                    onClick={() => {
                      setAddWorkEquipModal(true);
                    }}
                  >
                    Add test equipment
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
        </>
      )}
      {selectedPage === 'init' && (
        <Row className="my-4">
          <hr />
        </Row>
      )}

      {/* DELETE WORK EQUIPMENT CONFIRMATION MODAL */}
      {showDeleteWorkEquipment && (
        <ModalConfirm
          title="Remove test equipment?"
          text={
            <>
              Are you sure you want to remove this equipment from your list?
              <br />
              <br />
              This action cannot be undone.
            </>
          }
          cancelText="Remove"
          actionCancel={() => {
            deleteEquipment();
          }}
          confirmText="Cancel"
          actionConfirm={() => {
            // close delete modal
            setShowDeleteWorkEquipment(undefined);
          }}
        />
      )}

      {(editWorkEquip || addWorkEquipModal) && (
        <ModalWorkEquipment
          setIsLoading={(v) => setIsLoading(v)}
          isEdit={editWorkEquip}
          equipmentData={equipmentData || []}
          deleteEquipment={(id) => setShowDeleteWorkEquipment(id)}
          resetEquipment={() => {
            // clear states
            clearStates();
            // refresh equipment data
            reload({ reloadEquipments: true });
          }}
          action={() => {
            // clear states
            clearStates();
          }}
        />
      )}
    </>
  );
};

export default WorkEquipment;
