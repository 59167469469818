import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { RoleEnum } from 'store/@types/user';

import { Button, Col, FormControlProps } from 'react-bootstrap';

import autoMeiwcIcon from 'assets/images/iconset/auto-meiwc.svg';
import tableDelete from 'assets/images/iconset/table_delete.svg';
import { CertificateState } from 'enum/certificateDataState.enum';
import { FieldType } from 'enum/FieldType.enum';
import { TableIssuedAction } from 'enum/TableIssuedAction.enum';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import {
  CertificateDataValuesTableColumnProps,
  CertTableStruct,
  FieldAffects,
  FormOptions,
} from 'interfaces/CertificateTemplate';

import ModalAutoMeiwc from 'components/modals/ModalAutoMeiwc';

import { Combo, TextField } from '..';
import { CommonProps } from '../Types';
import * as S from './styles';

interface TableProps extends FormControlProps, CommonProps {
  tableStructure: CertTableStruct[];
  tableIssuedAction?: string;
  affects?: FieldAffects[];
  options?: FormOptions[];
}

const Table: FC<TableProps> = ({
  xs,
  fieldId,
  disabled,
  affects,
  options,
  tableStructure,
  autoObservations,
  tableIssuedAction,
}) => {
  const {
    handleAffects,
    handleAutoObservations,
    autoSave,
    certOptions,
    certData,
  } = useCerts();
  const { currentRole } = useAuth();
  const { control, getValues, setValue } = useFormContext();
  const [showAutoMeiwcModal, setShowAutoMeiwcModal] = useState(false);
  const [row, setRow] = useState<CertificateDataValuesTableColumnProps[]>([]);
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(false);

  const showAutoMeiwcAction =
    certData?.certificateState === CertificateState.ISSUED &&
    tableIssuedAction === TableIssuedAction.AUTO_MEIWC &&
    [RoleEnum.LEAD, RoleEnum.SOLO].includes(currentRole);

  const comboOptions = useMemo(() => {
    if (certOptions) {
      return certOptions[fieldId] ? certOptions[fieldId] : options;
    }
  }, [certOptions, options]);

  const currentValue = useWatch({
    control,
    name: fieldId,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldId,
  });

  useEffect(() => {
    if (
      disabled ||
      !autoSaveEnabled ||
      certData?.certificateState === CertificateState.ISSUED
    )
      return;

    // eslint-disable-next-line prefer-const
    let timeoutId: any;

    // clear the previous timeout
    timeoutId && clearTimeout(timeoutId);

    // set a new timeout of 5 seconds
    timeoutId = setTimeout(() => {
      // call the API

      autoSave({
        type: FieldType.Table,
        fieldId,
        value: currentValue,
        getValues,
        setValue,
      });
      if (affects) {
        handleAffects({
          fieldId,
          type: FieldType.Table,
          affects,
          newVal: currentValue,
          getValues,
          setValue,
        });
      }
      if (autoObservations) {
        handleAutoObservations({
          fieldId,
          autoObservations,
          getValues,
          setValue,
        });
      }
    }, 1000);

    // clean up the timeout when the component unmounts or the `currentValue` changes
    return () => clearTimeout(timeoutId);
  }, [currentValue]);

  return (
    <>
      <Col xs={xs}>
        <S.TableCertificateTableHeader>
          <S.TableHeaderText>
            {tableStructure.map(
              ({ keyColumn, columnSize, columnName }, index) => (
                <Fragment key={keyColumn + 'tableth'}>
                  <S.TableTH
                    columnSize={columnSize}
                    lastColumn={index <= tableStructure.length - 1}
                  >
                    {columnName}
                  </S.TableTH>
                  {index !== tableStructure.length - 1 && (
                    <S.TableHeaderDivider></S.TableHeaderDivider>
                  )}
                </Fragment>
              )
            )}
            <div
              style={{
                minWidth: '64px',
              }}
            />
          </S.TableHeaderText>
        </S.TableCertificateTableHeader>
      </Col>
      {fields.map((row, index) => {
        const fieldKey = row.id + 'field-item ';
        let hasHelperText: null | string = null;

        tableStructure.forEach((col, columnIndex) => {
          const item = (row as any)[`${columnIndex}`];
          if (item?.auxLabel) {
            hasHelperText = item.auxLabel;
          }
        });

        return (
          <Fragment key={fieldKey}>
            <Col
              xs={12}
              className="table-row"
              style={{ marginTop: '20px', gap: 5 }}
            >
              {tableStructure.map(
                (
                  { columnSize, columnType, options: fieldOptions },
                  columnIndex
                ) => {
                  const tableColumnKey =
                    fieldKey + index + 'header-column-key' + columnIndex;
                  const item = (row as any)[
                    `${columnIndex}`
                  ] as CertificateDataValuesTableColumnProps;
                  const isFirstColumn = columnIndex === 0;

                  return (
                    <S.TableTD key={tableColumnKey} columnSize={columnSize}>
                      {columnType === 'auto-number' && (
                        <span
                          className={clsx('tableAutoNumOption', { disabled })}
                        >
                          {item?.value}
                        </span>
                      )}
                      {columnType === 'string' && (
                        <span
                          className={clsx(
                            'tableItemOption grayed',
                            { 'first-column': isFirstColumn },
                            {
                              disabled,
                            }
                          )}
                        >
                          {item?.value}
                        </span>
                      )}

                      {columnType === 'textbox' && (
                        <TextField
                          onlyInput
                          xs={12}
                          label=""
                          isCertificate={false}
                          disabled={disabled}
                          fieldId={`${fieldId}.${index}.${columnIndex}.value`}
                          onChange={(e) => {
                            setAutoSaveEnabled(true);
                            setValue(
                              `${fieldId}.${index}.${columnIndex}.value`,
                              e.target.value
                            );
                          }}
                        />
                      )}
                      {columnType === 'combo' && (
                        <Combo
                          onlyInput
                          xs={12}
                          label=""
                          isCertificate={false}
                          disabled={disabled}
                          fieldId={`${fieldId}.${index}.${columnIndex}.value`}
                          options={comboOptions ?? fieldOptions}
                          additionalOnChange={() => {
                            setAutoSaveEnabled(true);
                            const current = getValues(
                              `${fieldId}.${index}.${columnIndex}`
                            );
                            if (!current.index) {
                              setValue(
                                `${fieldId}.${index}.${columnIndex}.index`,
                                columnIndex
                              );
                              setValue(
                                `${fieldId}.${index}.${columnIndex}.valueType`,
                                'string'
                              );
                            }
                          }}
                        />
                      )}
                    </S.TableTD>
                  );
                }
              )}
              <S.ActionContainer>
                {!disabled && (
                  <S.ButtonAction
                    disabled={disabled}
                    onClick={() => {
                      !disabled && remove(index);
                    }}
                  >
                    <img
                      src={tableDelete}
                      alt="table delete button"
                      width={24}
                      height={24}
                    />
                  </S.ButtonAction>
                )}
                {showAutoMeiwcAction && (
                  <S.ButtonAction
                    onClick={() => {
                      setRow(getValues(`${fieldId}.${index}`));
                      setShowAutoMeiwcModal(true);
                    }}
                  >
                    <img
                      src={autoMeiwcIcon}
                      alt="Automatic MEIWC"
                      width={30}
                      height={30}
                    />
                  </S.ButtonAction>
                )}
              </S.ActionContainer>
            </Col>
            {hasHelperText && (
              <Col
                xs={12}
                className="table-row-helper-text"
                style={{ marginTop: '10px', gap: 5 }}
              >
                {hasHelperText}
              </Col>
            )}
          </Fragment>
        );
      })}
      <Col xs={12} className="buttons-right-area">
        {!disabled && (
          <Button
            className="button-primary"
            onClick={() => {
              const nextIndex =
                getValues(fieldId).reduce(
                  (max: number, obj: any) =>
                    +obj[0].value > max ? +obj[0].value : max,
                  0
                ) + 1;

              const newF: any = {};

              tableStructure.forEach((col, index) => {
                if (col.columnType === 'auto-number') {
                  newF[index] = {
                    index,
                    value:
                      `${nextIndex}`.length === 1
                        ? `0${nextIndex}`
                        : `${nextIndex}`,
                    valueType: 'string',
                  };
                } else {
                  newF[index] = {
                    index,
                    value: '',
                    valueType: 'string',
                  };
                }
              });

              append(newF, {
                shouldFocus: false,
              });
            }}
          >
            Add Row
          </Button>
        )}
      </Col>
      {showAutoMeiwcModal && (
        <ModalAutoMeiwc
          closeModal={function (): void {
            setShowAutoMeiwcModal(false);
          }}
          location={row[2].value ?? ''}
          obervation={row[1].value ?? ''}
        />
      )}
    </>
  );
};

Table.defaultProps = {
  xs: 12,
};

export { Table };
