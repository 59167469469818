import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/esm/Button';

import MissingImg from 'assets/images/logo/image_missing_information.svg';

import './styles.css';

const MissingInfoModal: FC<{ close: () => void }> = ({ close }) => {
  const navigate = useNavigate();
  return (
    <div id="verify-container-modal">
      <div className="verify-container">
        <h1 className="title">We need some more information</h1>
        <h2 className="subtitle">Before you can issue certificates</h2>
        <img src={MissingImg} alt="onboarding-open-img" />

        <div className="explanation">
          In order to issue and sign certificates you need to complete your
          profile information. Head over to the Settings and enter the required
          data to proceed.
        </div>

        <div className="verify-btn-area">
          <a role="button" onClick={close}>
            Update later
          </a>
          <Button
            className="button-primary"
            type="button"
            onClick={() => {
              navigate('/settings', {
                state: { verify: true },
              });
            }}
          >
            Update my profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MissingInfoModal;
