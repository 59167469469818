import { Link, useLocation } from 'react-router-dom';
import { RoleEnum } from 'store/@types/user';

import TabCertificatesIcon from 'assets/images/iconset/tab_certificates.svg';
import TabCertificatesActiveIcon from 'assets/images/iconset/tab_certificates_active.svg';
import TabClientsIcon from 'assets/images/iconset/tab_clients.svg';
import TabClientsActiveIcon from 'assets/images/iconset/tab_clients_active.svg';
import TabDashboardIcon from 'assets/images/iconset/tab_dashboard.svg';
import TabDashboardActiveIcon from 'assets/images/iconset/tab_dashboard_active.svg';
import TabSettingsIcon from 'assets/images/iconset/tab_settings.svg';
import TabSettingsActiveIcon from 'assets/images/iconset/tab_settings_active.svg';
import useAuth from 'hooks/useAuth';

import * as S from './styles';

export const MenuMain = () => {
  const location = useLocation();
  const { currentRole } = useAuth();
  const canShowCustomer = [RoleEnum.SOLO, RoleEnum.LEAD].includes(currentRole);

  // Menu tab is active
  function isActive(route: Array<string>): boolean {
    if (route.indexOf(location.pathname) > -1) return true;
    return false;
  }

  return (
    <S.Container>
      <ul>
        <li>
          <Link to="/dashboard">
            <img
              src={
                isActive(['/dashboard'])
                  ? TabDashboardActiveIcon
                  : TabDashboardIcon
              }
              alt="dashboard icon"
            />
            <span className={isActive(['/dashboard']) ? 'active' : ''}>
              Dashboard
            </span>
          </Link>
        </li>
        <li>
          <Link to="/certificates">
            <img
              src={
                isActive(['/certificates'])
                  ? TabCertificatesActiveIcon
                  : TabCertificatesIcon
              }
              alt="dashboard icon"
            />
            <span className={isActive(['/certificates']) ? 'active' : ''}>
              Certificates
            </span>
          </Link>
        </li>
        {canShowCustomer && (
          <li>
            <Link to="/customers">
              <img
                src={
                  isActive(['/customers'])
                    ? TabClientsActiveIcon
                    : TabClientsIcon
                }
                alt="dashboard icon"
              />
              <span className={isActive(['/customers']) ? 'active' : ''}>
                Customers
              </span>
            </Link>
          </li>
        )}

        <li>
          <Link to="/settings">
            <img
              src={
                isActive(['/settings'])
                  ? TabSettingsActiveIcon
                  : TabSettingsIcon
              }
              alt="dashboard icon"
            />
            <span className={isActive(['/settings']) ? 'active' : ''}>
              Settings
            </span>
          </Link>
        </li>
      </ul>
    </S.Container>
  );
};
