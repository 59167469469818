import * as yup from 'yup';

import { string } from 'utils/formUtils';

export const yupSchema = yup.object().shape({
  occupier: string.required('Please fill in this location’s occupier'),
  street: string.required('Please fill in this location’s address'),
  city: string.required('Please fill in this location’s town/city'),
  county: string,
  postalCode: string.required('Please fill in this location’s postcode'),
});

export const defaultValues = {
  occupier: '',
  street: '',
  city: '',
  county: '',
  postalCode: '',
};
