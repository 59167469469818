import React from 'react';
import { Link } from 'react-router-dom';

import withHelmet from 'hooks/withHelmet';

import NoAuthBasePage from 'components/root/NoAuthBasePage';

function PaymentOverdue() {
  return (
    <NoAuthBasePage
      title="We're sorry but"
      subTitle="We are unable to log you in"
    >
      <div className="white-card-text-body margin-top">
        <p>
          It appears that your account subscription has some overdue payments,
          or financial requirements that need your input.
        </p>
        <p>
          Please <Link to="/contact">get in contact with our services</Link> so
          we can assist you in the necessary steps to regularize your access.
        </p>
      </div>
    </NoAuthBasePage>
  );
}

export default withHelmet(PaymentOverdue);
