import { FC } from 'react';

import { Button, FormControlProps } from 'react-bootstrap';

import * as FormStyles from 'components/Form/styles';

import { CommonProps } from '../Types';

interface TextFieldProps
  extends FormControlProps,
    Omit<CommonProps, 'fieldId'> {
  buttonClasses?: string;
  buttonLabel: string;
  buttonAction: () => void;
  [x: string]: unknown;
}

const ButtonLabeled: FC<TextFieldProps> = ({
  xs,
  label,
  buttonClasses,
  buttonLabel,
  buttonAction,
}) => {
  return (
    <FormStyles.FormGroupWithErrorSpace
      xs={xs}
      className="ButtonLabeledComponentForm"
    >
      {!!label && <FormStyles.FormLabel>{label}</FormStyles.FormLabel>}
      <Button className={buttonClasses} onClick={buttonAction}>
        {buttonLabel}
      </Button>
    </FormStyles.FormGroupWithErrorSpace>
  );
};

ButtonLabeled.defaultProps = {
  xs: 12,
};

export { ButtonLabeled };
