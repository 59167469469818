import styled from 'styled-components';

export const CommentContainer = styled.div`
  display: flex;
  position: relative;
`;

export const CommentIconContent = styled.span`
  display: flex;
  cursor: pointer;
`;

export const CommentIcon = styled.img`
  height: 20px;
  width: 20px;
`;

export const CommentModalOverlay = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const CommentModal = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 324px;
  height: 195px;
  top: 36px;
  right: -254px;
  z-index: 2;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #4fa3a1;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

  :before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #4fa3a1;
    right: 80%;
    top: -20px;
  }
  :after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 10px solid transparent;
    border-bottom: 10px solid #fff;
    right: 80%;
    top: -18px;
  }
`;

export const CommentModalInputContent = styled.div<{
  disabled: boolean | undefined;
}>`
  display: flex;
  background-color: #ffffff;
  width: 100%;
  padding: 10px 20px 10px 10px;

  textarea {
    padding: 0px;
    border: none;
    height: 101px !important;
    ${(props) => props.disabled && 'color: #000000;'};
    background-color: #ffffff !important;
    border-radius: 0 !important;
    :focus {
      border: none !important;
    }
  }
`;

export const CommentModalActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  background-color: #ffffff;

  .resolve {
    color: #666666;
    background-color: #ffffff;
    border: 1px solid #666666;
  }
`;
