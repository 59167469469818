import { ApiError } from 'services/api';

export class ApiException<T = object> extends Error {
  private readonly statusCode: number;
  private readonly error: string;
  private readonly data: T;

  constructor(message: string, apiError: ApiError) {
    super(message);

    this.statusCode = apiError.statusCode;
    this.error = apiError.error;
    this.data = apiError.data as T;
  }

  getStatus(): number {
    return this.statusCode;
  }

  getError(): string {
    return this.error;
  }

  getData(): T {
    return this.data;
  }
}
