import styled from 'styled-components';

import { TableStyles } from 'interfaces/Circuits';

export const TableColumn = styled.div<TableStyles>`
  width: ${({ column }) => column.width};
  margin: 0;
  white-space: nowrap;
  letter-spacing: 1em;
  height: 36px;
  .first-column-field {
    font-size: 14px;
    padding-inline: 0px;
    text-align: center;
    cursor: pointer;
  }
`;
