import { CertificateState } from 'enum/certificateDataState.enum';
import { CertTemplatePage } from 'interfaces/CertificateTemplate';

export const disabledFieldOnStates = [
  CertificateState.ISSUED,
  CertificateState.IN_REVIEW,
  CertificateState.PENDING_REVIEW,
];

export const CERT_PREVIEW_PAGE = '999';
export const CIRCUIT_INDEXES = 'doddleCircuitTableIndexes';

export const previewPageTemplate: CertTemplatePage = {
  isPreview: true,
  pageName: 'Preview certificate',
  pageOrder: CERT_PREVIEW_PAGE,
  sections: [
    {
      title: 'Preview certificate',
      subtitle:
        "Below you can see a preview of the certificate to be generated. If everything is correctly filled out, you can tap on 'Issue certificate' to produce a valid final document",
      rows: [],
    },
  ],
};
