import { AffectAction } from 'enum/AffectAction.enum';
import { FieldType } from 'enum/FieldType.enum';
import { HandleAffectsParams } from 'interfaces/CertificateTemplate';

import { applicableAffectsByAction } from '../applicableAffectsByAction';

export const changeDateAction = (handleAffectsParams: HandleAffectsParams) => {
  const {
    newVal,
    type,
    affects,
    setValue,
    getValues,
    defaultValues = {},
    autoSave,
  } = handleAffectsParams;

  const changeDateAffects = applicableAffectsByAction(
    AffectAction.INCREMENT_DATE,
    type,
    affects,
    newVal
  );

  const getFieldValue = (fieldId: string): string => {
    return getValues ? getValues(fieldId) : defaultValues[fieldId];
  };
  const setFieldValue = (fieldId: string, date: string): void => {
    if (setValue) {
      setValue(fieldId, date);
    } else if (defaultValues) {
      defaultValues[fieldId] = date;
    }
    if (autoSave) {
      autoSave({ type: FieldType.LargeDatepicker, fieldId, value: date });
    }
  };

  changeDateAffects.forEach(({ field, days, originDate }) => {
    if (originDate && days) {
      const date = new Date(getFieldValue(originDate));
      date.setDate(date.getDate() + days);
      setFieldValue(field, date.toISOString());
    }
  });
};
