import { FieldType } from 'enum/FieldType.enum';
import {
  HandleAutoObservationsParams,
  ObservationsFieldsApply,
} from 'interfaces/AutoObservations';
import { defaultToast } from 'utils/toast';

import { AutoObervationsTemp } from './autoObservations';
import {
  addObservation,
  addObservationSol,
  getCircuitDesignationValue,
  getCurrentDynamicTableValue,
  getCurrentFixedTableValue,
  getLocationValue,
  getReplacedValue,
  isObervationExists,
  isObservationSolExists,
  updateObservation,
} from './autoObservationsUtils';

export const applyObservations = (
  autoObervationsTemp: AutoObervationsTemp[],
  handleParams: HandleAutoObservationsParams
): void => {
  const fields: ObservationsFieldsApply = {};

  autoObervationsTemp.forEach((temp) => {
    const { autoObservation, currentFieldsIds } = temp;
    const { observation, sol } = autoObservation;

    // * Observation
    if (observation) {
      if (!fields[observation.fieldId]?.observationTable) {
        fields[observation.fieldId] = {
          observationTable: getCurrentDynamicTableValue(
            observation.fieldId,
            handleParams
          ),
        };
      }

      // ** Get location from current board or return main supply
      const location = getLocationValue(handleParams, currentFieldsIds);

      // ** Get circuit designation from current circuit and board
      const circuitDesignation = getCircuitDesignationValue(
        handleParams,
        currentFieldsIds
      );

      const observationExists = isObervationExists(
        observation,
        location,
        circuitDesignation,
        fields[observation.fieldId]?.observationTable
      );

      // ** Replace fieldIds from observation value by fields values
      const replacedValue = getReplacedValue(
        observation,
        handleParams,
        currentFieldsIds
      );

      // Create a new observation
      if (!observationExists) {
        addObservation(
          observation,
          replacedValue,
          location,
          fields[observation.fieldId]?.observationTable
        );
        fields[observation.fieldId].applied = true;
        // console.log('Observation marked to add', observation);
      } else {
        // Update current observation
        updateObservation(
          observation,
          replacedValue,
          location,
          circuitDesignation,
          fields[observation.fieldId]?.observationTable
        );
        fields[observation.fieldId].applied = true;
        // console.log('Observation marked to update', observation);
      }
    }

    // * Sol
    if (sol) {
      if (!fields[sol.fieldId]?.observationSolTable) {
        fields[sol.fieldId] = {
          observationSolTable: getCurrentFixedTableValue(
            sol.fieldId,
            handleParams
          ),
        };
      }

      const observationSolExists = isObservationSolExists(
        sol,
        fields[sol.fieldId]?.observationSolTable
      );

      if (!observationSolExists) {
        addObservationSol(sol, fields[sol.fieldId]?.observationSolTable);
        fields[sol.fieldId].applied = true;
        // console.log('Observation sol marked to add', sol);
      }
    }
  });

  const { autoSave, getValues, setValue } = handleParams;

  // * Set values and autosave one time per field
  Object.entries(fields).forEach(([fieldId, data]) => {
    if (data.applied && data.observationTable) {
      handleParams.setValue(fieldId, data.observationTable);

      if (autoSave) {
        autoSave({
          type: FieldType.Table,
          fieldId,
          value: data.observationTable,
          getValues,
          setValue,
          // onSave: () => {
          //   console.log('Observations Applied: ', fieldId, data);
          // },
        });
      }
      defaultToast(
        'S',
        'The input values have generated an automatic observation in Section K. You can manage these observations from there'
      );
    }

    if (data.applied && data.observationSolTable) {
      handleParams.setValue(fieldId, data.observationSolTable);
      if (autoSave) {
        autoSave({
          type: FieldType.TableFixed,
          fieldId,
          value: data.observationSolTable,
          getValues,
          setValue,
          // onSave: () => {
          //   console.log('Sols Applied: ', fieldId, data);
          // },
        });
      }
    }
  });
};
