import styled from 'styled-components';

export const MenuBackContainer = styled.div`
  width: 100%;
  height: 42px !important;
  padding: 0 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: solid 1px red;

  background-color: #fdfeff;
`;

export const MenuBackTitle = styled.div`
  text-transform: uppercase;

  color: #4fa3a1;

  font-size: 18px;
  font-weight: normal;
`;
