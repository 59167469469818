import React, { FC } from 'react';

import { Col, Row } from 'react-bootstrap';

import { TextField } from 'components/Form';

const AdditionalContactInfo: FC = () => {
  return (
    <>
      <Row className="mr-5">
        <Col xs={12}>
          <h2 className="section-title">Additional contact information</h2>
          <h3 className="section-subtitles">
            Add more information to your customer
          </h3>
        </Col>
        <TextField
          xs={4}
          label="Secondary phone number"
          fieldId="secondaryContact.phone"
        />
        <TextField
          xs={4}
          label="Secondary email"
          fieldId="secondaryContact.email"
        />
        <TextField
          xs={4}
          label="Secondary fax"
          fieldId="secondaryContact.fax"
        />
      </Row>
      <Row>
        <Col xs={12}>
          <hr />
        </Col>
      </Row>
    </>
  );
};

export default AdditionalContactInfo;
