import { FC } from 'react';

import { Col } from 'react-bootstrap';

export const Divider: FC = () => {
  return (
    <Col xs={12} className="mb-3">
      <hr />
    </Col>
  );
};
