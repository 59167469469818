import * as yup from 'yup';

import { boolean, string } from 'utils/formUtils';

export const yupSchema = yup.object().shape({
  name: string.required('Please insert a valid equipment name'),
  serialNumber: string.required('Please insert a valid serial number'),
  type: string.required('Please select an type'),
  make: string,
  modelNumber: string,
  calibrationDate: string,
  default: boolean,
});

export const defaultValues = {
  name: '',
  serialNumber: '',
  type: '',
  make: '',
  modelNumber: '',
  calibrationDate: '',
  default: false,
};
