import {
  ContactTopic,
  ContactUsPayload,
  ContactUsResponse,
} from 'store/@types/contact';

import { getDeviceInfoFormated } from 'utils/deviceInfoUtils';
import { GET, POST } from 'utils/requestTypes';
import { restCall } from 'utils/restHandler';

export const apiSendContactUs = (payload: ContactUsPayload) => {
  const deviceInfo = getDeviceInfoFormated();
  const description = `${deviceInfo}<br/><b>Description:</b> ${payload.description}`;

  return restCall<ContactUsResponse>(POST, '/contact', {
    ...payload,
    description,
  });
};

export const apiGetContactTopics = () =>
  restCall<ContactTopic[]>(GET, '/contactTopic');
