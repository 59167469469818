import { FC, useMemo } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';

import { Col, FormControlProps, Row } from 'react-bootstrap';

import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';
import { CertificateDataValueProps } from 'interfaces/CertificateData';
import {
  CertTemplateField,
  FieldAffects,
  FormOptions,
} from 'interfaces/CertificateTemplate';
import { getBoardInfo } from 'utils/certificates/defaultValues';
import { hasTextboxField } from 'utils/certificates/helpers';

import Comments from 'components/Comments';
import {
  FormAuxLabel,
  FormControlFeedback,
  FormGroupWithErrorSpace,
  FormLabel,
  FormLabelContainer,
  FormSelect,
} from 'components/Form/styles';

import { getError, TextField } from '..';
import { CommonProps } from '../Types';
import { ComboWrapper } from './styles';

interface ComboProps extends FormControlProps, CommonProps {
  placeholder?: string;
  options?: Array<FormOptions>;
  affects?: FieldAffects[];
  required?: boolean;
  onlyInput?: boolean;
  height?: string;
  additionalOnChange?: (changesValue: string) => void;
  field?: CertTemplateField;
  hasTextbox?: boolean;
  type?: FieldType.Combo | FieldType.ComboBoards | FieldType.ComboCircuits;
}

const Combo: FC<ComboProps> = ({
  xs,
  fieldId,
  label,
  disabled,
  placeholder,
  affects,
  auxLabel,
  height,
  additionalOnChange,
  required,
  onlyInput,
  options,
  hasTextbox,
  isCertificate,
  autoObservations,
  type,
}) => {
  const {
    control,
    getValues,
    setValue,
    setFocus,
    formState: { errors },
  } = useFormContext();
  const error = getError(fieldId, errors);
  const { certOptions, handleAffects, handleAutoObservations, autoSave } =
    useCerts();

  const comboOptions = useMemo(() => {
    const componentOptions =
      certOptions && certOptions[fieldId]
        ? certOptions[fieldId]
        : options ?? [];

    if (type && type === FieldType.ComboBoards) {
      //** remove current board from boards option list */
      const { boardId } = getBoardInfo(fieldId);
      return componentOptions.filter((e) => e.key !== boardId);
    }
    return componentOptions;
  }, [certOptions, options, type]);

  const comboPlaceholder: string = comboOptions?.length ? 'Select option' : '-';
  const noData = comboOptions?.length === 0;

  const thisFieldId = hasTextbox ? fieldId + '.value' : fieldId;
  const thisFieldIdText = hasTextbox ? fieldId + '.valueTextbox' : fieldId;

  const currentValue: CertificateDataValueProps = {
    key: fieldId,
    valueType: FieldType.Combo,
    value: '',
    valueTextbox: '',
  };

  const selectFieldProps = (
    field: ControllerRenderProps<FieldValues, string>,
    enableTextBox?: boolean
  ) => {
    return {
      ...field,
      height: height,
      isInvalid: Boolean(isCertificate && hasTextbox ? error?.value : error),
      disabled: !comboOptions || noData || disabled,
      placeholder: placeholder,
      onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
        const changedValue = e.target.value;
        setValue(thisFieldId, changedValue);

        if (additionalOnChange) {
          additionalOnChange(changedValue);
        }

        if (isCertificate) {
          if (hasTextbox) {
            currentValue.value = changedValue;
            currentValue.valueTextbox = '';

            if (affects) {
              handleAffects({
                fieldId,
                type: FieldType.Combo,
                affects,
                newVal: currentValue,
                getValues,
                setValue,
              });
            }
            if (autoObservations) {
              handleAutoObservations({
                fieldId,
                autoObservations,
                getValues,
                setValue,
              });
            }
            autoSave({
              type: FieldType.Combo,
              fieldId,
              value: currentValue,
              getValues,
              setValue,
            });
            setValue(thisFieldIdText, '', { shouldValidate: enableTextBox });
            setTimeout(() => setFocus(thisFieldIdText), 200);
          } else {
            if (affects) {
              handleAffects({
                fieldId,
                type: FieldType.Combo,
                affects,
                newVal: changedValue,
                getValues,
                setValue,
              });
            }
            if (autoObservations) {
              handleAutoObservations({
                fieldId,
                autoObservations,
                getValues,
                setValue,
              });
            }
            autoSave({
              type: FieldType.Combo,
              fieldId,
              value: changedValue,
              getValues,
              setValue,
            });
          }
        }
      },
    };
  };

  const selectFieldOptions = (
    <>
      {comboOptions?.length > 0 && <option value="">{comboPlaceholder}</option>}
      {comboOptions?.map((option) => {
        return (
          <option
            key={'combobox-' + option.key + option.value}
            value={option.key}
          >
            {option.value}
          </option>
        );
      })}
    </>
  );

  if (onlyInput) {
    return (
      <Controller
        name={thisFieldId}
        control={control}
        render={({ field }) => (
          <ComboWrapper height={height}>
            <FormSelect {...selectFieldProps(field)}>
              {selectFieldOptions}
            </FormSelect>
          </ComboWrapper>
        )}
      />
    );
  }

  return (
    <Controller
      name={thisFieldId}
      control={control}
      render={({ field }) => {
        const enableTextBox = hasTextboxField(
          FieldType.Combo,
          field.value,
          comboOptions
        );
        return (
          <FormGroupWithErrorSpace
            xs={xs}
            height={(hasTextbox && '124px') || (auxLabel && 'max-content')}
          >
            <FormLabel>
              <FormLabelContainer>
                {label}{' '}
                <Comments
                  fieldId={fieldId}
                  disabledField={disabled}
                  isCertificate={isCertificate}
                  handleOnChange={(comments) => {
                    autoSave({
                      type: FieldType.Combo,
                      fieldId,
                      value: {
                        ...currentValue,
                        value: getValues(thisFieldId),
                        valueTextbox: getValues(thisFieldIdText),
                        comments,
                      },
                      getValues,
                      setValue,
                    });
                  }}
                />
              </FormLabelContainer>
              {required && !noData && (
                <span className="required float-right">Required</span>
              )}
            </FormLabel>
            <Row>
              <Col xs={hasTextbox ? 6 : 12}>
                <ComboWrapper height={height}>
                  <FormSelect {...selectFieldProps(field, enableTextBox)}>
                    {selectFieldOptions}
                  </FormSelect>
                </ComboWrapper>
                <FormControlFeedback type="invalid">
                  {error?.message || error?.value?.message}
                </FormControlFeedback>
              </Col>
              {isCertificate && hasTextbox && (
                <Col xs={6} className="combo-text-box">
                  <TextField
                    onlyInput
                    fieldId={thisFieldIdText}
                    disabled={disabled ?? !enableTextBox}
                    isInvalid={Boolean(error?.valueTextbox)}
                    additionalOnBlur={(text: string) => {
                      const newVal = {
                        ...currentValue,
                        value: field.value,
                        valueTextbox: text,
                      };
                      autoSave({
                        type: FieldType.Combo,
                        fieldId,
                        value: newVal,
                        getValues,
                        setValue,
                      });
                      if (autoObservations) {
                        handleAutoObservations({
                          fieldId,
                          autoObservations,
                          getValues,
                          setValue,
                        });
                      }
                    }}
                  />
                  <FormControlFeedback type="invalid">
                    {error?.valueTextbox?.message}
                  </FormControlFeedback>
                </Col>
              )}
              {auxLabel && !error && (
                <Col xs={12}>
                  <FormAuxLabel>{auxLabel}</FormAuxLabel>
                </Col>
              )}
            </Row>
          </FormGroupWithErrorSpace>
        );
      }}
    />
  );
};

export { Combo };
