import React from 'react';

import Modal from 'react-bootstrap/Modal';

import closeIcon from 'assets/images/iconset/close_icon.svg';

import * as S from './styles';

interface ModalImageProps {
  title: string;
  text: string | React.ReactNode;
  actionCancel: () => void;
  qrCodeUrl: string;
}

const ModalImage: React.FC<ModalImageProps> = ({
  title,
  text,
  actionCancel,
  qrCodeUrl,
}) => {
  return (
    <Modal
      show
      className="image-modal"
      backdropClassName="image-modal-backdrop"
      onHide={actionCancel}
      backdrop="static"
      keyboard={false}
    >
      <S.ButtonClose
        src={closeIcon}
        alt="Close"
        onClick={() => actionCancel()}
        width={13}
        height={13}
      />
      <Modal.Title>{title}</Modal.Title>
      <S.Subtitle>{text}</S.Subtitle>
      <S.QRCode src={qrCodeUrl} />
    </Modal>
  );
};

export default ModalImage;
