import { AffectAction } from 'enum/AffectAction.enum';
import {
  AffectedMappedFieldsProps,
  HandleAffectsParams,
} from 'interfaces/CertificateTemplate';
import { normalizeTableStructure } from 'utils/certificates/operationsTable';

import { applicableAffectsByAction } from '..';

export const changeTableStructureAction = (
  handleAffectsParams: HandleAffectsParams
) => {
  const { newVal, type, affects } = handleAffectsParams;

  const changeTableStructureAffects = applicableAffectsByAction(
    AffectAction.CHANGE_TABLE_STRUCURE,
    type,
    affects,
    newVal
  );

  if (!changeTableStructureAffects.length) return;

  changeTableStructureAffects?.forEach(({ field, tableStructure }) => {
    if (
      tableStructure &&
      tableStructure.length > 0 &&
      handleAffectsParams.affectedFields
    ) {
      // REPLACE UNORMALIZED FIELD NAMES BY HASHES
      normalizeTableStructure(tableStructure);
      const mappedFields: AffectedMappedFieldsProps = {};
      tableStructure.forEach(
        (col, index) => (mappedFields[col.keyColumn] = { ...col, index })
      );

      handleAffectsParams.affectedFields[field + '*struct'] = {
        mappedFields,
      };

      handleAffectsParams.affectedFields[field] = {
        tableStructure,
      };
    }
  });
};
