import { FC, useEffect, useState } from 'react';
import { CERT_PREVIEW_PAGE } from 'constants/certificate.constants';
import { useFormContext } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import { RoleEnum } from 'store/@types/user';

import { Col } from 'react-bootstrap';

import { apiGetCertificatePdf } from 'api/certificates';
import {
  apiSoloSubscriptionPrices,
  apiSubscriptionChangePayment,
  apiSubscriptionPayment,
} from 'api/payment';
import CertificatePreviewError from 'assets/images/certificate404.svg';
import { CertificateState } from 'enum/certificateDataState.enum';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import { StripeSubscriptionPrices } from 'interfaces/Subscription';
import { ApiError } from 'services/api';
import { base64ToObjectUrl, downloadPdf } from 'utils/pdfUtils';
import { defaultToast } from 'utils/toast';

import { SubmitBar } from 'components/Form/SubmitBar';
import { ModalSubscription } from 'components/modals/ModalSubscription';

import {
  ImgCertificateDiv,
  ImgCertificateNotFound,
  PreviewCertificateSection,
  PreviewCertificateSectionSubTitle,
} from './styles';

interface PreviewPageProps {
  loading: boolean;
  canSubmit: boolean;
  setLoading: (loading: boolean) => void;
  checkAndNextStep: (forceCheck?: boolean) => Promise<unknown[]>;
  setErrorsInPage: (errs: unknown[]) => void;
}

const PreviewPage: FC<PreviewPageProps> = ({
  loading,
  canSubmit,
  setLoading,
  checkAndNextStep,
  setErrorsInPage,
}) => {
  const { currentRole, user, loadMe } = useAuth();
  const [showModalSubscription, setShowModalSubscription] = useState(false);
  const [previewCert, setPreviewCert] = useState<string | null>(null);
  const { setError } = useFormContext();
  const { templateId, certId } = useParams();
  const [query] = useSearchParams();
  const paymentSuccess = query.get('success') === 'true';
  const selectedPage = query.get('page');
  const [subscriptions, setSubscriptions] = useState<
    StripeSubscriptionPrices[]
  >([]);
  const isNewSubscription = !user?.subscription?.priceId;

  const {
    certData,
    templateFields,
    submitCertificate,
    loadCertificateTemplate,
  } = useCerts();
  const isIssued = certData?.certificateState === CertificateState.ISSUED;
  const isLeadRole = currentRole === RoleEnum.LEAD;
  const isSoloRole = currentRole === RoleEnum.SOLO;
  const isShowSubmit =
    certData &&
    [CertificateState.DRAFT, CertificateState.REVIEWED].includes(
      certData.certificateState
    );

  const loadPdf = () => {
    setPreviewCert(null);
    setLoading(true);
    if (certId) {
      setLoading(true);
      apiGetCertificatePdf(certId)
        .then((data) => {
          setPreviewCert(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const reloadCertificate = () => {
    if (templateId && certId) {
      loadCertificateTemplate(templateId, certId);
    }
  };

  const callSubmitCertificate = (paymentRquired?: () => void) => {
    submitCertificate()
      .then(() => {
        reloadCertificate();
      })
      .catch((error: ApiError) => {
        if (paymentRquired && error.statusCode === 402) {
          paymentRquired();
        } else {
          defaultToast('E');
        }
      });
  };

  const subscriptionCheckout = (priceId: string, currency: string) => {
    setLoading(true);
    const baseCertificateUrl = window.location.pathname;

    apiSubscriptionPayment({
      successUrlPath: `${baseCertificateUrl}?page=${CERT_PREVIEW_PAGE}&success=true`,
      cancelUrlPath: `${baseCertificateUrl}?page=${CERT_PREVIEW_PAGE}`,
      priceId,
      currency,
    })
      .then((data) => {
        if (data.session.url) {
          window.location.href = data.session.url;
        } else {
          setLoading(false);
          const err = data as any;
          if (err?.data?.data?.errors) {
            for (const key of Object.keys(err.data.data.errors)) {
              const field = templateFields?.find((e) => e.fieldId === key);
              if (field?.validations) {
                setError(field?.fieldId, {
                  message: field.validations[0].error,
                  type: field.validations[0].type,
                });
              }
            }

            defaultToast(
              'E',
              'Some required fields are missing information. Please revise the highlighted pages below.'
            );

            checkAndNextStep(true).then((errs) => {
              errs.length === 0 && setErrorsInPage(errs);
            });
          }
        }
      })
      .catch(() => {
        setLoading(false);
        defaultToast('E');
      });
  };

  const changeSUbscriptionPlan = (priceId: string) => {
    if (user) {
      setLoading(true);
      apiSubscriptionChangePayment(user._id, priceId)
        .then(() => {
          loadMe(true);
          setLoading(false);
          defaultToast('S', 'Plan changed successfully');
        })
        .catch(() => {
          setLoading(false);
          defaultToast('E');
        });
    }
  };

  useEffect(() => {
    if (selectedPage === CERT_PREVIEW_PAGE) {
      loadPdf();
    }
  }, [selectedPage, certData?.certificateState, paymentSuccess]);

  useEffect(() => {
    if (paymentSuccess) {
      setPreviewCert(null);
      setTimeout(() => {
        callSubmitCertificate();
      }, 1000);
    }
  }, [paymentSuccess]);

  useEffect(() => {
    if (isSoloRole && !isIssued) {
      loadMe(true);
      apiSoloSubscriptionPrices().then((data) => {
        setSubscriptions(data.web);
      });
    }
  }, []);

  return (
    <>
      <Col xs={12}>
        {previewCert && (
          <iframe
            title="pdf"
            width="100%"
            height="810px"
            src={`${base64ToObjectUrl(previewCert, 'application/pdf')}${
              !isIssued ? '#toolbar=0' : ''
            }`}
            style={{ border: '1px solid #4FA3A1' }}
          ></iframe>
        )}
        {!previewCert && !loading && (
          <>
            <ImgCertificateDiv>
              <ImgCertificateNotFound
                src={CertificatePreviewError}
                alt="Certificate404"
              />
            </ImgCertificateDiv>
            <PreviewCertificateSection>
              <PreviewCertificateSectionSubTitle>
                We’re unable to generate a preview of your certificate at this
                point.
                <br />
                Please make sure you have filled in the necessary information,
                and check your internet connection.
              </PreviewCertificateSectionSubTitle>
            </PreviewCertificateSection>
          </>
        )}
      </Col>
      {isIssued && (
        <SubmitBar
          canSubmit={canSubmit}
          label={'Download certificate'}
          clickAction={async () => {
            downloadPdf(previewCert, `${certData?._id ?? 'file'}.pdf`);
          }}
        />
      )}
      {isShowSubmit && isLeadRole && (
        <SubmitBar
          canSubmit={canSubmit}
          label={'Submit certificate'}
          clickAction={async () => {
            callSubmitCertificate();
          }}
        />
      )}
      {isShowSubmit && isSoloRole && (
        <>
          {showModalSubscription && (
            <ModalSubscription
              onCloseModal={() => {
                setShowModalSubscription(false);
              }}
              isNew
              subscriptions={subscriptions}
              handleOnSave={(priceId, currency) => {
                if (isNewSubscription) {
                  subscriptionCheckout(priceId, currency);
                } else {
                  changeSUbscriptionPlan(priceId);
                }
              }}
            />
          )}
          <SubmitBar
            canSubmit={canSubmit}
            label={'Issue certificate'}
            clickAction={async () => {
              const errs = await checkAndNextStep();
              if (errs.length === 0) {
                // Callback  is called if payment is required
                callSubmitCertificate(() => {
                  setShowModalSubscription(true);
                });
              } else {
                setErrorsInPage(errs);
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default PreviewPage;
