import { AffectAction } from 'enum/AffectAction.enum';
import { HandleAffectsParams } from 'interfaces/CertificateTemplate';

import { applicableAffectsByAction } from '..';

export const enabledDisabledAction = (
  handleAffectsParams: HandleAffectsParams
): void => {
  const { newVal, type, affects } = handleAffectsParams;

  const enabledDisabledAffects = applicableAffectsByAction(
    [AffectAction.DISABLED, AffectAction.ENABLED],
    type,
    affects,
    newVal
  );

  if (!enabledDisabledAffects.length || !handleAffectsParams?.affectedFields)
    return;

  enabledDisabledAffects?.forEach(({ field, action }) => {
    if (handleAffectsParams?.affectedFields) {
      handleAffectsParams.affectedFields[field] = {
        disabled: action === AffectAction.DISABLED,
      };
    }
  });
};
