import * as yup from 'yup';

import { emailValidator, stringRequired } from 'utils/formUtils';

export const schema = (fields: any) =>
  yup.object().shape({
    email: emailValidator.required('Please fill in your email'),
    topic: stringRequired.required('Please select a topic'),
    name: stringRequired.required('Please fill in your name'),
    description: stringRequired
      .max(fields.description)
      .required('Please describe your issue'),
  });

export const defaultValues = {
  email: '',
  topic: '',
  name: '',
  description: '',
};
