import { combineReducers } from '@reduxjs/toolkit';

import contactReducer from './contact/reducer';
import userReducer from './user/reducer';

const createRootReducer = () =>
  combineReducers({
    contact: contactReducer,
    user: userReducer,
  });

export default createRootReducer;
