import { RoleEnum, User, UserRole } from 'store/@types/user';

import { PractitionerStatus } from 'enum/PractitionerStatus';

import { getLSWorkspaceBusiness, getLSWorkspaceRole } from './workspace.util';

export const getInitialUserRole = (
  user: User | undefined
): UserRole | undefined => {
  if (user?.practitionerStatus === PractitionerStatus.SOLO) {
    return user?.roles.find((role) => role.role === RoleEnum.SOLO);
  }

  const enterpriseRole = user?.roles.find(
    (role) => role.role === RoleEnum.LEAD || role.role === RoleEnum.ENGINEER
  );

  if (enterpriseRole) {
    return enterpriseRole;
  }

  return user?.roles.find((role) => role.role === RoleEnum.SOLO);
};

export const getRoleByBusiness = (
  businessId: string,
  roles: UserRole[]
): RoleEnum =>
  roles.find((role) => role.businessId === businessId)?.role ?? RoleEnum.EMPTY;

export const userHasWorkspace = (roles: UserRole[]): boolean => {
  const currentBusiness = getLSWorkspaceBusiness();
  const currentRole = getLSWorkspaceRole();
  return roles.some(({ businessId, role }) => {
    return businessId === currentBusiness && role === currentRole;
  });
};

export const containsRoles = (userRoles: UserRole[], roles: RoleEnum[]) => {
  return userRoles.some((role) => roles.includes(role.role));
};
