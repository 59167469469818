import styled from 'styled-components';

export const TextButton = styled.p`
  cursor: pointer;
  display: flex;
  margin: 0;
  color: #666666;
  font-family: Lato;
  font-size: 16px;
  line-height: 19px;
`;
export const SetButton = styled.button`
  width: 136px;
  height: 44px;
  color: #ffffff;
  display: flex;
  align-self: end;
  justify-content: center;
  align-items: center;
`;
