import * as yup from 'yup';

import { emailValidator, errors, string } from 'utils/formUtils';

export const schema = yup.object().shape({
  email: emailValidator.required(errors.email.personalRequired),
  password: string.required(errors.password.personalRequired),
});

export const defaultValues = {
  email: '',
  password: '',
};
