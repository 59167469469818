import React, { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { SettingsUserInfo } from 'store/@types/user';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { apiDeleteUserImage, apiSetUserImage } from 'api/user';
import { FieldType } from 'enum/FieldType.enum';
import useAuth from 'hooks/useAuth';
import api from 'services/api';
import { defaultToast } from 'utils/toast';

import { Signature, TextField, UploadPhoto } from 'components/Form';

import { ReduxProps } from '.';
import ModalDeleteUserAccount from './DeleteUserAccount';

interface YourInformationProps extends ReduxProps {
  selectedPage: string;
  setIsLoading: (isLoading: boolean) => void;
}

const YourInformation: FC<YourInformationProps> = ({
  selectedPage,
  setIsLoading,
  logout,
  deleteUserAndLogout,
}) => {
  const methodsYourAccount = useFormContext<SettingsUserInfo>();
  const { user, loadMe, reload } = useAuth();
  const [showDeleteUser, setShowDeleteUser] = useState(false);

  function saveUser(
    values: Omit<SettingsUserInfo, 'presetSignature' | 'picture'>
  ) {
    if (user?._id) {
      api
        .patch(`/user/me`, values)
        .then(() => {
          defaultToast('S');
        })
        .finally(() => {
          loadMe(true);
          setIsLoading(false);
        });
    }
  }

  return (
    <>
      {(selectedPage === 'profile' || selectedPage === 'init') && (
        <>
          <Row>
            <div>
              <h2 className="section-title">Your account</h2>
              <h3 className="section-subtitles">
                Manage your account details and personal information
              </h3>
            </div>
          </Row>
          <Row>
            <Form
              id="settings-your-account-form"
              onSubmit={methodsYourAccount.handleSubmit((data) => {
                setIsLoading(true);
                saveUser(data);
              })}
            >
              <Row>
                <UploadPhoto
                  xs={3}
                  inputSize={1}
                  fieldId="picture"
                  label="Profile photo"
                  onload={(file, base64Data) => {
                    apiSetUserImage(file);
                    methodsYourAccount.setValue('picture', base64Data, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                  }}
                  onDelete={() => {
                    apiDeleteUserImage();
                  }}
                />
                <TextField
                  xs={3}
                  required
                  label="Display name"
                  fieldId="name"
                />
                <TextField xs={3} disabled label="Email" fieldId="email" />
                <Col xs={3} />
                <Signature
                  xs={3}
                  type="select"
                  componentType={FieldType.Signature}
                  required
                  submitNewSignature
                  fieldId="presetSignature"
                  label="Pre-set digital signature"
                  handleClose={() => {
                    const data =
                      methodsYourAccount.getValues('presetSignature');
                    setIsLoading(true);
                    api
                      .put(`signature/${data._id}`, { default: true })
                      .then(() => {
                        defaultToast('S');
                        reload({ reloadSignatures: true });
                      })
                      .catch(() => {
                        defaultToast(
                          'E',
                          'We’re having trouble setting your default signature. Please try again later.'
                        );
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }}
                />
                <TextField
                  xs={3}
                  disabled
                  label="Practitioner’s status"
                  fieldId="practitionerStatus"
                />
                <TextField
                  xs={3}
                  required
                  label="Position"
                  fieldId="position"
                />
                <Col xs={3} />
              </Row>

              <Row>
                <Col xs={4} lg={7}>
                  <Button
                    className="btn button-red"
                    onClick={() => {
                      setShowDeleteUser(true);
                    }}
                  >
                    Delete user account
                  </Button>
                </Col>
                <Col xs={3} className="align-self-end">
                  <Link
                    style={{ color: '#666666' }}
                    className="float-right pb-3"
                    to=""
                    onClick={() => logout(true)}
                  >
                    Logout from this device
                  </Link>
                </Col>
                <Col xs={2}>
                  <Button
                    id="settings-your-info-submit"
                    className="button-primary float-right"
                    type="submit"
                    disabled={
                      !methodsYourAccount.formState.isDirty ||
                      Object.keys(methodsYourAccount.formState.errors).length >
                        0
                    }
                  >
                    Save changes
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </>
      )}
      {selectedPage === 'init' && (
        <Row className="my-4">
          <hr />
        </Row>
      )}

      {/* DELETE USER ACCOUNT MODAL */}
      {showDeleteUser && user && (
        <ModalDeleteUserAccount
          actionCancel={() => {
            setShowDeleteUser(false);
          }}
          actionDeleteUser={() => {
            setIsLoading(true);
            deleteUserAndLogout();
          }}
          userName={user.name}
          userEmail={user.email}
        />
      )}
    </>
  );
};

export default YourInformation;
