import { FieldValues, UseFormGetValues } from 'react-hook-form';

import { AffectFunction } from 'enum/AffectFunction.enum';
import { RequiredValuesProps } from 'interfaces/CertificateTemplate';
import {
  fieldDivider,
  getBoardInfo,
  getBoardName,
} from 'utils/certificates/defaultValues';

export const getMultiplierValue = (
  fieldId: string,
  defaultValues: { [key: string]: unknown } | undefined,
  getValues: UseFormGetValues<FieldValues> | undefined,
  requiredValues: RequiredValuesProps[] | undefined
): number | null => {
  const requiredValue = requiredValues?.find(
    (requiredValue) => requiredValue?.function === AffectFunction.MULTIPLIER
  );

  if (!requiredValue?.fieldId) return null;

  let requireFieldId = requiredValue.fieldId;

  if (fieldId.includes('board')) {
    const { boardGridId, boardId, boardIndex } = getBoardInfo(fieldId);
    const boardName = getBoardName(boardGridId, Number(boardIndex), boardId);

    requireFieldId = `${boardName}${fieldDivider}${requiredValue.fieldId}`;
  }

  if (getValues) {
    const value = getValues(requireFieldId) as number;
    return Number(value);
  }

  if (defaultValues) {
    const value = defaultValues[requireFieldId] as number;
    return Number(value);
  }

  return null;
};
