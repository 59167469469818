import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';

import { userLoginRegister } from 'api/user';
import withHelmet from 'hooks/withHelmet';
import { defaultToast } from 'utils/toast';

import { TextField } from 'components/Form';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';

import { defaultValues, schema } from './yupSchema';

interface UserRegisterData {
  email: string;
  emailConfirm: string;
  password: string;
  passwordConfirm: string;
}

function Registration() {
  const [isLoading, setIsLoading] = useState(false);
  const [successOperation, setSuccessOperation] = useState(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const invitedEmail = query.get('email');

  const [userData, setUserData] = useState({
    email: invitedEmail || '',
    emailConfirm: '',
    password: '',
    passwordConfirm: '',
  } as UserRegisterData);

  useEffect(() => {
    if (invitedEmail) {
      setUserData({
        ...userData,
        email: invitedEmail,
      });
      setSuccessOperation(true);
    }
  }, [invitedEmail]);

  // React hook form instance
  const methods = useForm<UserRegisterData>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const onSubmit = async (payload: UserRegisterData) => {
    try {
      const userRegisterResponse = await userLoginRegister(payload);
      if (userRegisterResponse._id) {
        setUserData(payload);
        setSuccessOperation(true);
      }

      setIsLoading(false);
    } catch (err) {
      defaultToast('E');
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <SplashScreen />}
      {!successOperation && (
        <NoAuthBasePage title="Two minutes" subTitle="And you'll be logged in">
          <div className="white-card-text-body no-padding-top">
            <p>
              For your password, please be sure to use at least 8 characters,
              containing uppercase and lowercase letters, numbers and special
              characters, such as punctuation.
            </p>
            <br />
            <FormProvider {...methods}>
              <Form
                id="user-register"
                onSubmit={methods.handleSubmit((data) => {
                  setIsLoading(true);
                  onSubmit(data);
                })}
              >
                <Row>
                  <TextField xs={6} fieldId="email" label="Enter your email" />
                  <TextField
                    xs={6}
                    fieldId="emailConfirm"
                    label="Confirm your email"
                  />
                  <TextField
                    xs={6}
                    type="password"
                    fieldId="password"
                    label="Set up a password"
                  />
                  <TextField
                    xs={6}
                    type="password"
                    fieldId="passwordConfirm"
                    label="Confirm your password"
                  />
                </Row>
                <Row>
                  <Col xs={6} className="align-self-end">
                    <Link className="gray bold" to="/login">
                      Back to login screen
                    </Link>
                  </Col>
                  <Col xs={6} className="align-self-end">
                    <Button
                      className="button-primary float-right"
                      type="submit"
                    >
                      Sign up
                    </Button>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </div>
        </NoAuthBasePage>
      )}

      {successOperation && (
        <NoAuthBasePage
          title="Please check your inbox"
          subTitle="To verify your account"
        >
          <div className="white-card-text-body margin-top">
            <p>
              Your account has been created successfully, but it is pending
              activation.
            </p>
            <p>
              Please check your inbox at{' '}
              <span className="bold">{userData.email}</span> and click the link
              to verify your identity and start using your Doddle account.
              <br /> Please be patient, as this might take a few minutes to
              arrive at your inbox.
            </p>
            <p>
              If you don’t receive anything in the next 10 minutes, please be
              sure to check your spam folder, as some servers can filter
              automated responses messages.
            </p>
          </div>
          <Row>
            <Col xs={6} />
            <Col xs={6}>
              <Button
                className="button-primary float-right"
                type="button"
                onClick={() => {
                  navigate('/login');
                }}
              >
                Go to login
              </Button>
            </Col>
          </Row>
        </NoAuthBasePage>
      )}
    </>
  );
}

export default withHelmet(Registration);
