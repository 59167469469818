import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 98px;
  padding: 12px 10px;
  justify-content: center;
  border-radius: 12px 12px 0px 0px;
  background-color: rgba(246, 246, 246, 1);

  span:nth-child(1) {
    width: 100%;
    color: rgba(79, 163, 161, 1);
    font-family: Lato;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span:nth-child(2) {
    width: 100%;
    color: rgba(102, 102, 102, 1);
    font-family: lato;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 25px 15px 20px 15px;
  border-radius: 0 0 12px 12px;
  background-color: rgba(255, 255, 255, 1);
`;
export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const CustomerInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  span {
    color: rgba(102, 102, 102, 1);
    font-family: lato;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
`;

export const DateIcon = styled.img`
  color: #4fa3a1;
  height: '16px';
  width: '16px';
  cursor: pointer;
`;

export const DateValue = styled.span`
  color: rgba(102, 102, 102, 1);
  font-family: lato;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
`;
