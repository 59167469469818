import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { RoleEnum, SettingsBusinessInfo } from 'store/@types/user';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { apiDeleteBusinessImage, apiSetBusinessImage } from 'api/business';
import useAuth from 'hooks/useAuth';
import { Business } from 'interfaces/Business';
import api from 'services/api';
import { defaultToast } from 'utils/toast';

import { TextField, UploadPhoto } from 'components/Form';

interface BusinessInformationProps {
  selectedPage: string;
  setIsLoading: (isLoading: boolean) => void;
  handleBusinessFormReset: (data: SettingsBusinessInfo) => void;
}

const BusinessInformation: FC<BusinessInformationProps> = ({
  selectedPage,
  setIsLoading,
  handleBusinessFormReset,
}) => {
  const { user, currentBusiness, currentRole, reload } = useAuth();
  const methodsBusiness = useFormContext();
  const canEdit = currentRole === RoleEnum.SOLO;

  function saveBusiness(values: Omit<Business, 'logoPhoto' | 'schemeLogo'>) {
    if (!user) return;

    if (currentBusiness) {
      api
        .put(`/business/${currentBusiness}`, values)
        .then(({ data }) => handleBusinessFormReset(data))
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          reload({ reloadBusiness: true, reloadWorkspaces: true });
          setIsLoading(false);
        });
    } else {
      api
        .post('business', values)
        .then(() => {
          defaultToast('S');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          reload({ reloadBusiness: true, reloadWorkspaces: true });
          setIsLoading(false);
        });
    }
  }

  return (
    <>
      {(selectedPage === 'business' || selectedPage === 'init') && (
        <>
          <Row>
            <div>
              <h2 className="section-title">Business profile</h2>
              <h3 className="section-subtitles">
                Pre-filled information about your business
              </h3>
            </div>
          </Row>
          <Row>
            <Form
              id="settings-business-info"
              onSubmit={methodsBusiness.handleSubmit((data) => {
                setIsLoading(true);
                saveBusiness(data);
              })}
            >
              <Row>
                <Col xs={3}>
                  <Row>
                    <TextField
                      required
                      label="Organisation name"
                      fieldId="organizationName"
                    />
                  </Row>
                  <Row className="mt-0">
                    <TextField label="Company Number" fieldId="companyNumber" />
                  </Row>
                </Col>
                <UploadPhoto
                  xs={3}
                  inputSize={3}
                  fieldId="logoPhoto"
                  label="Logo"
                  onload={(file, base64Data) => {
                    apiSetBusinessImage(currentBusiness, {
                      logoPhoto: file,
                    });
                    methodsBusiness.setValue('logoPhoto', base64Data, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                  }}
                  onDelete={() => {
                    apiDeleteBusinessImage(currentBusiness, 'logoPhoto');
                  }}
                />
                <UploadPhoto
                  xs={3}
                  inputSize={3}
                  fieldId="schemeLogo"
                  label="Scheme / Trade association logo"
                  onload={(file, base64Data) => {
                    apiSetBusinessImage(currentBusiness, {
                      schemeLogo: file,
                    });
                    methodsBusiness.setValue('schemeLogo', base64Data, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                  }}
                  onDelete={() => {
                    apiDeleteBusinessImage(currentBusiness, 'schemeLogo');
                  }}
                />
                <Col xs={3}></Col>
                <TextField
                  xs={3}
                  required
                  label="Address"
                  fieldId="organizationAddress"
                />
                <TextField
                  xs={3}
                  required
                  label="Postcode"
                  fieldId="organizationPostal"
                />
                <TextField
                  xs={3}
                  required
                  label="Registration number"
                  fieldId="registrationNumber"
                />
                <Col xs={3}></Col>
                <TextField
                  xs={3}
                  required
                  label="Email"
                  fieldId="organizationEmail"
                />
                <TextField xs={3} label="Phone" fieldId="phoneNumber" />
                <TextField xs={3} label="Fax" fieldId="fax" />
                <Col xs={3}></Col>
              </Row>
              {canEdit && (
                <Row>
                  <Col xs={7} lg={10} />
                  <Col xs={2}>
                    <Button
                      className="button-primary float-right"
                      type="submit"
                      disabled={
                        !methodsBusiness.formState.isDirty ||
                        Object.keys(methodsBusiness.formState.errors).length > 0
                      }
                    >
                      Save changes
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          </Row>
        </>
      )}
      {selectedPage === 'init' && (
        <Row className="my-4">
          <hr />
        </Row>
      )}
    </>
  );
};

export default BusinessInformation;
