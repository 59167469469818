import { useTranslation } from 'react-i18next';

import reqNoPreview from 'assets/images/iconset/empty_carrousel_image.svg';

import Scrollbars from 'components/Scrollbars';

import CertificateCard from './Cards/CertificateCard';
import { BaseCarouselProps } from './interfaces/BaseCarouselProps';
import * as S from './styles';

export interface CarouselItemProps<T extends BaseCarouselProps> {
  cardType: 'certificate';
  marginLeft: string;
  height?: string;
  payload?: T[];
}

const NewCarousel = <T extends BaseCarouselProps>({
  cardType,
  marginLeft = '0px',
  height = 'auto',
  payload,
}: CarouselItemProps<T>) => {
  const { t } = useTranslation();

  if (payload?.length) {
    return (
      <Scrollbars height={height} marginLeft="0px">
        <S.CarouselContainer height={height} marginLeft={marginLeft}>
          {payload.map((item) => {
            if (cardType === 'certificate')
              return <CertificateCard payloadItem={item} key={item.id} />;
          })}
        </S.CarouselContainer>
      </Scrollbars>
    );
  }

  return (
    <S.EmptyCarousel height={height} marginLeft={marginLeft}>
      <img src={reqNoPreview} alt="no items" />
      <p>{t('there_are_no_items_to_display')}</p>
    </S.EmptyCarousel>
  );
};

export default NewCarousel;
