import styled from 'styled-components';

export const MainContainer = styled.div<{
  width: string;
  height: string;
  highlightCard?: boolean;
  cursor?: 'pointer';
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  margin-right: 18px;

  ${({ cursor }) => {
    return cursor ? `cursor: ${cursor};` : '';
  }}

  ${({ width }) => {
    if (width) {
      return `
        width: ${width};
        min-width: ${width};
      `;
    }
  }};

  ${({ height }) => {
    if (height) {
      return `
        height: ${height};
        min-height: ${height};
      `;
    }
  }};

  ${({ highlightCard }) => {
    if (highlightCard) {
      return `
        border: 1px solid #4fa3a1;
        border-radius: 12px;
        box-shadow: 0 0 10px 0 #4fa3a1;`;
    }
  }};
`;
