import {
  AutoObservations,
  HandleAutoObservationsParams,
} from 'interfaces/AutoObservations';

import { applyObservations } from './applyObservations';
import {
  getBoardFieldIdsFromCurrentField,
  isBoardOrCircuit,
} from './autoObservationsUtils';
import { checkObservationCondition } from './checkObservationCondition';
import { removeObservations } from './removeObservations';

export interface AutoObervationsTemp {
  currentFieldsIds?: string[];
  autoObservation: AutoObservations;
}

export const autoObservations = (
  handleParams: HandleAutoObservationsParams
): void => {
  const { fieldId, autoObservations, listBoardsFieldIds, getValues } =
    handleParams;
  if (!autoObservations.length || !getValues) return;

  const listBoards = getBoardFieldIdsFromCurrentField(
    fieldId,
    listBoardsFieldIds
  );

  const autoObservationsToAdd: AutoObervationsTemp[] = [];
  const autoObservationsToRemove: AutoObervationsTemp[] = [];

  autoObservations.forEach((autoObservation) => {
    const isBoards = isBoardOrCircuit(autoObservation);
    const process = (currentFieldsIds?: string[]) => {
      processAutoObservation(
        autoObservationsToAdd,
        autoObservationsToRemove,
        autoObservation,
        handleParams,
        currentFieldsIds
      );
    };

    // ** If the obsevation contitions have a board fields reference,
    // ** process observation for each board if exists
    if (isBoards) {
      listBoards.forEach(({ boardFieldIds, circuitRows }) => {
        // ** Run process if exists board with no circuits
        if (boardFieldIds.length && !circuitRows?.length) {
          process(boardFieldIds);
        } else {
          // ** Run process now with board and circuis, for each circuit
          circuitRows.forEach((circuitRow) => {
            process([...boardFieldIds, ...circuitRow.circuitFieldIds]);
          });
        }
      });
    } else {
      // ** Process each obsevation with no board or circuits
      process();
    }
  });

  removeObservations(autoObservationsToRemove, handleParams);
  applyObservations(autoObservationsToAdd, handleParams);
};

const processAutoObservation = (
  autoObservationsToAdd: AutoObervationsTemp[],
  autoObservationsToRemove: AutoObervationsTemp[],
  autoObservation: AutoObservations,
  handleParams: HandleAutoObservationsParams,
  currentFieldsIds: string[] = []
) => {
  const allConditionsTrue = autoObservation.conditions.every((condition) => {
    return checkObservationCondition(condition, handleParams, currentFieldsIds);
  });

  if (allConditionsTrue) {
    autoObservationsToAdd.push({
      autoObservation,
      currentFieldsIds,
    });
  } else {
    autoObservationsToRemove.push({
      autoObservation,
      currentFieldsIds,
    });
  }
};
