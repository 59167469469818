import styled from 'styled-components';

export const CardContainer = styled.div<{
  isSelected?: boolean;
  isCurrent?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #e4e4e4;
  background: #fff;
  padding: 11px 30px 11px 14px;
  box-shadow: 0px 4px 20.8px 0px rgba(0, 0, 0, 0.15);

  ${({ isCurrent }) => {
    return (
      !isCurrent &&
      `  :hover {
      border: 1px solid rgb(79, 163, 161);
    }
    `
    );
  }}

  ${({ isSelected }) => {
    return isSelected && `border: 1px solid rgb(79, 163, 161);`;
  }}
  ${({ isCurrent }) => {
    return `cursor: ${isCurrent ? 'auto' : 'pointer'};`;
  }}
`;

export const Container = styled.div`
  width: auto;
  display: flex;
  gap: 23px;
  justify-content: space-between;
  align-items: center;
`;

export const IconContainer = styled.div<{ bgColor: string }>`
  width: 98px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #979797;
  ${({ bgColor }) => {
    return `background-color: ${bgColor}`;
  }}
`;

export const ProductContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
  align-items: flex-start;

  span {
    font-size: 20px;
    font-style: normal;
    line-height: normal;
  }

  span:nth-child(1) {
    color: #370f08;
    font-weight: 700;
  }
  span:nth-child(2) {
    color: #666;
    font-weight: 400;
  }
`;
