import React, { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Container, Form, Modal, Row } from 'react-bootstrap';

import { BranchAddress } from 'interfaces/Customer';

import { ButtonLabeled, TextField } from 'components/Form';
import PostalCodeInput from 'components/Form/PostalCodeInput';

import { defaultValues, yupSchema } from './yupSchema';

import './styles.css';

interface AddLocationProps {
  onSave: (value: BranchAddress) => void;
  deleteItem: () => void;
  onCancel: () => void;
  isEdit?: BranchAddress;
}

const AddLocation: FC<AddLocationProps> = ({
  onSave,
  onCancel,
  deleteItem,
  isEdit,
}) => {
  // React hook form instance
  const formMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(yupSchema),
    defaultValues: isEdit || defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  return (
    <Modal show backdrop="static" className="add-container-equipment">
      <Modal.Header className="modal-red-header d-flex">
        <Modal.Title
          className="modal-header-left-action"
          onClick={() => onCancel()}
        >
          Cancel
        </Modal.Title>
        <Modal.Title className="modal-title">
          {isEdit ? 'Edit location' : 'Add location'}
        </Modal.Title>
        <Modal.Title
          className="modal-header-right-action"
          onClick={() => {
            document.getElementById('submit-btn')?.click();
          }}
        >
          {isEdit ? 'Save' : 'Add'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <FormProvider {...formMethods}>
            <Form
              id="manage-customer-locations-form"
              onSubmit={(e) => {
                e.stopPropagation();
                formMethods.handleSubmit((data) => {
                  onSave(data);
                })(e);
              }}
            >
              <Row>
                <TextField
                  xs={12}
                  required
                  label="Occupier"
                  fieldId="occupier"
                />
                <TextField xs={12} required label="Address" fieldId="street" />
                <TextField xs={6} required label="Town / City" fieldId="city" />
                <TextField xs={6} label="County" fieldId="county" />
                <PostalCodeInput
                  xs={6}
                  countyId="county"
                  cityId="city"
                  streetId="street"
                  companyNameId="companyName"
                  label="Postcode"
                  fieldId="postalCode"
                  postalCodeId="postalCode"
                />
                {isEdit && (
                  <ButtonLabeled
                    xs={6}
                    label="Remove location"
                    buttonClasses="btn button-red"
                    buttonLabel="Delete"
                    buttonAction={() => isEdit && deleteItem()}
                  />
                )}
              </Row>
              <Button
                id="submit-btn"
                form="manage-customer-locations-form"
                type="submit"
                style={{ display: 'none' }}
              ></Button>
            </Form>
          </FormProvider>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
export default AddLocation;
