import React, { FC } from 'react';

import Scrollbars from 'components/Scrollbars';

import * as S from './styles';

export type NewMenuTabItemProps = {
  selectedTab: boolean;
  onClick: () => Promise<void> | void;
  highLightButton?: boolean;
  errorButton?: boolean;
  label?: string;
  value?: string;
  hide?: boolean;
};

export type NewMenuTabProps = {
  tabItems: NewMenuTabItemProps[];
  sticky?: boolean;
  paddingLeft?: string;
  paddingRight?: string;
  marginBottom?: string;
  withDivider?: boolean;
  scrollable?: boolean;
  className?: string;
};

const NewMenuTab: FC<NewMenuTabProps> = ({
  tabItems,
  sticky,
  paddingLeft,
  paddingRight,
  withDivider,
  marginBottom,
  scrollable,
  className,
}) => {
  const getTabItems = () =>
    tabItems
      .filter(({ hide }) => !hide)
      .map((item, index) => {
        return (
          <S.MenuTabItemTab
            key={`${item.label}-${index + 1}`}
            selectedTab={item.selectedTab}
            highLightTab={item.highLightButton}
            errorTab={item.errorButton}
            onClick={item.onClick}
          >
            {item.label}
          </S.MenuTabItemTab>
        );
      });

  return (
    <S.MenuTabContainer marginBottom={marginBottom}>
      <S.MenuTabStickyRow
        className={className}
        sticky={sticky}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
      >
        {scrollable && (
          <Scrollbars height="65px" marginLeft="0">
            {getTabItems()}
          </Scrollbars>
        )}
        {!scrollable && getTabItems()}
      </S.MenuTabStickyRow>
      {withDivider && <S.MenuTabDivider />}
    </S.MenuTabContainer>
  );
};

export default NewMenuTab;
