import React, { FC, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AppThunk } from 'store/@types';
import { RoleEnum, SoloOnboarding } from 'store/@types/user';
import * as yup from 'yup';

import { Form } from 'react-bootstrap';

import useAuth from 'hooks/useAuth';
import withHelmet from 'hooks/withHelmet';
import { ApiError } from 'services/api';
import { defaultToast } from 'utils/toast';
import {
  handleEmptyValueFromOnboardTemplate,
  loadOnboardingSchema,
} from 'utils/userOnboard';

import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';
import StepperPagination from 'components/StepperPagination';

import { ReduxProps } from '.';
import FormPage from './PageTypes/FormPage';
import TextImage from './PageTypes/TextImage';

import './styles.css';

const OnBoarding: FC<ReduxProps> = ({
  user: reduxSoloOnboarding,
  fetchOnboarding,
  submitOnboarding,
}) => {
  const navigate = useNavigate();
  const { user, loadMe, checkRedirect } = useAuth();
  const { loading, onBoarding } = reduxSoloOnboarding;

  useEffect(() => {
    if (!user) {
      loadMe();
    } else {
      const path = checkRedirect('onboarding');
      path && navigate(path);
    }
  }, [user]);

  /**
   * https://nearseatech.atlassian.net/wiki/spaces/DOD/pages/1412923393/Onboarding
   *
   * OnboardingName		|	Platform	|	Roles			|	Login in Webapp
   *
   * SoloOnboarding		|	Apps		|	solo			|	YES
   * EngineerOnboarding	|	Apps		|	engineer | lead	|	YES
   * PortalOnboarding	|	Portal		|	qs | manager	|	 NO
   * AdminOnboarding	|	Portal		|	admin			|	 NO
   *
   */
  const onBoardingType = useMemo(() => {
    if (!user) return;
    const { roles } = user;

    if (
      roles.some((e) => [RoleEnum.ENGINEER, RoleEnum.LEAD].includes(e.role))
    ) {
      return 'EngineerOnboarding';
    }

    if (roles.some((e) => [RoleEnum.SOLO].includes(e.role))) {
      return 'SoloOnboarding';
    }
  }, [user]);

  useEffect(() => {
    if (!onBoardingType) return;
    fetchOnboarding(onBoardingType);
  }, [onBoardingType]);

  // Make an empty default values for each field from template
  const emptyDefaultValues = handleEmptyValueFromOnboardTemplate(onBoarding);
  // Make the validations schema from template
  const formValidations = loadOnboardingSchema(onBoarding);

  const renderOnboarding =
    onBoarding &&
    Object.keys(emptyDefaultValues).length > 0 &&
    Object.keys(formValidations).length > 0;

  return (
    <>
      {renderOnboarding && onBoardingType && (
        <RenderOnboardingData
          onBoardingType={onBoardingType}
          loading={loading}
          formValidations={formValidations}
          onBoarding={onBoarding}
          emptyDefaultValues={emptyDefaultValues}
          submitOnboarding={submitOnboarding}
        />
      )}
    </>
  );
};

const RenderOnboardingData: FC<{
  onBoardingType: string;
  loading: boolean;
  formValidations: any;
  onBoarding: SoloOnboarding | null;
  emptyDefaultValues: { [key: string]: unknown };
  submitOnboarding: (
    onBoardingType: string,
    payload: any,
    handle: (
      successResponse?: boolean | undefined,
      errorResponse?: ApiError | undefined
    ) => void
  ) => AppThunk;
}> = ({
  onBoardingType,
  loading,
  formValidations,
  onBoarding,
  emptyDefaultValues,
  submitOnboarding,
}) => {
  const navigate = useNavigate();
  const { user, loadMe, reload } = useAuth();
  const [step, setStep] = useState<number>(0);

  const prevStep = () => {
    if (step === 0) return;
    setStep((prev) => prev - 1);
  };

  const nextStep = () => {
    if (!onBoarding) return;
    if (step === onBoarding?.pages.length - 1) {
      reload({ reloadBusiness: true, reloadWorkspaces: true });
      loadMe(true);
      if (user) navigate('/dashboard');
    } else {
      setStep((prev) => prev + 1);
    }
  };

  // React hook form instance
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(yup.object().shape(formValidations).required()),
    defaultValues: emptyDefaultValues as any,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const onSubmit = (data: any) => {
    submitOnboarding(
      onBoardingType,
      data,
      (success?: boolean, error?: ApiError) => {
        if (error) {
          defaultToast(
            'E',
            'We are sorry, but we were not able to save some of the fields you entered. Please go to the Settings to update any missing required information so that you can start issuing certificates.'
          );
        }
      }
    );
  };

  if (!onBoarding) return null;

  const stepper = (
    <StepperPagination total={onBoarding.pages.length} current={step} />
  );

  return (
    <>
      {loading && <SplashScreen />}
      <FormProvider {...methods}>
        <Form id="user-onboarding" onSubmit={methods.handleSubmit(onSubmit)}>
          {onBoarding.pages.map((page, pageStep) => {
            if (step !== pageStep) return null;
            return (
              <NoAuthBasePage
                key={`onboarding-content-page-${pageStep}`}
                title={page.title}
                subTitle={page.subtitle || ''}
                noFooter
              >
                <div className="white-card-text-body no-padding-top">
                  {step === pageStep && page.type === 'text-image' && (
                    <TextImage
                      page={page}
                      stepper={stepper}
                      prevStep={() => prevStep()}
                      nextStep={() => nextStep()}
                    />
                  )}
                  {step === pageStep && page.type === 'form' && (
                    <FormPage
                      page={page}
                      stepper={stepper}
                      prevStep={() => prevStep()}
                      nextStep={() => nextStep()}
                      submit={() => {
                        if (step === onBoarding?.pages.length - 2) {
                          onSubmit(methods.getValues());
                        }
                      }}
                    />
                  )}
                </div>
              </NoAuthBasePage>
            );
          })}
        </Form>
      </FormProvider>
    </>
  );
};

export default withHelmet(OnBoarding);
