import React, { FC, useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';

import { apiGerCertificatePresets } from 'api/user';
import searchIcon from 'assets/images/iconset/input_search.svg';
import useCerts from 'hooks/useCerts';
import { CertificatePreset } from 'interfaces/CertificatePreset';
import { defaultToast } from 'utils/toast';

import PresetModalCard from 'components/Carousel/Cards/PresetModalCard';

import './styles.css';

interface ModalPresetSelectProps {
  setOpenModal: (data: boolean) => void;
  handleSave: (customerId: string, branchAddressesId?: string) => void;
  certificateTypeOnUse: string;
  onDashboard?: string;
}

const ModalPresetSelect: FC<ModalPresetSelectProps> = ({
  setOpenModal,
  certificateTypeOnUse,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [presets, setPresets] = useState<CertificatePreset[]>([]);
  const [presetSelected, setPresetSelected] = useState<CertificatePreset>();
  const { updateFromPreset, certData, clearAll, loadCertificateTemplate } =
    useCerts();

  useEffect(() => {
    apiGerCertificatePresets()
      .then((data) => {
        data.reverse();
        setPresets(
          data.filter((val) => val.certificateType === certificateTypeOnUse)
        );
      })
      .catch(() => {
        defaultToast('E');
      });
  }, []);
  const handleNext = () => {
    if (certData?.customer && certData.template && presetSelected) {
      updateFromPreset(
        certData.customer?._id,
        presetSelected?.data,
        (isSuccess) => {
          if (isSuccess) {
            defaultToast('S', 'Certificate preset applied successfully');
            clearAll();
            loadCertificateTemplate(
              String(certData?.template?._id),
              certData?._id
            );
            setOpenModal(false);
          } else {
            defaultToast('E');
          }
        }
      );
    }
  };
  return (
    <Modal
      show
      className="container-customer"
      key="modal-sig"
      backdrop="static"
    >
      <Modal.Header className="modal-red-header d-flex">
        <Modal.Title
          className="modal-header-left-action"
          onClick={() => setOpenModal(false)}
        >
          Cancel
        </Modal.Title>
        <Modal.Title className="modal-title">
          Select certificate Preset
        </Modal.Title>
        <Modal.Title className="modal-header-right-action" onClick={handleNext}>
          Apply
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ width: '600px' }}>
        <img className="search-icon" src={searchIcon} alt="search" />
        <input
          id="searchBar"
          className="input-customer"
          type="text"
          maxLength={30}
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />

        <div className="modal-customer">
          {presets
            ?.filter((val) => {
              if (searchTerm === '') return true;
              return val.name.toLowerCase().includes(searchTerm.toLowerCase());
            })
            .map((item) => {
              return (
                <PresetModalCard
                  key={item._id}
                  presetName={item.name}
                  certificateType={item.certificateType}
                  onClick={() => {
                    setPresetSelected(item);
                  }}
                  highlight={item._id === presetSelected?._id}
                />
              );
            })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPresetSelect;
