import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from 'store/@types';
import {
  clearError,
  thunkFetchTopics,
  thunkSendContact,
} from 'store/contact/thunks';

import DefaultComponent from './ContactPage';

const mapStateToProps = ({ contact }: RootState) => ({ contact });
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { thunkFetchTopics, thunkSendContact, clearError },
    dispatch
  );

export type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultComponent);
