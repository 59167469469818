import { AffectFunction } from 'enum/AffectFunction.enum';
import {
  FieldAffects,
  HandleAffectsParams,
  RequiredValuesProps,
} from 'interfaces/CertificateTemplate';

import { isEqualsAffect } from '../applicableAffectsByAction';
import { getCircuitsValues } from './getCircuitsValues';
import { getValuesFunction } from './getValue';

export const changeRequireValue = (
  requiredValues: RequiredValuesProps[],
  affect: FieldAffects,
  handleAffectsParams: HandleAffectsParams
) => {
  const { type, getValues, defaultValues = {} } = handleAffectsParams;

  const getFieldValue = (fieldId: string): string | undefined => {
    const value = getValues ? getValues(fieldId) : defaultValues[fieldId];
    if (value) {
      return typeof value !== 'string' ? value.value : value;
    }
  };

  const affectsToApply = requiredValues.filter((reqValue) => {
    if (
      reqValue.function !== AffectFunction.CHANGE_REQUIRED_VALUE ||
      !reqValue.operator ||
      !reqValue.value ||
      !reqValue.fieldId
    )
      return false;

    const requiredFieldValue = getFieldValue(reqValue.fieldId);
    return isEqualsAffect(
      type,
      reqValue.operator,
      reqValue.value,
      requiredFieldValue
    );
  });

  if (!affectsToApply?.length) return;

  affectsToApply.forEach(({ fieldId, requiredValue, requiredCircuitValue }) => {
    if (requiredValue && fieldId) {
      getValuesFunction(affect, handleAffectsParams, requiredValue);
    }
    if (requiredCircuitValue && fieldId) {
      getCircuitsValues(
        fieldId,
        affect,
        handleAffectsParams,
        requiredCircuitValue
      );
    }
  });
};
