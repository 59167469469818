import { FC } from 'react';
import clsx from 'clsx';

import { FormGroupWithErrorSpace } from 'components/Form/styles';

interface FormLabelProps {
  xs?: number;
  label: string;
  normal?: boolean;
  auxLabel?: string;
  className?: string;
  onClick?: () => void;
}

export const FormLabel: FC<FormLabelProps> = ({
  xs,
  label,
  normal,
  auxLabel,
  className,
  onClick,
}) => {
  return (
    <FormGroupWithErrorSpace
      xs={xs}
      className={className ?? 'mt-4'}
      height="auto"
      style={{ minHeight: '20px' }}
    >
      <h4
        {...(onClick && { onClick })}
        className={clsx('FormLabel form-section-label', {
          'form-section-normal-label': normal,
        })}
      >
        {label}
      </h4>
      {auxLabel && <h5 className={'form-section-aux-label '}>{auxLabel}</h5>}
    </FormGroupWithErrorSpace>
  );
};
