import React, { FC, useState } from 'react';

import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';

import mapQrcode from 'assets/images/iconset/map_qrcode_icon.svg';
import tableColapseImg from 'assets/images/iconset/table_colapse.svg';
import tableRowDelete from 'assets/images/iconset/table_delete.svg';
import tableEditImg from 'assets/images/iconset/table_edit.svg';
import tableExpandImg from 'assets/images/iconset/table_expand.svg';
import { BranchAddress } from 'interfaces/Customer';
import { generateQrCode } from 'utils/generateQrCode';

import { InfoField } from 'components/Form/InfoField';
import ModalImage from 'components/modals/ModalImage';

import './styles.css';

interface LocationsContentProps {
  data: BranchAddress[];
  editItem: (index: number) => void;
  deleteItem: (id: number) => void;
}

const LocationsContent: FC<LocationsContentProps> = ({
  data,
  editItem,
  deleteItem,
}) => {
  const [activeId, setActiveId] = useState<any>(null);
  const [hideBtn, setHideBtn] = useState('');
  const [hideInfo, setHideInfo] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  const qrCodeHandler = (item: BranchAddress) => {
    const generatedQrCodeUrl = generateQrCode(item);
    if (generatedQrCodeUrl) {
      setQrCodeUrl(generatedQrCodeUrl);
      setShowModal(true);
    }
  };

  function toggleActive(id: string) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  function buttonActive(id: string) {
    if (hideBtn === id) {
      setHideBtn('');
    } else {
      setHideBtn(id);
    }
  }

  function equipmentInfo(id: string) {
    if (hideInfo === id) {
      setHideInfo('');
    } else {
      setHideInfo(id);
    }
  }

  return (
    <Col xs={12} className="collapse-customers-locations">
      <Accordion>
        {data.map((item, i) => {
          const index = i.toString();
          return (
            <div key={index}>
              <Card>
                <Card.Header>
                  <div className="d-flex" style={{ whiteSpace: 'nowrap' }}>
                    {`${item.occupier} - ${item.street}`}
                  </div>
                  <div
                    style={{ width: '100%', height: 50, cursor: 'pointer' }}
                    onClick={() => {
                      toggleActive(index);
                      buttonActive(index);
                      equipmentInfo(index);
                    }}
                  />
                  <Accordion.Button
                    className="buttons-item-table"
                    as={Button}
                    eventkey={index}
                  >
                    {hideBtn === index && (
                      <>
                        <img
                          src={tableRowDelete}
                          alt="table delete"
                          onClick={() => {
                            deleteItem(i);
                          }}
                        />
                        <img
                          src={mapQrcode}
                          className="mb-2"
                          onClick={() => {
                            qrCodeHandler(item);
                          }}
                        />
                        <img
                          src={tableEditImg}
                          alt="table edit"
                          onClick={() => {
                            editItem(i);
                          }}
                        />
                        <i className="divider-item">&nbsp;</i>
                      </>
                    )}

                    <img
                      id="expand-btn"
                      src={
                        activeId === index ? tableColapseImg : tableExpandImg
                      }
                      alt="table expand"
                      onClick={() => {
                        toggleActive(index);
                        buttonActive(index);
                        equipmentInfo(index);
                      }}
                    />
                  </Accordion.Button>
                </Card.Header>
                <div
                  className={hideInfo === index ? 'item-details' : 'hide-btn'}
                >
                  <Row>
                    <InfoField xs={4} label="Name" value={item.occupier} />
                    <InfoField
                      xs={8}
                      label="Street / Address"
                      value={item.street}
                    />
                    <InfoField xs={4} label="Town / City" value={item.city} />
                    <InfoField xs={4} label="County" value={item.county} />
                    <InfoField
                      xs={4}
                      label="Postcode"
                      value={item.postalCode}
                    />
                  </Row>
                </div>
              </Card>
            </div>
          );
        })}
        {showModal && (
          <ModalImage
            title="Directions"
            text="Scan this QR Code with your smartphone to instantly navigate to your desired destination."
            actionCancel={() => setShowModal(false)}
            qrCodeUrl={qrCodeUrl}
          />
        )}
      </Accordion>
    </Col>
  );
};

export default LocationsContent;
