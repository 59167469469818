import axios from 'axios';

import { envConfig } from '../config/config';

export const getAddressSugestions = async (postalCode: string) => {
  const apiUrl = envConfig.REACT_APP_GET_ADDRESS_API_KEY;
  const response = await axios.get(
    `https://api.getaddress.io/autocomplete/${postalCode}?api-key=${apiUrl}`
  );
  return response.data;
};

export const getAddressDetail = async (id: string) => {
  const apiUrl = envConfig.REACT_APP_GET_ADDRESS_API_KEY;
  const response = await axios.get(
    `https://api.getaddress.io/get/${id}?api-key=${apiUrl}`
  );
  return response.data;
};
