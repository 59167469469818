import { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, Form, Row } from 'react-bootstrap';

import { apiGenerateAutoMeiwc } from 'api/certificates';
import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';
import {
  BoardGridCard,
  CertificateDataOperationsTableProps,
} from 'interfaces/CertificateData';
import { FormOptions } from 'interfaces/CertificateTemplate';

import { Combo } from 'components/Form';

import * as S from './styles';
import { defaultValues, yupSchema } from './yupSchema';

interface AutoMeiwcForm {
  boardId: string;
  circuit: string;
}

interface ModalAutoMeiwcProps {
  closeModal: () => void;
  location: string;
  obervation: string;
}

interface BoarderInfo {
  certificateId: string;
  boardGridId: string;
  boardId: string;
  value: string;
  circuitOptions: {
    key: string;
    value: string;
  }[];
}

const ModalAutoMeiwc: FC<ModalAutoMeiwcProps> = ({
  closeModal,
  location,
  obervation,
}) => {
  const navigate = useNavigate();
  const { getValues } = useFormContext();
  const { templateFields, certData, setCertLoading } = useCerts();
  const [boardsInfo, setBoardsInfo] = useState<BoarderInfo[]>([]);
  const [currentBoardInfo, setCurrentBoardInfo] = useState<BoarderInfo>();

  const methods = useForm<AutoMeiwcForm>({
    mode: 'onTouched',
    resolver: yupResolver(yupSchema),
    defaultValues: defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const handleSave = (data: AutoMeiwcForm) => {
    setCertLoading(true);
    if (certData?._id && currentBoardInfo) {
      apiGenerateAutoMeiwc({
        certificateId: certData?._id,
        boardGridKey: currentBoardInfo.boardGridId,
        boardId: data.boardId,
        circuitId: data.circuit,
      })
        .then((resultData) => {
          navigate(`/?duplicated=${resultData._id}`);
        })
        .finally(() => {
          setCertLoading(false);
        });
    }
  };

  useEffect(() => {
    const boardGridField = templateFields?.find(
      (field) => field.type === FieldType.BoardGrid
    );
    const operationsTableFields = templateFields?.filter(
      (field) =>
        field.fieldId.includes('board') &&
        field.type === FieldType.OperationsTable
    );

    if (
      boardGridField?.fieldId &&
      operationsTableFields?.length &&
      certData?._id
    ) {
      const boardGrid = getValues(boardGridField?.fieldId) as BoardGridCard[];

      const boardsInfo = boardGrid.map((board) => {
        const circuitOptions: FormOptions[] = [];
        const boardDesignationField = board.fields.find(
          (boardField) => boardField.label === 'Board designation'
        );

        const operationsTableField = operationsTableFields.find((table) =>
          table.fieldId.includes(board.id)
        );

        const circuitTableValues = operationsTableField
          ? (getValues(
              operationsTableField?.fieldId
            ) as CertificateDataOperationsTableProps[]) ?? []
          : [];

        circuitTableValues.forEach((table) => {
          const rows =
            table.merged && table.mergedRow ? [table.mergedRow] : table.rows;

          rows.forEach((row) => {
            if (row.columns.length) {
              circuitOptions.push({
                key: row.columns[0].value ?? '',
                value: `${row.columns[0].value} - ${row.columns[1].value}`,
              });
            }
          });
        });

        return {
          certificateId: certData._id ?? '',
          boardGridId: boardGridField.fieldId,
          boardId: board.id,
          value: boardDesignationField?.value ?? '',
          circuitOptions,
        };
      });
      setBoardsInfo(boardsInfo);

      const boardInfo = boardsInfo.find((board) => board.value === location);
      if (boardInfo) {
        setCurrentBoardInfo(boardInfo);
        methods.setValue('boardId', boardInfo.boardId);

        const currentCircuit = boardInfo.circuitOptions.find((option) =>
          obervation.includes(option.key)
        );

        if (currentCircuit) {
          methods.setValue('circuit', currentCircuit.key);
        }
      }
    }
  }, []);

  return (
    <S.Modal show backdrop="static">
      <S.ModalHeader>
        <button onClick={closeModal}>Cancel</button>
        <h1>Automatic MEIWC</h1>
      </S.ModalHeader>
      <S.ModalBody>
        <S.Subtitle>Select the respective board and circuit.</S.Subtitle>
        <br />
        <FormProvider {...methods}>
          <Form
            onSubmit={methods.handleSubmit((data) => {
              handleSave(data);
            })}
          >
            <Row xs={12}>
              <Combo
                xs={6}
                required
                isCertificate={false}
                label="Board"
                fieldId="boardId"
                options={boardsInfo?.map((e) => ({
                  key: e.boardId,
                  value: e.value,
                }))}
                additionalOnChange={(value) => {
                  methods.setValue('circuit', '');
                  const current = boardsInfo.find(
                    (board) => board.boardId === value
                  );
                  setCurrentBoardInfo(current);
                }}
              />
              <Combo
                xs={6}
                required
                label="Circuit"
                isCertificate={false}
                fieldId="circuit"
                options={currentBoardInfo?.circuitOptions}
              />
            </Row>
            <Row xs={12} className="button-wrapper">
              <Button type="submit" className="button-primary btn">
                {'Generate'}
              </Button>
            </Row>
          </Form>
        </FormProvider>
      </S.ModalBody>
    </S.Modal>
  );
};

export default ModalAutoMeiwc;
