import React, { createContext, FC, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { previewPageTemplate } from 'constants/certificate.constants';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import uuid from 'react-uuid';

import {
  apiCertificateSubmit,
  apiCreateCertificateData,
  apiGetCertificateBoard,
  apiGetCertificateBoards,
  apiGetCertificateData,
  apiGetIssuedCertificateData,
  apiUpdateCertificateData,
  certificateAddNewBoard,
} from 'api/certificates';
import { CertificateState } from 'enum/certificateDataState.enum';
import {
  HandleAutoObservations,
  HandleAutoObservationsParams,
  ListBoardsFieldIds,
} from 'interfaces/AutoObservations';
import { AutoSaveParams } from 'interfaces/AutoSaveParams';
import {
  CertData,
  CertificateDataBoardsValuesProps,
} from 'interfaces/CertificateData';
import {
  AffectedFields,
  CertificateOptions,
  CertTemplate,
  CertTemplateField,
  HandleAffects,
  HandleAffectsParams,
} from 'interfaces/CertificateTemplate';
import { SelectedTableIndexes } from 'interfaces/Circuits';
import { ClearAllParams } from 'interfaces/ClearAllParams';
import api from 'services/api';
import { resetAffectedFields } from 'utils/certificates/affects';
import {
  changeCircuits,
  changeDateAction,
  changeOptionsAction,
  changeTableStructureAction,
  enabledDisabledAction,
  ifEmptyAction,
  triggerRequiredValuesAction,
} from 'utils/certificates/affects/actions';
import { autoObservations } from 'utils/certificates/autoObservations/autoObservations';
import { autoSaveSubmit } from 'utils/certificates/autoSave';
import { createNewCertificateWithDefaultProps } from 'utils/certificates/createCertData';
import { getDefaultValuesFromCertData } from 'utils/certificates/defaultValues';
import { updateCertDataWithBoards } from 'utils/certificates/helpers';
import {
  getFieldsAffectsAndOptions,
  loadOptionsFromRequiredValues,
  updateInitialLoadedValuesWithCertData,
} from 'utils/certificates/loadFormValues';
import { isPreset } from 'utils/certificates/presetUtils';
import { defaultToast } from 'utils/toast';

import { ReduxProps } from '.';

export const CertificateContext = createContext<CertificateProps>(
  {} as CertificateProps
);

const CertificateProvider: FC<ReduxProps> = ({ children }) => {
  const [autoSaveLoading, setASLoading] = useState(false);
  const [isCircuits, setIsCircuits] = useState(false);
  const [certLoading, setIsLoading] = useState(false);
  const [redirectToEdit, setRedirectToEdit] = useState<string | null>(null);

  const [certData, setCertData] = useState<CertData | null>(null);
  const [certTemplate, setCurrentTemplate] = useState<CertTemplate | null>(
    null
  );
  const [certRawTemplate, setRawTemplate] = useState<CertTemplate | null>(null);
  const [templateFields, setTemplateFields] = useState<
    CertTemplateField[] | null
  >(null);
  const [fieldsPerPage, setFieldsPerPage] = useState<{
    [fieldId: string]: number | string;
  }>({});
  const [defaultValues, setDefaultValues] = useState<{
    [key: string]: unknown;
  }>({});
  const [certOptions, setCertOptions] = useState<CertificateOptions>({});

  // Affected fields with inputs behaviors changes
  const [affectedFields, setAffectedFields] = useState<AffectedFields>({});

  // Certificate selected page
  const [selectedPage, setSelectedPage] = useState<unknown>(0);

  // Current Circuits Id
  const [circuitsId, setCircuitsId] = useState<string>();

  const [listBoardsFieldIds, setListBoardsFieldIds] = useState<
    ListBoardsFieldIds[]
  >([]);

  // SelectedCircuitsTableIndexes
  const [selectedCircuitIndexes, setSelectedCircuitIndexes] =
    useState<SelectedTableIndexes>({ row: undefined, cloumn: undefined });

  // Affected fields with inputs behaviors changes
  const [valuesForNewBoard, setValuesForNewBoard] = useState<
    CertificateDataBoardsValuesProps[]
  >([]);

  // Flag to remember if there is errors checked inside boards to show again after reload.
  // Reason: when open board, the form is reloaded and also lose the form state.
  const [isFormChecked, setIsFormChecked] = useState(false);

  /**
   * It loads a certificate template from the API.
   * @param {string} templateId - The id of the template to load
   * @param [create=false] - boolean - if true, the template will be used to create a new certificate
   */
  const loadCertificateTemplate = (
    templateId: string,
    certId?: string,
    customerId?: string
  ) => {
    setIsLoading(true);
    api
      .get<CertTemplate>(`/certificate-template/${templateId}`)
      .then(({ data }) => {
        setRawTemplate(data);
        const template = { ...data };
        template.pages = [
          ...template.pages.map((page) => ({
            ...page,
            isPreview: false,
          })),
        ];

        // Add preview page only in certificate mode
        if (!isPreset()) {
          template.pages.push(previewPageTemplate);
        }

        // if no certificateDATA ID is provided, create a new certificate using the template
        if (!certId) {
          // Create a certificate if exists a previous selected customer and exists userId
          if (customerId) {
            // create a mapping of the certificate fields
            createTemplateFieldsMapper(template);
            // create a new certificate using the template
            createCertificateFromTemplate(templateId, template, customerId);
          } else {
            console.error('to create a certificate need a customer');
            setIsLoading(false);
          }
        } else {
          // otherwise, load the certificate data and use it to edit the certificate
          loadCertificateData(certId, template);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        defaultToast('E');
      });
  };

  const loadCertificateBoards = (
    certificateData: CertData,
    boardGridId: string,
    onLoad: () => void,
    boardId?: string
  ) => {
    if (!certificateData?._id || !certRawTemplate) {
      return;
    }
    const template = { ...certRawTemplate };
    template.pages = [
      ...template.pages.map((page) => ({
        ...page,
        isPreview: false,
      })),
    ];
    template.pages.push(previewPageTemplate);

    setIsLoading(true);
    if (boardId) {
      apiGetCertificateBoard(certificateData._id, boardId, boardGridId)
        .then((singleBoard) => {
          onLoad();
          const updatedCertData = updateCertDataWithBoards({
            boardGridId,
            certData: certificateData,
            singleBoard,
          });
          loadCertificateWithDataAndTamplate(template, updatedCertData);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      apiGetCertificateBoards(certificateData._id, boardGridId)
        .then((boardList) => {
          onLoad();
          const updatedCertData = updateCertDataWithBoards({
            boardGridId,
            certData: certificateData,
            boardList,
          });
          loadCertificateWithDataAndTamplate(template, updatedCertData);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * It loads a issued certificate with static data and template.
   * @param {string} certificateId - The id of the issued certificate to load
   */
  const loadIssuedCertificate = (certificateId: string) => {
    setIsLoading(true);
    apiGetIssuedCertificateData(certificateId)
      .then((issuedCertData) => {
        const { template, ...certData } = issuedCertData;

        template.pages = [
          ...template.pages.map((page) => ({
            ...page,
            isPreview: false,
          })),
          previewPageTemplate,
        ];

        const data: CertData = {
          ...(certData as CertData),
          template: {
            _id: template._id,
            certificateDescription: template.certificateDescription,
            certificateName: template.certificateName,
            createdAt: template.createdAt,
          },
        };

        // create a mapping of the certificate fields using the template and the certificate data
        createTemplateFieldsMapper(template, data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        defaultToast('E');
        setTimeout(() => {
          window.location.href = '/dashboard';
        }, 3000);
      });
  };

  /**
   * It takes a templateId and a data object, creates a payload object, and then sends the payload to
   * the API
   * @param {string} templateId - The id of the template you want to use to create the certificate.
   * @param {CertTemplate} certTemplate - CertTemplate
   * @param {string} customerId - CustomerId
   */
  const createCertificateFromTemplate = (
    templateId: string,
    certTemplate: CertTemplate,
    customerId: string
  ) => {
    const payload = {
      template: templateId,
      values: createNewCertificateWithDefaultProps(certTemplate, customerId),
      customer: customerId,
    };
    apiCreateCertificateData(payload)
      .then(({ _id = null }) => {
        console.info(
          'New [' +
            certTemplate.certificateName +
            '] certificate created with the id: ' +
            _id +
            ' from template.'
        );
        setRedirectToEdit(_id);
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          return err.response?.data.message;
        }
        alert('There was a problem saving the certificate!');

        return err;
      })
      .finally(() => setIsLoading(false));
  };

  /**
   * It takes a templateId and a data object, creates a payload object, and then sends the payload to
   * the API
   * @param {string} templateId - The id of the template you want to use to create the certificate.
   * @param {string} customerId - CustomerId
   * @param {string} presetData - preset data id
   */
  const updateFromPreset = async (
    customerId: string,
    presetData: string,
    success: (isSuccess: boolean) => void
  ) => {
    await apiGetCertificateData(presetData).then((presetCertificateData) => {
      const payload = {
        template: certData?.template?._id ?? '',
        values: presetCertificateData.values,
        customer: customerId,
      };

      if (certData?._id) {
        apiUpdateCertificateData(certData._id, payload)
          .then(({ _id = null }) => {
            console.info(
              'New [' +
                presetCertificateData.template?.certificateName +
                '] certificate created with the id: ' +
                _id +
                ' from preset id: ' +
                presetData
            );
            setRedirectToEdit(_id);
            success(true);
          })
          .catch((err: Error | AxiosError) => {
            if (axios.isAxiosError(err)) {
              return err.response?.data.message;
            }
            alert('There was a problem saving the certificate!');
            success(false);
            return err;
          })
          .finally(() => setIsLoading(false));
      }
    });
  };

  /**
   * It loads a certificate template from the API.
   * @param {string} templateId - The id of the template to load
   * @param [create=false] - boolean - if true, the template will be used to create a new certificate
   */
  const loadCertificateData = (certId: string, template: CertTemplate) => {
    setIsLoading(true);
    api.get(`/certificate-data/${certId}`).then(({ data }) => {
      loadCertificateWithDataAndTamplate(template, data);
    });
  };

  const loadCertificateWithDataAndTamplate = (
    template: CertTemplate,
    certificateData: CertData
  ) => {
    // If user try to load issued certificate deliberately instead a non issued,
    // Call function that load issued certificate.
    if (
      certificateData._id &&
      certificateData?.certificateState === CertificateState.ISSUED
    ) {
      loadIssuedCertificate(certificateData._id);
    } else {
      // create a mapping of the certificate fields using the template and the certificate data
      createTemplateFieldsMapper(template, certificateData);
    }
  };

  // This will load options for all required values for each field.
  const reloadRequiredValues = (
    fieldsMapper: CertTemplateField[],
    certOptions: CertificateOptions,
    defaultValues: {
      [key: string]: unknown;
    },
    getValues: UseFormGetValues<FieldValues>
  ) => {
    const newCertificateOptions: CertificateOptions = {
      ...certOptions,
    };
    loadOptionsFromRequiredValues(
      fieldsMapper,
      newCertificateOptions,
      defaultValues,
      getValues
    );

    setCertOptions(newCertificateOptions);
  };

  const createTemplateFieldsMapper = (temp: CertTemplate, cdata?: CertData) => {
    setIsLoading(true);
    //**
    //** GENERATE NECESSARY HELPERS WITH THE TEMPLATE DEFAULT VALUES */
    //**
    const { fieldsMapper, fieldsPerPage } = getFieldsAffectsAndOptions(temp);

    setCurrentTemplate(temp);
    setFieldsPerPage(fieldsPerPage);
    setTemplateFields(fieldsMapper);

    if (cdata) {
      setCertData(cdata);

      // create an object of default field values from the certificate data
      const {
        initEditFormValues,
        newTemplate,
        defaultValuesForNewBoard,
        listBoardsFieldIds,
      } = getDefaultValuesFromCertData(fieldsMapper, cdata, temp);

      //? THIS CHANGE IS TO ADD EACH CERTIFICATE BOARD AS DUMMY PAGES
      // After generate a newTemplate is necessary update the states according
      const {
        fieldsPerPage: newFieldsPerPage,
        fieldsMapper: newFieldsMapper,
        fieldsWithAffects: newFieldsWithAffects,
        certificateOptions: newCertificateOptions,
        affectedFields: newAffectedFields,
      } = getFieldsAffectsAndOptions(newTemplate);

      // This will load options for all required values for each field.
      loadOptionsFromRequiredValues(
        newFieldsMapper,
        newCertificateOptions,
        initEditFormValues
      );

      // Update certificate states generated after include dummy
      setCurrentTemplate(newTemplate);
      setFieldsPerPage(newFieldsPerPage);
      setTemplateFields(newFieldsMapper);
      setValuesForNewBoard(defaultValuesForNewBoard);
      setCertOptions(newCertificateOptions);
      setAffectedFields(newAffectedFields);
      setListBoardsFieldIds(listBoardsFieldIds);

      //**
      //** UPDATE HELPERS WITH THE CERTIFICATE DATA VALUES */
      //**
      const affectedFieldValidationsHelper =
        updateInitialLoadedValuesWithCertData(
          newFieldsWithAffects,
          newCertificateOptions,
          newFieldsMapper,
          newAffectedFields,
          initEditFormValues,
          handleAffects,
          cdata
        );

      setDefaultValues({
        ...initEditFormValues,
        ...affectedFieldValidationsHelper,
      });
    }
    setIsLoading(false);
  };

  const handleAffects = (
    handleAffectsParams: HandleAffectsParams
  ): CertificateOptions => {
    // Update handle params with the current state if not exits
    handleAffectsParams.defaultValues ||= defaultValues;
    handleAffectsParams.templateFields ||= templateFields;
    handleAffectsParams.affectedFields ||= affectedFields;
    handleAffectsParams.certOptions ||= certOptions;
    handleAffectsParams.certData ||= certData;
    handleAffectsParams.autoSave = autoSave;

    const { certOptions: existentCertOptions = {} } = handleAffectsParams;

    // * Reset the affected fields before apply new values.
    resetAffectedFields(handleAffectsParams);

    // * Field change
    changeDateAction(handleAffectsParams);

    // AffectedFields changes
    enabledDisabledAction(handleAffectsParams);
    changeTableStructureAction(handleAffectsParams);

    const resultOptions: CertificateOptions = {
      // TODO add here more options by other affects action types
      ...existentCertOptions,

      // Process each affect action separately, and return your options
      ...changeOptionsAction(handleAffectsParams),
      ...ifEmptyAction(handleAffectsParams),
      ...changeCircuits(handleAffectsParams),
      ...triggerRequiredValuesAction(handleAffectsParams),
    };

    // Update certificate options state
    setCertOptions(resultOptions);

    // Update affectedFields state after param changes
    setAffectedFields({ ...handleAffectsParams.affectedFields });

    return resultOptions;
  };

  const handleAutoObservations = (
    handleParams: HandleAutoObservationsParams
  ): void => {
    handleParams.autoSave = autoSave;
    handleParams.templateFields ||= templateFields ?? [];
    handleParams.listBoardsFieldIds ||= listBoardsFieldIds;

    const { getValues, setValue } = handleParams;
    if (!getValues || !setValue || !templateFields) return;

    autoObservations(handleParams);
  };

  const autoSave = (params: AutoSaveParams) => {
    if (!certData?._id) return;
    const { onSave, ...rest } = params;
    autoSaveSubmit({
      ...rest,
      setASLoading,
      certDataId: certData._id,
      templateFields,
      onSuccess: () => {
        if (onSave) {
          onSave();
        }
      },
    });
  };

  const submitCertificate = async (): Promise<CertData | undefined> => {
    if (certData?._id) {
      setIsLoading(true);
      const data = await apiCertificateSubmit(certData._id).finally(() => {
        setIsLoading(false);
      });
      return data;
    }
  };

  const createNewBoard = (
    boardGridFieldId: string,
    reset: any,
    navigate: (path: string, index: number) => void
  ) => {
    if (!certData?._id) return;
    const newBoardId = uuid();
    const newBoard = {
      key: boardGridFieldId,
      isComplete: false,
      boardId: newBoardId,
      values: valuesForNewBoard,
    };

    setIsLoading(true);
    certificateAddNewBoard(certData._id, newBoard)
      .then((res?: CertData) => {
        if (certData?.template?._id && certData._id) {
          reset({});
          clearAll();
          loadCertificateTemplate(certData?.template?._id, certData._id);
          return res;
        } else {
          window.location.href = '/';
        }
      })
      .then((res: any) => {
        const boardItems = (res as CertData).values.find(
          (val) => val.key === boardGridFieldId
        );
        if (boardItems) {
          boardItems.valueBoards?.forEach(
            (item, i) => item.id === newBoardId && navigate(newBoardId, i)
          );
        }
      })
      .catch((error) => {
        console.error(error);
        defaultToast('E');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const clearAll = (params?: ClearAllParams) => {
    const { skipFormChecked } = params ?? {};
    setASLoading(false);
    setIsLoading(false);
    setRedirectToEdit(null);

    setCertData(null);
    setCurrentTemplate(null);
    setListBoardsFieldIds([]);

    if (!skipFormChecked) {
      setIsFormChecked(false);
    }

    setTemplateFields([]);
    setFieldsPerPage({});
    setDefaultValues({});
    setCertOptions({});
    setAffectedFields({});
  };

  const contextValue = useMemo<CertificateProps>(
    () => ({
      certLoading,
      isCircuits,
      setIsCircuits,
      circuitsId,
      setCircuitsId,
      isFormChecked,
      setIsFormChecked,
      listBoardsFieldIds,
      setListBoardsFieldIds,
      certTemplate,
      certRawTemplate,
      redirectToEdit,
      certOptions,
      affectedFields,
      certData,
      templateFields,
      defaultValues,
      fieldsPerPage,
      autoSaveLoading,
      selectedPage,
      setSelectedPage: (page: unknown) => setSelectedPage(page),
      selectedCircuitIndexes,
      setSelectedCircuitIndexes: (indexes: SelectedTableIndexes) =>
        setSelectedCircuitIndexes(indexes),
      submitCertificate,
      clearAll,
      loadCertificateTemplate,
      loadCertificateBoards,
      loadIssuedCertificate,
      handleAffects,
      handleAutoObservations,
      reloadRequiredValues,
      autoSave,
      setCertLoading: (loading: boolean) => setIsLoading(loading),
      setRedirectToEdit: () => setRedirectToEdit(null),
      valuesForNewBoard,
      createNewBoard,
      updateFromPreset,
    }),
    [
      selectedPage,
      selectedCircuitIndexes,
      autoSaveLoading,
      certLoading,
      certTemplate,
      certRawTemplate,
      redirectToEdit,
      certOptions,
      affectedFields,
      certData,
      templateFields,
      defaultValues,
      isCircuits,
      listBoardsFieldIds,
      circuitsId,
      isFormChecked,
    ]
  );

  return (
    <CertificateContext.Provider value={contextValue}>
      {children}
    </CertificateContext.Provider>
  );
};

export interface CertificateProps {
  autoSaveLoading: boolean;
  certLoading: boolean;
  selectedPage: unknown;
  setSelectedPage: (page: unknown) => void;
  selectedCircuitIndexes: SelectedTableIndexes;
  setSelectedCircuitIndexes: (indexes: SelectedTableIndexes) => void;
  setCertLoading: (loading: boolean) => void;
  isCircuits: boolean;
  setIsCircuits: (loading: boolean) => void;
  circuitsId?: string;
  setCircuitsId: (circuitsId: string) => void;
  isFormChecked: boolean;
  setIsFormChecked: (checked: boolean) => void;
  listBoardsFieldIds: ListBoardsFieldIds[];
  setListBoardsFieldIds: (listBoardsFieldIds: ListBoardsFieldIds[]) => void;
  submitCertificate: () => Promise<CertData | undefined>;
  certData: CertData | null;
  certTemplate: CertTemplate | null;
  certRawTemplate: CertTemplate | null;
  fieldsPerPage: { [key: string]: number | string };
  defaultValues: { [key: string]: unknown };
  certOptions: CertificateOptions;
  affectedFields: AffectedFields;
  valuesForNewBoard: CertificateDataBoardsValuesProps[];
  createNewBoard: (
    boardGridFieldId: string,
    reset: any,
    navigate: (path: string, index: number) => void
  ) => void;
  loadCertificateTemplate: (
    templateId: string,
    certId?: string,
    customerId?: string
  ) => void;
  loadCertificateBoards: (
    certificateData: CertData,
    boardGridId: string,
    onLoad: () => void,
    boardId?: string
  ) => void;
  loadIssuedCertificate: (certificateId: string) => void;
  redirectToEdit: string | null;
  setRedirectToEdit: () => void;
  clearAll: (params?: ClearAllParams) => void;
  autoSave: (params: AutoSaveParams) => void;
  handleAffects: HandleAffects;
  handleAutoObservations: HandleAutoObservations;
  templateFields: CertTemplateField[] | null;
  updateFromPreset: (
    customerId: string,
    presetData: string,
    success: (isSuccess: boolean) => void
  ) => void;
  reloadRequiredValues: (
    fieldsMapper: CertTemplateField[],
    certOptions: CertificateOptions,
    defaultValues: {
      [key: string]: unknown;
    },
    getValues: UseFormGetValues<FieldValues>
  ) => void;
}

export default CertificateProvider;
