import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { ContactUsPayload } from 'store/@types/contact/index';

import { Button, Col, Form, Row } from 'react-bootstrap';

import FormSent from 'assets/images/onboarding/image_formsent.svg';
import withHelmet from 'hooks/withHelmet';
import { defaultToast } from 'utils/toast';

import { Combo } from 'components/Form/Combo';
import { TextField } from 'components/Form/TextField';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';

import { ReduxProps } from '.';
import { defaultValues, schema } from './yupSchema';

const ContactPage: FC<ReduxProps> = ({
  contact: reduxContact,
  thunkFetchTopics,
  thunkSendContact,
  clearError,
}) => {
  const [successOperation, setSuccessOperation] = useState(false);
  const navigate = useNavigate();

  const { error, helpTopics, loading } = reduxContact;

  useEffect(() => {
    thunkFetchTopics();
  }, []);

  useEffect(() => {
    if (error) {
      defaultToast(
        'E',
        'An error occurred while sending your message. Please try again later.'
      );
      clearError();
    }
  }, [error]);

  // React hook form instance
  const methods = useForm<ContactUsPayload>({
    mode: 'onTouched',
    resolver: yupResolver(schema({ description: 500 })),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const onSubmit = async (payload: ContactUsPayload) => {
    thunkSendContact(payload, () => setSuccessOperation(true));
  };

  return (
    <>
      {loading && <SplashScreen />}
      {!successOperation && (
        <NoAuthBasePage
          title="Describe your issue below"
          subTitle="So we can assist you the best"
          noFooter
        >
          <div className="white-card-text-body no-padding-top">
            <p>
              Please fill out the form below to best describe the issue you need
              assistance with. Our support team will do our best to get back to
              you shortly.
            </p>
            <FormProvider {...methods}>
              <Form
                id="user-register"
                onSubmit={methods.handleSubmit((data) => {
                  onSubmit(data);
                })}
              >
                <br />
                <Row>
                  <Combo
                    xs={6}
                    fieldId="topic"
                    options={helpTopics?.map((e) => ({
                      key: e._id,
                      value: e.name,
                    }))}
                    label="What do you need help with?"
                  />
                  <Col xs={6} />
                  <TextField xs={6} fieldId="name" label="Name" />
                  <TextField xs={6} fieldId="email" label="Email" />
                  <TextField
                    xs={12}
                    fieldId="description"
                    label="Tell us how we can help"
                    placeholder="Please describe your issue as well as possible."
                    maxLength={500}
                  />
                </Row>
                <Row>
                  <Col xs={6} className="align-self-end">
                    <Link className="gray bold" to="/login">
                      Back to login screen
                    </Link>
                  </Col>
                  <Col xs={6} className="align-self-end">
                    <Button
                      className="button-primary float-right"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </div>
        </NoAuthBasePage>
      )}

      {successOperation && (
        <NoAuthBasePage
          title="Thank you"
          subTitle="Your request was sent successfully"
          noFooter
        >
          <div className="white-card-text-body margin-top">
            <img
              src={FormSent}
              style={{
                width: '598px',
                height: '387px',
                marginTop: '-40px',
                marginBottom: '20px',
              }}
              alt="formSentImage"
            />
            <p style={{ marginBottom: '30px' }}>
              Thank you for your message.
              <br />
              We’ll do our best to assist you as briefly as possible. If you
              need any further help please reach us at{' '}
              <a href="mailto:doddle.support@arma-software.com">
                doddle.support@arma-software.com
              </a>
            </p>
            <Row>
              <Col xs={6} />
              <Col xs={6}>
                <Button
                  className="button-primary float-right"
                  type="button"
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  Back to login screen
                </Button>
              </Col>
            </Row>
          </div>
        </NoAuthBasePage>
      )}
    </>
  );
};

export default withHelmet(ContactPage);
