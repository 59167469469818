import React, { Fragment } from 'react';
import clsx from 'clsx';

import { TableColumnStyles, TableStructHeader } from 'interfaces/Circuits';

import { TableHeaderColumn } from './styles';

interface CircuitHeaderColumnsProps {
  tableColumnStyles: TableColumnStyles;
  tableStructHeaders: TableStructHeader[];
}

const CircuitHeaderColumns: React.FC<CircuitHeaderColumnsProps> = ({
  tableColumnStyles,
  tableStructHeaders,
}) => {
  return (
    <Fragment>
      {tableStructHeaders.map(({ headerIndex, headerTitle }) => {
        const headerKey = headerTitle + '-header> ';
        const tableStyles = tableColumnStyles[headerIndex];

        return (
          <TableHeaderColumn
            key={headerKey}
            column={tableStyles.column}
            className={clsx({ 'arrow-icon': headerIndex === 0 })}
          >
            {headerIndex > 0 && headerTitle}
          </TableHeaderColumn>
        );
      })}
    </Fragment>
  );
};

export default CircuitHeaderColumns;
