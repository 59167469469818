import { AffectFunction } from 'enum/AffectFunction.enum';
import {
  FieldAffects,
  HandleAffectsParams,
  ReqValue,
} from 'interfaces/CertificateTemplate';

export const getValuesFunction = (
  affect: FieldAffects,
  handleAffectsParams: HandleAffectsParams,
  requiredValue: ReqValue
) => {
  const { getValues, setValue, defaultValues } = handleAffectsParams;

  //* if the change-required-value is of type GET_VALUE
  if (requiredValue && requiredValue.function === AffectFunction.GET_VALUE) {
    //** get the VALUE from either the form or the loading defaultValue
    const fetchedValue = getValues
      ? getValues(requiredValue.fieldId)
      : defaultValues && defaultValues[requiredValue.fieldId];

    //** set the fetched VALUE to the affected field
    if (fetchedValue) {
      if (setValue) {
        setValue(affect.field, fetchedValue);
      } else if (defaultValues) {
        defaultValues[affect.field] = fetchedValue;
      }
    }
  }
};
