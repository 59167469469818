import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { ApiException } from 'api/exceptions/api.exception';
import axios, { ApiError } from 'services/api';

import { DELETE, GET, PATCH, POST, PUT } from './requestTypes';

/** Axios request helper
 *
 * handles the GET POST PUT DELETE EXPORT methods
 * already with toast notification seamlessly with the API response
 * and with java error messages
 */
const restCall = async <T = never, R = T>(
  type: number,
  path: string,
  reqPayload: any = undefined,
  config: AxiosRequestConfig<T> | undefined = undefined
): Promise<R> => {
  let data = null;

  // TODO tratar o erro e adicionar toast.
  const success = (response: AxiosResponse<T>) => {
    data = response.data;
  };

  const error = ({
    name,
    response,
    config,
    cause,
    code = '500',
  }: AxiosError) => {
    const apiError: ApiError = {
      error: name,
      data: {},
      statusCode: Number(code),
    };

    if (response) {
      apiError.statusCode = response.status;
      apiError.data = {
        method: response.config.method,
        url: response.config.url,
        ...(response.data as object),
      };
    } else {
      apiError.data = {
        method: config.method,
        url: config.url,
        cause: cause,
      };
    }

    throw new ApiException(name, apiError);
  };

  const REQUESTS: {
    [x: string]: () => Promise<void>;
  } = {
    [`${GET}`]: async () =>
      axios.get<T>(`${path}`, reqPayload).then(success).catch(error),
    [`${PUT}`]: async () =>
      axios.put<T>(`${path}`, reqPayload, config).then(success).catch(error),
    [`${POST}`]: async () =>
      axios.post<T>(`${path}`, reqPayload, config).then(success).catch(error),
    [`${DELETE}`]: async () =>
      axios
        .delete<T>(`${path}`, { data: { reqPayload } })
        .then(success)
        .catch(error),
    [`${PATCH}`]: async () =>
      axios.patch<T>(`${path}`, reqPayload, config).then(success).catch(error),
  };

  await REQUESTS[type]();

  return data || ({} as R);
};

export { restCall };
