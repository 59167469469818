import {
  ApiOnTimePaymentParams,
  StripeCheckout,
  StripeCurrency,
} from 'store/@types/payment';

import { StripeSubscriptionPricesPayload } from 'interfaces/Subscription';
import { GET, PATCH, POST } from 'utils/requestTypes';
import { restCall } from 'utils/restHandler';

export const apiSubscriptionPayment = (params: ApiOnTimePaymentParams) => {
  const {
    successUrlPath,
    cancelUrlPath,
    currency = StripeCurrency.GBP,
    priceId,
  } = params;

  return restCall<StripeCheckout>(POST, `payment/create-checkout-session`, {
    ...(priceId && { priceId }),
    currency,
    successUrlPath,
    cancelUrlPath,
  });
};

export const apiSubscriptionChangePayment = (
  userId: string,
  priceId: string
) => {
  return restCall(PATCH, `user/${userId}/subscription`, {
    priceId,
  });
};

export const apiSoloSubscriptionPrices = () =>
  restCall<StripeSubscriptionPricesPayload>(GET, '/payment/subscriptions');
