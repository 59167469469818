import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';

import { apiSendPasswordRecovery } from 'api/user';
import withHelmet from 'hooks/withHelmet';
import { defaultToast } from 'utils/toast';

import { TextField } from 'components/Form';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';

import { defaultValues, schema } from './yupSchema';

interface RecoverPWData {
  email: string;
  emailConfirm: string;
  password: string;
  passwordConfirm: string;
}

function PasswordRecovery() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [successOperation, setSuccessOperation] = useState(false);

  // React hook form instance
  const methods = useForm<RecoverPWData>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const onSubmit = async (payload: RecoverPWData) => {
    try {
      await apiSendPasswordRecovery(payload);
      setSuccessOperation(true);
      setIsLoading(false);
    } catch (err) {
      defaultToast('E');
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <SplashScreen />}
      {!successOperation && (
        <NoAuthBasePage
          title="Forgot your password?"
          subTitle="We’re here to help"
        >
          <FormProvider {...methods}>
            <Form
              id=""
              onSubmit={methods.handleSubmit((data) => {
                setIsLoading(true);
                onSubmit(data);
              })}
            >
              <p id="password-recovery-explanation">
                To help us recover your access credentials, please fill in your
                email address below and we’ll send you instructions to guide you
                through the process.
              </p>
              <Row>
                <TextField xs={6} fieldId="email" label="Enter your email" />
                <TextField
                  xs={6}
                  fieldId="emailConfirm"
                  label="Confirm your email"
                />
              </Row>
              <Row>
                <Col xs={6} className="align-self-end">
                  <Link className="gray bold" to="/login">
                    Back to login screen
                  </Link>
                </Col>
                <Col xs={6} className="align-self-end">
                  <Button className="button-primary float-right" type="submit">
                    Reset password
                  </Button>
                </Col>
              </Row>
            </Form>
          </FormProvider>
        </NoAuthBasePage>
      )}
      {successOperation && (
        <NoAuthBasePage
          title="Your request has been sent"
          subTitle="Please check your inbox"
        >
          <div className="white-card-text-body margin-top">
            <p>
              We have sent you an email with instructions to recover your
              credentials. Please be patient, this might take a few minutes to
              arrive at your inbox.
            </p>
            <p>
              If you don’t receive anything in the next 10 minutes, please be
              sure to check your spam folder, as some servers can filter
              automated responses messages.
            </p>
          </div>
          <Row>
            <Col xs={6} />
            <Col xs={6}>
              <Button
                className="button-primary float-right"
                type="button"
                onClick={() => {
                  navigate('/login');
                }}
              >
                Back to login
              </Button>
            </Col>
          </Row>
        </NoAuthBasePage>
      )}
    </>
  );
}

export default withHelmet(PasswordRecovery);
