import React, { useMemo } from 'react';

interface StepperPaginationProps {
  total: number;
  current: number;
}
const interval = 14;

const StepperPagination: React.FC<StepperPaginationProps> = ({
  total,
  current,
}) => {
  const stepperIcons = useMemo(() => {
    const icons = [];
    for (let i = 0; i < total + 1; i++) {
      icons.push(
        <rect
          key={i}
          x={i * interval}
          y="0"
          rx="3"
          ry="3"
          fill="#FF7F7F"
          width="6"
          height="6"
        />
      );
    }
    return icons;
  }, [total, current]);

  return (
    <svg width="76" height="6">
      {stepperIcons}
      <rect
        x={current * interval}
        y="0"
        rx="3"
        ry="3"
        fill="#FF0000"
        width="20"
        height="6"
      />
    </svg>
  );
};
export default StepperPagination;
