import React from 'react';

import './styles.css';

interface ErrorAreaProps {
  message: string | React.ReactNode;
}
const ErrorArea: React.FC<ErrorAreaProps> = ({ message, children }) => (
  <>
    {Boolean(message) && (
      <div className="error-message">
        {message}
        {children}
      </div>
    )}
  </>
);

export default ErrorArea;
