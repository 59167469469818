import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoleEnum } from 'store/@types/user';

import { apiListDashboardsInfor } from 'api/dashboard';
import ClockIcon from 'assets/images/iconset/clock.svg';
import ClockSmallIcon from 'assets/images/iconset/clock_small.svg';
import InreviewIcon from 'assets/images/iconset/inreview.svg';
import InreviewSmallIcon from 'assets/images/iconset/inreview_small.svg';
import IssuedIcon from 'assets/images/iconset/issued.svg';
import IssuedSmallIcon from 'assets/images/iconset/issued_small.svg';
import { CertificateState } from 'enum/certificateDataState.enum';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import { CertData } from 'interfaces/CertificateData';
import { CertTemplate } from 'interfaces/CertificateTemplate';
import { DashBoardCounters } from 'interfaces/DashBoard';
import {
  getSiteNameByCertData,
  sortCertificateDataByLastUpdated,
} from 'utils/certificates/helpers';
import { formatDate } from 'utils/index';

import { MenuMain } from 'components/Menu/MenuMain';
import NewMenuTab from 'components/Menu/NewMenuTab';
import MissingInfoModal from 'components/modals/MissingInfoModal';
import ModalCustomer from 'components/modals/ModalCustomers';
import NewCarousel from 'components/NewCarousel';
import { CertificateCardPayload } from 'components/NewCarousel/interfaces/CertificateCardPayload';
import MainLayout from 'components/root/MainLayout';
import SplashScreen from 'components/root/SplashScreen';

import CreateCertButton from './CreateCertButton';
import InfoCard from './InfoCard';
import * as S from './styles';

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const duplicatedId = query.get('duplicated');

  const {
    authLoading,
    userHasRequiredInfoFilled,
    currentBusiness,
    currentRole,
    reload,
  } = useAuth();
  const { clearAll } = useCerts();
  const { t } = useTranslation();

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<CertTemplate>();
  const [showVerify, setShowVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recentCerts, setRecentCerts] = useState<CertData[] | null>(null);
  const [templates, setTemplates] = useState<CertTemplate[] | null>(null);
  const [counters, setCounters] = useState<DashBoardCounters>();
  const [selectedTab, setSelectedTab] = useState(CertificateState.DRAFT);
  const canCreate = [RoleEnum.SOLO, RoleEnum.LEAD].includes(currentRole);

  useEffect(() => {
    if (!currentBusiness) return;
    setIsLoading(true);
    apiListDashboardsInfor()
      .then(({ certificateData, certificateTemplate, counters }) => {
        setSelectedTab(CertificateState.DRAFT);
        certificateData.sort(sortCertificateDataByLastUpdated);
        setRecentCerts(certificateData);
        setTemplates(certificateTemplate);
        setCounters(counters);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentBusiness]);

  useEffect(() => {
    clearAll();
  }, [currentBusiness]);

  useEffect(() => {
    reload({ reloadWorkspaces: true });
  }, []);

  const newTemplate = (template: CertTemplate, customerId: string) => {
    setIsLoading(false);
    navigate(`/manage-certificate/${template._id}`, {
      state: { customerId },
    });
  };

  const createButtonAction = (template: CertTemplate) => {
    const { hasAllRequiredInfo } = userHasRequiredInfoFilled();

    if (hasAllRequiredInfo) {
      setCurrentTemplate(template);
      setShowCustomerModal(true);
    } else {
      setShowVerify(true);
    }
  };

  const menuTabItems = [
    {
      label: t('dashboard_drafts_filter'),
      selectedTab: selectedTab === CertificateState.DRAFT,
      onClick: () => {
        setSelectedTab(CertificateState.DRAFT);
      },
    },
    {
      label: t('dashboard_pending_corrections_filter'),
      selectedTab: selectedTab === CertificateState.REVIEWED,
      onClick: () => {
        setSelectedTab(CertificateState.REVIEWED);
      },
    },
  ];

  return (
    <>
      {(authLoading || isLoading) && <SplashScreen />}
      {showVerify && <MissingInfoModal close={() => setShowVerify(false)} />}
      <MainLayout pageTitle={t('dashboard_title')} title={t('dashboard_title')}>
        <MenuMain />
        <S.ScrollContainer>
          <S.DashContainer>
            <S.InfoCardsContainer>
              <InfoCard
                iconBack={ClockIcon}
                icon={ClockSmallIcon}
                title={`${counters?.draft ?? 0}`}
                description={t('dashboard_draft_certificates')}
              />
              {currentRole === RoleEnum.LEAD && (
                <InfoCard
                  iconBack={InreviewIcon}
                  icon={InreviewSmallIcon}
                  title={`${
                    (counters?.['in-review'] ?? 0) +
                    (counters?.['pending-review'] ?? 0)
                  }`}
                  description={t('dashboard_in_review_certificates')}
                />
              )}
              <InfoCard
                iconBack={IssuedIcon}
                icon={IssuedSmallIcon}
                title={`${counters?.issued ?? 0}`}
                description={t('dashboard_issued_certificates')}
              />
            </S.InfoCardsContainer>
            <S.CertificateContainer>
              <S.CertificateHeader>
                <span>{t('dashboard_certificates')}</span>
                <S.CreateButtonContainer>
                  {canCreate &&
                    templates?.map((template) => (
                      <CreateCertButton
                        key={template._id}
                        onClick={() => {
                          createButtonAction(template);
                        }}
                        label={template.certificateName ?? ''}
                      />
                    ))}
                </S.CreateButtonContainer>
              </S.CertificateHeader>
              <S.CertificateBody>
                {currentRole === RoleEnum.LEAD && (
                  <NewMenuTab
                    paddingLeft="25px"
                    paddingRight="25px"
                    marginBottom="32px"
                    withDivider
                    tabItems={menuTabItems}
                  />
                )}
                <NewCarousel<CertificateCardPayload>
                  cardType={'certificate'}
                  height="270px"
                  marginLeft={'25px'}
                  payload={recentCerts
                    ?.filter(({ certificateState }) => {
                      if (selectedTab === CertificateState.DRAFT) {
                        return certificateState === CertificateState.DRAFT;
                      }

                      if (selectedTab === CertificateState.REVIEWED) {
                        return certificateState === CertificateState.REVIEWED;
                      }
                    })
                    .map((item) => {
                      const { siteName, city, postalCode } =
                        getSiteNameByCertData(item);
                      return {
                        id: item._id ?? '0',
                        highlight: duplicatedId === item._id,
                        onClick: () => {
                          navigate(
                            `/manage-certificate/${item.template?._id}/${item._id}`
                          );
                        },
                        title: item.template?.certificateName ?? '',
                        subtitle: item.customer?.name ?? '',
                        siteName,
                        city,
                        postalCode,
                        updatedAt:
                          (item.updatedAt &&
                            formatDate(String(item.updatedAt), true)) ??
                          '',
                      };
                    })}
                />
              </S.CertificateBody>
            </S.CertificateContainer>
          </S.DashContainer>
        </S.ScrollContainer>
        {showCustomerModal && currentTemplate && (
          <ModalCustomer
            onDashboard={currentTemplate._id}
            setOpenModal={setShowCustomerModal}
            handleSave={(customerId) => {
              newTemplate(currentTemplate, customerId);
            }}
          />
        )}
      </MainLayout>
    </>
  );
};

export default Dashboard;
