import React from 'react';
import {
  toast as originalToast,
  ToastContent,
  ToastOptions,
} from 'react-toastify';

import { ReactComponent as Symbol } from 'assets/images/doddle_symbol.svg';

import { errors } from './formUtils';

export const toast = (content: ToastContent, options?: ToastOptions) => {
  return originalToast(
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <span>
        <Symbol />
      </span>
      <span>{content}</span>
    </div>,
    options
  );
};

/**
 * It takes a message as a parameter and displays a toast notification with the message
 * @param {} NO_PARAM -> show default SUCCESS msg
 * @param {true} [msg] - show default ERROR msg
 * @param {string} [msg] - The message you want to display in the toast.
 */
export const defaultToast = (type: 'S' | 'E', msg?: string) => {
  if (type === 'S') {
    toast(msg || errors.defaultSuccess, {
      ...notifications.success,
      toastId: 'saved-changes',
    });
  }
  if (type === 'E') {
    toast(msg || errors.defaultError, {
      ...notifications.error,
      toastId: 'saved-changes',
    });
  }
};

export const notifications: {
  success: ToastOptions;
  error: ToastOptions;
  genericError: ToastContent;
} = {
  success: {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
  error: {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
  genericError: (
    <>
      <h3 style={{ fontSize: '16px', fontWeight: 600, margin: '10px 0 0 0' }}>
        We’re having some issues
      </h3>
      <p style={{ fontSize: '16px', fontWeight: 400, margin: '5px 0 10px 0' }}>
        Something went wrong. Please try again.
      </p>
    </>
  ),
};
