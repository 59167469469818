import styled from 'styled-components';

export const ComboWrapper = styled.div<{ width?: string; height?: string }>`
  display: block;
  direction: rtl;
  margin-right: 48px;
  max-width: 225px;
  ${({ width, height }) => {
    return `
    width: ${width ?? '100%'};
    height: ${height ?? '40px'};
  `;
  }};
`;

export const CustomSelect = styled.div<{
  icon: string;
  disabled?: boolean;
  height?: string;
  isInvalid?: boolean;
  isVerticalCentered?: boolean;
}>`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: normal;
  color: var(--color-primary);
  cursor: pointer;
  background: #fdfeff url(${({ icon }) => icon}) no-repeat right center;
  outline: none;
  padding-right: 22px;
  ${({ disabled, height, isVerticalCentered }) => {
    return `
    height: ${height ?? '40px'};
    opacity: ${disabled ? '0.6' : '1'};
    cursor: ${disabled ? 'initial' : 'pointer'};
    ${isVerticalCentered && 'justify-content: center;'}
    `;
  }};
`;

export const TitlePlaceHolder = styled.div<{ height?: string }>`
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  white-space: nowrap;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  text-align: right;
  opacity: 1;
  color: rgba(102, 102, 102, 1);
`;

export const SubTitlePlaceHolder = styled.div<{ height?: string }>`
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  text-align: right;
  opacity: 1;
  color: rgba(102, 102, 102, 1);
`;

export const Dropdown = styled.div<{ top: number }>`
  width: min-content;
  position: relative;
  overflow-x: hidden;
  min-width: 240px;
  max-width: 325px;
  height: fit-content;
  max-height: 220px;
  top: ${({ top }) => (top ? `${Math.trunc(top)}px` : '0')};
  z-index: 2;
  overflow-y: auto;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
  background-color: #fdfeff;
  border-radius: 12px;
`;

export const DropdownItem = styled.div`
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer;
  padding: 15px 25px;
  color: #666666;
  font-family: Lato;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  letter-spacing: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  direction: ltr;
  &:hover {
    background-color: var(--color-primary);
    color: #fdfeff;
  }
`;

export const DropdownOverlay = styled.div`
  z-index: 1;
  position: fixed;
  inset: 0;
`;
