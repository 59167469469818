import {
  BOARD_DESIGNATION_PLACEHOLDER,
  CIRCUIT_DESIGNATION_PLACEHOLDER,
  MAIN_SUPPLY,
} from 'constants/autoObservations.constants';

import { FieldType } from 'enum/FieldType.enum';
import {
  AutoObservations,
  DinamycTable,
  HandleAutoObservationsParams,
  ListBoardsFieldIds,
  Observation,
  ObservationComparationTable,
  ObservationSol,
  TabulatedTable,
} from 'interfaces/AutoObservations';
import { CertificateDataValuesTableRowProps } from 'interfaces/CertificateData';

import { getCircuitFieldIdInfo } from '../circuitsUtils';
import { getBoardInfo } from '../defaultValues';
import { hashCode, isNormalValueTextInputs } from '../helpers';

export const getCurrentStringValue = (
  fieldId: string,
  handleParams: HandleAutoObservationsParams,
  currentFieldsIds: string[] = []
): string => {
  const { templateFields, getValues } = handleParams;
  const convertedFieldId = getConvertedFieldId(fieldId, currentFieldsIds);
  const fieldTemplate = templateFields?.find((temp) =>
    temp.fieldId.includes(convertedFieldId)
  );

  if (!fieldTemplate) return '';

  //** DATA LOAD VALUE FOR --- TEXT COMPONENTS
  if (isNormalValueTextInputs(fieldTemplate.type)) {
    return getValues(convertedFieldId) ?? '';
  }

  //** DATA LOAD VALUE FOR --- 	SIGNATURE AREA COMPONENT
  if (FieldType.SignatureArea === fieldTemplate.type) {
    return getValues(convertedFieldId).value ?? '';
  }

  //** DATA LOAD VALUE FOR --- COMBO COMPONENT
  if ([FieldType.Combo, FieldType.ComboCircuits].includes(fieldTemplate.type)) {
    if (fieldTemplate.hasTextbox) {
      return getValues(convertedFieldId).value ?? '';
    } else {
      return getValues(convertedFieldId) ?? '';
    }
  }

  //** DATA LOAD VALUE FOR --- 	RADIOBUTTON, CHECKBOX COMPONENT
  if (
    [FieldType.Radiobutton, FieldType.Checkbox].includes(fieldTemplate.type)
  ) {
    return getValues(convertedFieldId).value ?? '';
  }

  console.info(
    'loaded data for autoObservation with type not mapped: ',
    fieldTemplate.type
  );

  return getValues(convertedFieldId) ?? '';
};

const getConvertedFieldId = (fieldId: string, currentFieldsIds: string[]) => {
  const isCircuitField = !fieldId.includes('field');

  if (isCircuitField) {
    return (
      currentFieldsIds.find((field) =>
        field.includes(`crt${hashCode(fieldId)}`)
      ) ?? fieldId
    );
  } else {
    return currentFieldsIds.find((field) => field.includes(fieldId)) ?? fieldId;
  }
};

export const getCurrentDynamicTableValue = (
  fieldId: string,
  handleParams: HandleAutoObservationsParams
): DinamycTable[] => {
  return (handleParams.getValues(fieldId) as DinamycTable[]) ?? [];
};

const findObservationFunction = (
  observation: Observation,
  location: string,
  circuitDesignation: string
) => {
  return (row: DinamycTable) => {
    const observationWords = getObservationsWords(
      observation.value,
      circuitDesignation
    );
    const hasAllWords = observationWords.every((word) =>
      row[1]?.value?.includes(word)
    );

    return (
      hasAllWords &&
      row[2].value === location &&
      row[3].value === observation.code
    );
  };
};

export const isObervationExists = (
  observation: Observation,
  location: string,
  circuitDesignation: string,
  valueTable: DinamycTable[] = []
) => {
  return valueTable.some(
    findObservationFunction(observation, location, circuitDesignation)
  );
};

export const addObservation = (
  observation: Observation,
  replacedValue: string,
  location: string,
  valueTable: DinamycTable[] = []
) => {
  const nextIndex = valueTable.length + 1;
  const lineNumber =
    `${nextIndex}`.length === 1 ? `0${nextIndex}` : `${nextIndex}`;

  valueTable.push({
    0: { index: 0, valueType: 'string', value: lineNumber },
    1: { index: 1, valueType: 'string', value: replacedValue },
    2: { index: 2, valueType: 'string', value: location },
    3: { index: 3, valueType: 'string', value: observation.code },
  });
};

export const updateObservation = (
  observation: Observation,
  replacedValue: string,
  location: string,
  circuitDesignation: string,
  valueTable: DinamycTable[] = []
) => {
  const rowIndex = valueTable.findIndex(
    findObservationFunction(observation, location, circuitDesignation)
  );

  if (rowIndex !== -1) {
    valueTable[rowIndex][1].value = replacedValue;
    valueTable[rowIndex][2].value = location;
  }
};

export const deleteObervation = (
  observation: Observation,
  location: string,
  circuitDesignation: string,
  valueTable: DinamycTable[] = []
): boolean => {
  const index = valueTable.findIndex(
    findObservationFunction(observation, location, circuitDesignation)
  );
  if (index !== -1) {
    valueTable.splice(index, 1);
    return true;
  }
  return false;
};

export const getCurrentFixedTableValue = (
  fieldId: string,
  handleParams: HandleAutoObservationsParams
): CertificateDataValuesTableRowProps[] => {
  return (
    (handleParams.getValues(fieldId) as CertificateDataValuesTableRowProps[]) ??
    []
  );
};

export const isObservationSolExists = (
  sol: ObservationSol,
  valueTable: CertificateDataValuesTableRowProps[] = []
) => {
  return valueTable.some(
    (table) =>
      table.columns[0].value === sol.value &&
      table.columns[2].value === sol.code
  );
};

export const addObservationSol = (
  sol: ObservationSol,
  valueTable: CertificateDataValuesTableRowProps[] = [],
  options?: { emptyCode?: boolean }
) => {
  valueTable.forEach((table) => {
    if (table.columns[0].value === sol.value) {
      table.columns[2].value = options?.emptyCode ? '' : sol.code;
    }
  });
};

export const getAandBfromCompatarionTable = (
  valueX: string,
  comparationTable: ObservationComparationTable,
  handleParams: HandleAutoObservationsParams,
  currentFieldsIds: string[] = []
) => {
  const data = { newValueA: '', newValueB: '' };
  const { fieldY, x, y } = comparationTable;

  const valueY = getCurrentStringValue(fieldY, handleParams, currentFieldsIds);
  const indexX = x.indexOf(valueY);

  if (indexX < 0 || indexX >= y.length) {
    return data;
  } else {
    data.newValueA = valueX;
    data.newValueB = y[indexX];
  }
  console.log(data);

  return data;
};

export const isBoardOrCircuit = (autoObservation: AutoObservations) => {
  return autoObservation.conditions.some(
    (condition) => condition.operationsTableId || condition.boardCircuitId
  );
};

export const getReplacedValue = (
  observation: Observation,
  handleParams: HandleAutoObservationsParams,
  currentFieldsIds: string[] = []
) => {
  const pattern = /%([^%]+)%/g;
  const matches = observation.value.match(pattern);
  let observationValue = `${observation.value}`;

  if (!matches?.length) return observation.value;

  matches?.forEach((match) => {
    const fieldId = getFieldIdFromString(match);
    const fieldValue = getCurrentStringValue(
      fieldId,
      handleParams,
      currentFieldsIds
    );

    observationValue = observationValue.replace(match, fieldValue);
  });

  return observationValue;
};

export const getObservationsWords = (
  value: string,
  circuitDesignation: string
): string[] => {
  const pattern = /%([^%]+)%/g;
  const matches = value.match(pattern);
  const observationWords = value.split(' ');

  if (!matches?.length) return observationWords;

  return matches
    .reduce((acc, match) => {
      if (BOARD_DESIGNATION_PLACEHOLDER === match) {
        acc = acc.replace(match, circuitDesignation);
      } else {
        acc = acc.replace(match, '');
      }
      return acc;
    }, value)
    .split(' ');
};

const getFieldIdFromString = (match: string) => {
  const fieldIdWithNoPorcentage = match.slice(1, -1); //Remove the %
  // TODO remove in future and apply this in template
  /* These ids were hammered because they follow the same
     implementation that we have on other platforms
     and do not have in the template
  */
  if (match === BOARD_DESIGNATION_PLACEHOLDER) {
    return 'field1630945276757';
  }
  if (match === CIRCUIT_DESIGNATION_PLACEHOLDER) {
    return 'Circuit Number';
  }

  return fieldIdWithNoPorcentage;
};

export const getLocationValue = (
  handleParams: HandleAutoObservationsParams,
  currentFieldsIds: string[] = []
) => {
  const locationFieldId = getFieldIdFromString(BOARD_DESIGNATION_PLACEHOLDER);

  const value = getCurrentStringValue(
    locationFieldId,
    handleParams,
    currentFieldsIds
  );
  return value || MAIN_SUPPLY;
};

export const getCircuitDesignationValue = (
  handleParams: HandleAutoObservationsParams,
  currentFieldsIds: string[] = []
) => {
  const fieldId = getFieldIdFromString(CIRCUIT_DESIGNATION_PLACEHOLDER);
  const value = getCurrentStringValue(fieldId, handleParams, currentFieldsIds);
  return value;
};

export const getBoardFieldIdsFromCurrentField = (
  fieldId: string,
  listBoardsFieldIds: ListBoardsFieldIds[] = []
): ListBoardsFieldIds[] => {
  if (fieldId.includes('board')) {
    const { boardIndex } = getBoardInfo(fieldId);
    const filteredBoardFields = listBoardsFieldIds.find(
      (board) => board.boardIndex === Number(boardIndex)
    );

    if (filteredBoardFields) {
      if (fieldId.includes('circuit')) {
        const { tableIndex, rowIndex } = getCircuitFieldIdInfo(fieldId);
        const circuitRows = filteredBoardFields.circuitRows.filter(
          (circuit) =>
            circuit.tableIndex === tableIndex && circuit.rowIndex === rowIndex
        );

        const boardWithFilteredCircuits: ListBoardsFieldIds = {
          boardIndex: filteredBoardFields.boardIndex,
          boardFieldIds: filteredBoardFields.boardFieldIds,
          circuitRows,
        };
        return [boardWithFilteredCircuits];
      } else {
        return [filteredBoardFields];
      }
    }
  }

  return listBoardsFieldIds;
};

export const getTabulatedValueY = (
  tabulatedValueY: TabulatedTable[],
  handleParams: HandleAutoObservationsParams,
  currentFieldsIds: string[]
) => {
  const getFieldValue = (fieldId: string) => {
    return getCurrentStringValue(fieldId, handleParams, currentFieldsIds);
  };

  return tabulatedValueY.reduce(
    (acc, { fieldId: tabulatedReqFieldId, values, yModifier }) => {
      const tabulatedReqFieldValue = getFieldValue(tabulatedReqFieldId);

      const value = values.find((value) => {
        if (tabulatedReqFieldValue) {
          return value.value === tabulatedReqFieldValue;
        }
        if (value.default) {
          return value.default === true;
        }
      });

      if (!value?.matrixTable?.length) return '';

      const matrixTable = value.matrixTable.find((table) =>
        getFieldValue(table.fieldId).includes(table.containsValue)
      );
      if (!matrixTable?.lookupTable?.length) return '';

      const lookupTable = matrixTable.lookupTable.find(
        (lookupTable) =>
          getFieldValue(lookupTable.fieldId) === lookupTable.value
      );

      if (lookupTable?.table) {
        const { fieldX, fieldY, matrix, x, y } = lookupTable.table;
        const xValue = getFieldValue(fieldX);
        const yValue = getFieldValue(fieldY);

        if (matrix && xValue && yValue && x && y) {
          const xIndex = x.indexOf(xValue);
          const yIndex = y.indexOf(yValue);

          if (xIndex !== -1 && yIndex !== -1) {
            if (yModifier) {
              const fieldModifierValue = getFieldValue(yModifier.fieldModifier); // Modifier value
              const valueA = Number(matrix[yIndex][xIndex]); // Table value

              if (yModifier.condition && yModifier.fieldValue) {
                if (
                  yModifier.condition === '==' &&
                  fieldModifierValue === yModifier.fieldValue
                ) {
                  const valueB = Number(yModifier.value);
                  acc = processValueWithOperator(
                    yModifier.operation,
                    valueA,
                    valueB
                  );
                }
              } else {
                const valueB = Number(fieldModifierValue);
                acc = processValueWithOperator(
                  yModifier.operation,
                  valueA,
                  valueB
                );
              }
            } else {
              acc = matrix[yIndex][xIndex];
            }
          }
        }
      }
      return acc;
    },
    ''
  );
};

export const processValueWithOperator = (
  operator: string,
  valueA: number,
  valueB: number
) => {
  switch (operator) {
    case '*': {
      return String(valueA * valueB);
    }
    case '+': {
      return String(valueA + valueB);
    }
    case '-': {
      return String(valueA - valueB);
    }
    case '/': {
      return String(valueA / valueB);
    }
    default:
      console.info('unknow operator: ', operator);
      return String(valueA);
  }
};
