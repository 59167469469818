import React, { FC, useState } from 'react';
import { DODDLE_VERSION } from 'constants/doddle.constants';
import { Link } from 'react-router-dom';

import { Button, Row } from 'react-bootstrap';

import ModalContactUs from 'components/modals/ModalContactUs';

interface AboutThisProps {
  selectedPage: string;
}

const AboutThis: FC<AboutThisProps> = ({ selectedPage }) => {
  const [showContactUs, setShowContactUs] = useState(false);
  return (
    <>
      {(selectedPage === 'about' || selectedPage === 'init') && (
        <Row>
          <div>
            <h2 className="section-title">About this web app</h2>
            <div className="section-subtitles w-75">
              Doddle for Web - version {DODDLE_VERSION} <br />
              <br /> © Arma Software {new Date().getFullYear()} - All rights
              reserved.
              <br /> All brands, logos and client information is proprietary of
              each own brand, and their usage by Arma Software or its clients on
              this digital application cannot constitute any means of trademark
              violation.
            </div>
            <div className="w-75 pt-5 pb-4">
              <Link
                to={'https://getdoddle.app/license-and-terms.html'}
                target="_blank"
                type="button"
                className="mr-5 settings-about-policy-button"
              >
                Terms & Conditions
              </Link>
              <Link
                to={'https://getdoddle.app/privacy-policy.html'}
                target="_blank"
                type="button"
                className="ml-1 settings-about-policy-button"
              >
                Privacy Policy
              </Link>
            </div>
            <hr />
            <div className="mt-4 pt-2 d-flex flex-column align-items-start">
              <span className="section-subtitles font-weight-bold text-dark">
                Contact us
              </span>
              <span className="section-subtitles mt-2">
                Do you encounter any issues or wish to provide feedback? Please
                do not hesitate to reach out to us.
              </span>
              <Button
                style={{ minWidth: 0 }}
                className="button-primary mt-3 "
                onClick={() => {
                  setShowContactUs(true);
                }}
              >
                Contact us
              </Button>
              {showContactUs && (
                <ModalContactUs
                  onClose={() => {
                    setShowContactUs(false);
                  }}
                />
              )}
            </div>
          </div>
        </Row>
      )}
      <Row className="my-5" />
      <Row className="my-5" />
    </>
  );
};

export default AboutThis;
