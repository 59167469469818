import { FieldValues, UseFormGetValues } from 'react-hook-form';

import { AffectAction } from 'enum/AffectAction.enum';
import { CertData } from 'interfaces/CertificateData';
import {
  AffectedFields,
  CertificateOptions,
  CertTemplate,
  CertTemplateField,
  FieldAffects,
  HandleAffects,
  HandleAffectsParams,
} from 'interfaces/CertificateTemplate';

import { getBoards, lookupOptionsTable } from './affects/functions';
import { changeOptions } from './affects/functions/changeOptions';

export const getFieldsAffectsAndOptions = (temp: CertTemplate) => {
  // create an empty array to hold the fields in the certificate template
  let fieldsMapper: CertTemplateField[] = [];

  // create an empty object to hold fields in the certificate template that have an effect on other fields
  const fieldsWithAffects: { [key: string]: CertTemplateField } = {};

  // Affected fields behaviors
  const affectedFields: AffectedFields = {};

  // create an empty object to hold options for the certificate
  const certificateOptions: CertificateOptions = {};
  const fieldsPerPage: { [fieldId: string]: number | string } = {};

  /**
	├─ loop over the certificate template pages, sections, rows, and fields:
	│   ├─ add each field to the `fieldsMapper` array
	│   ├─ if the field has options, add them to the `certificateOptions` object
	│   └─ if the field has an effect on other fields, add it to the `fieldsWithAffects` object
	*/
  for (const page of temp.pages) {
    for (const section of page.sections) {
      for (const row of section.rows) {
        // fields
        fieldsMapper = [...fieldsMapper, ...row.fields];
        for (const field of row.fields) {
          fieldsPerPage[field.fieldId] = page.pageOrder;
          // field options
          if (field.options) {
            certificateOptions[field.fieldId] = field.options;
          }
          // field modalOptions
          if (field.modalOptions) {
            certificateOptions[field.fieldId] = field.modalOptions;
          }
          // field affects
          if (field.affects) {
            fieldsWithAffects[field.fieldId] = field;
          }
          // For field behavior
          if (field.disabled) {
            affectedFields[field.fieldId] = {
              disabled: field.disabled,
            };
          }
          // For table estructure
          if (field.tableStructure) {
            affectedFields[field.fieldId] = {
              tableStructure: field.tableStructure,
            };
          }
        }
      }
    }
  }

  return {
    fieldsMapper,
    fieldsWithAffects,
    certificateOptions,
    affectedFields,
    fieldsPerPage,
  };
};

export const updateInitialLoadedValuesWithCertData = (
  fieldsWithAffects: { [key: string]: CertTemplateField },
  certificateOptions: CertificateOptions,
  fieldsMapper: CertTemplateField[],
  affectedFields: AffectedFields,
  initEditFormValues: { [key: string]: unknown },
  handleAffects: HandleAffects,
  certData: CertData
): { [key: string]: unknown } => {
  // in order to have dynamic validations, in the certificates (eg: conditional required)
  // is required to have an aditional field inside the form for they comunicate.
  // this is a helper to handle these dynamic fake fields in the form
  const affectedFieldValidationsHelper: { [key: string]: unknown } = {};

  // loop over the `fieldsWithAffects` object and apply their affects on the `certificateOptions` object using the `handleAffects`
  for (const [key, value] of Object.entries(fieldsWithAffects)) {
    // this condition represents a default value of a field that has an Affect on other fields
    if (initEditFormValues[key] !== '') {
      const { type, affects = [] } = value;

      certificateOptions = handleAffects({
        fieldId: key,
        type,
        affects,
        newVal: initEditFormValues[key],
        defaultValues: initEditFormValues,
        templateFields: fieldsMapper,
        certOptions: certificateOptions,
        certData,
        affectedFields,
      });
    }
  }

  // this "field0000options" is used as an helper field in the yup validation to handle required values when options exists
  for (const value of Object.values(fieldsWithAffects)) {
    if (value.affects) {
      for (const aft of value.affects) {
        if (certificateOptions && aft.action === AffectAction.CHANGE_OPTIONS) {
          affectedFieldValidationsHelper[`${aft.field}options`] =
            certificateOptions[aft.field] || [];
        }
      }
    }
  }

  return affectedFieldValidationsHelper;
};

export const loadOptionsFromRequiredValues = (
  fieldsMapper: CertTemplateField[],
  certOptions: CertificateOptions,
  defaultValues: {
    [key: string]: unknown;
  },
  getValues?: UseFormGetValues<FieldValues>
) => {
  fieldsMapper.forEach(({ fieldId, requiredValues }) => {
    if (!requiredValues) return;

    changeOptions(fieldId, requiredValues, certOptions, defaultValues);

    getBoards(fieldId, requiredValues, certOptions, defaultValues);

    lookupOptionsTable(
      requiredValues,
      { field: fieldId } as FieldAffects,
      {
        defaultValues,
        certOptions,
        getValues,
      } as HandleAffectsParams
    );
  });
};
