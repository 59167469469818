import { FC } from 'react';

import { Row } from 'react-bootstrap';

import Carousel, { CarouselProps } from 'components/Carousel';

interface CarouselSectionProps extends CarouselProps {
  title: string;
  subTitle?: string;
  noDivider?: boolean;
}

const CarouselSection: FC<CarouselSectionProps> = ({
  title,
  subTitle,
  cardSize,
  payload,
  noDivider,
}) => {
  return (
    <>
      <Row>
        <div>
          <h2 className="section-title">{title}</h2>
          <h3 className="section-subtitles mb-1">{subTitle}</h3>
        </div>
      </Row>
      <Row>
        <Carousel cardSize={cardSize} payload={payload} />
      </Row>
      {!noDivider && (
        <Row className="my-4">
          <hr />
        </Row>
      )}
    </>
  );
};

CarouselSection.defaultProps = {
  subTitle: undefined,
  noDivider: false,
};

export default CarouselSection;
