import { AffectAction } from 'enum/AffectAction.enum';
import { FieldType } from 'enum/FieldType.enum';
import { CertificateDataValueProps } from 'interfaces/CertificateData';
import { HandleAffectsParams } from 'interfaces/CertificateTemplate';

import {
  applyEmptyTableFixedInputValues,
  isDynamicTableComponents,
  isNormalValueTextInputs,
  parseToFormCheckboxInputValues,
  parseToFormComboInputValues,
  parseToFormRadioButtonInputValues,
  parseToFormSignatureAreaInputValues,
  parseToFormTableDynamicInputValues,
  parseToFormTextInputValues,
} from '../helpers';

export const resetAffectedFields = (
  handleAffectsParams: HandleAffectsParams
) => {
  const { affects, setValue, templateFields } = handleAffectsParams;
  const uniqueAffectsFields = [
    ...new Set(
      affects
        .filter(
          (affect) => affect.action !== AffectAction.TRIGGER_REQUIRED_VALUES
        )
        .map(({ field }) => field)
    ),
  ];

  if (!setValue || !templateFields) return;

  uniqueAffectsFields.forEach((field) => {
    const { type, hasTextbox, valueTable } =
      templateFields.find(({ fieldId }) => fieldId === field) ?? {};

    if (!type) return;

    const newDataValueProps: CertificateDataValueProps = {
      key: field,
      valueType: type,
      valueTextbox: '',
      value: '',
    };

    if (isNormalValueTextInputs(type)) {
      setValue(field, parseToFormTextInputValues(newDataValueProps));
      return;
    }

    if (type === FieldType.SignatureArea) {
      setValue(field, parseToFormSignatureAreaInputValues(newDataValueProps));
      return;
    }

    if (type === FieldType.Combo) {
      setValue(
        field,
        parseToFormComboInputValues(newDataValueProps, hasTextbox)
      );
      return;
    }

    if (type === FieldType.Radiobutton) {
      setValue(field, parseToFormRadioButtonInputValues(newDataValueProps));
      return;
    }

    if (type === FieldType.Checkbox) {
      setValue(field, parseToFormCheckboxInputValues(newDataValueProps));
      return;
    }

    if (isDynamicTableComponents(type)) {
      const newTable = valueTable?.map((table) => {
        table.columns[2] = {
          index: 2,
          value: '',
          valueType: 'string',
        };
        return table;
      });
      setValue(field, parseToFormTableDynamicInputValues(newTable));
    }

    if (type === FieldType.TableFixed) {
      setValue(field, applyEmptyTableFixedInputValues(valueTable));
    }
  });
};
