import { AffectAction } from 'enum/AffectAction.enum';
import { CertificateState } from 'enum/certificateDataState.enum';
import {
  CertificateOptions,
  HandleAffectsParams,
  RequiredValuesProps,
} from 'interfaces/CertificateTemplate';

import { filterAffectsByAction } from '../../helpers';
import { changeRequireValue, lookupOptionsTable } from '../functions';
import { lookupTable } from '../functions/lookupTable';

export const triggerRequiredValuesAction = (
  handleAffectsParams: HandleAffectsParams
): CertificateOptions => {
  const { templateFields, affects, certData, getValues } = handleAffectsParams;

  // Prevent update form values after issued.
  if (certData?.certificateState === CertificateState.ISSUED || !getValues) {
    return {};
  }

  const filtredAffects = filterAffectsByAction(
    affects,
    AffectAction.TRIGGER_REQUIRED_VALUES
  );

  if (!filtredAffects?.length) return {};

  const requiredValuesOptions = filtredAffects.reduce((options, affect) => {
    const field = templateFields?.find(
      (field) => field.fieldId === affect.field
    );
    let requiredValues: RequiredValuesProps[] = [];
    if (!field) {
      templateFields?.forEach((field) => {
        if (field.fieldId.includes(affect.field)) {
          requiredValues = field.requiredValues ?? [];
        }
      });
    } else {
      requiredValues =
        templateFields?.flatMap(
          (field) =>
            (field.fieldId === affect.field && field.requiredValues) || []
        ) ?? [];
    }

    // Find the "requiredValues" from the field indicated on affect
    if (!requiredValues || requiredValues.length === 0) return options;

    // Process each function of requiredValues separately and return your certOptions
    changeRequireValue(requiredValues, affect, handleAffectsParams);
    lookupTable(requiredValues, affect, handleAffectsParams);
    return {
      ...options,
      ...lookupOptionsTable(requiredValues, affect, handleAffectsParams),
      // Add here others process functions according the  @RequiredValuesFunction type
    };
  }, {} as CertificateOptions);

  return requiredValuesOptions || {};
};
