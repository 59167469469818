import { FC, useMemo } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';

import { FormControlProps } from 'react-bootstrap';

import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';
import { FieldAffects } from 'interfaces/CertificateTemplate';

import { FormControl, FormGroupWithErrorSpace } from 'components/Form/styles';

import { getError } from '..';
import { CommonProps } from '../Types';

interface TextFieldProps extends FormControlProps, CommonProps {
  placeholder?: string;
  maxLength?: number;
  decimal?: boolean;
  required?: boolean;
  onlyInput?: boolean;
  affects?: FieldAffects[];
  hasError?: unknown;
  additionalOnBlur?: (text: string) => void;
  [x: string]: unknown;
}

const TextFieldCircuitTable: FC<TextFieldProps> = ({
  xs,
  fieldId,
  type,
  decimal,
  disabled,
  placeholder,
  maxLength,
  onlyInput,
  affects,
  isCertificate,
  value,
  hasError,
  additionalOnBlur,
  autoObservations,
  ...rest
}) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const error = getError(fieldId, errors) || hasError;
  const { autoSave, affectedFields, handleAffects, handleAutoObservations } =
    useCerts();

  const disabledField = useMemo(() => {
    return affectedFields && affectedFields[fieldId]
      ? affectedFields[fieldId].disabled
      : disabled;
  }, [affectedFields, disabled]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const removibles = ['e', 'E', '+', '-'];
    if (!decimal) {
      removibles.push(...[',', '.']);
    }

    return (
      type === 'number' &&
      removibles.includes(event.key) &&
      event.preventDefault()
    );
  };

  const handleSave = (newValue: string) => {
    if (isCertificate) {
      if (affects) {
        handleAffects({
          fieldId,
          type: FieldType.TextboxCircuitTable,
          affects,
          newVal: newValue,
          getValues,
          setValue,
        });
      }
      if (autoObservations) {
        handleAutoObservations({
          fieldId,
          autoObservations,
          getValues,
          setValue,
        });
      }
      autoSave({
        type: FieldType.TextboxCircuitTable,
        fieldId,
        value: newValue,
        getValues,
        setValue,
      });
    }
  };

  const handleOnBlur = (
    e: React.ChangeEvent<HTMLSelectElement>,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    const newValue = e.target.value;
    field.onBlur();
    if (additionalOnBlur) {
      additionalOnBlur(newValue);
    }
    handleSave(newValue);
  };

  const maxLengthProps = {
    ...(maxLength && {
      maxLength,
      as: 'textarea',
      height: '100px',
      error: Boolean(error),
    }),
  };

  if (onlyInput) {
    return (
      <Controller
        name={fieldId}
        control={control}
        render={({ field }) => {
          const isHifen = field.value === '-' || value === '-';
          return (
            <FormControl
              {...(!isHifen && { onKeyDown: handleKeyDown })}
              {...field}
              value={value ?? field.value}
              {...maxLengthProps}
              type={isHifen ? 'text' : type}
              disabled={disabledField}
              isInvalid={Boolean(error)}
              placeholder={placeholder}
              onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                handleOnBlur(e, field);
              }}
              {...rest}
            />
          );
        }}
      />
    );
  }

  return (
    <FormGroupWithErrorSpace xs={xs} {...(maxLength && { height: '144px' })}>
      {fieldId === 'null' ? (
        <FormControl
          value={value}
          {...maxLengthProps}
          disabled={disabledField}
          isInvalid={Boolean(error)}
          placeholder={placeholder}
          {...rest}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Controller
          name={fieldId}
          control={control}
          render={({ field }) => {
            const isHifen = field.value === '-' || value === '-';
            return (
              <FormControl
                {...(!isHifen && { onKeyDown: handleKeyDown })}
                {...field}
                value={value ?? field.value}
                {...maxLengthProps}
                type={isHifen ? 'text' : type}
                disabled={disabledField}
                isInvalid={Boolean(error)}
                placeholder={placeholder}
                onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  handleOnBlur(e, field);
                }}
                {...rest}
              />
            );
          }}
        />
      )}
    </FormGroupWithErrorSpace>
  );
};

TextFieldCircuitTable.defaultProps = {
  xs: 12,
};

export { TextFieldCircuitTable };
