import styled from 'styled-components';

export const InfoCardContainer = styled.div<{ icon: string }>`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 100%;
  max-width: 668px;
  height: 164px;
  padding: 38px 23px 35px 23px;
  border-radius: 10px;
  border: 1px solid rgba(226, 241, 239, 1);
  opacity: 1;
  background: rgba(243, 249, 248, 1) url(${({ icon }) => icon}) no-repeat right
    center;
  @media (max-width: 992px) {
    align-items: center;
    padding: 25px 23px 35px 23px;
  }
`;

export const InfoCardTitle = styled.div`
  display: flex;
  opacity: 1;
  color: rgba(74, 74, 74, 1);
  font-family: lato;
  font-size: 52px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.58px;
  line-height: 0;
  text-align: left;
  align-items: center;
`;

export const InfoCardIcon = styled.img`
  margin-right: 16px;
`;

export const InfoCardDescription = styled.div`
  display: flex;
  opacity: 1;
  color: rgba(102, 102, 102, 1);
  font-family: lato;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  line-height: normal;
  text-align: left;
  margin-top: 25px;
  @media (max-width: 992px) {
    text-align: center;
  }
`;
