import { FC } from 'react';

import * as S from './styles';

interface PresetModalCardInterface {
  onClick: (() => void) | undefined;
  certificateType: string;
  presetName: string;
  highlight: boolean | undefined;
}
const PresetModalCard: FC<PresetModalCardInterface> = ({
  onClick,
  certificateType,
  presetName,
  highlight,
}) => {
  return (
    <S.PresetModalCard highlightCard={highlight} onClick={onClick}>
      <S.Container justifyContent="center">
        <S.CertificateType>{certificateType}</S.CertificateType>
      </S.Container>
      <S.PresetName>{presetName}</S.PresetName>
    </S.PresetModalCard>
  );
};

export default PresetModalCard;
