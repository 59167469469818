import { AffectFunction } from 'enum/AffectFunction.enum';
import { FieldType } from 'enum/FieldType.enum';
import { CircuitRow, ListBoardsFieldIds } from 'interfaces/AutoObservations';
import {
  CertData,
  CertificateDataBoardsValueProps,
  CertificateDataBoardsValuesProps,
  CertificateDataValueProps,
} from 'interfaces/CertificateData';
import {
  AffectedFields,
  CertTableStruct,
  CertTemplate,
  CertTemplateField,
  CertTemplateSection,
} from 'interfaces/CertificateTemplate';
import {
  applyEmptyTableFixedInputValues,
  isDynamicTableComponents,
  isFormLabelComponents,
  isNormalValueTextInputs,
  parseToFormCheckboxInputValues,
  parseToFormComboInputValues,
  parseToFormCustomerDataInputValues,
  parseToFormRadioButtonInputValues,
  parseToFormSignatureAreaInputValues,
  parseToFormTableDynamicInputValues,
  parseToFormTableFixedValues,
  parseToFormTextInputValues,
} from 'utils/certificates/helpers';

import { changeTableStructureAction } from './affects/actions';
import {
  gerCircuitFieldTypeMapped,
  parseCircuitFieldAffects,
  parseCircuitRequiredValues,
  parseCircuitValidations,
} from './circuitsUtils';
import { getFieldsAffectsAndOptions } from './loadFormValues';

export const fieldDivider = '*';
export const circuitDivider = `${fieldDivider}circuit${fieldDivider}`;

export const getBoardName = (fieldId: string, index: number, boardId: string) =>
  `${fieldId}${fieldDivider}board${fieldDivider}${index}${fieldDivider}${boardId}`;

export const getBoardInfo = (
  boardName: string
): {
  boardGridId: string;
  boardIndex: string;
  boardId: string;
  fieldId: string;
} => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [boardGridId, _, index, boardId, fieldId] =
    boardName.split(fieldDivider);
  return {
    boardGridId,
    boardIndex: index,
    boardId,
    fieldId,
  };
};
export const findBoardGridCardValues = (
  nightmareObj: any,
  valueBoards?: CertificateDataBoardsValueProps[]
) => {
  const uniqueValues: any = {};
  let boardItem = null;

  // helper function to search for cardValues in nested objects/arrays
  function searchForObject(object: any) {
    // if the object has a cardValue property and it's in the cardValueList, add it to the uniqueValues set
    if (object?.cardValue) {
      const cardItem = {
        fieldId: object.key || object.fieldId || null,
        label: object.label || null,
        value: object.value || null,
        valueType: object.valueType || null,
        inputType: object.inputType || null,
        valueOperationsTable: object.valueOperationsTable || null,
        cardValue: object.cardValue || null,
        cardValueOrder: object.cardValueOrder || null,
        valueTextbox: object.valueTextbox || null,
        type: object.type || null,
      };
      uniqueValues[object.key || object.fieldId] = cardItem;

      if (valueBoards && object.valueType === FieldType.ComboBoards) {
        const boardSelected: CertificateDataBoardsValueProps | undefined =
          valueBoards.find((e) => e.id === cardItem.value);
        if (boardSelected) {
          boardItem =
            boardSelected.values.find(
              (field) => field.cardValue === 'board-title'
            ) ?? {};
        }
      }

      // check if the object is an array
    } else if (Array.isArray(object)) {
      object.forEach((item) => {
        searchForObject(item); // recursively search each item in the array
      });
    } else if (typeof object === 'object' && object !== null) {
      // if the object is an object (not an array), recursively search its properties
      Object.values(object).forEach((value) => {
        searchForObject(value); // recursively search each property value
      });
    }
  }
  // start searching the nightmareObj for cardValues
  searchForObject(nightmareObj);

  return { uniqueValues, boardItem };
};

const getDefaultValuesFromCertDataForBoards = (
  certTemplateFields: CertTemplateField[],
  certData: CertData,
  temp: CertTemplate,
  initEditFormValues: { [key: string]: unknown }
): {
  initEditFormValues: { [key: string]: unknown };
  newTemplate: CertTemplate;
  defaultValuesForNewBoard: CertificateDataBoardsValuesProps[];
  listBoardsFieldIds: ListBoardsFieldIds[];
} => {
  const defaultValuesForNewBoard: CertificateDataBoardsValuesProps[] = [];
  const listBoardsFieldIds: ListBoardsFieldIds[] = [];

  //******* TEMPLATE DEFAULT VALUES **********  */
  // get default values from template
  certTemplateFields.forEach(({ type, childFields }) => {
    if (type === FieldType.BoardGrid && childFields) {
      //**
      //** GENERATE NECESSARY HELPERS WITH THE BOARD_GRID DEFAULT VALUES */
      //**
      const { fieldsMapper, fieldsWithAffects: fieldsWithAffectsBoards } =
        getFieldsAffectsAndOptions({
          pages: [{ sections: childFields }],
        } as unknown as CertTemplate);

      const { uniqueValues: boardFieldsForCards } =
        findBoardGridCardValues(childFields);

      for (const field of fieldsMapper) {
        if (
          isFormLabelComponents(field.type) ||
          [FieldType.ComboCircuitTable, FieldType.TextboxCircuitTable].includes(
            field.type
          )
        ) {
          continue;
        }

        defaultValuesForNewBoard.push({
          key: field.fieldId,
          value: '',
          valueType: field.type,
          valueTextbox: '',
          cardValue: field.cardValue,
          cardValueOrder: field.cardValueOrder ?? 0,
          valueOperationsTable:
            field.type === FieldType.OperationsTable ? [] : undefined,
          field,
        });
      }

      //******* BOARD_GRID CERTIFICATE DATA VALUES **********  */
      // get values from CertData
      certData.values.forEach((field) => {
        const { key, valueType, valueBoards } = field;

        if (valueType === FieldType.BoardGrid) {
          /**
           * This code documentation describes changes made to the handling of boards in a system.
           * 	The goal is to make it easier to validate and access data.
           *
           * 1º The first step is to use the field1234556767 field of the board-grid component to hold
           * 	information for the page 6 cards. This field will only contain the necessary
           * 	information for creating the cards.
           *
           * 2º Next, for each board, a form entry is created with fields named
           * 	`field1234556767 * board * 0 * uuid`
           * 	The * character is used as a divider between each component.
           * 	This syntax allows access to the main boardGrid fieldId and explicitly identifies the
           * 	board position in the array and its board ID.
           *
           * Overall, these changes are designed to make it easier to manage and validate board data.
           */

          //******* INITIAL VALUE FOR BOARD-GRID CARD INFO FOR PAGE 6
          initEditFormValues[key] = [];

          valueBoards?.map((board, index) => {
            const operationsTableTemplateFields: CertTemplateField[] = [];
            const fieldsWithTableStructure: {
              [key: string]: CertTableStruct[];
            } = {};

            //******* INITIAL VALUE FOR THE BOARD VALUES, USED IN AUTOSAVE
            initEditFormValues[board.id] = [];

            //******* BOARD UNIQUE NAME
            const boardName = getBoardName(key, index, board.id);

            //******* BOARD-GRID CARD INFO FOR PAGE 6
            const { uniqueValues: fields } = findBoardGridCardValues(
              {
                ...board,
                values: board.values.map((val) => ({
                  ...boardFieldsForCards[val.key],
                  ...val,
                })),
              },
              valueBoards
            );

            (initEditFormValues[key] as any).push({
              id: board.id,
              isComplete: board.isComplete || false,
              fields: Object.values(fields),
            });

            //******* GET DEFAULT VALUES FROM TEMPLATE
            fieldsMapper.forEach(({ fieldId, ...field }) => {
              handleDefaultValueFromTemplate(
                { fieldId: boardName + fieldDivider + fieldId, ...field },
                initEditFormValues
              );
            });

            // **** init object structure for to use on future on context
            const boardFieldIds: ListBoardsFieldIds = {
              boardIndex: index,
              boardFieldIds: [],
              circuitRows: [],
            };

            //******* GET VALUES CERTIFICATE DATA
            board.values.forEach(({ key: boardValueKey, ...field }) => {
              //*** add board fields ids
              boardFieldIds.boardFieldIds.push(
                boardName + fieldDivider + boardValueKey
              );

              handleDefaultValueFromCertData(
                {
                  key: boardName + fieldDivider + boardValueKey,
                  ...field,
                } as CertificateDataValueProps,
                initEditFormValues,
                fieldsMapper.map(({ fieldId, ...field }) => ({
                  fieldId: boardName + fieldDivider + fieldId,
                  ...field,
                }))
              );
              (initEditFormValues[board.id] as any).push({
                key: boardValueKey,
                ...field,
              });
            });

            //******* LOAD COMMENTS VALUES FROM EACH BOARD
            if (board.comments) {
              initEditFormValues[boardName + 'comments'] = board.comments;
            }

            // CIRCUITS OPERATIONS TABLE
            board.values.forEach((boardField) => {
              if (
                boardField.valueType === FieldType.OperationsTable &&
                boardField.valueOperationsTable?.length
              ) {
                // GET MAIN SWITCH TEMPLATE FIELD FROM CURRENT BOARD
                const mainSwitchField = Object.values(
                  fieldsWithAffectsBoards
                ).find((fieldWithAffect) =>
                  fieldWithAffect.affects?.some(
                    (affect) => affect.field === boardField.key
                  )
                );

                // GET MAIN SWITCH BOARD VALUE FROM CURRENT BOARD
                const mainSwitchValueProps = board.values.find(
                  (value) => value.key === mainSwitchField?.fieldId
                );

                if (!mainSwitchField || !mainSwitchValueProps) return;

                // GET TEMPLATE STRUCTURE ACCORDING MAIN SWITCH BOARD VALUE AND FIELD TEMPLATE
                const { affects = [], type } = mainSwitchField;
                const { value: newVal } = mainSwitchValueProps;

                // NOW, RUN AFFECT ACTION ON LOAD CERTIFICATE DATA TO GET TABLE STRUCTURE
                const affectedFields: AffectedFields = {};
                changeTableStructureAction({
                  fieldId: boardField.key,
                  newVal,
                  type,
                  affects,
                  affectedFields,
                });

                const { mappedFields = {} } =
                  affectedFields[boardField.key + '*struct'];
                const { tableStructure = [] } = affectedFields[boardField.key];

                const circuitName = `${boardField.key}${circuitDivider}`;

                //******* INITIAL VALUE FOR OPERATIONSTABLE
                initEditFormValues[
                  `${boardName}${fieldDivider}${boardField.key}`
                ] = boardField.valueOperationsTable;

                initEditFormValues[
                  `${boardName}${fieldDivider}${boardField.key}*struct`
                ] = {
                  mappedFields,
                };
                // TO UPDATE TABLE STRUCTURE ON TEMPLATE FIELD
                fieldsWithTableStructure[boardField.key] = tableStructure;

                /**
                 * * CREATE CIRCUITS TEMPLATESFIELDS FROM OPERATIONS TABLE VALUES
                 * * AND LOAD VALUES INTO initEditFormValues
                 */
                boardField.valueOperationsTable.forEach((table) => {
                  const rows =
                    table.merged && table.mergedRow
                      ? [table.mergedRow]
                      : table.rows;

                  rows.forEach((row) => {
                    //**** init circuitFieldIds array object for this rowIndex
                    const circuitRow: CircuitRow = {
                      tableIndex: table.index,
                      rowIndex: row.index,
                      circuitFieldIds: [],
                    };

                    Object.entries(mappedFields).forEach(([key, column]) => {
                      const {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        keyColumn,
                        columnName,
                        columnType,
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        columnSize,
                        affects,
                        requiredValues,
                        validations,
                        ...struct
                      } = column;

                      const columnIndexesName = `${fieldDivider}${table.index}${fieldDivider}${row.index}${fieldDivider}${column.index}`;

                      const mappadField: CertTemplateField = {
                        ...struct,
                        type: gerCircuitFieldTypeMapped(columnType),
                        label: columnName,
                        fieldId: `${circuitName}${key}${columnIndexesName}`,
                        affects: parseCircuitFieldAffects(
                          mappedFields,
                          circuitName,
                          table.index,
                          row.index,
                          affects
                        ),
                        validations: parseCircuitValidations(
                          mappedFields,
                          `${boardName}${fieldDivider}${circuitName}`,
                          table.index,
                          row.index,
                          validations
                        ),
                        requiredValues: parseCircuitRequiredValues(
                          mappedFields,
                          `${boardName}${fieldDivider}${circuitName}`,
                          table.index,
                          row.index,
                          requiredValues
                        ),
                      };

                      //*** add circuit fields ids
                      circuitRow.circuitFieldIds.push(
                        `${boardName}${fieldDivider}${circuitName}${key}${columnIndexesName}`
                      );

                      // ADD FIELDS WITHIN certTemplateFields
                      operationsTableTemplateFields.push(mappadField);

                      // SET DATA TO DEFAUTLVALUES
                      handleDefaultValueFromCertData(
                        {
                          ...row.columns.find(
                            (col) => col.index == column.index
                          ),
                          key: `${boardName}${fieldDivider}${mappadField.fieldId}`,
                          valueType: mappadField.type,
                        } as CertificateDataValueProps,
                        initEditFormValues,
                        operationsTableTemplateFields
                      );
                    });

                    // *** add circuit row
                    boardFieldIds.circuitRows.push(circuitRow);
                  });
                });
              }
            });

            //*** add board fields ids into a list */
            listBoardsFieldIds.push(boardFieldIds);

            //******* CREATE DUMMY PAGE FOR CURRENT BOARD
            const dummyTemplatePageForBoard = createDummyPageForBoard(
              boardName,
              childFields,
              operationsTableTemplateFields,
              fieldsWithTableStructure
            );
            temp.pages.push(dummyTemplatePageForBoard);
          });

          valueBoards?.map(({ values, id }) => {
            const boardTitleId = Object.entries(boardFieldsForCards).reduce(
              (acc: string, [boardField, boardValue]) => {
                if ((boardValue as any).cardValue === 'board-title') {
                  acc = boardField;
                }
                return acc;
              },
              ''
            );

            const title = values.find((value) => value.key === boardTitleId);
            initEditFormValues[id + '*name'] = title?.value ?? '';
          });
        }
      });
    }
  });

  return {
    //** BOARD FIELDS TRANSFORMED INTO LEVEL 1 FIELDS WITH THE STRING JOIN */
    initEditFormValues,
    //** TEMPLATE WITH THE DUMMY PAGES ADDED */
    newTemplate: temp,
    defaultValuesForNewBoard,
    listBoardsFieldIds,
  };
};

export const createDummyPageForBoard = (
  boardName: string,
  childFields: CertTemplateSection[],
  operationsTableTemplateFields: CertTemplateField[],
  fieldsWithTableStructure: { [key: string]: CertTableStruct[] }
) => {
  //******* CHANGE THE FIELD ID TO INCLUDE THE BOARD NAME IN THE DUMMY PAGE
  const currentFieldsForPage = childFields.map((section) => {
    if (section.sectionOrder === 2) {
      section.rows = section.rows.filter((row) => row.order !== 999);
      section.rows.push({
        order: 999,
        fields: operationsTableTemplateFields,
      });
    }
    return {
      ...section,
      rows: section.rows.map((row) => ({
        ...row,
        fields: row.fields.map(({ fieldId, ...field }) => {
          if (field.tableStructure && fieldsWithTableStructure[fieldId]) {
            field.tableStructure.push(...fieldsWithTableStructure[fieldId]);
          }
          return {
            ...field,
            fieldId: boardName + fieldDivider + fieldId,
            affects:
              field.affects?.map((affect) => ({
                ...affect,
                field: boardName + fieldDivider + affect.field,
              })) ?? undefined,
            requiredValues: field.requiredValues?.map((requiredValue) => {
              if (
                requiredValue.function === AffectFunction.CHANGE_REQUIRED_VALUE
              ) {
                return {
                  ...requiredValue,
                  fieldId: boardName + fieldDivider + requiredValue.fieldId,
                };
              }
              return requiredValue;
            }),
          };
        }),
      })),
    };
  });

  //******* CREATE DUMMY PAGE FOR CURRENT BOARD
  return {
    isPreview: false,
    pageName: '',
    pageOrder: boardName,
    sections: currentFieldsForPage,
  };
};

const handleDefaultValueFromTemplate = (
  {
    type,
    defaultValue,
    fieldId,
    valueTable,
    hasTextbox,
    tableStructure,
  }: CertTemplateField,
  defaultValues: { [key: string]: unknown }
) => {
  const newDataValueProps: CertificateDataValueProps = {
    key: fieldId,
    valueType: type,
    valueTextbox: '',
    value: defaultValue,
  };

  //** TEMPLATE LABELS COMPONENTS
  if (isFormLabelComponents(type)) return defaultValues;

  //** TEMPLATE LOAD DEFAULT VALUE FOR --- 	TEXT COMPONENTS
  if (isNormalValueTextInputs(type)) {
    defaultValues[fieldId] = parseToFormTextInputValues(newDataValueProps);
    return defaultValues;
  }

  //** TEMPLATE LOAD DEFAULT VALUE FOR --- 	SIGNATURE AREA COMPONENTS
  if (type === FieldType.SignatureArea) {
    defaultValues[fieldId] =
      parseToFormSignatureAreaInputValues(newDataValueProps);
    return defaultValues;
  }

  //** TEMPLATE LOAD DEFAULT VALUE FOR --- 	COMBO COMPONENT
  if (type === FieldType.Combo) {
    defaultValues[fieldId] = parseToFormComboInputValues(
      newDataValueProps,
      hasTextbox
    );
    return defaultValues;
  }

  //** TEMPLATE LOAD DEFAULT VALUE FOR --- 	RADIOBUTTON COMPONENT
  if (type === FieldType.Radiobutton) {
    defaultValues[fieldId] =
      parseToFormRadioButtonInputValues(newDataValueProps);
    return defaultValues;
  }

  //** TEMPLATE LOAD DEFAULT VALUE FOR --- 	CHECKBOX COMPONENT
  if (type === FieldType.Checkbox) {
    defaultValues[fieldId] = parseToFormCheckboxInputValues(newDataValueProps);
    return defaultValues;
  }

  //** TEMPLATE LOAD DEFAULT VALUE FOR --- 	COMPONENTS WITH TABLE
  if (isDynamicTableComponents(type)) {
    defaultValues[fieldId] = [];
  }

  //** TEMPLATE LOAD DEFAULT VALUE FOR --- 	COMPONENTS WITH TABLE FIXED
  if (type === FieldType.TableFixed) {
    defaultValues[fieldId] = applyEmptyTableFixedInputValues(
      valueTable,
      tableStructure
    );
  }

  //** CERTIFICATE DATA LOAD VALUE FOR --- 	COMPONENTS WITH TABLE
  if (type === FieldType.BoardGrid) {
    defaultValues[fieldId] = [];
    return defaultValues;
  }

  return defaultValues;
};

const handleDefaultValueFromCertData = (
  certificateDataValueProps: CertificateDataValueProps,
  defaultValues: { [key: string]: unknown },
  templateFields: CertTemplateField[]
) => {
  const { key, valueType, valueTable, comments } = certificateDataValueProps;

  if (isFormLabelComponents(valueType)) return defaultValues;

  //** QS COMMENTS VALUE
  if (comments) {
    defaultValues[`${key}comments`] = comments;
  }

  //** CERTIFICATE DATA LOAD VALUE FOR --- 	TEXT COMPONENTS
  if (isNormalValueTextInputs(valueType)) {
    defaultValues[key] = parseToFormTextInputValues(certificateDataValueProps);
    return defaultValues;
  }

  //** TEMPLATE LOAD DEFAULT VALUE FOR --- 	SIGNATURE AREA COMPONENTS
  if (valueType === FieldType.SignatureArea) {
    defaultValues[key] = parseToFormSignatureAreaInputValues(
      certificateDataValueProps
    );
    return defaultValues;
  }

  //** CERTIFICATE LOAD DEFAULT VALUE FOR --- 	CUSTOMER DATA COMPONENTS
  if (valueType === FieldType.CustomerData) {
    defaultValues[key] = parseToFormCustomerDataInputValues(
      certificateDataValueProps
    );
    return defaultValues;
  }

  //** CERTIFICATE DATA LOAD VALUE FOR --- 	COMBO COMPONENT
  if ([FieldType.Combo, FieldType.ComboCircuits].includes(valueType)) {
    const field = templateFields.find((e) => e.fieldId === key);
    defaultValues[key] = parseToFormComboInputValues(
      certificateDataValueProps,
      field?.hasTextbox
    );
    return defaultValues;
  }

  //** CERTIFICATE DATA LOAD VALUE FOR --- 	RADIOBUTTON COMPONENT
  if (valueType === FieldType.Radiobutton) {
    defaultValues[key] = parseToFormRadioButtonInputValues(
      certificateDataValueProps
    );
    return defaultValues;
  }

  //** CERTIFICATE DATA LOAD VALUE FOR --- 	CHECKBOX COMPONENT
  if (valueType === FieldType.Checkbox) {
    defaultValues[key] = parseToFormCheckboxInputValues(
      certificateDataValueProps
    );
    return defaultValues;
  }

  //** CERTIFICATE DATA LOAD VALUE FOR --- 	COMPONENTS WITH DYNAMIC TABLES
  if (isDynamicTableComponents(valueType)) {
    const editedValues = parseToFormTableDynamicInputValues(valueTable);
    defaultValues[key] = editedValues;
    return defaultValues;
  }

  //** CERTIFICATE DATA LOAD VALUE FOR --- 	COMPONENTS WITH TABLE FIXED
  if (valueType === FieldType.TableFixed) {
    defaultValues[key] = parseToFormTableFixedValues(
      key,
      templateFields,
      valueTable
    );
    return defaultValues;
  }

  //** CERTIFICATE DATA LOAD VALUE FOR --- 	COMPONENTS WITH OPERATIONS TABLE
  if (valueType === FieldType.OperationsTable) {
    defaultValues[key] = certificateDataValueProps.valueOperationsTable;
    return defaultValues;
  }

  // if (![FieldType.BoardGrid].includes(valueType)) {
  //   console.warn(
  //     'getDefaultValuesFromCertData missing default value for: ',
  //     key,
  //     valueType
  //   );
  // }

  return defaultValues;
};

export const getDefaultValuesFromCertData = (
  certTemplateFields: CertTemplateField[],
  certData: CertData,
  temp: CertTemplate
): {
  initEditFormValues: { [key: string]: unknown };
  newTemplate: CertTemplate;
  defaultValuesForNewBoard: CertificateDataBoardsValuesProps[];
  listBoardsFieldIds: ListBoardsFieldIds[];
} => {
  const initEditFormValues: { [key: string]: unknown } = {};

  // get default values from template
  certTemplateFields.forEach((field) => {
    handleDefaultValueFromTemplate(field, initEditFormValues);
  });

  // get values from CertData
  certData.values.forEach((field) => {
    handleDefaultValueFromCertData(
      field,
      initEditFormValues,
      certTemplateFields
    );
  });

  return getDefaultValuesFromCertDataForBoards(
    certTemplateFields,
    certData,
    temp,
    initEditFormValues
  );
};
