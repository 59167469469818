import styled from 'styled-components';

export const SubTitle = styled.h4`
  color: #370f08;
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
`;
