import { SoloOnboarding } from 'store/@types/user';

import { GET, POST } from 'utils/requestTypes';
import { restCall } from 'utils/restHandler';

export const apiLoadUserOnboard = (onBoardingType: string) =>
  restCall<SoloOnboarding>(GET, `/onboarding/${onBoardingType}`);

export const apiSubmitOnboardingData = (
  onBoardingType: string,
  payload: FormData
) =>
  restCall<SoloOnboarding>(
    POST,
    `/onboarding/${onBoardingType}/data`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
