import { AffectFunction } from 'enum/AffectFunction.enum';
import { CertificateDataValueProps } from 'interfaces/CertificateData';
import {
  CertificateOptions,
  RequiredValuesProps,
} from 'interfaces/CertificateTemplate';

export const changeOptions = (
  fieldId: string,
  requiredValues: RequiredValuesProps[],
  newCertificateOptions: CertificateOptions,
  defaultValues: {
    [key: string]: unknown;
  }
) => {
  requiredValues.forEach(
    ({
      function: functionName,
      fieldId: reqFieldId,
      values: optionsValues,
    }) => {
      if (reqFieldId && functionName === AffectFunction.CHANGE_OPTIONS) {
        const requiredField = defaultValues[
          reqFieldId
        ] as CertificateDataValueProps;

        const optionsValue = optionsValues?.find(
          (optionsValue) => optionsValue.value === requiredField.value
        );

        if (optionsValue?.options) {
          newCertificateOptions[fieldId] = optionsValue.options;
        }
      }
    }
  );
};
