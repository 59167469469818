import * as yup from 'yup';

import { emailValidator, string } from 'utils/formUtils';

export const BusinessSchema = yup.object().shape({
  organizationName: string.required('Please fill in your organisation’s name'),
  companyNumber: string,
  schemeLogo: yup.string().nullable(),
  logoPhoto: yup.string().nullable(),
  organizationAddress: string.required(
    'Please fill in your organisation’s address'
  ),
  organizationPostal: string.required(
    'Please fill in your organisation’s postcode'
  ),
  registrationNumber: string.required(
    'Please fill in your organisation’s registration number'
  ),
  organizationEmail: emailValidator.required(
    'Please fill in your organisation’s email'
  ),
  phoneNumber: string,
  fax: string,
});

export const BusinessSchemaDV = {
  organizationName: '',
  companyNumber: '',
  schemeLogo: '',
  logoPhoto: '',
  organizationAddress: '',
  organizationPostal: '',
  registrationNumber: '',
  organizationEmail: '',
  phoneNumber: '',
  fax: '',
};

export const CertificateSettingsDV = {
  prefix: '',
  startNum: 0,
  color: '',
};

export const CertificateSettingsSchema = yup.object().shape({
  prefix: string,
});

export const YourAccountSchema = yup.object().shape({
  picture: yup.string().nullable(),
  presetSignature: string.required('Please enter at least one signature'),
  practitionerStatus: string,
  name: string.required('Please enter a valid name'),
  email: emailValidator,
  position: string.required('Please enter a position'),
});

export const YourAccountSchemaDV = {
  picture: '',
  presetSignature: undefined,
  practitionerStatus: '',
  name: '',
  email: '',
  position: '',
};
