import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ContactActions as Actions,
  ContactState,
  ContactTopic,
} from 'store/@types/contact';

const initialState: ContactState = {
  loading: false,
  error: null,
  helpTopics: [],
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    [Actions.SET_LOADING]: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      error: null,
      loading: payload,
    }),
    [Actions.SET_HELP_TOPICS]: (
      state,
      { payload }: PayloadAction<ContactTopic[]>
    ) => ({
      ...state,
      loading: false,
      helpTopics: payload,
    }),
    [Actions.SET_ERROR]: (state, { payload }: PayloadAction<unknown>) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export const contactActions = contactSlice.actions;
export default contactSlice.reducer;
