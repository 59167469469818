import styled from 'styled-components';

export const Main = styled.main`
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0;
  background: #fdfeff;
  overflow: hidden;
`;

export const ContentWrapper = styled.div<{ maxWidth?: number }>`
  width: 100%;
  height: 100vh;
  overflow: auto;
  max-width: none;
  padding: 40px 10px 40px 40px;
`;

export const Header = styled.header`
  width: 100%;
  height: 100px;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  background: #fdfeff;
`;

export const HeaderTitleContainer = styled.div`
  display: flex;
`;

export const HeaderWorkspaceCointainer = styled.div`
  display: flex;
  padding: 20px 40px 0px 0px;
`;

export const HeaderTitle = styled.h1`
  padding: 20px 10px 40px 40px;
  color: #370f08;
  line-height: 43px;
  font-size: 36px;
  font-weight: bold;
`;

export const HeaderSubTitle = styled.span`
  padding: 40px 0px 0px 0px;
  color: #666666;
  font-family: Lato;
  font-size: 17px;
  line-height: 20px;
  opacity: 0.5;
  text-align: left;
  white-space: nowrap;
`;

export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderUserMenuContent = styled.div`
  width: 343px;
  height: 153px;
  top: 80px;
  right: 30px;
  padding: 20px;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  border-radius: 12px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);

  background: #fdfeff;

  z-index: 999;
`;

type FooterFixed = {
  fixed?: boolean;
};

export const Footer = styled.footer<FooterFixed>`
  width: 100%;
  padding: 4px;
  text-align: center;
  border-top: 1px solid #4fa3a1;
  color: var(--color-primary);
  background: #fdfeff;
  position: ${(props) => (props.fixed ? 'fixed' : '')};
  bottom: 0;
  left: 0;
  font-size: 14px;
  font-weight: normal;
`;
