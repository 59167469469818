import React, { FC } from 'react';
import { FileContentType } from 'store/@types/user';

import emptyCustomers from 'assets/images/empty_customers_image.svg';
import reqNoPreview from 'assets/images/iconset/empty_carrousel_image.svg';

import Scrollbars from 'components/Scrollbars';

import PresetCard from './Cards/PresetCard';
import RectangleCard from './Cards/RectangleCard';
import SquareCard from './Cards/SquareCard';

import './styles.css';

export interface CarouselItemProps {
  id: string;
  link?: string;
  highlight?: boolean;
  image?: FileContentType;
  title: string;
  topTitle?: string;
  clientName?: string;
  onClick?: () => void;
  onDeleteClick?: () => void;
}

export interface CarouselProps {
  cardSize: 'square' | 'rectangle' | 'preset';
  payload?: CarouselItemProps[];
}

const Carousel: FC<CarouselProps> = ({ cardSize, payload }) => {
  const heightSizes = {
    square: '220px',
    rectangle: '270px',
    preset: '180px',
  };

  if (payload && payload?.length > 0) {
    return (
      <Scrollbars height={heightSizes[cardSize]}>
        <div className={'carousel-with-data ' + cardSize}>
          {payload.map((item) => {
            if (cardSize === 'square')
              return <SquareCard key={item.id} {...item} />;
            if (cardSize === 'rectangle')
              return <RectangleCard key={item.id} {...item} />;
            if (cardSize === 'preset')
              return <PresetCard key={item.id} {...item} />;
          })}
        </div>
      </Scrollbars>
    );
  }

  return (
    <div className={'empty-carousel ' + cardSize}>
      <img
        src={cardSize === 'square' ? emptyCustomers : reqNoPreview}
        alt="no certificates"
      />
      <p>There are no items to display…</p>
    </div>
  );
};

Carousel.defaultProps = {
  payload: undefined,
};

export default Carousel;
