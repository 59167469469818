import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import defaultColor from 'assets/images/iconset/default_color.svg';

import { FormControl, FormGroup } from 'components/Form/styles';

import * as S from './styles';

interface CertificateColorPickerProps {
  fieldId: string;
  colorOptions: string[];
  disabledField?: boolean;
  xs?: number;
}

const ColorPicker: FC<CertificateColorPickerProps> = ({
  fieldId,
  disabledField,
  colorOptions,
  xs = 12,
}) => {
  const { control, setValue } = useFormContext();
  const [colorSelected, setColorSelected] = useState('none');
  const options = ['none', ...colorOptions];

  const currentValue = useWatch({
    control,
    name: fieldId,
  });

  useEffect(() => {
    if (currentValue) {
      setColorSelected(currentValue);
    }
  }, [currentValue]);

  return (
    <FormGroup xs={xs}>
      <Controller
        name={fieldId}
        control={control}
        render={({ field }) => {
          return (
            <FormControl
              {...field}
              value={field.value}
              type="hidden"
              disabled={disabledField}
            />
          );
        }}
      />
      <S.ColorSelectWrapper disabled={disabledField}>
        {options.map((option) => (
          <S.ColorSelectBox
            selected={option === colorSelected}
            key={`color-key-${option}`}
            onClick={() => {
              if (!disabledField) {
                setValue(fieldId, option, { shouldDirty: true });
              }
            }}
          >
            {option === 'none' ? (
              <img src={defaultColor} />
            ) : (
              <S.ColorSelectBody backgroundColor={option} />
            )}
          </S.ColorSelectBox>
        ))}
      </S.ColorSelectWrapper>
    </FormGroup>
  );
};

export default ColorPicker;
