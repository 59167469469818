import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import {
  CERT_PREVIEW_PAGE,
  disabledFieldOnStates,
} from 'constants/certificate.constants';
import { FormProvider, useForm } from 'react-hook-form';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import * as yup from 'yup';

import { Col, Container, Form, Row } from 'react-bootstrap';

import { CertificateState } from 'enum/certificateDataState.enum';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import { getBoardInfo } from 'utils/certificates/defaultValues';
import { createFormValidationSchema } from 'utils/certificates/formYupValidation';
import {
  getBoardGridFieldFromPage,
  validateBoardCompletion,
} from 'utils/certificates/helpers';
import {
  getPresetByCertificateId,
  isPreset,
} from 'utils/certificates/presetUtils';
import { defaultToast } from 'utils/toast';

import { Divider } from 'components/Form';
import { ComponentFieldSelector } from 'components/Form/ComponentFieldSelector';
import MenuBack from 'components/Menu/MenuBack';
import MenuTab from 'components/Menu/MenuTab';
import MenuTabItem from 'components/Menu/MenuTab/MenuTabItem';
import MainLayout from 'components/root/MainLayout';
import { ContentWrapper } from 'components/root/MainLayout/styles';
import SplashScreen from 'components/root/SplashScreen';

import CertificateActions from './CertificateActions';
import CircuitsActions from './CircuitsActions';
import CircuitTable from './CircuitTable';
import PreviewPage from './PreviewPage';

const ManageCertificates = () => {
  const [loading, setLoading] = useState(false);
  const { templateId, certId } = useParams();

  const { state } = useLocation();
  const [query] = useSearchParams();
  const initialIsCircuits = query.get('isCircuits');
  const { currentRole } = useAuth();

  const {
    isCircuits,
    certData,
    certTemplate,
    certLoading,
    setIsCircuits,
    defaultValues,
    templateFields,
    loadCertificateTemplate,
    loadIssuedCertificate,
    clearAll,
    selectedPage,
    fieldsPerPage,
  } = useCerts();

  useEffect(() => {
    if (initialIsCircuits === 'true') {
      setIsCircuits(true);
    }
    if (initialIsCircuits === 'false') {
      setIsCircuits(false);
    }
  }, [initialIsCircuits]);

  useEffect(() => {
    if (templateId && certId) {
      if (state?.issued === true) {
        loadIssuedCertificate(certId);
      } else {
        loadCertificateTemplate(templateId, certId);
      }
    }
  }, [templateId, certId]);

  // Reload all boards if contains a boardgrid in current page.
  useEffect(() => {
    if (
      templateId &&
      certData &&
      !isPreset() &&
      certData?.certificateState !== CertificateState.ISSUED
    ) {
      const boardGridField = getBoardGridFieldFromPage(
        selectedPage,
        fieldsPerPage,
        templateFields
      );

      if (boardGridField) {
        clearAll({ skipFormChecked: true });
        loadCertificateTemplate(templateId, certData._id);
      }
    }
  }, [selectedPage]);

  const renderCertificate =
    Object.keys(defaultValues).length > 0 && templateFields;

  const formValidations = useMemo(() => {
    if (!templateFields) return null;

    return createFormValidationSchema(
      templateFields,
      defaultValues,
      currentRole
    );
  }, [templateFields, defaultValues]);

  const buildTitle = () => {
    const title = isCircuits ? 'Circuit details' : 'Certificate';
    return isPreset() ? `${title} preset` : title;
  };

  const buildPageTitle = () => {
    const title = `Manage ${certTemplate?.certificateName}`;
    return isPreset() ? `${title} preset` : `${title} Certificate`;
  };

  return (
    <>
      {(loading || certLoading) && <SplashScreen />}
      <MainLayout title={buildTitle()} pageTitle={buildPageTitle()}>
        {renderCertificate && formValidations && (
          <RenderCertificateData
            formValidations={formValidations}
            loading={loading}
            setLoading={(c) => setLoading(c)}
          />
        )}
      </MainLayout>
    </>
  );
};

const RenderCertificateData: FC<{
  loading: boolean;
  setLoading: (loading: boolean) => void;
  formValidations: any;
}> = ({ loading, setLoading, formValidations }) => {
  const pagesRef = useRef(null);
  const { user } = useAuth();

  const {
    defaultValues,
    certData,
    autoSave,
    certTemplate,
    isCircuits,
    templateFields,
    fieldsPerPage,
    selectedPage,
    setIsCircuits,
    setSelectedPage,
    isFormChecked,
    setIsFormChecked,
  } = useCerts();

  const navigate = useNavigate();
  const [query, setSearchParams] = useSearchParams();
  const prevPage = query.get('prevPage');
  const initialPage = query.get('page');

  const [errorsInPage, setErrorsInPage] = useState<unknown[]>([]);

  const showMenu =
    certData && !disabledFieldOnStates.includes(certData.certificateState);

  useEffect(() => {
    setSelectedPage(initialPage ?? 0);
  }, []);

  // React hook form instance
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(yup.object().shape(formValidations)),
    reValidateMode: 'onChange',
    defaultValues: defaultValues as any,
    shouldUnregister: false,
  });

  const checkAndNextStep = async (forceCheck = false) => {
    const isValid = await methods.trigger(Object.keys(defaultValues), {
      shouldFocus: true,
    });

    // Mark the form checked
    setIsFormChecked(true);

    const errorsPage = [];
    if (!isValid || forceCheck) {
      console.info('validations errors: ', methods.formState.errors);
      for (const key of Object.keys(methods.formState.errors)) {
        if (key.includes('board')) {
          const { boardGridId } = getBoardInfo(key);
          errorsPage.push(fieldsPerPage[boardGridId]);
        } else {
          errorsPage.push(fieldsPerPage[key]);
        }
      }
    }
    return errorsPage;
  };

  useEffect(() => {
    if (!pagesRef.current) return;
    (pagesRef.current as any).scrollIntoView({ top: 0 });
  }, [selectedPage]);

  useEffect(() => {
    const boardGridField = getBoardGridFieldFromPage(
      selectedPage,
      fieldsPerPage,
      templateFields
    );
    if (boardGridField) {
      const boards = validateBoardCompletion(
        boardGridField?.fieldId,
        methods.getValues,
        methods.setValue,
        templateFields
      );
      autoSave({
        fieldId: boardGridField.fieldId,
        type: boardGridField.type,
        value: boards,
        getValues: methods.getValues,
        setValue: methods.setValue,
      });
    }
  }, []);

  const fieldDisabled = disabledFieldOnStates.includes(
    certData?.certificateState as CertificateState
  );

  const formContent = useMemo(
    () =>
      isCircuits ? (
        <CircuitTable />
      ) : (
        <>
          {certTemplate?.pages.map((page) => {
            if (selectedPage != page.pageOrder) return null;
            window.scrollTo(0, 0);
            const pageKey = 'EditCertificate>' + page.pageName + '- Section> ';
            return (
              <Fragment key={pageKey}>
                {page.sections.map((section, index) => {
                  const sectionKey = pageKey + section.title + '- Row> ';
                  return (
                    <Fragment key={sectionKey}>
                      {index > 0 && <Divider />}
                      <Col xs={12}>
                        <h2 className="form-title">{section.title}</h2>
                        <h3 className="form-subtitle">{section.subtitle}</h3>
                      </Col>
                      {section.rows.map((row) => {
                        if (row.order === 999) return;
                        const rowKey =
                          sectionKey + '-' + row.order + '- Field> ';

                        return (
                          <Fragment key={rowKey}>
                            {/* Fields */}
                            {row.fields.map((field) => {
                              // if (field.type === 'subtitle')
                              if (field.type)
                                return (
                                  <ComponentFieldSelector
                                    isCertificate
                                    fieldDisabled={fieldDisabled}
                                    key={rowKey + field.fieldId}
                                    field={field}
                                  />
                                );
                            })}
                          </Fragment>
                        );
                      })}
                      {page.isPreview && (
                        <PreviewPage
                          canSubmit={errorsInPage.length === 0}
                          loading={loading}
                          setLoading={(c) => setLoading(c)}
                          checkAndNextStep={checkAndNextStep}
                          setErrorsInPage={(errs) => setErrorsInPage(errs)}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
        </>
      ),
    [
      selectedPage,
      formValidations,
      certData,
      certTemplate,
      errorsInPage,
      loading,
      isCircuits,
    ]
  );

  const currentSelectedPage = certTemplate?.pages.find(
    (e) => e.pageOrder == selectedPage
  );

  // Trigger validation for current board
  useEffect(() => {
    if (isFormChecked) {
      methods.trigger(Object.keys(fieldsPerPage));
    }
  }, []);

  // this will never happen
  if (!certTemplate || !certData) return null;

  const buildCertificateName = () => {
    const title = certTemplate.certificateName ?? 'TEMPLATE';
    if (isCircuits) {
      return `${title} - CIRCUITS`;
    }
    if (isPreset()) {
      const preset = getPresetByCertificateId(
        certData._id,
        user?.certificatePresets
      );
      return preset ? `${title} - ${preset?.name ?? ''}` : title;
    }
    return title;
  };

  const menuActions = (): JSX.Element | null => {
    if (showMenu && isCircuits) {
      return <CircuitsActions />;
    }
    if (showMenu && isPreset()) {
      return null;
    }
    if (!isCircuits) {
      return <CertificateActions />;
    }
    return null;
  };

  return (
    <FormProvider {...methods}>
      <MenuBack
        menuTitle={buildCertificateName()}
        handleBack={() => {
          if (isCircuits) {
            setIsCircuits(false);
            setSearchParams(`page=${initialPage}&prevPage=${prevPage}`);
          } else if (
            typeof selectedPage === 'string' &&
            selectedPage.includes('board')
          ) {
            setSelectedPage(prevPage);
            navigate(`${window.location.pathname}?page=${prevPage}`, {
              replace: true,
            });
          } else {
            const path = isPreset()
              ? `/settings?tab=certificate`
              : '/dashboard';
            navigate(path, { replace: true });
          }
        }}
      >
        {menuActions()}
      </MenuBack>
      <ContentWrapper
        style={{
          paddingTop: '0',
          paddingRight: '0',
          overflow: isCircuits ? 'hidden' : 'auto',
        }}
      >
        <Container
          className={clsx({
            'certificate-page-form': !isCircuits,
            'circuits-page-form': isCircuits,
          })}
          ref={pagesRef}
        >
          {currentSelectedPage?.pageName !== '' ? (
            <MenuTab>
              {certTemplate.pages.map(
                ({ pageOrder, pageName }) =>
                  pageName !== '' && (
                    <MenuTabItem
                      key={pageOrder + 'MenuTabItem'}
                      label={pageName}
                      selectedButton={pageOrder == selectedPage}
                      highLightButton={errorsInPage.includes(pageOrder)}
                      onClick={async () => {
                        setSelectedPage(`${pageOrder}`);

                        if (
                          pageOrder === CERT_PREVIEW_PAGE &&
                          certData.certificateState !== CertificateState.ISSUED
                        ) {
                          const errs = await checkAndNextStep();
                          errs.length > 0 &&
                            defaultToast(
                              'S',
                              'Some required fields are missing information. Please revise the highlighted pages below.'
                            );
                          setErrorsInPage(errs);
                        } else if (errorsInPage.includes(pageOrder)) {
                          setErrorsInPage((prev) =>
                            prev.filter((p) => p !== pageOrder)
                          );
                        }

                        navigate(
                          `${window.location.pathname}?page=${pageOrder}`,
                          {
                            replace: true,
                          }
                        );
                      }}
                    />
                  )
              )}
            </MenuTab>
          ) : (
            <Col
              className={clsx({
                'pt-5': !isCircuits,
              })}
            />
          )}
          <Form
            id="manage-certificate"
            onSubmit={methods.handleSubmit(() => {
              return null;
            })}
          >
            <Row className="mt-4 pb-5 mb-5">{formContent}</Row>
          </Form>
        </Container>
      </ContentWrapper>
    </FormProvider>
  );
};

export default ManageCertificates;
