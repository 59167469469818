export interface StripeCheckout {
  session: {
    url: string;
  };
}

export enum StripeCurrency {
  USD = 'usd',
  GBP = 'gbp',
}

export interface ApiOnTimePaymentParams {
  certificateId?: string;
  successUrlPath?: string;
  cancelUrlPath?: string;
  currency?: string;
  priceId?: string;
}
