import { AffectFunction } from 'enum/AffectFunction.enum';
import {
  FieldAffects,
  HandleAffectsParams,
  RequiredValuesProps,
} from 'interfaces/CertificateTemplate';
import { getBoardInfo } from 'utils/certificates/defaultValues';

export const lookupTable = (
  requiredValues: RequiredValuesProps[],
  affect: FieldAffects,
  handleAffectsParams: HandleAffectsParams
) => {
  const {
    getValues,
    defaultValues = {},
    setValue,
    autoSave,
    templateFields,
  } = handleAffectsParams;
  const { boardId: affectedBoardId } = getBoardInfo(affect.field);

  const lookUpAffects = requiredValues.filter((reqValue) => {
    if (reqValue.function === AffectFunction.LOOKUP_TABLE) {
      if (affectedBoardId) {
        return !!reqValue.fieldId?.includes(affectedBoardId);
      }
      return true;
    }
  });

  if (!lookUpAffects?.length) return;

  const getFieldValue = (fieldId: string): string | undefined => {
    const value = getValues ? getValues(fieldId) : defaultValues[fieldId];
    if (value) {
      return typeof value !== 'string' ? value.value : value;
    }
  };

  // Enable field before apply the affect
  if (handleAffectsParams.affectedFields) {
    handleAffectsParams.affectedFields[affect.field] = { disabled: false };
  }

  lookUpAffects.forEach(({ fieldId: matrixTableReqFieldId, values }) => {
    if (!matrixTableReqFieldId || !values?.length) return;

    const matrixTableReqFieldValue = getFieldValue(matrixTableReqFieldId);
    const value = values.find(
      ({ value }) => value === matrixTableReqFieldValue
    );

    if (!value?.matrixTable?.length) return;

    const matrixTable = value.matrixTable.find(
      (table) => getFieldValue(table.fieldId) === table.value
    );

    if (!matrixTable?.lookupTable?.length) return;

    const lookupTable = matrixTable.lookupTable.find(
      (lookupTable) => getFieldValue(lookupTable.fieldId) === lookupTable.value
    );

    if (lookupTable?.table) {
      const { fieldX, fieldY, matrix, x, y } = lookupTable.table;
      const xValue = getFieldValue(fieldX);
      const yValue = getFieldValue(fieldY);

      if (matrix && xValue && yValue && x && y) {
        const xIndex = x.indexOf(xValue);
        const yIndex = y.indexOf(yValue);

        if (xIndex !== -1 && yIndex !== -1) {
          const matrixValue = matrix[yIndex][xIndex];
          if (autoSave && setValue && templateFields) {
            const field = templateFields.find(
              (field) =>
                affect.field.includes(field.fieldId) ||
                field.fieldId.includes(affect.field)
            );
            if (field) {
              setValue(affect.field, matrixValue);
              autoSave({
                fieldId: affect.field,
                type: field.type,
                value: matrixValue,
                setValue,
                getValues,
              });
            }
          } else {
            defaultValues[affect.field] = matrixValue;
          }

          // APPLY DISABLED IF EXISTS VALUE IN TABLE RESULT
          if (handleAffectsParams.affectedFields) {
            handleAffectsParams.affectedFields[affect.field] = {
              disabled: !!matrixValue,
            };
          }
        }
      }
    }
  });
};
