import styled from 'styled-components';

export const ComboWrapper = styled.div<any>`
  height: ${({ height }) => height || '50px'};
  display: flex;
  gap: 20px;
  white-space: nowrap;

  > div {
    min-width: 48%;
  }

  > div > div {
    padding: 0;
  }
`;
