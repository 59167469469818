import {
  AutoMeiwcPayload,
  CertData,
  CertDataPayload,
  CertificateDataBoardsValueProps,
} from 'interfaces/CertificateData';
import { CertTemplate } from 'interfaces/CertificateTemplate';
import { IssuedCertData } from 'interfaces/IssuedCertificateData';
import { DELETE, GET, PATCH, POST } from 'utils/requestTypes';
import { restCall } from 'utils/restHandler';

export const apiCertificateAutoSave = (
  certificateId: string,
  payload: unknown
) => restCall(PATCH, `/certificate-data/${certificateId}`, payload);

export const apiCertificateBoardAutoSave = (
  certificateId: string,
  boardId: string,
  payload: unknown
) =>
  restCall<CertData>(
    PATCH,
    `/certificate-data/${certificateId}/board/${boardId}`,
    payload
  );

export const apiCertificateSubmit = (certificateId: string) =>
  restCall<CertData>(PATCH, `/certificate-data/${certificateId}/submit`);

export const certificateAddNewBoard = (
  certificateId: string,
  payload: unknown
) => restCall(POST, `/certificate-data/${certificateId}/board`, payload);

export const apiCertificateDeleteBoard = (
  certificateId: string | undefined,
  boardId: string | undefined,
  boardGriId: string
) =>
  restCall(
    DELETE,
    `/certificate-data/${certificateId}/board/${boardId}?board-grid-id=${boardGriId}`
  );

export const apiListCertTemplatesTypes = () =>
  restCall<CertTemplate[]>(GET, '/certificate-template');

export const apiGetCertificateData = (certificateId: string) =>
  restCall<CertData>(GET, `/certificate-data/${certificateId}`);

export const apiGetIssuedCertificateData = (certificateId: string) =>
  restCall<IssuedCertData>(GET, `/issued-certificate-data/${certificateId}`);

export const apiCreateCertificateData = (payload: CertDataPayload) =>
  restCall<CertData>(POST, '/certificate-data/', payload);

export const apiUpdateCertificateData = (
  certificateId: string,
  payload: CertDataPayload
) => restCall<CertData>(PATCH, `/certificate-data/${certificateId}`, payload);

export const apiGetCertificatePdf = (certId: string) =>
  restCall<string>(GET, `/certificate-data/${certId}/pdf`);

export const apiGenerateAutoMeiwc = (payload: AutoMeiwcPayload) =>
  restCall<CertData>(POST, '/certificate-data/convert/auto-meiwc', payload);

export const apiGetCertificateBoards = (
  certificateId: string,
  boardGridId: string
) =>
  restCall<CertificateDataBoardsValueProps[]>(
    GET,
    `/certificate-data/${certificateId}/board?board-grid-id=${boardGridId}`
  );

export const apiGetCertificateBoard = (
  certificateId: string,
  boardId: string,
  boardGridId: string
) =>
  restCall<CertificateDataBoardsValueProps>(
    GET,
    `/certificate-data/${certificateId}/board/${boardId}?board-grid-id=${boardGridId}`
  );

export const apiAssignUserUpdateCertificateData = (
  certificateId: string,
  payload: { users: string[] }
) =>
  restCall<CertData>(
    PATCH,
    `/certificate-data/${certificateId}/assigned-engineers`,
    payload
  );
