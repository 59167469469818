import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleEnum } from 'store/@types/user';

import { Col } from 'react-bootstrap';

import { CertificateState } from 'enum/certificateDataState.enum';
import useAuth from 'hooks/useAuth';
import { CertData } from 'interfaces/CertificateData';
import api from 'services/api';
import { certTemplateIds } from 'utils/certificates/helpers';
import { formatDate } from 'utils/index';

import CarouselSection from 'components/CarouselSection';
import { MenuMain } from 'components/Menu/MenuMain';
import MenuTab from 'components/Menu/MenuTab';
import MenuTabItem from 'components/Menu/MenuTab/MenuTabItem';
import MainLayout from 'components/root/MainLayout';
import { ContentWrapper } from 'components/root/MainLayout/styles';
import SplashScreen from 'components/root/SplashScreen/index';

interface ICertificatesStates {
  state: CertificateState[];
  name: string;
}

const certificatesStates: Array<ICertificatesStates> = [
  { state: [CertificateState.DRAFT], name: 'Draft' },
  {
    state: [CertificateState.IN_REVIEW, CertificateState.PENDING_REVIEW],
    name: 'Reviewing',
  },
  { state: [CertificateState.REVIEWED], name: 'Pending corrections' },
  { state: [CertificateState.ISSUED], name: 'Issued certificates' },
];

function Certificates() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [issuedCerts, setIssuedCerts] = useState<CertData[] | null>(null);
  const [certificates, setCertificates] = useState<CertData[] | null>(null);
  const [selectedPage, setSelectedPage] = useState<ICertificatesStates>({
    state: [CertificateState.DRAFT],
    name: 'Draft',
  });
  const { customers, currentRole, business } = useAuth();
  const canShowCustomers = [RoleEnum.SOLO, RoleEnum.LEAD].includes(currentRole);
  const canShowIssueds = canShowCustomers;

  useEffect(() => {
    if (certificates) return;
    const fetchData = () => {
      api
        .get('/certificate-data')
        .then(({ data }) => {
          setCertificates(data.reverse());
        })
        .finally(() => setIsLoading(false));
    };
    setIsLoading(true);
    fetchData();
  }, []);

  useEffect(() => {
    if (issuedCerts) return;
    const fetchData = () => {
      api
        .get('/issued-certificate-data')
        .then(({ data }) => {
          setIssuedCerts(data.reverse());
        })
        .finally(() => setIsLoading(false));
    };

    if (canShowIssueds) {
      setIsLoading(true);
      fetchData();
    }
  }, []);

  return (
    <>
      {isLoading && <SplashScreen />}
      <MainLayout title="Certificates" pageTitle="Certificates">
        <MenuMain />
        <ContentWrapper>
          <Col>
            {/* Issued certificates */}
            <MenuTab sticky={false}>
              {certificatesStates
                .filter((item) => {
                  if (currentRole === RoleEnum.SOLO) {
                    return (
                      item.state.includes(CertificateState.DRAFT) ||
                      item.state.includes(CertificateState.ISSUED)
                    );
                  }
                  return true;
                })
                .map((item) => (
                  <MenuTabItem
                    key={item.name + 'MenuTabItem'}
                    label={item.name}
                    selectedButton={item.state == selectedPage.state}
                    onClick={() =>
                      setSelectedPage({ state: item.state, name: item.name })
                    }
                  />
                ))}
            </MenuTab>
            {selectedPage.state.includes(CertificateState.ISSUED) ? (
              <CarouselSection
                title={selectedPage.name}
                cardSize="rectangle"
                payload={issuedCerts?.map((item) => ({
                  id: item._id ?? '0',
                  onClick: () => {
                    navigate(
                      `/manage-certificate/${
                        certTemplateIds[item.template?.certificateName ?? '']
                      }/${item._id}`,
                      { state: { issued: true } }
                    );
                  },
                  title:
                    item.template?.certificateName && item.createdAt
                      ? `${item.template?.certificateName} - ${formatDate(
                          String(item.createdAt)
                        )}`
                      : '',
                  topTitle: business?.organizationName ?? '',
                  clientName: item.customer?.name ?? '',
                }))}
              />
            ) : (
              <CarouselSection
                title={selectedPage.name}
                cardSize="rectangle"
                payload={certificates
                  ?.filter((item) =>
                    selectedPage.state.includes(item.certificateState)
                  )
                  ?.map((item) => ({
                    id: item._id ?? '0',
                    link: `/manage-certificate/${item.template?._id}/${item._id}`,
                    title:
                      item.template?.certificateName && item.createdAt
                        ? `${item.template?.certificateName} - ${formatDate(
                            String(item.createdAt)
                          )}`
                        : '',
                    topTitle: business?.organizationName ?? '',
                    clientName: item.customer?.name ?? '',
                  }))}
              />
            )}
            {/* Certificates by customer */}
            {canShowCustomers && (
              <CarouselSection
                title="Certificates by customer"
                subTitle="Browse each customer’s certificates"
                cardSize="square"
                payload={customers?.map((item) => ({
                  id: item._id ?? '0',
                  link: `/certificates/bycustomer/${item?._id}`,
                  image: item.picture,
                  title: item.name,
                }))}
              />
            )}
          </Col>
        </ContentWrapper>
      </MainLayout>
    </>
  );
}

export default Certificates;
