import styled from 'styled-components';

export const CarouselContainer = styled.div<{
  height: string;
  marginLeft: string;
}>`
  padding-top: 5px;
  margin: 0;
  display: flex;
  max-width: 100%;
  white-space: nowrap;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: ${({ marginLeft }) => marginLeft};
  height: ${({ height }) => height};
`;

export const EmptyCarousel = styled.div<{ height: string; marginLeft: string }>`
  background-color: rgba(244, 244, 244, 0.4);
  border-radius: 12px 0 0 12px;
  display: flex;
  padding: 15px 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  margin-left: ${({ marginLeft }) => marginLeft};
  height: ${({ height }) => height};
  p {
    color: var(--color-primary);
    font-size: 17px;
    font-weight: 400;
    padding: 32px 20px;
    margin: 0;
  }
`;
