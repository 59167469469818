import { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';

import { FormControlProps } from 'react-bootstrap';

import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';

import Comments from 'components/Comments';
import * as FormStyles from 'components/Form/styles';
import { FormLabelContainer } from 'components/Form/styles';

import { getError } from '..';
import { CommonProps } from '../Types';

interface TextFieldProps extends FormControlProps, CommonProps {
  placeholder?: string;
  maxLength?: number;
  required?: boolean;
  longDate?: boolean;
  [x: string]: unknown;
}

const DatePicker: FC<TextFieldProps> = ({
  xs,
  fieldId,
  label,
  disabled,
  longDate,
  required,
  isCertificate,
  autoObservations,
}) => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const error = getError(fieldId, errors);
  const { autoSave, handleAutoObservations } = useCerts();

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({ field: ogField }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onChange, onBlur, value, ...field } = ogField;
        return (
          <FormStyles.FormGroupWithErrorSpace
            xs={xs}
            className="DatePickerComponentForm"
          >
            {!!label && (
              <FormStyles.FormLabel>
                <FormLabelContainer>
                  {label}{' '}
                  <Comments
                    fieldId={fieldId}
                    disabledField={disabled}
                    isCertificate={isCertificate}
                    handleOnChange={(comments) => {
                      autoSave({
                        type: longDate
                          ? FieldType.LargeDatepicker
                          : FieldType.SmallDatepicker,
                        fieldId,
                        value: getValues(fieldId),
                        getValues,
                        setValue,
                        comments,
                      });
                    }}
                  />
                </FormLabelContainer>
                {required && (
                  <span className="required float-right">Required</span>
                )}
              </FormStyles.FormLabel>
            )}
            <ReactDatePicker
              {...field}
              dateFormat={longDate ? 'MMMM dd yyyy' : 'dd-MM-yyyy'}
              disabled={disabled}
              showMonthDropdown
              showYearDropdown
              selected={
                typeof value === 'string' && value.length > 0
                  ? new Date(value)
                  : value
              }
              onChange={(date) => {
                if (date) {
                  onChange(date);
                }
                if (isCertificate) {
                  autoSave({
                    type: longDate
                      ? FieldType.LargeDatepicker
                      : FieldType.SmallDatepicker,
                    fieldId,
                    value: getValues(fieldId),
                    getValues,
                    setValue,
                  });
                  if (autoObservations) {
                    handleAutoObservations({
                      fieldId,
                      autoObservations,
                      getValues,
                      setValue,
                    });
                  }
                }
              }}
            />

            <FormStyles.FormControlFeedback type="invalid">
              <span style={{ width: '100%' }}>{error?.message}</span>
              {!disabled && (
                <FormStyles.FormLabel
                  style={{ lineHeight: 2, cursor: 'pointer' }}
                  onClick={() => {
                    setValue(fieldId, null);
                    autoSave({
                      type: longDate
                        ? FieldType.LargeDatepicker
                        : FieldType.SmallDatepicker,
                      fieldId,
                      value: null,
                      getValues,
                      setValue,
                    });
                  }}
                >
                  reset
                </FormStyles.FormLabel>
              )}
            </FormStyles.FormControlFeedback>
          </FormStyles.FormGroupWithErrorSpace>
        );
      }}
    />
  );
};

DatePicker.defaultProps = {
  xs: 12,
  longDate: false,
};

export { DatePicker };
