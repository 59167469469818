import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from 'store/@types';
import { setLogin } from 'store/user/actions';
import { logout } from 'store/user/thunks';

import DefaultComponent from './AuthProvider';

const mapStateToProps = ({ user }: RootState) => ({ user });

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ logout, setLogin }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const ConnectedAuthProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultComponent);
