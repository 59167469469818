import styled from 'styled-components';

import BootstrapModal from 'react-bootstrap/Modal';

export const Modal = styled(BootstrapModal)`
  .modal-dialog,
  .modal-content {
    width: 638px;
    height: max-content;
  }
  .modal-body {
    padding: 40px;
    width: 638px;
  }
`;

export const ContactTitle = styled.div`
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 0;
  font-family: Lato;
  color: var(--color-secundary);
`;

export const ContactSubTitle = styled.div`
  font-size: 34px;
  font-weight: 600;
  font-family: Lato;
  color: var(--color-primary-dark);
  margin-left: 0px;
  margin-bottom: 20px;
`;
