import * as yup from 'yup';

import { emailValidator, errors } from 'utils/formUtils';

export const schema = yup.object().shape({
  email: emailValidator.required(errors.email.required),
  emailConfirm: emailValidator
    .required(errors.email.personalRequired)
    .oneOf([yup.ref('email')], errors.email.dontMatch),
});

export const defaultValues = {
  email: '',
  emailConfirm: '',
};
