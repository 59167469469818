import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  LoginResponse,
  SoloOnboarding,
  UserActions as Actions,
  UserState,
} from 'store/@types/user';

import { ApiError } from 'services/api';

const initialState: UserState = {
  loading: false,
  error: null,
  userInfo: null,
  userAuth: null,
  onBoarding: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    [Actions.SET_LOADING]: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      error: null,
      loading: payload,
    }),
    [Actions.SET_ERROR]: (state, { payload }: PayloadAction<ApiError>) => ({
      ...state,
      loading: false,
      error: { ...payload },
    }),
    [Actions.SET_LOGIN]: (
      state,
      { payload }: PayloadAction<LoginResponse | null>
    ) => {
      if (!payload) {
        return { ...state, loading: false, userAuth: null, userInfo: null };
      }
      const { user, ...session } = payload;
      return { ...state, loading: false, userAuth: session, userInfo: user };
    },
    [Actions.SET_ONBOARDING]: (
      state,
      { payload }: PayloadAction<SoloOnboarding | null>
    ) => ({
      ...state,
      loading: false,
      onBoarding: payload,
    }),
  },
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
