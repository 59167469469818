import {
  OnboardingField,
  OnboardingValidation,
  SoloOnboarding,
} from 'store/@types/user';
import * as yup from 'yup';

import { FieldType } from 'enum/FieldType.enum';

import { isNormalValueTextInputs } from './certificates/helpers';
import { objectToFormData, regex, string, stringRequired } from './formUtils';

const types: {
  [typeName: string]: any;
} = {
  textbox: string,
  signature: yup.object().shape({}),
  combo: yup.object().shape({ value: string }),
};

export const createOnBoardObjectSchema = (fields: OnboardingField[]) => {
  const objectSchema: Record<string, yup.Schema<unknown, unknown>> = {};

  fields.forEach((field) => {
    let inputValidationObject = types[field.type];
    const required = getOnboardFieldValidation(field, 'is-required');
    const validEmail = getOnboardFieldValidation(field, 'is-valid-email');

    if (required.required) {
      inputValidationObject = inputValidationObject.required(required.error);
    }
    if (required.required && field.type === FieldType.Combo) {
      inputValidationObject = field.hasTextbox
        ? yup.object({
            value: stringRequired.required(required.error),
          })
        : stringRequired.required(required.error);
    }
    if (required.required && field.type === 'signature') {
      inputValidationObject = yup.object({
        description: stringRequired.required(required.error),
        signature: yup.mixed().required(required.error),
      });
    }
    if (validEmail.required) {
      inputValidationObject = inputValidationObject.required(validEmail.error);
    }

    if (validEmail.type) {
      inputValidationObject = inputValidationObject.matches(
        regex.email,
        validEmail.error
      );
    }

    objectSchema[field.id] = inputValidationObject;
  });

  return objectSchema;
};

export const handleDefaultValueFromOnBoardData = ({
  id,
  type,
  hasTextbox,
}: OnboardingField): unknown => {
  //** ONBOARD DATA LOAD VALUE FOR --- 	TEXT COMPONENTS
  if (isNormalValueTextInputs(type)) {
    return '';
  }

  //** ONBOARD DATA LOAD VALUE FOR --- 	SIGNATURE COMPONENTS
  if (type === FieldType.Signature) {
    return {
      id,
      description: '',
      signature: null,
    };
  }

  //** ONBOARD DATA LOAD VALUE FOR --- 	COMBO COMPONENTS
  if (type === FieldType.Combo) {
    if (hasTextbox) {
      return {
        id,
        valueTextbox: '',
        value: '',
      };
    } else {
      return '';
    }
  }

  //** ONBOARD DATA LOAD VALUE FOR --- 	RADIOBUTTON COMPONENTS
  if (type === FieldType.Radiobutton) {
    return {
      id,
      valueTextbox: '',
      value: '',
    };
  }

  //** ONBOARD DATA LOAD VALUE FOR --- 	CHECKBOX COMPONENTS
  if (type === FieldType.Checkbox) {
    return {
      id,
      valueTextbox: '',
      value: false,
    };
  }

  console.warn('onBoard: field type with no default value defined', id, type);
  return null;
};

export const getOnBoardFields = (
  userOnboard: SoloOnboarding
): OnboardingField[] => {
  return userOnboard.pages.reduce(
    (pageFields: OnboardingField[], { fields = [] }) => {
      pageFields.push(...fields);
      return pageFields;
    },
    []
  );
};

const getOnboardFieldValidation = (
  { validations }: OnboardingField,
  type: string
): OnboardingValidation =>
  validations?.find((validation) => validation.type === type) || {
    required: false,
    type: '',
    error: '',
  };

export const prepareSubmitPayload = (payload: any): FormData => {
  const submitPayload: any = [];
  const signature: any[] = [];
  Object.keys(payload).forEach((key) => {
    const value: any = {};
    if (typeof payload[key] === 'string') value['value'] = payload[key];
    if (payload[key].value) value['value'] = payload[key].value;
    if (payload[key].signature) {
      value['valueSignature'] = {
        description: payload[key]?.description,
      };
      signature.push(payload[key]?.signature);
    }
    submitPayload.push({
      id: +key,
      ...value,
    });
  });
  return objectToFormData({
    values: submitPayload,
    ...(signature.length && { signature: signature[0] }),
  });
};

export const loadOnboardingSchema = (userOnboard: SoloOnboarding | null) => {
  if (userOnboard) {
    const fields = getOnBoardFields(userOnboard);
    return createOnBoardObjectSchema(fields);
  }
  return {};
};

export const handleEmptyValueFromOnboardTemplate = (
  userOnboard: SoloOnboarding | null
) => {
  const defaultValues: { [key: string]: unknown } = {};
  if (userOnboard) {
    const fields = getOnBoardFields(userOnboard);
    fields.forEach((field) => {
      defaultValues[field.id] = handleDefaultValueFromOnBoardData(field);
    });
  }
  return defaultValues;
};
