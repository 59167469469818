import { FC } from 'react';

import CustomerAdd from 'assets/images/iconset/customer_add.svg';

import '../styles.css';

interface AddCardProps {
  title: string;
  link?: string;
  onClick?: () => void;
}

const AddCard: FC<AddCardProps> = ({ link, title, onClick }) => {
  return (
    <div className="add-card">
      <a
        href={link || '#'}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <img src={CustomerAdd} alt="Customer Add Btn" />
        <p>{title}</p>
      </a>
    </div>
  );
};
AddCard.defaultProps = {
  link: undefined,
  onClick: undefined,
};
export default AddCard;
