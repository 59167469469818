import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import ContextIcon from 'assets/images/iconset/context_menu.svg';
import CopyBlanksIcon from 'assets/images/iconset/copy_blanks.svg';
import CopyCircuitsIcon from 'assets/images/iconset/copy_circuit.svg';
import MarkSpareIcon from 'assets/images/iconset/mark_spare.svg';
import MergeCircuitsIcon from 'assets/images/iconset/merge_icon.svg';
import NumberOfWaysIcon from 'assets/images/iconset/num_ways.svg';
import SplitCircuitsIcon from 'assets/images/iconset/split_circuits.svg';
import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';
import { CertificateDataOperationsTableProps } from 'interfaces/CertificateData';
import { CertTemplateField } from 'interfaces/CertificateTemplate';
import { TableIndexes } from 'interfaces/Circuits';
import {
  CircuitTableValuesGenerator,
  getOptionsByColumnIndex,
} from 'utils/certificates/circuitsUtils';
import { defaultToast } from 'utils/toast';

import MenuActionDropdown from 'components/Menu/MenuActionDropdown';
import CircuitWaysModal from 'components/modals/CircuitWaysModal';

const CircuitsActions: FC = () => {
  const { getValues, setValue } = useFormContext();
  const [showWaysModal, setShowWaysModal] = useState(false);
  const [numberWays, setNumberWays] = useState(0);
  const [operationsField, setOperationsField] = useState<CertTemplateField>();
  const { templateId } = useParams();
  const {
    certData,
    certOptions,
    affectedFields,
    templateFields,
    setCertLoading,
    clearAll,
    selectedCircuitIndexes,
    setSelectedCircuitIndexes,
    loadCertificateTemplate,
    autoSave,
    circuitsId,
  } = useCerts();

  const getTableValues = (circuitsId: string) => {
    return getValues(circuitsId) as CertificateDataOperationsTableProps[];
  };

  const saveAndReload = (
    circuitsId: string,
    operationsTable: CertificateDataOperationsTableProps[]
  ) => {
    setCertLoading(true);
    autoSave({
      type: FieldType.OperationsTable,
      fieldId: circuitsId,
      value: operationsTable,
      getValues,
      setValue,
      onSave: () => {
        if (templateId && certData?._id) {
          clearAll();
          loadCertificateTemplate(templateId, certData._id);
        }
      },
    });
  };

  const getSelectedRow = (): TableIndexes | null => {
    const { row } = selectedCircuitIndexes;
    if (!row) {
      defaultToast(
        'E',
        'It is only possible to perform this action with a circuit selected'
      );
      return null;
    }
    return row;
  };

  const getSelectedColumn = (): TableIndexes | null => {
    const { cloumn } = selectedCircuitIndexes;
    if (!cloumn) {
      defaultToast(
        'E',
        'It is only possible to perform this action with a field selected'
      );
      return null;
    }
    return cloumn;
  };

  const mergeCircuits = () => {
    if (!circuitsId || !templateFields) return;
    const row = getSelectedRow();
    const operationsTableField = templateFields.find(
      (field) => field.fieldId === circuitsId
    );

    if (row && operationsTableField) {
      const { tableIndex, rowIndex, columnIndex } = row;
      const { groupSuffix } = operationsTableField;
      const tableValues = getTableValues(circuitsId);

      if (tableValues[tableIndex].merged) {
        defaultToast('E', 'Circuits are already merged');
      } else {
        const mergedRow = tableValues[tableIndex].rows[rowIndex];
        const rowLabel = `${tableIndex + 1}${groupSuffix}`;

        mergedRow.columns[columnIndex].value = rowLabel;
        tableValues[tableIndex].mergedRow = mergedRow;
        tableValues[tableIndex].merged = true;

        saveAndReload(circuitsId, tableValues);
        defaultToast('S', 'Circuits merged successfully!');
      }
    }

    setSelectedCircuitIndexes({});
  };

  const splitCircuits = () => {
    if (!circuitsId || !templateFields) return;
    const row = getSelectedRow();
    const operationsTableField = templateFields.find(
      (field) => field.fieldId === circuitsId
    );

    if (row && operationsTableField) {
      const { tableIndex, rowIndex, columnIndex } = row;
      const { rowNomenclature } = operationsTableField;
      const tableValues = getTableValues(circuitsId);

      if (!tableValues[tableIndex].merged) {
        defaultToast(
          'E',
          'It is only possible to perform action on merged circuits'
        );
      } else {
        const mergedRow = tableValues[tableIndex].mergedRow;
        if (mergedRow) {
          const rowLabel = `${tableIndex + 1}${rowNomenclature}${rowIndex + 1}`;
          mergedRow.columns[columnIndex].value = rowLabel;
          tableValues[tableIndex].rows[rowIndex] = mergedRow;
        }

        tableValues[tableIndex].mergedRow = undefined;
        tableValues[tableIndex].merged = false;

        saveAndReload(circuitsId, tableValues);
        defaultToast('S', 'Circuits splitted successfully!');
      }
    }

    setSelectedCircuitIndexes({});
  };

  const copyToBlanks = () => {
    if (!circuitsId || !affectedFields || !certOptions) return;
    const column = getSelectedColumn();
    const affectedField = affectedFields[circuitsId];

    if (column && affectedField) {
      const { tableIndex, rowIndex, columnIndex } = column;
      const { tableStructure = [] } = affectedField;
      const tableValues = getTableValues(circuitsId);
      const currentColumnValue = tableValues[tableIndex].merged
        ? tableValues[tableIndex].mergedRow?.columns[columnIndex].value
        : tableValues[tableIndex].rows[rowIndex].columns[columnIndex].value;

      if (!currentColumnValue) {
        defaultToast(
          'E',
          'It is only possible to perform this action with a non-empty value'
        );
        return;
      }

      tableValues.forEach((table) => {
        if (table.index >= tableIndex) {
          if (table.merged) {
            table.mergedRow?.columns.forEach((column) => {
              if (column.index === columnIndex && !column.value) {
                const options = getOptionsByColumnIndex(
                  tableStructure,
                  certOptions,
                  circuitsId,
                  column.index,
                  tableIndex,
                  rowIndex
                );
                const hasOption = options?.some(
                  (option) => option.value === currentColumnValue
                );
                if (hasOption || column.valueType === 'textbox') {
                  column.value = currentColumnValue;
                }
              }
            });
          } else {
            table.rows.forEach((row) => {
              row.columns.forEach((column) => {
                if (table.index === tableIndex && row.index <= rowIndex) return;
                if (column.index === columnIndex && !column.value) {
                  const options = getOptionsByColumnIndex(
                    tableStructure,
                    certOptions,
                    circuitsId,
                    column.index,
                    table.index,
                    row.index
                  );
                  const hasOption = options?.some(
                    (option) => option.value === currentColumnValue
                  );
                  if (hasOption || column.valueType === 'textbox') {
                    column.value = currentColumnValue;
                  }
                }
              });
            });
          }
        }
      });
      saveAndReload(circuitsId, tableValues);
      defaultToast('S', 'Copied to blanks successfully!');
    }

    setSelectedCircuitIndexes({});
  };

  const copyCircuit = () => {
    if (!circuitsId) return;
    const row = getSelectedRow();

    if (row) {
      const { tableIndex, rowIndex } = row;
      const tableValues = getTableValues(circuitsId);
      const currentRow = tableValues[tableIndex].merged
        ? tableValues[tableIndex].mergedRow
        : tableValues[tableIndex].rows[rowIndex];

      tableValues.forEach((table) => {
        if (table.index >= tableIndex) {
          if (table.merged) {
            table.mergedRow?.columns.forEach((column) => {
              if (currentRow && column.index > 0) {
                column.value = currentRow.columns[column.index].value;
              }
            });
          } else {
            table.rows.forEach((row) => {
              row.columns.forEach((column) => {
                if (table.index === tableIndex && row.index <= rowIndex) return;
                if (currentRow && column.index > 0) {
                  column.value = currentRow.columns[column.index].value;
                }
              });
            });
          }
        }
      });
      saveAndReload(circuitsId, tableValues);
      defaultToast('S', 'Circuit copied successfully!');
    }

    setSelectedCircuitIndexes({});
  };

  const markSpare = () => {
    if (!circuitsId) return;
    const row = getSelectedRow();

    if (row) {
      const { tableIndex, rowIndex } = row;
      const tableValues = getTableValues(circuitsId);

      if (tableValues[tableIndex].merged) {
        tableValues[tableIndex].mergedRow?.columns.forEach((column) => {
          if (column.index > 0) {
            column.value = column.index === 1 ? 'SPARE' : '-';
          }
        });
      } else {
        tableValues[tableIndex].rows[rowIndex].columns.forEach((column) => {
          if (column.index > 0) {
            column.value = column.index === 1 ? 'SPARE' : '-';
          }
        });
      }

      saveAndReload(circuitsId, tableValues);
      defaultToast('S', 'Spare marked successfully!');
    }

    setSelectedCircuitIndexes({});
  };

  const openWaysModal = () => {
    if (!circuitsId || !templateFields) return;
    const tableValues = getTableValues(circuitsId);
    const operationsField = templateFields.find(
      (field) => field.fieldId === circuitsId
    );
    setNumberWays(tableValues.length);
    setOperationsField(operationsField);
    setShowWaysModal(true);
  };

  const changeNumbersOfWays = (ways: number) => {
    if (!circuitsId || !operationsField) return;

    const tableValues = getTableValues(circuitsId);
    if (ways === 0 || tableValues.length === ways) return;

    if (ways < tableValues.length) {
      const newTableValues = tableValues.slice(0, ways);
      saveAndReload(circuitsId, newTableValues);
    } else {
      const certTableStructs = affectedFields[circuitsId].tableStructure;
      const numberOfPhases = tableValues[0]?.rows?.length;
      const { rowNomenclature } = operationsField;

      if (certTableStructs && numberOfPhases && rowNomenclature) {
        const newTableValues = CircuitTableValuesGenerator({
          certTableStructs,
          numberOfPhases,
          numberOfWays: ways,
          rowNomenclature,
        });

        newTableValues.forEach((table) => {
          if (tableValues[table.index]) {
            if (tableValues[table.index].merged) {
              table.merged = true;
              table.mergedRow = tableValues[table.index].mergedRow;
            }
            table.rows.forEach((row) => {
              if (tableValues[table.index].rows[row.index]) {
                row.columns = tableValues[table.index].rows[row.index].columns;
              }
            });
          }
        });
        saveAndReload(circuitsId, newTableValues);
      }
      defaultToast('S', 'Number of Ways updated successfully!');
    }
  };

  return (
    <>
      <MenuActionDropdown
        menuIcon={ContextIcon}
        items={[
          {
            icon: MergeCircuitsIcon,
            label: 'Merge circuits',
            onClick: mergeCircuits,
            show: true,
          },
          {
            icon: SplitCircuitsIcon,
            label: 'Split circuits',
            onClick: splitCircuits,
            show: true,
          },
          {
            icon: MarkSpareIcon,
            label: 'Mark as spare',
            onClick: markSpare,
            show: true,
          },
          {
            icon: CopyCircuitsIcon,
            label: 'Copy circuit',
            onClick: copyCircuit,
            show: true,
          },
          {
            icon: CopyBlanksIcon,
            label: 'Copy to blanks',
            onClick: copyToBlanks,
            show: true,
          },
          {
            icon: NumberOfWaysIcon,
            label: 'Number of ways',
            onClick: openWaysModal,
            show: true,
          },
        ]}
      />
      {showWaysModal && operationsField?.popup && (
        <CircuitWaysModal
          title={operationsField.popup.popupTitle}
          text={operationsField.popup.popupDescription}
          confirmText={operationsField.popup.actionButtonLabel}
          initWays={numberWays}
          actionConfirm={(ways) => {
            changeNumbersOfWays(ways);
            setShowWaysModal(false);
          }}
          cancelText="Cancel"
          actionCancel={() => {
            setShowWaysModal(false);
          }}
        />
      )}
    </>
  );
};

export default CircuitsActions;
