import styled from 'styled-components';

export const DashContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: max-content;
  overflow: hidden;
  min-width: 768px;
  max-width: 1360px;
  padding: 72px 40px 40px 40px;
`;

export const ScrollContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: auto;
  justify-content: center;
  margin-bottom: 25px;
`;

export const InfoCardsContainer = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 25px;
  width: 100%;
  height: 164px;
`;

export const CertificateContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 435px;
  min-height: 435px;
  margin-top: 40px;
  border-radius: 15px;
  border: 1px solid rgba(226, 241, 239, 1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
`;

export const CertificateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 95px;
  padding: 20px;
  span:nth-child(1) {
    color: rgba(55, 15, 8, 1);
    font-family: lato;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    margin-right: 20px;
  }
`;

export const CertificateBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 435px;
`;

export const CreateButtonContainer = styled.div`
  display: flex;
  gap: 18px;
  overflow: auto;
  max-width: 1000px;
`;
